import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import InfiniteScroll from "react-infinite-scroll-component";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import classnames from 'classnames';


// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
  uuid: '',
  jenis_pembayaran: '',
}

class Produk extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_detail: [],
      foto_produk: [],
      pagination: [],
      status: {
        form: false,
        formDetail: false,
        btnForm: false,
        btnAksi: false,
        btnAksi22: false,
        btnAksiUnggulan: false,
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Toko",
      alert: null,
      show: false,
      basicTitle: '',
      basicType: "default",
      pesanAlert: '',

      // Data Etalase
      uuid_jenis_etalase1: '',
      uuid_jenis_etalase2: '',
      get_data_jenis_etalase1: [],
      get_data_jenis_etalase2: [],

      // produk proses
      set_idProduk: [],
      set_idProduk22: [],
      set_idProdukUnggulan: [],
      set_idProdukUnggulanList: [],
      data_produk: [],
      pageNumber: null,
      hasMore: '',
      style_class: {
        button: 'btn btn-default btn-sm',
      },

      style_class22: {
        button: 'btn btn-default btn-sm',
      },

      style_class_unggulan: {
        button: 'btn btn-danger btn-sm',
        label: 'Tidak',
        icon: "fa fa-times",
      },

      style_class_unggulanList: {
        button: 'btn btn-success btn-sm',
        label: 'Tidak',
        icon: "fa fa-times",
      },

      // produk tayang
      set_idProduk2: [],
      data_produk2: [],
      pageNumber2: null,
      hasMore2: '',
      style_class2: {
        button: 'btn btn-success btn-sm',
      },

      // produk tolak tayang
      set_idProduk3: [],
      data_produk3: [],
      pageNumber3: null,
      hasMore3: '',
      style_class3: {
        button: 'btn btn-success btn-sm',
      },

      // produk unggulan
      set_idProduk4: [],
      data_produk4: [],
      pageNumber4: null,
      hasMore4: '',
      style_class4: {
        button: 'btn btn-success btn-sm',
      },

      //Tab Pane
      active_tab_icon: '1',

      // Search

      name: '',
      name2: '',
      name3: '',
      name4: '',

    };
    this.pilihProdukUnggulan = this.pilihProdukUnggulan.bind(this);
    this.pilihProdukUnggulanList = this.pilihProdukUnggulanList.bind(this);
  }

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      this.setState({
        active_tab_icon: tab
      });
    }
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  }

  onRecieveInput = (value) => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  }
  // =================================================

  fetch = (params = {}) => {
    let link = "";
    let where = [];
    let id_category = null;
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
        case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
        case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
        case '<<': link = "?page=1"; break;
        default: link = "?page=" + params.page; break;
      }
    }

  }

  // Pagination
  pagination = (data, type = null) => {
    // const data = this.state.pagination;
    // console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      }
      else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger"
        }
        if (type == null) {
          hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        } else {
          hal.push(<a key={i} onClick={() => this.fetch({ page: i, category: type })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        }

      }
      if (p > 0) {
        if (type == null) {
          sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        } else {
          sebelum = (<a onClick={() => this.fetch({ page: p, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        }

      }
      if (n > 0) {
        if (type == null) {
          setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        } else {
          setelah = (<a onClick={() => this.fetch({ page: n, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        }

      }

      if (type == null) {
        awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
      } else {
        awal = (<a onClick={() => this.fetch({ page: 1, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhir = (<a onClick={() => this.fetch({ page: data.last_page, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
      }

    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  }


  detailData = (data) => {
    this.state.status.formDetail = true;
    this.state.judul = "Detail Produk";
    this.forceUpdate();


    console.log("PARSING DATA DETAIL PRODUKNYA")
    console.log(data)


    var bukti = data.foto_produk
    var data_foto = JSON.parse(bukti);

    // console.log("FINAL FOTO")
    // console.log(data_foto)
    var cek_nama_lengkap = data.data_toko.data_pengguna.nama_lengkap;
    if (cek_nama_lengkap == "") {
      var nama_pengguna = data.data_toko.data_pengguna.username;
    }
    else {
      var nama_pengguna = data.data_toko.data_pengguna.nama_lengkap;
    }

    this.state.data_detail.id_toko = data.id_toko;
    this.state.data_detail.nama_produk = data.nama_produk;
    this.state.data_detail.nama_toko = data.data_toko.nama_toko;
    this.state.data_detail.nama_pengguna = nama_pengguna;
    this.state.data_detail.foto_produk = data.foto_produk;
    this.state.data_detail.deskripsi = data.deskripsi;
    this.state.data_detail.selalu_tersedia = data.selalu_tersedia;
    this.state.data_detail.stok = data.stok;
    // this.state.data_detail.is_active = data.is_active;
    this.state.data_detail.is_active = {
      value: data.is_active, label: (
        data.is_active == '0' ? 'Aktif'
          : data.is_active == '1' ? 'Tidak Aktif'
            : data.is_active == '2' ? 'Blokir'
              : ''
      )
    };

    this.state.data_detail.status_unggulan = {
      value: data.status_unggulan, label: (
        data.status_unggulan == 'Ya' ? 'Ya'
          : data.status_unggulan == 'Tidak' ? 'Tidak'
            : ''
      )
    };

    this.state.data_detail.diskon = data.diskon;
    this.state.data_detail.harga_produk = data.harga_produk;

    this.state.foto_produk = data_foto;
    this.state.data_detail.berat = data.berat;
    this.state.data_detail.tanggal = data.created_at;
    this.state.data_detail.status = data.status;
    this.state.data_detail.id_produk = data.uuid;
    this.state.data_detail.jenis_etalase = data.data_jenis_etalase.jenis_etalase;
    this.state.data_detail.etalase_tayang = data.data_jenis_etalase.uuid
    this.state.uuid_jenis_etalase2 = { value: data.data_jenis_etalase.uuid, label: data.data_jenis_etalase.jenis_etalase }

    var myStr = data.data_jenis_etalase.kode_jenis_etalase
    var strArray = myStr.split("-");


    Get('jenis-etalase/with-level?level=1&kode=' + strArray[0], null, (data) => {
      if (data.result) {
        this.setState({
          uuid_jenis_etalase1: { value: data.result[0].uuid, label: data.result[0].jenis_etalase },
        });
      }

      Get('jenis-etalase/with-level?level=2&kode=' + data.result[0].kode_jenis_etalase, null, (data2) => {
        if (data2.result) {
          this.setState({
            get_data_jenis_etalase2: data2.result
          });
        }
      });
    });



  }




  // Cari Data Table
  cariData = e => {
    if (e) {
      let addToko = {
        q: e.target.value,
      };
      // Post('toko', null, addToko, (data) => {
      //   if (data.data.result) {
      //     this.setState({ data: data.data.result.data, pagination: data.data.result });
      //   }
      // });
    } else {
      // Get('toko', null, (lihat) => {
      //   if (lihat.result) {
      //     this.setState({ data: lihat.result.data, pagination: lihat.result });
      //     this.pagination(lihat.result);
      //   }
      // });
    }
  }


  // ===========================================================================
  // ============================ SET PRODUK UNGGULAN ==========================
  // ===========================================================================

  pilihProdukUnggulan = (id) => {

    let newArrayTayang = this.state.set_idProduk

    newArrayTayang = newArrayTayang.filter(day => day == id);
    // console.log("Hasil Filter")
    // console.log(newArrayTayang)
    if (newArrayTayang == [] || newArrayTayang == "" || newArrayTayang == null) {
      this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Produk Unggulan', pesanAlert: 'Produk Unggulan Hanya Berlaku Pada Produk Tayang' });
    }
    else {
      // console.log("Data Ketemu")
      // console.log("Produk Unggulan: " + id)
      let newArray = [...this.state.set_idProdukUnggulan, id];

      if (this.state.set_idProdukUnggulan.includes(id)) {
        newArray = newArray.filter(day => day !== id);

        var kondisi = document.getElementById("unggulan" + id);
        kondisi.classList.remove("btn-success")
        kondisi.classList.add("btn-danger")

      }
      else {

        var kondisi = document.getElementById("unggulan" + id);
        kondisi.classList.remove("btn-danger")
        kondisi.classList.add("btn-success")

      }

      this.setState({
        set_idProdukUnggulan: newArray,
        style_class_unggulan: {
          button: 'btn btn-success btn-sm',
          label: 'Ya',
          icon: "fa fa-check",
        },
      });
    }

    this.forceUpdate()

    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }



  pilihProdukUnggulanList = (id) => {

    let newArrayTayang = this.state.set_idProduk

    newArrayTayang = newArrayTayang.filter(day => day == id);

    let newArray = [...this.state.set_idProdukUnggulanList, id];

    if (this.state.set_idProdukUnggulanList.includes(id)) {
      newArray = newArray.filter(day => day !== id);

      var kondisi = document.getElementById("unggulan" + id);
      kondisi.classList.remove("btn-danger")
      kondisi.classList.add("btn-success")

    }
    else {

      var kondisi = document.getElementById("unggulan" + id);
      kondisi.classList.remove("btn-success")
      kondisi.classList.add("btn-danger")

    }

    this.setState({
      set_idProdukUnggulanList: newArray,
      style_class_unggulanList: {
        button: 'btn btn-success btn-sm',
        label: 'Ya',
        icon: "fa fa-check",
      },
    });

    this.forceUpdate()

    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }

  // ===========================================================================
  // ========================== END SET PRODUK UNGGULAN ========================
  // ===========================================================================

  // ===========================================================================
  // ============================ SET PRODUK SELECTED ==========================
  // ===========================================================================
  pilihProduk = (id) => {
    let newArray = [...this.state.set_idProduk, id];
    let newArray22 = this.state.set_idProduk22;

    if (this.state.set_idProduk.includes(id)) {
      newArray = newArray.filter(day => day !== id);

      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-success")
      kondisi.classList.add("btn-default")

      var kondisi2 = document.getElementById("silent" + id);
      kondisi2.classList.remove("btn-danger")
      kondisi2.classList.add("btn-default")

    }
    else {
      if (this.state.set_idProduk22.includes(id)) {
        newArray22 = newArray22.filter(day => day !== id);
      }

      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-default")
      kondisi.classList.add("btn-success")

      var kondisi2 = document.getElementById("silent" + id);
      kondisi2.classList.remove("btn-danger")
      kondisi2.classList.add("btn-default")

    }

    this.setState({
      set_idProduk: newArray,
      set_idProduk22: newArray22,
      style_class: { button: 'btn btn-success btn-sm' },
    });


    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }

  pilihProduk22 = (id) => {
    let newArray = this.state.set_idProduk;
    let newArray22 = [...this.state.set_idProduk22, id];

    if (this.state.set_idProduk22.includes(id)) {
      newArray22 = newArray22.filter(day22 => day22 !== id);

      var kondisi = document.getElementById("silent" + id);
      kondisi.classList.remove("btn-danger")
      kondisi.classList.add("btn-default")

      var kondisi2 = document.getElementById(id);
      kondisi2.classList.remove("btn-success")
      kondisi2.classList.add("btn-default")

    }
    else {
      if (this.state.set_idProduk.includes(id)) {
        newArray = newArray.filter(day => day !== id);
      }

      var kondisi = document.getElementById("silent" + id);
      kondisi.classList.remove("btn-default")
      kondisi.classList.add("btn-danger")

      var kondisi2 = document.getElementById(id);
      kondisi2.classList.remove("btn-success")
      kondisi2.classList.add("btn-default")

    }

    this.setState({
      set_idProduk22: newArray22,
      set_idProduk: newArray,
      style_class22: { button: 'btn btn-danger btn-sm' },
    });


    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }

  pilihProduk2 = (id) => {
    let newArray = [...this.state.set_idProduk2, id];

    if (this.state.set_idProduk2.includes(id)) {
      newArray = newArray.filter(day => day !== id);
      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-danger")
      kondisi.classList.add("btn-success")
    }
    else {
      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-success")
      kondisi.classList.add("btn-danger")
    }

    this.setState({
      set_idProduk2: newArray,
      style_class2: { button: 'btn btn-danger btn-sm' },
    });


    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }

  // pilihProduk3 = (id) => {
  //   let newArray = [...this.state.set_idProduk3, id];

  //   if (this.state.set_idProduk3.includes(id)) {
  //     newArray = newArray.filter(day => day !== id);
  //     var kondisi = document.getElementById(id);
  //     kondisi.classList.remove("btn-danger")
  //     kondisi.classList.add("btn-success")
  //   }
  //   else {
  //     var kondisi = document.getElementById(id);
  //     kondisi.classList.remove("btn-success")
  //     kondisi.classList.add("btn-danger")
  //   }

  //   this.setState({
  //     set_idProduk3: newArray,
  //     style_class3: { button: 'btn btn-danger btn-sm' },
  //   });
  // }

  // ===========================================================================
  // ============================ END SET PRODUK SELECTED ======================
  // ===========================================================================	  


  // ===========================================================================
  // ============================ SIMPAN DATA SELECTED =========================
  // ===========================================================================	  

  simpan = e => {
    e.preventDefault();
    this.forceUpdate();

    let newArray = this.state.set_idProduk
    let newArray2 = this.state.set_idProduk22
    let newArrayUnggulan = this.state.set_idProdukUnggulan
    let newArrayUnggulanList = this.state.set_idProdukUnggulanList
    let addProduk = []
    let allProduk = []
    let status_unggulan = []
    let id = null

    for (var i = 0; i < newArray2.length; i++) {

      let addProdukTolak = {
        status: 'Tolak',
        id_produk: newArray2[i],
        status_unggulan: "Tidak"
      };

      let psn = 'Tolak Verifikasi';
      let resstat = 200;
      let metode = "create";

      Post('verifikasi-produk/status', id, addProdukTolak, (res) => {
        this.forceUpdate();
        this.componentDidMount();
      });

    }

    newArray.map(dt => {
      let psn = 'Dilakukan';
      let resstat = 200;
      let metode = "create";

      let filterProdukUnggul = newArrayUnggulan.filter(produkUnggul => produkUnggul == dt);
      if (dt == filterProdukUnggul) {

        addProduk = {
          status: 'Tayang',
          id_produk: dt,
          status_unggulan: "Ya"
        };

        allProduk = [...allProduk, addProduk]
        Post('verifikasi-produk/status', id, addProduk, (res) => {
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({ set_idProduk: [], set_idProduk22: [], pageNumber: 0, show: true, basicType: 'success', basicTitle: 'Verifikasi Data Produk', pesanAlert: 'Berhasil ' + psn + ' Data' });
          }
          else {
            this.setState({ set_idProduk: [], set_idProduk22: [], pageNumber: 0, show: true, basicType: 'danger', basicTitle: 'Verifikasi Data Produk', pesanAlert: 'Gagal ' + psn + ' Data' });
          }
          this.componentDidMount();
        });
      }
      else {

        addProduk = {
          status: 'Tayang',
          id_produk: dt,
          status_unggulan: "Tidak"
        };

        allProduk = [...allProduk, addProduk]

        Post('verifikasi-produk/status', id, addProduk, (res) => {
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({ set_idProduk: [], set_idProduk22: [], pageNumber: 0, show: true, basicType: 'success', basicTitle: 'Verifikasi Data Produk', pesanAlert: 'Berhasil ' + psn + ' Data' });
          }
          else {
            this.setState({ set_idProduk: [], set_idProduk22: [], pageNumber: 0, show: true, basicType: 'danger', basicTitle: 'Verifikasi Data Produk', pesanAlert: 'Gagal ' + psn + ' Data' });
          }
          this.componentDidMount();
        });
      }
    });

    console.log("Simpan Seluruh Data Tayang")
    console.log(allProduk)


  }

  simpanUnggulanList = e => {
    e.preventDefault();
    // this.state.status.btnForm = true;
    this.forceUpdate();

    let newArrayUnggulanList = this.state.set_idProdukUnggulanList

    let id = null
    let psn = "Ubah";
    let resstat = 200;
    let metode = "update";

    for (var i = 0; i < newArrayUnggulanList.length; i++) {

      let addProduk = {
        id_produk: newArrayUnggulanList[i],
        status: 'Tayang',
        status_unggulan: "Tidak"
      };


      Post('verifikasi-produk/status', id, addProduk, (res) => {
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({ set_idProdukUnggulanList: [], pageNumber: 0, show: true, basicType: 'success', basicTitle: 'Data Produk Unggulan', pesanAlert: 'Berhasil ' + psn + ' Data' });
        }
        else {
          this.setState({ set_idProdukUnggulanList: [], pageNumber: 0, show: true, basicType: 'danger', basicTitle: 'Data Produk Unggulan', pesanAlert: 'Gagal ' + psn + ' Data' });
        }
        this.componentDidMount();
      });

      console.log("Data Simpan")
      console.log(addProduk)

      // Post('produk/' + metode, addProduk.id_produk, addProduk, (res) => {
      //   this.forceUpdate();
      //   if (res.status === resstat) {
      //     this.setState({ show: true, basicType: 'success', basicTitle: 'Data Produk Unggulan', pesanAlert: 'Berhasil ' + psn + ' Data' });
      //   }
      //   else {
      //     this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Produk Unggulan', pesanAlert: 'Gagal ' + psn + ' Data' });
      //   }
      //   this.componentDidMount();
      // });


    }
  }


  simpan2 = e => {
    e.preventDefault();
    this.forceUpdate();

    let newArray = this.state.set_idProduk2

    for (var i = 0; i < newArray.length; i++) {

      var id = null;

      let addProduk = {
        status: 'Tolak',
        id_produk: newArray[i],
      };

      let psn = 'Tolak Verifikasi';
      let resstat = 200;
      let metode = "create";

      Post('verifikasi-produk/status', id, addProduk, (res) => {
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({ pageNumber: 0, show: true, basicType: 'success', basicTitle: 'Data Produk', pesanAlert: 'Berhasil ' + psn + ' Data' });
        }
        else {
          this.setState({ pageNumber: 0, show: true, basicType: 'danger', basicTitle: 'Data Produk', pesanAlert: 'Gagal ' + psn + ' Data' });
        }
        this.componentDidMount();
      });

    }

  }


  simpan3 = e => {
    e.preventDefault();
    this.forceUpdate();

    let newArray = this.state.set_idProduk3

    for (var i = 0; i < newArray.length; i++) {

      var id = null;

      let addProduk = {
        status: 'Tayang',
        id_produk: newArray[i],
      };

      let psn = 'Verifikasi Tayang';
      let resstat = 200;

      Post('verifikasi-produk/status', id, addProduk, (res) => {
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({ pageNumber: 0, show: true, basicType: 'success', basicTitle: 'Data Produk', pesanAlert: 'Berhasil ' + psn + ' Data' });
        }
        else {
          this.setState({ pageNumber: 0, show: true, basicType: 'danger', basicTitle: 'Data Produk', pesanAlert: 'Gagal ' + psn + ' Data' });
        }
        this.componentDidMount();
      });

    }

  }


  // ===========================================================================
  // ============================ END SIMPAN DATA SELECTED =====================
  // ===========================================================================


  // ===========================================================================
  // ============================ SIMPAN DATA KONFIRMASI =======================
  // ===========================================================================

  simpan_konfirmasi = e => {
    e.preventDefault();
    // this.state.status.btnForm = true;
    this.forceUpdate();

    if (this.state.data_detail.status == "Tayang") {
      var status_is = this.state.data_detail.is_active.value
      var status_unggulan = this.state.data_detail.status_unggulan.value
      var id_jenis = this.state.data_detail.etalase_tayang
    }
    else {
      var status_is = document.getElementById("is_active").value
      if (this.state.uuid_jenis_etalase2 != "") {
        var id_jenis = this.state.uuid_jenis_etalase2
      }
      else {
        var id_jenis = this.state.uuid_jenis_etalase1
      }
    }


    let addJenisEtalase = {
      uuid: document.getElementById("uuid_produk").value,
      id_toko: document.getElementById("id_toko").value,
      nama_produk: document.getElementById("nama_produk").value,
      foto_produk: document.getElementById("foto_produk").value,
      deskripsi: document.getElementById("deskripsi").value,
      selalu_tersedia: document.getElementById("selalu_tersedia").value,
      stok: document.getElementById("stok").value,
      is_active: status_is,
      status_unggulan: status_unggulan,
      diskon: document.getElementById("diskon").value,
      harga_produk: document.getElementById("harga_produk").value,
      id_jenis_etalase: id_jenis,
    };


    let psn = "Ubah";
    let resstat = 200;
    let metode = "update";



    Post('produk/' + metode, addJenisEtalase.uuid, addJenisEtalase, (res) => {
      this.state.status.btnForm = false;
      this.state.status.formDetail = false;
      this.forceUpdate();
      if (res.status === resstat) {
        this.setState({ show: true, basicType: 'success', basicTitle: 'Data Jenis Etalase Produk', pesanAlert: 'Berhasil ' + psn + ' Data' });
      }
      else {
        this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Jenis Etalase Produk', pesanAlert: 'Gagal ' + psn + ' Data' });
      }
      this.componentDidMount();
    });
  }

  // ===========================================================================
  // ========================== END SIMPAN DATA KONFIRMASI =====================
  // ===========================================================================

  ubahData = (id) => {
    this.state.status.btnAksi = true;
    this.state.judul = "Ubah Data Produk";
    this.forceUpdate();

    Get('toko/find', id, (data) => {
      if (data.result) {
        this.state.data_toko.id_toko = id;
        this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
        this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
        this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

        this.state.data_toko.nama_toko = data.result.nama_toko;
        this.state.data_toko.telepon_toko = data.result.telepon;
        this.state.data_toko.alamat_toko = data.result.alamat;
        this.state.data_toko.lat_toko = data.result.lat;
        this.state.data_toko.lang_toko = data.result.lang;
        this.state.data_toko.jam_buka_toko = data.result.jam_buka;
        this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
        this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
      }
      this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm('edit');
    });
  }

  konfirmHapus = (id) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  }

  hapusData = (id) => {
    this.hideAlert();
    let psn = "Hapus";
    Delete('toko/delete', id, (res) => {
      if (res == 200) {
        this.setState({ show: true, basicType: 'success', basicTitle: 'Data Produk', pesanAlert: 'Berhasil ' + psn + ' Data' });
      }
      else {
        this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Produk', pesanAlert: 'Gagal ' + psn + ' Data' });
      }
      this.componentDidMount();
    })
  }

  bukaForm = (e) => {
    if (e == 'tambah') {
      this.state.data_toko.id_toko = '';
      this.state.id_kategori_usaha = '';
      this.state.id_desa = '';
      this.state.id_pengguna = '';
      this.state.data_toko.nama_toko = '';
      this.state.data_toko.telepon_toko = '';
      this.state.data_toko.alamat_toko = '';
      this.state.data_toko.lat_toko = '';
      this.state.data_toko.lang_toko = '';
      this.state.data_toko.jam_buka_toko = '';
      this.state.data_toko.jam_tutup_toko = '';
      this.state.id_status_toko = '';
      this.state.status.form = true;
      this.forceUpdate();
    } else if (e == 'edit') {
      this.state.status.form = true;
      this.forceUpdate();
    }

  }

  tutupForm = () => {
    this.state.status.form = false;
    this.state.status.formDetail = false;
    this.forceUpdate();
  }

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
        <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
      </>
    )
  }

  tombolAksiDetail = (cell, row) => {
    return (
      <>
        <button onClick={() => this.detailData(row)} color="primary" className="btn btn-primary"><i className="fa fa-eye"></i>  Detail</button>
      </>
    )
  }

  tombolAksiUnggulan = (cell, row) => {
    return (
      <>
        <button type="button" style={{ width: 120 }} id={"unggulan" + row.uuid} className={this.state.style_class_unggulan.button} onClick={() => this.pilihProdukUnggulan(row.uuid)} ><i className="fa fa-thumbs-o-up"></i> Unggulan</button>
      </>
    )
  }

  tombolAksiUnggulanList = (cell, row) => {
    return (
      <>
        <button type="button" style={{ width: 120 }} id={"unggulan" + row.uuid} className={this.state.style_class_unggulanList.button} onClick={() => this.pilihProdukUnggulanList(row.uuid)} ><i className="fa fa-thumbs-o-up"></i> Unggulan</button>
      </>
    )
  }

  tombolAksi2 = (cell, row) => {
    return (
      <>
        <button type="button" style={{ width: 120 }} id={row.uuid} className={this.state.style_class.button} onClick={() => this.pilihProduk(row.uuid)} disabled={this.state.status.btnAksi2}><i className="fa fa-check"></i> Tayang</button>
      </>
    )
  }

  tombolAksi22 = (cell, row) => {
    return (
      <>
        <button type="button" style={{ width: 120 }} id={"silent" + row.uuid} className={this.state.style_class22.button} onClick={() => this.pilihProduk22(row.uuid)} disabled={this.state.status.btnAksi22}><i className="fa fa-times"></i> Tolak</button>
      </>
    )
  }


  tombolAksi3 = (cell, row) => {
    return (
      <>
        <button type="button" id={row.uuid} className={this.state.style_class2.button} onClick={() => this.pilihProduk2(row.uuid)} disabled={this.state.status.btnAksi3}><i className="fa fa-check"></i></button>
      </>
    )
  }

  // tombolAksi4 = (cell, row) => {
  //   return (
  //     <>
  //       <button type="button" id={row.uuid} className={this.state.style_class3.button} onClick={() => this.pilihProduk3(row.uuid)} disabled={this.state.status.btnAksi4}><i className="fa fa-check"></i></button>
  //     </>
  //   )
  // }

  componentDidMount() {
    this.fetch({ page: null, where: [] });

    // DATA JENIS ETALASE

    Get('jenis-etalase/with-level?level=1', null, (data) => {
      // console.log("Data Jenis Etalasenya")
      // console.log(data)
      if (data.result) {
        this.setState({
          get_data_jenis_etalase1: data.result
        });
      }
    });


    let params = null

    this.state.pageNumber = 1

    Post(`verifikasi-produk/proses?page=${this.state.pageNumber}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POSTNYA")
        // console.log(data.data.result)
        this.state.data_produk = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk: data.data.result.data,
          hasMore: status_hasMore,
          pageNumber: 1,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
          style_class_unggulan: { button: 'btn btn-danger btn-sm' },
        });
      }
    });


    this.state.pageNumber2 = 1

    Post(`verifikasi-produk/tayang?page=${this.state.pageNumber2}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POSTNYA")
        // console.log(data.data.result)
        this.state.data_produk2 = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk2: data.data.result.data,
          hasMore2: status_hasMore,
          pageNumber2: 1,
          style_class2: { button: 'btn btn-success btn-sm' },
        });
      }
    });

    this.state.pageNumber3 = 1

    Post(`verifikasi-produk/tolak?page=${this.state.pageNumber3}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POST 3 NYA")
        // console.log(data.data.result)
        this.state.data_produk3 = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk3: data.data.result.data,
          hasMore3: status_hasMore,
          pageNumber3: 1,
          style_class3: { button: 'btn btn-success btn-sm' },
        });
      }
    });


    this.state.pageNumber4 = 1

    Post(`verifikasi-produk/unggulan?page=${this.state.pageNumber4}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POSTNYA")
        // console.log(data.data.result)
        this.state.data_produk4 = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk4: data.data.result.data,
          hasMore4: status_hasMore,
          pageNumber4: 1,
          style_class4: { button: 'btn btn-success btn-sm' },
        });
      }
    });


  }

  // =============================================================================
  // ===============================LOAD DATA SCROLL==============================
  // =============================================================================
  fetchMoreData = () => {

    let newpageNumber = this.state.pageNumber + 1

    this.setState({ pageNumber: newpageNumber });

    let params = null

    Post(`verifikasi-produk/proses?page=${this.state.pageNumber}`, null, params, (data) => {

      if (newpageNumber >= data.data.result.last_page) {
        this.setState({
          data_produk: [...this.state.data_produk, ...data.data.result.data],
          hasMore: false,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
          style_class_unggulan: { button: 'btn btn-danger btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk: [...this.state.data_produk, ...data.data.result.data],
            pageNumber: newpageNumber,
            style_class: { button: 'btn btn-default btn-sm' },
            style_class22: { button: 'btn btn-default btn-sm' },
            style_class_unggulan: { button: 'btn btn-danger btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }

  fetchMoreData2 = () => {

    let newpageNumber2 = this.state.pageNumber2 + 1

    this.setState({ pageNumber2: newpageNumber2 });

    let params = null

    Post(`verifikasi-produk/tayang?page=${this.state.pageNumber2}`, null, params, (data) => {

      if (newpageNumber2 >= data.data.result.last_page) {
        this.setState({
          data_produk2: [...this.state.data_produk2, ...data.data.result.data],
          hasMore2: false,
          style_class2: { button: 'btn btn-success btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk2: [...this.state.data_produk2, ...data.data.result.data],
            pageNumber2: newpageNumber2,
            style_class2: { button: 'btn btn-success btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }


  fetchMoreData3 = () => {

    let newpageNumber3 = this.state.pageNumber3 + 1

    this.setState({ pageNumber3: newpageNumber3 });

    let params = null

    Post(`verifikasi-produk/tolak?page=${this.state.pageNumber3}`, null, params, (data) => {

      if (newpageNumber3 >= data.data.result.last_page || data.data.result.last_page == 1) {
        this.setState({
          data_produk3: [...this.state.data_produk3, ...data.data.result.data],
          hasMore3: false,
          style_class3: { button: 'btn btn-success btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk3: [...this.state.data_produk3, ...data.data.result.data],
            pageNumber3: newpageNumber3,
            style_class3: { button: 'btn btn-success btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }


  fetchMoreData4 = () => {

    let newpageNumber4 = this.state.pageNumber4 + 1

    this.setState({ pageNumber4: newpageNumber4 });

    let params = null

    Post(`verifikasi-produk/unggulan?page=${this.state.pageNumber4}`, null, params, (data) => {

      if (newpageNumber4 >= data.data.result.last_page) {
        this.setState({
          data_produk4: [...this.state.data_produk4, ...data.data.result.data],
          hasMore4: false,
          style_class4: { button: 'btn btn-success btn-sm' },
          style_class_unggulanList: { button: 'btn btn-success btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk4: [...this.state.data_produk4, ...data.data.result.data],
            pageNumber4: newpageNumber4,
            style_class4: { button: 'btn btn-success btn-sm' },
            style_class_unggulanList: { button: 'btn btn-success btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }

  // =============================================================================
  // ===============================END LOAD DATA SCROLL==============================
  // =============================================================================


  // =============================================================================
  // ===============================JENIS ETALASE=================================
  // =============================================================================

  ChangeLevel1 = (e) => {
    if (e) {
      var myStr = e.target.value
      this.state.get_data_jenis_etalase2 = []
      this.state.uuid_jenis_etalase2 = ''

      this.setState({ uuid_jenis_etalase1: e.target.value });
      if (e.target.value == '') {
        this.setState({
          get_data_jenis_etalase1: [],
          get_data_jenis_etalase2: [],
          uuid_jenis_etalase2: '',
        });
      }
      else {
        Get('jenis-etalase/find/' + e.target.value, null, (data) => {

          Get('jenis-etalase/with-level?level=2&kode=' + data.result.kode_jenis_etalase, null, (data) => {
            if (data.result) {
              this.setState({
                get_data_jenis_etalase2: data.result,
              });
            }
          });
        });
      }
    }
    else {
      this.setState({ uuid_jenis_etalase1: '' });
    }
  }



  ChangeLevel2 = (e) => {
    if (e) {

      this.setState({
        uuid_jenis_etalase2: e.target.value,
      });

    }
    else {
      this.setState({ uuid_jenis_etalase2: '' });
    }
  }


  // =============================================================================
  // ============================END JENIS ETALASE================================
  // =============================================================================


  // Making dynamic function
  onChangeEtalase = (e) => {
    if (e) {
      // console.log(e);
      this.state.id_jenis_etalase = { value: e.value, label: e.label };
      this.setState({ id_jenis_etalase: { value: e.value, label: e.label } });
      this.fetch({ page: null, where: [], category: this.state.id_jenis_etalase.value });
      // console.log(this.state.id_jenis_etalase);
    } else {
      this.setState({ id_jenis_etalase: '' });
      this.fetch({ page: null, where: [] });
    }
  }
  getEtalase = (e) => {
    if (e) {
      let find_data = {
        q: e
      }
      Post('jenis-etalase', null, find_data, (data) => {
        if (data.data.result) {
          let data_etalase = [];
          data.data.result.data.map(dt => {
            data_etalase.push({ value: dt.uuid, label: dt.jenis_etalase });
          });
          this.setState({ data_etalase });
        }
      });
    } else {
      Get('jenis-etalase', null, data => {
        if (data.result) {
          let data_etalase = [];
          data.result.data.map(dt => {
            data_etalase.push({ value: dt.uuid, label: dt.jenis_etalase });
          });
          this.setState({ data_etalase });
        }
      });
    }
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault();

    let addProduk = {
      q: this.state.name,
    };


    this.state.data_produk = [];

    Post('verifikasi-produk/proses', null, addProduk, (data) => {

      if (data.data.result) {

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk: data.data.result.data,
          hasMore: status_hasMore,
          pageNumber: 1,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
        });
      }
    });
  }

  handleChange2 = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmit2 = (e) => {
    e.preventDefault();

    let addProduk = {
      q: this.state.name2,
    };


    this.state.data_produk2 = [];

    Post('verifikasi-produk/tayang', null, addProduk, (data) => {

      if (data.data.result) {

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk2: data.data.result.data,
          hasMore2: status_hasMore,
          pageNumber2: 1,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
        });
      }
    });
  }

  handleChange3 = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmit3 = (e) => {
    e.preventDefault();

    let addProduk = {
      q: this.state.name3,
    };


    this.state.data_produk3 = [];

    Post('verifikasi-produk/tolak', null, addProduk, (data) => {

      if (data.data.result) {

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk3: data.data.result.data,
          hasMore3: status_hasMore,
          pageNumber3: 1,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
        });
      }
    });
  }

  handleChange4 = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmit4 = (e) => {
    e.preventDefault();

    let addProduk = {
      q: this.state.name4,
    };


    this.state.data_produk4 = [];

    Post('verifikasi-produk/unggulan', null, addProduk, (data) => {

      if (data.data.result) {

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk4: data.data.result.data,
          hasMore4: status_hasMore,
          pageNumber4: 1,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
        });
      }
    });
  }

  onChangeStatus = (e) => {
    if (e) {
      this.state.data_detail.is_active = { value: e.value, label: e.label };
      this.forceUpdate();
    }
    else {
      this.state.data_detail.is_active = '';
      this.forceUpdate();
    }
  }

  onChangeStatusUnggulan = (e) => {
    if (e) {
      this.state.data_detail.status_unggulan = { value: e.value, label: e.label };
      this.forceUpdate();
    }
    else {
      this.state.data_detail.status_unggulan = '';
      this.forceUpdate();
    }
  }



  render() {
    console.log("Produk UNggulannya")
    console.log(this.state.set_idProdukUnggulanList)
    // console.log("Warna")
    // console.log(this.state.style_class_unggulan.button)
    // console.log("Produk UNggulannya")
    // console.log(this.state.set_idProdukUnggulan)
    // console.log("COBA WORK");
    // console.log(this.state.set_idProduk);
    // console.log(this.state.set_idProduk22);
    // console.log(this.state.set_idProduk2);
    // console.log(this.state.set_idProduk3);
    // console.log("FOTO HAHA")
    // console.log(this.state.foto_produk)
    // console.log("Level 1")
    // console.log(this.state.uuid_jenis_etalase1)
    // console.log(this.state.get_data_jenis_etalase1)
    // console.log("Level 2")
    // console.log(this.state.uuid_jenis_etalase2)
    // console.log(this.state.get_data_jenis_etalase2)


    // const foto_set = []

    // for (const [index, value] of data_detail_foto.entries()) {
    //   foto_set.push(<img src={value} alt="" id={index}/>)
    // }

    var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    // var data = this.state.data;
    var data = this.state.data_produk;
    var data2 = this.state.data_produk2;
    var data3 = this.state.data_produk3;
    var data4 = this.state.data_produk4;

    const frmDef = {
      uuid: '',
      jenis_pembayaran: '',
    }
    const { SearchBar } = Search;

    const columns = [
      {
        dataField: 'created_at',
        text: 'Diinputkan pada tanggal',
        // sort: true
      },
      {
        dataField: 'nama_produk',
        text: 'Nama Produk',
        // sort: true
      },
      {
        dataField: 'data_jenis_etalase.jenis_etalase',
        text: 'Jenis Etalase',
        // sort: true
      },
      {
        dataField: 'status',
        text: 'Status Verifikasi',
        // sort: true
      },
      {
        dataField: 'aksidetail',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },

      {
        dataField: 'aksi',
        text: 'Verifikasi Tayang',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi2,
      },
      {
        dataField: 'aksii',
        text: 'Verifikasi Tolak Tayang',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi22,
      },
      {
        dataField: 'aksiii',
        text: 'Produk Unggulan',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiUnggulan,
      },
    ];

    const columns2 = [
      {
        dataField: 'created_at',
        text: 'Diinputkan pada tanggal',
        // sort: true
      },
      {
        dataField: 'nama_produk',
        text: 'Nama Produk',
        // sort: true
      },
      {
        dataField: 'data_jenis_etalase.jenis_etalase',
        text: 'Jenis Etalase',
        // sort: true
      },
      {
        dataField: 'status',
        text: 'Status Verifikasi',
        // sort: true
      },
      // {
      //   dataField: 'aksiii',
      //   text: 'Produk Unggulan',
      //   isDummyField: true,
      //   csvExport: false,
      //   formatter: this.tombolAksiUnggulan,
      // },
      {
        dataField: 'aksidetail',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },
    ];

    const columns3 = [
      {
        dataField: 'created_at',
        text: 'Diinputkan pada tanggal',
        // sort: true
      },
      {
        dataField: 'nama_produk',
        text: 'Nama Produk',
        // sort: true
      },
      {
        dataField: 'data_jenis_etalase.jenis_etalase',
        text: 'Jenis Etalase',
        // sort: true
      },
      {
        dataField: 'status',
        text: 'Status Verifikasi',
        // sort: true
      },
      {
        dataField: 'aksidetail',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },
      // {
      //   dataField: 'aksi',
      //   text: 'Aksi verifikasi Tayang',
      //   isDummyField: true,
      //   csvExport: false,
      //   formatter: this.tombolAksi4,
      // },
    ];

    const columns4 = [
      {
        dataField: 'created_at',
        text: 'Diinputkan pada tanggal',
        // sort: true
      },
      {
        dataField: 'nama_produk',
        text: 'Nama Produk',
        // sort: true
      },
      {
        dataField: 'data_jenis_etalase.jenis_etalase',
        text: 'Jenis Etalase',
        // sort: true
      },
      {
        dataField: 'status',
        text: 'Status Verifikasi',
        // sort: true
      },
      {
        dataField: 'aksidetail',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },

      {
        dataField: 'aksiii',
        text: 'Produk Unggulan',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiUnggulanList,
      },
    ];

    const defaultSorted = [{
      dataField: 'jenis_pembayaran',
      order: 'asc'
    }];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = '';
      classname = 'btn btn-success';
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing { from} to { to} of { total} Result
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };

    return (
      <div>
        <Breadcrumb title="Data Produk" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  {/* <div className="row" style={{ marginBottom: "10px", display: 'flex', justifyContent: "space-between" }}> */}
                  <div className="row">
                    <div className="col-sm-12">
                      <Nav tabs className="tabs-color">
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.active_tab_icon === '1' })}
                            onClick={() => { this.toggle_icon('1'); }}
                          ><i className="icofont icofont-checked"></i>
                          Proses Verifikasi
                        </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
                            onClick={() => { this.toggle_icon('2'); }} >
                            <i className="icofont icofont-monitor"></i>Tayang
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
                            onClick={() => { this.toggle_icon('3'); }} >
                            <i className="icofont icofont-close"></i>Tolak Tayang
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({ active: this.state.active_tab_icon === '4' })}
                            onClick={() => { this.toggle_icon('4'); }} >
                            <i className="fa fa-thumbs-o-up"></i>Unggulan
                          </NavLink>
                        </NavItem>
                      </Nav>
                      {/* ===============================================================										 */}
                      <TabContent activeTab={this.state.active_tab_icon}>
                        <TabPane tabId="1">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                <div className="col-sm-12" align="right">
                                  <form>
                                    <label>
                                      <input
                                        name='name'
                                        value={this.state.name}
                                        onChange={e => this.handleChange(e)}
                                        style={{ marginBottom: "10px" }}
                                        className="form-control"
                                        placeholder="Ketikkan sesuatu..."
                                      />
                                    </label>
                                    <button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>
                                  </form>
                                </div>
                                <div><br /><br /><h2 align="center">Produk Dalam Proses Verifikasi</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk.length}
                                    next={this.fetchMoreData}
                                    hasMore={this.state.hasMore}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data}
                                      columns={columns}
                                    />
                                  </InfiniteScroll>
                                </div>
                                <br />
                                <div className="pull-right text-white">
                                  <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </TabPane>
                        {/* ==================================================================== */}
                        <TabPane tabId="2">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                <div className="col-sm-12" align="right">
                                  <form>
                                    <label>
                                      <input
                                        name='name2'
                                        value={this.state.name2}
                                        onChange={e => this.handleChange2(e)}
                                        style={{ marginBottom: "10px" }}
                                        className="form-control"
                                        placeholder="Ketikkan sesuatu..."
                                      />
                                    </label>
                                    <button onClick={(e) => this.onSubmit2(e)} color="primary" className="btn btn-primary">Cari Data</button>
                                  </form>
                                </div>
                                <div><br /><br /><h2 align="center">Produk Tayang</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv2" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk2.length}
                                    next={this.fetchMoreData2}
                                    hasMore={this.state.hasMore2}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv2"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data2}
                                      columns={columns2}
                                    />
                                  </InfiniteScroll>
                                </div>
                                <br />
                                {/* <div className="pull-right text-white">
                                  <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan2}>Simpan</button>
                                </div> */}
                              </Col>
                            </Row>

                          </div>
                        </TabPane>

                        {/* =========================================================================== */}
                        <TabPane tabId="3">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                <div className="col-sm-12" align="right">
                                  <form>
                                    <label>
                                      <input
                                        name='name3'
                                        value={this.state.name3}
                                        onChange={e => this.handleChange3(e)}
                                        style={{ marginBottom: "10px" }}
                                        className="form-control"
                                        placeholder="Ketikkan sesuatu..."
                                      />
                                    </label>
                                    <button onClick={(e) => this.onSubmit3(e)} color="primary" className="btn btn-primary">Cari Data</button>
                                  </form>
                                </div>
                                <div><br /><br /><h2 align="center">Produk Tolak Tayang</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv3" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk3.length}
                                    next={this.fetchMoreData3}
                                    hasMore={this.state.hasMore3}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv3"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data3}
                                      columns={columns3}
                                    />
                                  </InfiniteScroll>
                                  {/* <div className="pull-right text-white">
                                    <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan3}>Simpan</button>
                                  </div> */}
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </TabPane>

                        {/* =========================================================================== */}
                        <TabPane tabId="4">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                <div className="col-sm-12" align="right">
                                  <form>
                                    <label>
                                      <input
                                        name='name4'
                                        value={this.state.name4}
                                        onChange={e => this.handleChange4(e)}
                                        style={{ marginBottom: "10px" }}
                                        className="form-control"
                                        placeholder="Ketikkan sesuatu..."
                                      />
                                    </label>
                                    <button onClick={(e) => this.onSubmit4(e)} color="primary" className="btn btn-primary">Cari Data</button>
                                  </form>
                                </div>
                                <div><br /><br /><h2 align="center">Produk Unggulan</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk4.length}
                                    next={this.fetchMoreData4}
                                    hasMore={this.state.hasMore4}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv4"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data4}
                                      columns={columns4}
                                    />
                                  </InfiniteScroll>
                                </div>
                                <br />
                                <div className="pull-right text-white">
                                  <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpanUnggulanList}>Simpan</button>
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </TabPane>
                        {/* ==================================================================== */}
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
          <Form className="theme-form" onSubmit={this.simpan_konfirmasi}>
            <input className="form-control" id="uuid_produk" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.id_produk} />
            <input className="form-control" id="id_toko" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.id_toko} />
            <input className="form-control" id="nama_produk" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.nama_produk} />
            <input className="form-control" id="foto_produk" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.foto_produk} />
            <input className="form-control" id="deskripsi" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.deskripsi} />
            <input className="form-control" id="selalu_tersedia" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.selalu_tersedia} />
            <input className="form-control" id="stok" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.stok} />
            <input className="form-control" id="diskon" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.diskon} />
            <input className="form-control" id="harga_produk" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.harga_produk} />
            <div className="modal-header">
              <h5 className="modal-title" align="center">Detail Produk</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12">
                    <b>Nama Produk</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.nama_produk}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>

                  <div className="col-md-12">
                    <b>Nama Toko</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.nama_toko}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>

                  <div className="col-md-12">
                    <b>Nama Pengguna</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.nama_pengguna}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                  </div>

                  <div className="col-md-12">
                    <b>Berat</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.berat}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>

                  {
                    this.state.data_detail.status == "Tolak"
                      ?
                      <>
                        <input className="form-control" id="is_active" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.is_active.value} />
                        <div className="col-md-12" align="left">
                          <b>Jenis Etalase</b>
                        </div>
                        <div className="col-md-12" align="left">
                          {this.state.data_detail.jenis_etalase}
                        </div>
                      </>

                      : this.state.data_detail.status == "Proses Verifikasi"
                        ?
                        <>
                          <div className="col-md-12" align="left">
                            <b>Status Produk</b>
                          </div>
                          <div className="col-md-12" align="left">
                            Aktif
                          </div>
                        </>
                        :
                        <>
                          <div className="col-md-12" align="left">
                            <b>Status Produk</b>
                          </div>
                          <div className="col-md-12" align="left">
                            <FormGroup className="row">
                              <Col sm='12'>
                                <Select
                                  classNamePrefix="select"
                                  onChange={this.onChangeStatus}
                                  value={this.state.data_detail.is_active}
                                  name="is_active"
                                  options={[
                                    { value: '0', label: 'Aktif' },
                                    { value: '1', label: 'Tidak Aktif' },
                                    { value: '2', label: 'Blokir' },
                                  ]}
                                  placeholder="Pilih Status Produk"
                                  isClearable
                                />
                              </Col>
                            </FormGroup>
                          </div>
                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            &nbsp;
                          </div>
                          <div className="col-md-12" align="left">
                            <b>Status Produk Unggulan</b>
                          </div>
                          <div className="col-md-12" align="left">
                            <FormGroup className="row">
                              <Col sm='12'>
                                <Select
                                  classNamePrefix="select"
                                  onChange={this.onChangeStatusUnggulan}
                                  value={this.state.data_detail.status_unggulan}
                                  name="status_unggulan"
                                  options={[
                                    { value: 'Ya', label: 'Ya' },
                                    { value: 'Tidak', label: 'Tidak' },
                                  ]}
                                  placeholder="Pilih Status Produk Unggulan"
                                  isClearable
                                />
                              </Col>
                            </FormGroup>
                          </div>
                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            &nbsp;
                          </div>
                        </>
                  }

                </div>
                {/* ============================= */}
                <div className="col-md-6">
                  <div className="col-md-12">
                    <b>Harga Produk</b>
                  </div>
                  <div className="col-md-12">
                    Rp. {new Intl.NumberFormat(['ban', 'id']).format(this.state.data_detail.harga_produk)}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                  </div>

                  <div className="col-md-12">
                    <b>Diskon</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.diskon}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                  </div>


                  <div className="col-md-12">
                    <b>Tanggal Input</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.tanggal}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>

                  <div className="col-md-12">
                    <b>Status Tayang</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail.status}
                  </div>


                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>

                  {
                    this.state.data_detail.status == "Tolak"
                      ?
                      <>
                      </>

                      : this.state.data_detail.status == "Proses Verifikasi"
                        ?
                        <>
                          <input className="form-control" id="is_active" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.is_active.value} />
                          <div className="col-md-12" align="left">
                            <b>Jenis Etalase</b>
                          </div>
                          <div className="col-md-12" align="left">
                            <FormGroup className="row">
                              <Col sm='12'>
                                <select
                                  className="form-control"
                                  name="uuid_jenis_etalase1"
                                  value={this.state.uuid_jenis_etalase1.value}
                                  onChange={this.ChangeLevel1}
                                >
                                  <option>Pilih Jenis Etalase Level 1</option>
                                  {this.state.get_data_jenis_etalase1.map((e, key) => {
                                    return <option key={key} value={e.uuid}>{e.jenis_etalase}</option>;
                                  })}
                                </select>
                                <br />
                                <select
                                  className="form-control"
                                  name="uuid_jenis_etalase2"
                                  value={this.state.uuid_jenis_etalase2.value}
                                  onChange={this.ChangeLevel2}
                                >
                                  <option>Pilih Jenis Etalase Level 2</option>
                                  {this.state.get_data_jenis_etalase2.map((e, key) => {
                                    return <option key={key} value={e.uuid}>{e.jenis_etalase}</option>;
                                  })}
                                </select>
                              </Col>
                            </FormGroup>
                          </div>
                        </>
                        :
                        <>
                          <div className="col-md-12" align="left">
                            <b>Jenis Etalase</b>
                          </div>
                          <div className="col-md-12" align="left">
                            {this.state.data_detail.jenis_etalase}
                          </div>

                        </>
                  }
                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>



                </div>
                {/* ================================ */}
                <div className="col-md-12">
                  <div className="col-md-12" align="center">
                    <hr />
                    <b>Deskripsi Produk</b>
                  </div>
                  <div className="col-md-12" align="center">
                    {this.state.data_detail.deskripsi}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>

                  {/* {
                    this.state.data_detail.status == "Tolak"
                      ?
                      <>
                        <input className="form-control" id="is_active" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.is_active.value} />
                        <div className="col-md-12" align="left">
                          <b>Jenis Etalase</b>
                        </div>
                        <div className="col-md-12" align="left">
                          {this.state.data_detail.jenis_etalase}
                        </div>
                      </>

                      : this.state.data_detail.status == "Proses Verifikasi"
                        ?
                        <>
                          <input className="form-control" id="is_active" type="hidden" placeholder="UUID PRODUK" defaultValue={this.state.data_detail.is_active.value} />
                          <div className="col-md-12" align="left">
                            <b>Jenis Etalase</b>
                          </div>
                          <div className="col-md-12" align="left">
                            <FormGroup className="row">
                              <Col sm='12'>
                                <select
                                  className="form-control"
                                  name="uuid_jenis_etalase1"
                                  value={this.state.uuid_jenis_etalase1.value}
                                  onChange={this.ChangeLevel1}
                                >
                                  <option>Pilih Jenis Etalase Level 1</option>
                                  {this.state.get_data_jenis_etalase1.map((e, key) => {
                                    return <option key={key} value={e.uuid}>{e.jenis_etalase}</option>;
                                  })}
                                </select>
                                <br />
                                <select
                                  className="form-control"
                                  name="uuid_jenis_etalase2"
                                  value={this.state.uuid_jenis_etalase2.value}
                                  onChange={this.ChangeLevel2}
                                >
                                  <option>Pilih Jenis Etalase Level 2</option>
                                  {this.state.get_data_jenis_etalase2.map((e, key) => {
                                    return <option key={key} value={e.uuid}>{e.jenis_etalase}</option>;
                                  })}
                                </select>
                              </Col>
                            </FormGroup>
                          </div>
                        </>
                        :
                        <>
                          <div className="col-md-12" align="left">
                            <b>Jenis Etalase</b>
                          </div>
                          <div className="col-md-12" align="left">
                            {this.state.data_detail.jenis_etalase}
                          </div>

                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            &nbsp;
                          </div>
                          <div className="col-md-12" align="center">
                            <b>Status Produk</b>
                          </div>
                          <div className="col-md-12" align="center">
                            <FormGroup className="row">
                              <Col sm='12'>
                                <Select
                                  classNamePrefix="select"
                                  onChange={this.onChangeStatus}
                                  value={this.state.data_detail.is_active}
                                  name="is_active"
                                  options={[
                                    { value: '0', label: 'Aktif' },
                                    { value: '1', label: 'Tidak Aktif' },
                                    { value: '2', label: 'Blokir' },
                                  ]}
                                  placeholder="Pilih Status Produk"
                                  isClearable
                                />
                              </Col>
                            </FormGroup>
                          </div>
                          <div className="col-md-12">
                            <b>&nbsp;</b>
                          </div>
                          <div className="col-md-12">
                            &nbsp;
                          </div>
                          <div className="col-md-12" align="center">
                            <b>Status Produk Unggulan</b>
                          </div>
                          <div className="col-md-12" align="center">
                            <FormGroup className="row">
                              <Col sm='12'>
                                <Select
                                  classNamePrefix="select"
                                  onChange={this.onChangeStatusUnggulan}
                                  value={this.state.data_detail.status_unggulan}
                                  name="status_unggulan"
                                  options={[
                                    { value: 'Ya', label: 'Ya' },
                                    { value: 'Tidak', label: 'Tidak' },
                                  ]}
                                  placeholder="Pilih Status Produk Unggulan"
                                  isClearable
                                />
                              </Col>
                            </FormGroup>
                          </div>
                        </>
                  } */}

                </div>

                <div className="col-md-12">
                  <div className="col-md-12" align="center">
                    <b>Foto Produk</b>
                  </div>
                  <div className="col-md-12" align="center">
                    <div className="row">
                      {this.state.foto_produk.map(e => {
                        return <div className="col-md-4"><img src={e} style={{ width: 150 }} alt="" /></div>;
                      })}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
                      </div>
                </div>

              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
              {
                this.state.data_detail.status == "Tolak"
                  ?
                  <>
                  </>
                  :
                  <>
                    <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan_konfirmasi}>Simpan</button>
                  </>
              }

            </div>
          </Form>
        </Modal>

      </div >
    )
  }
}


export default Produk;
