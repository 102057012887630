import React, { Component } from 'react';
import Select from 'react-select'
// import Custom Componenets
import Breadcrumb from '../../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/css/site.css';

const frmDef = {
	idnya: '',
	nama: '',
	id_provinsi: '',
	cek: 'simpan',
}

class KabKota extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			status2: {
				form2: false,
				btnForm2: false,
				btnAksi2: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Kabupaten Kota",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			form_status: 'tambah',
			// data kabkota
			data_kabkota: {
				id_lama: '',
				id: '',
				id_provinsi: '',
				nama: '',
			},

			data_provinsi: [],
			id_provinsi: '',

			name: '',

			dataCari: {
				q: '',
			},
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	fetch = (params = {}) => {
		let link = "";
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case ">>":
				  link =
					"?page=" +
					Math.ceil(
					  this.state.pagination.total / this.state.pagination.per_page
					);
				  break;
				case ">":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
				  break;
				case "<":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
				  break;
				case "<<":
				  link = "?page=1";
				  break;
				default:
				  link = "?page=" + params.page;
				  break;
			  }
		}
		Post('kabkota' + link, null, this.state.dataCari, (lihat) => {
			if (lihat.data.result) {
				this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
				this.pagination(lihat.data.result);
			}
		});
	}

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "success";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		
		this.setState({ awal, sebelum, hal, setelah, akhir });
		
	};

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			
			this.setState({ 
				dataCari:{
					q: e.target.value,
				}, 
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		var id = null;

		let addKabKota = {
			id: document.getElementById("idnya").value,
			nama: document.getElementById("nama").value,
			id_provinsi: this.state.id_provinsi.value,
		};

		let psn = 'Tambah';
		let resstat = 200;
		let metode = "create";

		if (this.state.form_status == 'edit') {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
			id = this.state.data_kabkota.id_lama;
		}

		Post('kabkota/' + metode, id, addKabKota, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Kabupaten Kota', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Kabupaten Kota', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	ubahData = (id) => {
		this.state.status2.btnAksi2 = true;
		this.state.judul = "Ubah Kabupaten Kota";
		this.forceUpdate();

		Get('kabkota/find', id, (data) => {
			if (data.result) {
				this.state.data_kabkota.id_lama = data.result.id;
				this.state.data_kabkota.id = data.result.id;
				this.state.data_kabkota.nama = data.result.nama;
				this.state.id_provinsi = { value: data.result.id_provinsi, label: data.result.data_provinsi.nama };
			}
			this.state.status2.btnAksi2 = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('kabkota/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Kabupaten Kota', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Kabupaten Kota', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_kabkota.id = '';
			this.state.data_kabkota.nama = '';
			this.state.id_provinsi = '';

			this.state.form_status = 'tambah';
		} else {
			this.state.form_status = 'edit';
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.id)} disabled={this.state.status2.btnAksi2}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.id)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });
		// get data provinsi for select dropdown
		Get('provinsi', null, (data) => {
			if (data.result) {
				let data_provinsi = [];
				data.result.data.map(dt => {
					data_provinsi.push({ value: dt.id, label: dt.nama });
				})
				this.setState({
					data_provinsi
				});
			}
		});
	}

	onChangeProvinsi = (e) => {
		if (e) {
			this.setState({ id_provinsi: { value: e.value, label: e.label } });
		} else {
			this.setState({ id_provinsi: '' });
		}
	}

	getProvinsi = (e) => {
		if (e) {
			let addProvinsi = {
				q: e
			};
			Post('provinsi', null, addProvinsi, (data) => {
				if (data.data.result) {
					let data_provinsi = [];
					data.data.result.data.map(dt => {
						data_provinsi.push({ value: dt.id, label: dt.nama });
					});
					this.setState({ data_provinsi });
				}
			})
		}
		else {
			Get('provinsi', null, (data) => {
				if (data.result) {
					let data_provinsi = [];
					data.result.data.map(dt => {
						data_provinsi.push({ value: dt.id, label: dt.nama });
					})
					this.setState({
						data_provinsi
					});
				}
			});
		}
	}

	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

   onSubmit = (e) => {
	e.preventDefault();
	   
	   let addKabkota = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)
		
	   Post('kabkota', null, addKabkota, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
         /* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	render() {
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		var data = this.state.data;

		const frmDef = {
			id: '',
			nama: '',
			id_provinsi: '',
		}

		// Combo Box Dinamis
		// Cara1        
		// let optionItems = this.state.prov.map(cek =>{
		//     return (
		//         { key: cek.id, value: cek.id, label: cek.nama }
		//     );
		// });
		// Cara2

		// let optionItems = this.state.prov.map(cek =>(
		//     <option key={cek.id} value={cek.id}>{cek.nama}</option>
		// ));

		// End Combo Box Dinamis

		const { SearchBar } = Search;
		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
						align: 'center',
				formatter: (key, obj, index) =>{          
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index+1);
					let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					return serial_num; 
				}
			},
			{
				dataField: 'id',
				text: 'Id Kab/Kota',
				sort: false
			},
			{
				dataField: 'nama',
				text: 'Kabupaten Kota',
				sort: true
			},
			{
				dataField: 'data_provinsi.nama',
				text: 'Provinsi',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];
	
		return (
			<div>
				<Breadcrumb title="Data Kabupaten Kota" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Kabupaten atau Kota..."
												defaultValue={this.state.dataCari.q}
												onKeyPress={this.cariData}
											/>
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Kabupaten Kota", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div>
									</div>
									<BootstrapTable
										keyField="id"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map(dt => {
										return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="cek" type="hidden" defaultValue={this.state.form_status} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">ID</Label>
								<Col sm='9'>
									<input className="form-control" id="idnya" type="text" placeholder="Id Kabupaten Kota" defaultValue={this.state.data_kabkota.id} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Kabupaten Kota</Label>
								<Col sm='9'>
									<input className="form-control" id="nama" type="text" placeholder="Nama Kabupaten Kota" defaultValue={this.state.data_kabkota.nama} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Provinsi</Label>
								<Col sm='9'>
									<Select classNamePrefix="select" onChange={this.onChangeProvinsi}
										value={this.state.id_provinsi}
										name="id_provinsi"
										options={this.state.data_provinsi}
										placeholder="Pilih Provinsi"
										onInputChange={this.getProvinsi}
										isClearable />
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>

			</div>
		)
	}
}


export default KabKota;
