import React, { Component } from 'react';
import axios from 'axios';

import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';


// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/css/customckeditor.css';

const frmDef = {
	uuid: '',
	jenis_pembayaran: '',
}

class DetailTutorial extends Component {

	constructor(props) {
		super(props);
		this.state = {
			// KONTEN sTATE
			data: [],
			preview: this.props.location.state
		};

	}


	componentDidMount() {

		// let id_state = this.state.preview
		// let linknya = `${window.location.href.toString()}`
		// let explode = linknya.split("/")
		// let id = ''

		// if (explode == 'undefined') {
		// 	id = id_state
		// }
		// else {
		// 	id = explode[4]
		// }

		// console.log("Berapa Idnya")
		// console.log(id)

		let linknya = `${window.location.href.toString()}`
		let explode = linknya.split("/")
		let id = explode[4]

		Get('panduan/find', id, (data) => {
			if (data.result) {
				console.log("Data DIDMOUNT")
				console.log(data.result)
				this.setState({
					data: {
						uuid: id,
						jenis: data.result.jenis,
						konten: data.result.konten,
						judul: data.result.judul,
						dibuat: data.result.created_at,
						diubah: data.result.updated_at,
					}
				})
			}
		});

	}


	//============== SHOW DETAIL CKEDITOR RESULT ==================
	createMarkup = () => {
		return { __html: '<div class="my-gallery card-body row">' + this.state.data.konten + '</div>' };
	}
	//============= END SHOW DETAIL CKEDITOR RESULT===========


	render() {
		console.log("========================")
		console.log("Data Pharsing")
		console.log(this.props.location.state)
		console.log("========================")
		console.log("Coba State")
		console.log(this.state.preview)
		// console.log(linknya)
		// console.log("EXPLODE")
		// console.log(explode[4])

		return (
			<div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12" style={{ padding: '0px' }}>
							<div className="card mb-0">
								{
									this.state.data.judul && this.state.data.jenis && this.state.data.konten
										?
										<div className="card-body datatable-react" style={{ padding: '16px' }}>
											<h3 align="center">{this.state.data.judul}</h3>
											<br />
											{/* <p align="left"><b>Dibuat Pada:</b> {this.state.data.dibuat} </p>
									<p align="left"><b>Diubah Pada:</b> {this.state.data.diubah} </p> */}
											<b>Kategori Panduan:</b> {this.state.data.jenis}
											<div dangerouslySetInnerHTML={this.createMarkup()}></div>

										</div>
										:
										<>
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default DetailTutorial;
