import React, { Component } from 'react';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import Breadcrumb from '../../components/common/breadcrumb.component';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';


// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

var images = require.context('../../assets/images/', true);

const frmDef = {
	username: '',
	password: '',
	id_desa: '',
	no_hp: '',
	email: '',
}

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			style: {},
			data: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			dt: frmDef,
			judul: "Register",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			// data desa
			data_keldes: [],
			id_desa: '',
		};
	}


	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let addRegister = {
			username: document.getElementById("username").value,
			password: document.getElementById("password").value,
			id_desa: document.getElementById("id_desa").value,
			no_hp: document.getElementById("no_hp").value,
			email: document.getElementById("email").value,
		};

		let psn = '';
		let resstat = 200;
		let metode = "register";


		// console.log(addRegister)

		Post('auth/' + metode, null, addRegister, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Register', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Register', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}
	// ========================================================================


	componentDidMount() {
		setTimeout(function () {
			this.setState({ style: { display: 'none' } });
		}.bind(this), 1000);

		Get('keldes', null, (data) => {
			let data_keldes = [];
			data.result.data.map(dt => {
				data_keldes.push({ value: dt.id, label: dt.nama });
			})
			this.setState({
				data_keldes
			});
		});
	}

	onChangeDesa = (e) => {
		if (e) {
			this.setState({ id_desa: { value: e.value, label: e.label } });
		} else {
			this.setState({ id_desa: '' });
		}
	}
	getDesa = (e) => {
		if (e) {
			let addDesa = {
				q: e
			};
			Post('keldes', null, addDesa, (data) => {
				let data_keldes = [];
				data.data.result.data.map(dt => {
					data_keldes.push({ value: dt.id, label: dt.nama });
				});
				this.setState({ data_keldes });
			});
		} else {
			Get("keldes", null, data => {
				let data_keldes = [];
				data.result.data.map(dt => {
					data_keldes.push({ value: dt.id, label: dt.nama });
				});
				this.setState({ data_keldes });
			});
		}
	}

	render() {
		let style = this.state.style;
		const background = require('../../assets/images/auth-layer.png')

		return (
			<div>

				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				{/* Loader starts */}
				<div className="loader-wrapper" style={style}>
					<div className="loader bg-white">
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<h4>Have a great day at work today <span>&#x263A;</span></h4>
					</div>
				</div>
				{/* Loader ends */}

				{/*page-wrapper Start*/}
				<div className="page-wrapper">

					<div className="container-fluid">

						{/*sign up page start*/}
						<div className="authentication-main">
							<div className="row">
								<div className="col-md-4 p-0">
									<div className="auth-innerleft" style={{ backgroundImage: "url(" + background + ")" }}>
										<div className="text-center">
											<img src={require('../../assets/images/logo-auth.gif')} className="logo-login" alt="" />
											<hr />
											<div className="social-media">
												<ul className="list-inline">
													<li className="list-inline-item"><i className="fa fa-facebook" aria-hidden="true"></i></li>
													<li className="list-inline-item"><i className="fa fa-google-plus" aria-hidden="true"></i></li>
													<li className="list-inline-item"><i className="fa fa-twitter" aria-hidden="true"></i></li>
													<li className="list-inline-item"><i className="fa fa-instagram" aria-hidden="true"></i></li>
													<li className="list-inline-item"><i className="fa fa-rss" aria-hidden="true"></i></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-8 p-0">
									<div className="auth-innerright">
										<div className="authentication-box">
											<h3 className="text-center">Register</h3>
											{/* <h6 className="text-center">Enter your Username and Password For Login or Signup</h6> */}
											<div className="card mt-4 p-4">
												<form className="theme-form" onSubmit={this.simpan}>
													<div className="form-row">
														<div className="col-md-6">
															<div className="form-group">
																<label className="col-form-label">Username</label>
																<input required type="text" className="form-control" placeholder="Username" id="username" defaultValue={this.state.dt.username} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="col-form-label">Password</label>
																<input required type="password" className="form-control" placeholder="Password" id="password" defaultValue={this.state.dt.password} />
															</div>
														</div>
													</div>
													<div className="form-group">
														<label className="col-form-label">Nama Desa</label>
														<Select
															classNamePrefix="select"
															onChange={this.onChangeDesa}
															value={this.state.id_desa}
															name="id_desa"
															options={this.state.data_keldes}
															placeholder="Pilih Desa"
															onInputChange={this.getDesa}
															isClearable
														/>
														{/* <input type="text" required className="form-control" placeholder="ID Desa" id="id_desa" defaultValue={this.state.dt.id_desa} /> */}
													</div>
													<div className="form-group">
														<label className="col-form-label">No. Handphone</label>
														<input type="text" required className="form-control" placeholder="No. Handphone" id="no_hp" defaultValue={this.state.dt.no_hp} />
													</div>
													<div className="form-group">
														<label className="col-form-label">Email</label>
														<input type="text" required className="form-control" placeholder="Email" id="email" defaultValue={this.state.dt.email} />
													</div>

													<div className="form-row">
														<div className="col-sm-3">
															<button type="submit" className="btn btn-secondary" disabled={this.state.status.btnForm} onClick={this.simpan}>Register</button>
														</div>
														<div className="col-sm-3">
														<a href={`${process.env.PUBLIC_URL}/dashboard`} className="btn btn-secondary">Dashboard</a>
														</div>
													</div>

												</form>
											</div>
										</div>
									</div>
								</div>

							</div>

							{/*sign up page Ends*/}

						</div>
					</div>
				</div>
			</div>

		)
	}
}


export default Register;