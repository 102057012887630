import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col,Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { data } from 'browserslist';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
	username: '',
	password: '',
	role: '0',
	no_hp: '',
	email: '',
}
class VerifikasiHapusPengguna extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dataVerifikasi: [],
			// Pagination data table
			pagination: [],
			paginationDiverifikasi: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			// Diajukan
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			// Diverifikasi
			awal2: "",
			sebelum2: "",
			hal2: [],
			setelah2: "",
			akhir2: "",

			dt: frmDef,
			judul: "Tambah Pengguna",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			name: '',

			
			dataCari: {
				q: '',
			},

			cek_posisi:'Pertama',
			active_tab_icon: "1",
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			if(tab == 1){
				this.state.cek_posisi = 'Pertama'
				this.state.dataCari= {
				q: ''
				}
			}
			else if(tab == 2){
				this.state.cek_posisi = 'Kedua'
				this.state.dataCari= {
					q: ''
				}
			}
		  
			this.setState({
				active_tab_icon: tab,
			});
		}
		
		this.forceUpdate();
		this.componentDidMount();
	}

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
			case ">>":
				switch (params.tab) {
				case "Diajukan":
					link =
					"?page=" +
					Math.ceil(
						this.state.pagination.total / this.state.pagination.per_page
					);
					break;
	
				case "Diverifikasi":
					link =
					"?page=" +
					Math.ceil(
						this.state.paginationDiverifikasi.total /
						this.state.paginationDiverifikasi.per_page
					);
					break;
				}
				break;
			case ">":
				switch (params.tab) {
				case "Diajukan":
					link =
					"?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
	
				case "Diverifikasi":
					link =
					"?page=" +
					(parseInt(this.state.paginationDiverifikasi.current_page) +
						1);
					break;
				}
				break;
			case "<":
				switch (params.tab) {
				case "Diajukan":
					link =
					"?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
	
				case "Diverifikasi":
					link =
					"?page=" +
					(parseInt(this.state.paginationDiverifikasi.current_page) -
						1);
					break;
				}
				break;
			case "<<":
				link = "?page=1";
				break;
			default:
				link = "?page=" + params.page;
				break;
			}
		}
		
		let endPoint = "pengguna/list-pengajuan-hapus-akun"
		// Get data from db
		if(this.state.cek_posisi == "Pertama"){
			params.tab = "Diajukan"
			endPoint = "pengguna/list-pengajuan-hapus-akun"
		}
		else if(this.state.cek_posisi == "Kedua"){
			params.tab = "Diverifikasi"
			endPoint = "pengguna/list-verifikasi-hapus-akun"
		}

		Post(endPoint + link, null, this.state.dataCari, (lihat) => {
			switch (params.tab) {
				case "Diajukan":
				this.setState({
					data: lihat.data.result.data,
					pagination: lihat.data.result
				});
				break;
		
				case "Diverifikasi":
				this.setState({
					dataVerifikasi: lihat.data.result.data,
					paginationDiverifikasi: lihat.data.result
				});
				break;
			}
		});
		// Post('pengguna/list-pengajuan-hapus-akun' + link, null, this.state.dataCari, (lihat) => {
		// 	// console.log('Data List')
		// 	// console.log(lihat)
		// 	if (lihat.data.result.data) {
		// 		this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
		// 		this.pagination(lihat.data.result);
		// 	}

		// });
	}

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "primary";
			if (i == data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-primary btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-primary btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-primary btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-primary btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		switch (tab) {
		  case "Diajukan":
			this.setState({ awal, sebelum, hal, setelah, akhir });
			break;
		  case "Diverifikasi":
			this.setState({
			  awal2: awal,
			  sebelum2: sebelum,
			  hal2: hal,
			  setelah2: setelah,
			  akhir2: akhir
			});
			break;
		}
	  };

	// pagination = (data) => {
	// 	// const data = this.state.pagination;
	// 	// console.log(data);
	// 	let awal = "";
	// 	let hal = [];
	// 	let sebelum = "";
	// 	let setelah = "";
	// 	let akhir = "";
	// 	if (data.total > 0) {
	// 		let start = 1;
	// 		let end = 5;
	// 		let n = 0;
	// 		let p = 0;
	// 		if (data.current_page <= 3) {
	// 			start = 1;
	// 			end = 5;
	// 			if (data.last_page > data.current_page) {
	// 				n = data.current_page + 1;
	// 			}
	// 		}
	// 		else {
	// 			p = data.current_page - 1;
	// 			n = data.current_page + 1;
	// 			start = data.current_page - 2;
	// 			end = data.current_page + 2;
	// 		}
	// 		if (end >= data.last_page - 2) {
	// 			p = data.current_page - 1;
	// 			if (start >= 5) {
	// 				start = data.last_page - 4;
	// 			}
	// 			end = data.last_page;
	// 		}

	// 		for (let i = start; i <= end; i++) {
	// 			let warna = "success";
	// 			if (i == data.current_page) {
	// 				warna = "danger"
	// 			}
	// 			hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
	// 		}
	// 		if (p > 0) {
	// 			sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
	// 		}
	// 		if (n > 0) {
	// 			setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
	// 		}
	// 		awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
	// 		akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
	// 	}
	// 	this.setState({ awal, sebelum, hal, setelah, akhir });
	// }
	

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let addKeldes = {
				q: e.target.value,
			};
			this.setState({ 
				dataCari:{
					q: e.target.value,
				}, 
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}


	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let cek_pass = document.getElementById("password").value

		let addPengguna = {
			uuid: document.getElementById("uuid").value,
			username: document.getElementById("username").value,
			no_hp: document.getElementById("no_hp").value,
			email: document.getElementById("email").value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPengguna.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addPengguna.uuid = null;
			addPengguna.password = document.getElementById("password").value;
			addPengguna.role = document.getElementById("role").value;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";

			if (cek_pass != '') {
				addPengguna.password = document.getElementById("password").value
			}
		}

		Post('pengguna/' + metode, addPengguna.uuid, addPengguna, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pengguna', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengguna', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}


	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Pengguna";
		this.forceUpdate();

		Get('pengguna/find', id, (data) => {
			if (data.result) {
				this.state.dt.uuid = data.result.uuid;
				this.state.dt.username = data.result.username;
				this.state.dt.password = data.result.password;
				this.state.dt.no_hp = data.result.no_hp;
				this.state.dt.email = data.result.email;
				this.state.dt.role = data.result.role;
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (data) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus akun pengguna ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(data)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (data) => {
		this.hideAlert();
		let psn = "Nonaktif";
		let addPengguna = {
			uuid: data.uuid,
			username: data.username,
			status_verifikasi: 'Verifikasi Hapus Akun',
		};

		Post('pengguna/verifikasi-hapus-akun', addPengguna.uuid, addPengguna, (res) => {
			console.log('respon hapus')
			console.log(res)
			if (res.status == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pengguna', pesanAlert: 'Berhasil ' + psn + ' Pengguna' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengguna', pesanAlert: 'Gagal ' + psn + ' Pengguna' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.dt.uuid = '';
			this.state.dt.username = '';
			this.state.dt.password = '';
			this.state.dt.no_hp = '';
			this.state.dt.email = '';
			this.state.dt.role = '';
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
			{this.state.cek_posisi=="Pertama" ?
				<div>
					<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row)} disabled={this.state.status.btnAksi}><i className="fa fa-check"></i></Button>
{/* 					
					<button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn btn-success"><i className="fa fa-check"></i> Verifikasi</button> */}
				</div>
			:this.state.cek_posisi=="Kedua"
			?
				<div>
					{/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button> */}
				</div>
			:
				<>
				</>
			}

			</>
		)
	}
	componentDidMount() {
		if (typeof this.props.location.tabProps != "undefined") {
			this.state.active_tab_icon = this.props.location.tabProps;
		}
		if (typeof this.props.location.state != "undefined") {
			this.state.active_tab_icon = this.props.location.state.tab;
		}

		let tabnya = ""
		if(this.state.cek_posisi=="Pertama"){
			tabnya="Diajukan"
		}
		else if(this.state.cek_posisi=="Kedua"){
			tabnya="Diverifikasi"
		}
		
		// Fetching data diajukan
		this.fetch({ page: null, where: [], tab: tabnya });
		this.forceUpdate();
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addPengguna = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('pengguna', null, addPengguna, (data) => {
			//    console.log("Datanyaa")
			//    console.log(data.data.result.data)
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});

		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var dataVerifikasi = this.state.dataVerifikasi;
		const frmDef = {
			uuid: '',
			username: '',
			no_hp: '',
			email: '',
		}
		const { SearchBar } = Search;
		let columns = []
			
		{
			this.state.active_tab_icon == '1'?
				columns =[
					{
						dataField: "no",
						text: "No",
						isDummyField: true,
						csvExport: false,
						headerAlign: 'center',
						align: 'center',
						style:{
							width: '15px',
						},
						formatter: (key, obj, index) =>{          
							let current_pagenum = this.state.pagination.current_page;
							let total_records_per_page = this.state.pagination.per_page;
							let row_index = (index+1);
							let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
							return serial_num; 
						}
					},
					{
						dataField: 'username',
						text: 'Username',
						sort: true
					},
					{
						dataField: 'no_hp',
						text: 'No. Handphone',
						sort: true
					},
					{
						dataField: 'email',
						text: 'Email',
						sort: true
					},
					{
						dataField: 'aksi',
						text: 'Aksi',
						isDummyField: true,
						csvExport: false,
						headerAlign: 'center',
						align: 'center',
						style:{
							width: '120px',
						},
						formatter: this.tombolAksi,
					},
				]
			:
				columns =[
					{
						dataField: "no",
						text: "No",
						isDummyField: true,
						csvExport: false,
						headerAlign: 'center',
						align: 'center',
						style:{
							width: '15px',
						},
						formatter: (key, obj, index) =>{          
							let current_pagenum = this.state.pagination.current_page;
							let total_records_per_page = this.state.pagination.per_page;
							let row_index = (index+1);
							let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
							return serial_num; 
						}
					},
					{
						dataField: 'username',
						text: 'Username',
						sort: true
					},
					{
						dataField: 'no_hp',
						text: 'No. Handphone',
						sort: true
					},
					{
						dataField: 'email',
						text: 'Email',
						sort: true
					},
				]

	}

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Verifikasi Hapus Pengguna" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<Nav tabs className="tabs-color">
										<NavItem style={{ cursor: "pointer" }}>
											<NavLink
												className={classnames({
												active: this.state.active_tab_icon === "1"
												})}
												onClick={() => {
												this.toggle_icon("1");
												}}
											>
												<i className="fa fa-hourglass-half"></i>
												
												Diajukan
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor: "pointer" }}>
											<NavLink
												className={classnames({
												active: this.state.active_tab_icon === "2"
												})}
												onClick={() => {
												this.toggle_icon("2");
												}}
											>
												<i className="fa fa-tasks"></i>

												Diverifikasi
											</NavLink>
										</NavItem>
									</Nav>

									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row">
															<div className="col-sm-6">
																<input
																	type="search"
																	className="form-control"
																	id="cariTable"
																	placeholder="Cari Pengguna..."
																	defaultValue={this.state.dataCari.q}
																	onKeyPress={this.cariData}
																/>
															</div>
															<div className="col-sm-6 text-right">
																&nbsp;
															</div>
														</div>

														<BootstrapTable
															keyField="uuid"
															data={data}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
													<div className="row">
															<div className="col-sm-6">
																<input
																	type="search"
																	className="form-control"
																	id="cariTable"
																	placeholder="Cari Pengguna..."
																	defaultValue={this.state.dataCari.q}
																	onKeyPress={this.cariData}
																/>
															</div>
															<div className="col-sm-6 text-right">
																&nbsp;
															</div>
														</div>

														<BootstrapTable
															keyField="uuid"
															data={dataVerifikasi}
															columns={columns}
														/>
														<div className="pull-right text-white">
															{this.state.awal2}
															{this.state.sebelum2}
															{this.state.hal2.map(dt => {
																return dt;
															})}
															{this.state.setelah2}
															{this.state.akhir2}
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
									</TabContent>	
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Pengguna" defaultValue={this.state.dt.uuid} />
							<input className="form-control" id="role" type="hidden" placeholder="Pengguna" defaultValue='0' />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Username Pengguna</Label>
								<Col sm='9'>
									<input className="form-control" id="username" type="text" placeholder="Username Pengguna" defaultValue={this.state.dt.username} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Password Pengguna</Label>
								<Col sm='9'>
									<input className="form-control" id="password" type="password" placeholder="Password Pengguna" defaultValue={this.state.dt.password} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">No. Handphone</Label>
								<Col sm='9'>
									<input className="form-control" id="no_hp" type="text" placeholder="No. Handphone Pengguna" defaultValue={this.state.dt.no_hp} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Email</Label>
								<Col sm='9'>
									<input className="form-control" id="email" type="text" placeholder="Email Pengguna" defaultValue={this.state.dt.email} />
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default VerifikasiHapusPengguna;
