import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Switch from "react-switch";

// koneksi
import { Post, Get, Delete, GetDigidesa, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


const frmDef = {
	uuid: '',
}

class DetailAPI extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				form2: false,
				btnForm2: false,
				btnAksi2: false,
				formLisensi: false,
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			jenis_pembayaran: [],
			uuid_bank: '',
			data_bank: [],
			uuid_bank_blank: '',
			id_bank: '',

			data_pembayaran: {
				uuid: '',
				nomor_rekening: '',
				atas_nama: '',
				kode_pembayaran: '',
			},

			id_jenis_pengiriman: '',
			jenis_pengiriman: [],

			data_akun: [],
			data_lisensi: [],
			data_layanan: null,
			data_detail_layanan: {
				pelayanan: '',
				icon: '',
				image: '',
				route: '',
				title: '',
			},
			config_list_layanan_desa: {
				status_absensi: false,
				status_todolist: false
			},
			list_layanan_desa_mobile: [],
			list_layanan_capil_mobile: [],
			list_layanan_kua_mobile: [],
			list_layanan_desa_web: [],
			list_layanan_capil_web: [],
			list_layanan_kua_web: [],
			list_layanan_ppob: [],
			koneksi_web: [],
			version_desa_app: "",
			version_min_desa_app: "",
			ketersedian_ttd: '',
			status_tte: '',
			ketersediaan_info: '',
			ketersediaan_pengumuman: '',
			ketersediaan_info_bupati: '',
			riwayat_disposisi: '',
			layanan_capil: '',
			layanan_kua: '',
			layanan_ppob: '',
			pembayaran: '',
			konfig_umum: "none",
			konfig_web: "none",

			//Tab Pane
			active_tab_icon: '1',
			

			// Tab Vertical
            active_tab_vertical: '1',

			checked: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeUmum = this.handleChangeUmum.bind(this);
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	handleChange(checked, jenis_surat) {
		switch (jenis_surat) {
			// LAYANAN DESA MOBILE
			case "tidak_mampu":
				if(this.state.list_layanan_desa_mobile.tidak_mampu == true){
					this.state.list_layanan_desa_mobile.tidak_mampu = checked
				}
				else{
					this.state.list_layanan_desa_mobile.tidak_mampu = checked
				}
			  break;
			case "domisili":
				if(this.state.list_layanan_desa_mobile.domisili == true){
					this.state.list_layanan_desa_mobile.domisili = checked
				}
				else{
					this.state.list_layanan_desa_mobile.domisili = checked
				}
			  break;
			case "kelahiran":
				if(this.state.list_layanan_desa_mobile.kelahiran == true){
					this.state.list_layanan_desa_mobile.kelahiran = checked
				}
				else{
					this.state.list_layanan_desa_mobile.kelahiran = checked
				}
			  break;
			case "belum_memiliki_rumah":
				if(this.state.list_layanan_desa_mobile.belum_memiliki_rumah == true){
					this.state.list_layanan_desa_mobile.belum_memiliki_rumah = checked
				}
				else{
					this.state.list_layanan_desa_mobile.belum_memiliki_rumah = checked
				}
			  break;
			case "jalan":
				if(this.state.list_layanan_desa_mobile.jalan == true){
					this.state.list_layanan_desa_mobile.jalan = checked
				}
				else{
					this.state.list_layanan_desa_mobile.jalan = checked
				}
			  break;
			case "kematian":
				if(this.state.list_layanan_desa_mobile.kematian == true){
					this.state.list_layanan_desa_mobile.kematian = checked
				}
				else{
					this.state.list_layanan_desa_mobile.kematian = checked
				}
			  break;
			case "kepemilikan_rumah":
				if(this.state.list_layanan_desa_mobile.kepemilikan_rumah == true){
					this.state.list_layanan_desa_mobile.kepemilikan_rumah = checked
				}
				else{
					this.state.list_layanan_desa_mobile.kepemilikan_rumah = checked
				}
			break;
			case "penghasilan":
				if(this.state.list_layanan_desa_mobile.penghasilan == true){
					this.state.list_layanan_desa_mobile.penghasilan = checked
				}
				else{
					this.state.list_layanan_desa_mobile.penghasilan = checked
				}
			break;
			case "skck":
				if(this.state.list_layanan_desa_mobile.skck == true){
					this.state.list_layanan_desa_mobile.skck = checked
				}
				else{
					this.state.list_layanan_desa_mobile.skck = checked
				}
			break;
			case "kehilangan":
				if(this.state.list_layanan_desa_mobile.kehilangan == true){
					this.state.list_layanan_desa_mobile.kehilangan = checked
				}
				else{
					this.state.list_layanan_desa_mobile.kehilangan = checked
				}
			break;
			case "usaha":
				if(this.state.list_layanan_desa_mobile.usaha == true){
					this.state.list_layanan_desa_mobile.usaha = checked
				}
				else{
					this.state.list_layanan_desa_mobile.usaha = checked
				}
			break;
			case "skgr":
				if(this.state.list_layanan_desa_mobile.skgr == true){
					this.state.list_layanan_desa_mobile.skgr = checked
				}
				else{
					this.state.list_layanan_desa_mobile.skgr = checked
				}
			break;
			case "belum_menikah":
				if(this.state.list_layanan_desa_mobile.belum_menikah == true){
					this.state.list_layanan_desa_mobile.belum_menikah = checked
				}
				else{
					this.state.list_layanan_desa_mobile.belum_menikah = checked
				}
			break;
			case "tidak_menikah":
				if(this.state.list_layanan_desa_mobile.tidak_menikah == true){
					this.state.list_layanan_desa_mobile.tidak_menikah = checked
				}
				else{
					this.state.list_layanan_desa_mobile.tidak_menikah = checked
				}
			break;
			case "pindah_menikah":
				if(this.state.list_layanan_desa_mobile.pindah_menikah == true){
					this.state.list_layanan_desa_mobile.pindah_menikah = checked
				}
				else{
					this.state.list_layanan_desa_mobile.pindah_menikah = checked
				}
			break;
			case "pernah_menikah":
				if(this.state.list_layanan_desa_mobile.pernah_menikah == true){
					this.state.list_layanan_desa_mobile.pernah_menikah = checked
				}
				else{
					this.state.list_layanan_desa_mobile.pernah_menikah = checked
				}
			break;
			// CAPIL MOBILE
			case "kelahiran_capil_mobile":
				if(this.state.list_layanan_capil_mobile.kelahiran == true){
					this.state.list_layanan_capil_mobile.kelahiran = checked
				}
				else{
					this.state.list_layanan_capil_mobile.kelahiran = checked
				}
			break;
			case "pindah_datang_capil_mobile":
				if(this.state.list_layanan_capil_mobile.pindah_datang == true){
					this.state.list_layanan_capil_mobile.pindah_datang = checked
				}
				else{
					this.state.list_layanan_capil_mobile.pindah_datang = checked
				}
			break;
			case "akta_kematian_capil_mobile":
				if(this.state.list_layanan_capil_mobile.akta_kematian == true){
					this.state.list_layanan_capil_mobile.akta_kematian = checked
				}
				else{
					this.state.list_layanan_capil_mobile.akta_kematian = checked
				}
			break;
			case "penambahan_anak_capil_mobile":
				if(this.state.list_layanan_capil_mobile.penambahan_anak == true){
					this.state.list_layanan_capil_mobile.penambahan_anak = checked
				}
				else{
					this.state.list_layanan_capil_mobile.penambahan_anak = checked
				}
			break;
			case "pecah_kk_capil_mobile":
				if(this.state.list_layanan_capil_mobile.pecah_kk == true){
					this.state.list_layanan_capil_mobile.pecah_kk = checked
				}
				else{
					this.state.list_layanan_capil_mobile.pecah_kk = checked
				}
			break;
			case "perubahan_data_capil_mobile":
				if(this.state.list_layanan_capil_mobile.perubahan_data == true){
					this.state.list_layanan_capil_mobile.perubahan_data = checked
				}
				else{
					this.state.list_layanan_capil_mobile.perubahan_data = checked
				}
			break;
			// KUA MOBILE
			case "pernikahan":
				if(this.state.list_layanan_kua_mobile.pernikahan == true){
					this.state.list_layanan_kua_mobile.pernikahan = checked
				}
				else{
					this.state.list_layanan_kua_mobile.pernikahan = checked
				}
			break;
			// PPOB MOBILE
			case "top_up_bumdes":
				if(this.state.list_layanan_ppob.top_up_bumdes == true){
					this.state.list_layanan_ppob.top_up_bumdes = checked
				}
				else{
					this.state.list_layanan_ppob.top_up_bumdes = checked
				}
			break;
			case "transfer_bank":
				if(this.state.list_layanan_ppob.transfer_bank == true){
					this.state.list_layanan_ppob.transfer_bank = checked
				}
				else{
					this.state.list_layanan_ppob.transfer_bank = checked
				}
			break;
			case "va":
				if(this.state.list_layanan_ppob.va == true){
					this.state.list_layanan_ppob.va = checked
				}
				else{
					this.state.list_layanan_ppob.va = checked
				}
			break;

			// CAPIL Web
			case "kelahiran_capil_web":
				if(this.state.list_layanan_capil_web.kelahiran == true){
					this.state.list_layanan_capil_web.kelahiran = checked
				}
				else{
					this.state.list_layanan_capil_web.kelahiran = checked
				}
			break;
			case "pindah_datang_capil_web":
				if(this.state.list_layanan_capil_web.pindah_datang == true){
					this.state.list_layanan_capil_web.pindah_datang = checked
				}
				else{
					this.state.list_layanan_capil_web.pindah_datang = checked
				}
			break;
			case "akta_kematian_capil_web":
				if(this.state.list_layanan_capil_web.akta_kematian == true){
					this.state.list_layanan_capil_web.akta_kematian = checked
				}
				else{
					this.state.list_layanan_capil_web.akta_kematian = checked
				}
			break;
			case "penambahan_anak_capil_web":
				if(this.state.list_layanan_capil_web.penambahan_anak == true){
					this.state.list_layanan_capil_web.penambahan_anak = checked
				}
				else{
					this.state.list_layanan_capil_web.penambahan_anak = checked
				}
			break;
			case "pecah_kk_capil_web":
				if(this.state.list_layanan_capil_web.pecah_kk == true){
					this.state.list_layanan_capil_web.pecah_kk = checked
				}
				else{
					this.state.list_layanan_capil_web.pecah_kk = checked
				}
			break;
			case "perubahan_data_capil_web":
				if(this.state.list_layanan_capil_web.perubahan_data == true){
					this.state.list_layanan_capil_web.perubahan_data = checked
				}
				else{
					this.state.list_layanan_capil_web.perubahan_data = checked
				}
			break;
			// KUA WEB
			case "pernikahan_kua_web":
				if(this.state.list_layanan_kua_web.pernikahan == true){
					this.state.list_layanan_kua_web.pernikahan = checked
				}
				else{
					this.state.list_layanan_kua_web.pernikahan = checked
				}
			break;
		  }

		this.forceUpdate();
		this.setState({ checked });
		this.simpanLayanan(checked);
	  }

	handleChangeWeb(checked, jenis_surat) {
		
		// console.log("CHANGE JENIS SURAT")
		// console.log(jenis_surat)
		// console.log(this.state.list_layanan_desa_web[jenis_surat])
		
		if(this.state.list_layanan_desa_web[jenis_surat] === true){
			this.state.list_layanan_desa_web[jenis_surat] = checked
		}
		else{
			this.state.list_layanan_desa_web[jenis_surat] = checked
		}

		this.forceUpdate();
		this.setState({ checked });
		this.simpanLayanan(checked);
	}

	handleChangeConfigLayananDesa(checked, jenis_konfig){
		let val = 0
		if (checked) {
			val = 1
		}

		this.setState({
			config_list_layanan_desa: {
				...this.state.config_list_layanan_desa,
				[jenis_konfig] : val
			}
		})
	}
		
	handleChangeUmum(checked, jenis_konfig) {
		// console.log("CHANGE JENIS Konfig")
		// console.log(jenis_konfig)
		switch (jenis_konfig) {
			// LAYANAN TTD
			case "ketersediaan_ttd":
				if(this.state.ketersedian_ttd == true){
					this.state.ketersedian_ttd = checked
				}
				else{
					this.state.ketersedian_ttd = checked
				}
			  break;
			case "status_tte":
				if(this.state.status_tte == true){
					this.state.status_tte = checked
				}
				else{
					this.state.status_tte = checked
				}
			  break;
			case "ketersediaan_info":
				if(this.state.ketersediaan_info == true){
					this.state.ketersediaan_info = checked
				}
				else{
					this.state.ketersediaan_info = checked
				}
			  break;
			case "ketersediaan_pengumuman":
				if(this.state.ketersediaan_pengumuman == true){
					this.state.ketersediaan_pengumuman = checked
				}
				else{
					this.state.ketersediaan_pengumuman = checked
				}
			  break;
			case "ketersediaan_info_bupati":
				if(this.state.ketersediaan_info_bupati == true){
					this.state.ketersediaan_info_bupati = checked
				}
				else{
					this.state.ketersediaan_info_bupati = checked
				}
			  break;
			case "riwayat_disposisi":
				if(this.state.riwayat_disposisi == true){
					this.state.riwayat_disposisi = checked
				}
				else{
					this.state.riwayat_disposisi = checked
				}
			  break;
			case "layanan_capil":
				if(this.state.layanan_capil == true){
					this.state.layanan_capil = checked
				}
				else{
					this.state.layanan_capil = checked
				}
			  break;
			case "layanan_kua":
				if(this.state.layanan_kua == true){
					this.state.layanan_kua = checked
				}
				else{
					this.state.layanan_kua = checked
				}
			  break;
			case "layanan_ppob":
				if(this.state.layanan_ppob == true){
					this.state.layanan_ppob = checked
				}
				else{
					this.state.layanan_ppob = checked
				}
			  break;
			case "pembayaran":
				if(this.state.pembayaran == true){
					this.state.pembayaran = checked
				}
				else{
					this.state.pembayaran = checked
				}
			  break;
		  }

		this.forceUpdate();
		this.setState({ checked });
	  }

	// Toggle Vertical Tab
	toggle_vertical(tab) {
        if (this.state.active_tab_vertical !== tab) {
            this.setState({
                active_tab_vertical: tab
            });
        }
    }

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {

		let id = this.props.location.state.id_desa;

		Get('jenis-pembayaran-desa/by-id-desa/' + id, null, (lihat) => {
			if (lihat.result) {
				this.setState({ data: lihat.result });
			}
		});
	}

	// ================== simpan data akun ==================
	simpan_akun = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let cek_pass = document.getElementById("password").value

		let addPengguna = {
			uuid: document.getElementById("uuid").value,
			username: document.getElementById("username").value,
			no_hp: document.getElementById("no_hp").value,
			email: document.getElementById("email").value,
			role: document.getElementById("role").value,
			id_desa: document.getElementById("id_desa").value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPengguna.uuid === '') {
			psn = "Tambah";
			resstat = 201;
			addPengguna.uuid = null;
			addPengguna.password = document.getElementById("password").value
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";

			if (cek_pass != '') {
				addPengguna.password = document.getElementById("password").value
			}
		}


		if (addPengguna.password == '' && addPengguna.uuid == '') {
			this.setState({ show: true, basicType: 'danger', basicTitle: 'Daftar Akun Bumdes', pesanAlert: 'Gagal ' + 'Ubah' + ' Data, Password Wajib Diisi !' });
		}
		else {
			Post('bumdes/' + metode, addPengguna.uuid, addPengguna, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ show: true, basicType: 'success', basicTitle: 'Daftar Akun Bumdes', pesanAlert: 'Berhasil ' + psn + ' Data' });
				}
				else {
					this.setState({ show: true, basicType: 'danger', basicTitle: 'Daftar Akun Bumdes', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			});
		}
	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		var id = null;
		if (this.state.jenis_pembayaran.value == 'Pembayaran Ditempat') {
			this.state.id_bank = document.getElementById("uuid_bank_blank").value;
		}
		else {
			this.state.id_bank = (this.state.uuid_bank.value).toString();
		}
		let addPembayaranDesa = {
			uuid: document.getElementById("uuid_pembayaran_desa").value,
			jenis_pembayaran: this.state.jenis_pembayaran.value,
			id_desa: document.getElementById("id_desa").value,
			uuid_bank: this.state.id_bank,
			nomor_rekening: document.getElementById("nomor_rekening").value,
			atas_nama: document.getElementById("atas_nama").value,
			kode_pembayaran: document.getElementById("kode_pembayaran").value,
		};

		// console.log("DATA SIMPAN")
		// console.log(addPembayaranDesa)

		// console.log("COBA TAMPILKAN")
		// console.log(addPembayaranDesa.kode_pembayaran)

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPembayaranDesa.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addPembayaranDesa.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('jenis-pembayaran-desa/' + metode, addPembayaranDesa.uuid, addPembayaranDesa, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pembayaran Desa', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pembayaran Desa', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}


	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Pembayaran Desa";
		this.forceUpdate();

		Get('jenis-pembayaran-desa/find', id, (data) => {
			// console.log("UBAH DATA")
			// console.log(data.result)
			if (data.result) {
				this.state.data_pembayaran.uuid = id;

				if (data.result.nomor_rekening != '') {
					this.state.data_pembayaran.nomor_rekening = data.result.nomor_rekening;
				}
				else {
					this.state.data_pembayaran.nomor_rekening = '';
				}

				if (data.result.uuid_bank != '') {
					this.state.uuid_bank = { value: data.result.uuid_bank, label: data.result.data_bank.nama_bank };
				}
				else {
					this.state.uuid_bank = '';
				}

				this.state.data_pembayaran.atas_nama = data.result.atas_nama;

				this.state.data_pembayaran.kode_pembayaran = data.result.kode_pembayaran;

				this.state.data_pembayaran.uuid = data.result.uuid;

				this.state.jenis_pembayaran = {
					value: data.result.jenis_pembayaran, label: (
						data.result.jenis_pembayaran == 'Transfer Bank' ? 'Transfer Bank'
							: data.result.jenis_pembayaran == 'Virtual Account' ? 'Virtual Account'
								: data.result.jenis_pembayaran == 'Pembayaran Ditempat' ? 'Pembayaran Ditempat'
									: ''
					)
				};
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}


	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('jenis-pembayaran-desa/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Jenis Pembayaran', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Jenis Pembayaran', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}


	konfirmHapus2 = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData2(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData2 = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('jenis-pengiriman-desa/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Jenis Pengiriman', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Jenis Pengiriman', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}




	simpan2 = e => {
		e.preventDefault();
		this.state.status.btnForm2 = true;
		this.forceUpdate();

		var id = null;
		let addPengirimanDesa = {
			uuid: document.getElementById("uuid_pengiriman_desa").value,
			uuid_jenis_pengiriman: this.state.id_jenis_pengiriman.value,
			id_desa: document.getElementById("id_desa").value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPengirimanDesa.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addPengirimanDesa.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('jenis-pengiriman-desa/' + metode, addPengirimanDesa.uuid, addPengirimanDesa, (res) => {
			this.state.status.btnForm2 = false;
			this.state.status.form2 = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pengiriman Desa', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengiriman Desa', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	// ============================ Ubah Data Lisensi ===========================
	ubahDataLisensi = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Lisensi Desa";
		this.forceUpdate();

		Get('invoice-pembelian/find', id, (data) => {
			// console.log("UBAH DATA")
			// console.log(data.result)
			if (data.result) {
				let tgl_lhr = data.result.tgl_pembelian.split(" ");
      	let tgl_pembelian = tgl_lhr[0]

				let tgl_lhr2 = data.result.tgl_expired.split(" ");
      	let tgl_expired = tgl_lhr2[0]

				this.state.data_lisensi.uuid = id
				this.state.data_lisensi.tgl_pembelian = tgl_pembelian
				this.state.data_lisensi.tgl_expired = tgl_expired
				// this.state.data_lisensi.durasi_expired = data.result.durasi_expired
				// console.log("Tanggal Split")
				// console.log(tgl_lhr)
				// console.log(tgl_pembelian)
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('ubah_lisensi');
		});
	}
	// ============================ End Ubah Data Lisensi ===========================

	// ======================= SIMPAN LISENSI =====================
	simpanLisensi = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let addData = {
			uuid: document.getElementById("uuid_lisensi").value,
			uuid_list_api: this.props.location.state.uuid,
			tgl_pembelian: document.getElementById("tgl_pembelian").value,
			tgl_expired: document.getElementById("tgl_expired").value,
			// durasi_expired: document.getElementById("durasi_expired").value,
		};


		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addData.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addData.uuid = null;

		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		// console.log("Data SIMPAN LISENSI")
		// console.log(addData)
		// console.log(psn +'/' + resstat+'/' +metode)

		Post('invoice-pembelian/' + metode, addData.uuid, addData, (res) => {
			// console.log(res)
			this.state.status.btnForm = false;
			this.state.status.formLisensi = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Lisensi', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data LIsensi', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	// ======================= END SIMPAN LISENSI =====================

	// ============================ Hapus Data Lisensi ==========================

	konfirmHapusLisensi = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusDataLisensi(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusDataLisensi = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('invoice-pembelian/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Lisensi', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Lisensi', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	// ============================ End Hapus Data Lisensi ==========================


	// ============================ Ubah Data Layanan ===========================
	// ubahDataLayanan = (id) => {
	// 	this.state.status.btnAksi = true;
	// 	this.state.judul = "Ubah Lisensi Desa";
	// 	this.forceUpdate();

	// 	Get('invoice-pembelian/find', id, (data) => {
	// 		console.log("UBAH DATA")
	// 		console.log(data.result)
	// 		if (data.result) {
	// 			let tgl_lhr = data.result.tgl_pembelian.split(" ");
    //   			let tgl_pembelian = tgl_lhr[0]

	// 			this.state.data_lisensi.uuid = id
	// 			this.state.data_lisensi.tgl_pembelian = tgl_pembelian
	// 			this.state.data_lisensi.durasi_expired = data.result.durasi_expired
	// 			console.log("Tanggal Split")
	// 			console.log(tgl_lhr)
	// 			console.log(tgl_pembelian)
	// 		}
	// 		this.state.status.btnAksi = false;
	// 		this.forceUpdate();
	// 		this.bukaForm('ubah_lisensi');
	// 	});
	// }
	// ============================ End Ubah Data Layanan ===========================

	// ======================= SIMPAN Layanan =====================
	simpanLayanan = e => {
		// e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let cekLayanan = this.state.data_layanan
		let allLayanan = []
		let dataLayananDesaMobile = ""
		let dataLayananCapilMobile = ""
		let dataLayananKuaMobile = ""
		let dataLayananDesaWeb = ""
		let dataLayananCapilWeb = ""
		let dataLayananKuaWeb = ""
		let dataKoneksiWeb = ""
		let dataLayananPPOB = ""

		let psn = '';
		let resstat = 204;
		let metode = "";

		let ttd_digital = ""
		let tte_digital = ""
		let ketersediaan_info = ""
		let ketersediaan_pengumuman = ""
		let ketersediaan_info_bupati = ""
		let riwayat_disposisi = ""
		let layanan_capil = ""
		let layanan_kua = ""
		let layanan_ppob = ""
		let pembayaran = ""

		if(this.state.ketersedian_ttd == true){
			ttd_digital = 1
		}
		else if(this.state.ketersedian_ttd == false){
			ttd_digital = 0
		}

		if(this.state.status_tte == true){
			tte_digital = 1
		}
		else if(this.state.status_tte == false){
			tte_digital = 0
		}

		if(this.state.ketersediaan_info == true){
			ketersediaan_info = 1
		}
		else if(this.state.ketersediaan_info == false){
			ketersediaan_info = 0
		}

		if(this.state.ketersediaan_pengumuman == true){
			ketersediaan_pengumuman = 1
		}
		else if(this.state.ketersediaan_pengumuman == false){
			ketersediaan_pengumuman = 0
		}

		if(this.state.ketersediaan_info_bupati == true){
			ketersediaan_info_bupati = 1
		}
		else if(this.state.ketersediaan_info_bupati == false){
			ketersediaan_info_bupati = 0
		}

		if(this.state.riwayat_disposisi == true){
			riwayat_disposisi = 1
		}
		else if(this.state.riwayat_disposisi == false){
			riwayat_disposisi = 0
		}

		if(this.state.layanan_capil == true){
			layanan_capil = 1
		}
		else if(this.state.layanan_capil == false){
			layanan_capil = 0
		}

		if(this.state.layanan_kua == true){
			layanan_kua = 1
		}
		else if(this.state.layanan_kua == false){
			layanan_kua = 0
		}

		if(this.state.layanan_ppob == true){
			layanan_ppob = 1
		}
		else if(this.state.layanan_ppob == false){
			layanan_ppob = 0
		}

		if(this.state.pembayaran == true){
			pembayaran = 1
		}
		else if(this.state.pembayaran == false){
			pembayaran = 0
		}

		let version_desa_app = ""
		let version_min_desa_app = ""
		if(this.state.konfig_umum == "inherit"){
			version_desa_app = document.getElementById('version_desa_app').value
			version_min_desa_app = document.getElementById('version_min_desa_app').value
		}
		else if(this.state.konfig_umum == "none"){
			version_desa_app = this.state.version_desa_app
			version_min_desa_app = this.state.version_min_desa_app
		}

		if(this.state.konfig_web == "inherit"){
			dataKoneksiWeb = {
				jenis_desa: document.getElementById('jenis_desa').value,
				jenis_dusun: document.getElementById('jenis_dusun').value,
				kepala_polisi_kecamatan: document.getElementById('kepala_polisi_kecamatan').value,
				kantor_polisi_kecamatan: document.getElementById('kantor_polisi_kecamatan').value,
				kepala_polisi_kabupaten: document.getElementById('kepala_polisi_kabupaten').value,
				kantor_polisi_kabupaten: document.getElementById('kantor_polisi_kabupaten').value,
				kepala_polisi_provinsi: document.getElementById('kepala_polisi_provinsi').value,
				kantor_polisi_provinsi: document.getElementById('kantor_polisi_provinsi').value,
			}
		}
		else if(this.state.konfig_web == "none"){
			dataKoneksiWeb = {
				jenis_desa: this.state.koneksi_web.jenis_desa,
				jenis_dusun: this.state.koneksi_web.jenis_dusun,
				kepala_polisi_kecamatan: this.state.koneksi_web.kepala_polisi_kecamatan,
				kantor_polisi_kecamatan: this.state.koneksi_web.kantor_polisi_kecamatan,
				kepala_polisi_kabupaten: this.state.koneksi_web.kepala_polisi_kabupaten,
				kantor_polisi_kabupaten: this.state.koneksi_web.kantor_polisi_kabupaten,
				kepala_polisi_provinsi: this.state.koneksi_web.kepala_polisi_provinsi,
				kantor_polisi_provinsi: this.state.koneksi_web.kantor_polisi_provinsi,
			}
		}

		this.forceUpdate()

		if(cekLayanan == null){
			// console.log("Data null bos")
			// console.log("Metode Create")
			dataLayananDesaMobile = this.state.list_layanan_desa_mobile
			dataLayananCapilMobile = this.state.list_layanan_capil_mobile
			dataLayananKuaMobile = this.state.list_layanan_kua_mobile
			dataLayananDesaWeb = this.state.list_layanan_desa_web
			dataLayananCapilWeb = this.state.list_layanan_capil_web
			dataLayananKuaWeb = this.state.list_layanan_kua_web
			dataLayananPPOB = this.state.list_layanan_ppob
			allLayanan = {
				uuid_list_api: this.props.location.state.uuid,
				list_layanan_desa_mobile: JSON.stringify(dataLayananDesaMobile),
				list_layanan_capil_mobile: JSON.stringify(dataLayananCapilMobile),
				list_layanan_kua_mobile: JSON.stringify(dataLayananKuaMobile),
				list_layanan_desa_web: JSON.stringify(dataLayananDesaWeb),
				list_layanan_capil_web: JSON.stringify(dataLayananCapilWeb),
				list_layanan_kua_web: JSON.stringify(dataLayananKuaWeb),
				koneksi_web: JSON.stringify(dataKoneksiWeb),
				version_desa_app: version_desa_app,
				version_min_desa_app: version_min_desa_app,
				ketersedian_ttd: ttd_digital,
				status_tte: tte_digital,
				ketersediaan_info: ketersediaan_info,
				ketersediaan_pengumuman: ketersediaan_pengumuman,
				ketersediaan_info_bupati: ketersediaan_info_bupati,
				riwayat_disposisi: riwayat_disposisi,
				layanan_capil : layanan_capil,
				layanan_kua : layanan_kua,
				layanan_ppob : layanan_ppob,
				pembayaran : pembayaran,
				list_layanan_ppob : JSON.stringify(dataLayananPPOB),
				status_absensi : this.state.config_list_layanan_desa.status_absensi,
				status_todolist : this.state.config_list_layanan_desa.status_todolist,
			}
			psn = "Tambah";
			resstat = 200;
			metode = "create";
			allLayanan.uuid = null
		}
		else{
			// console.log("Data Ada")
			// console.log("Metode Update")
			dataLayananDesaMobile = this.state.list_layanan_desa_mobile
			dataLayananCapilMobile = this.state.list_layanan_capil_mobile
			dataLayananKuaMobile = this.state.list_layanan_kua_mobile
			dataLayananDesaWeb = this.state.list_layanan_desa_web
			dataLayananCapilWeb = this.state.list_layanan_capil_web
			dataLayananKuaWeb = this.state.list_layanan_kua_web
			dataLayananPPOB = this.state.list_layanan_ppob
			allLayanan = {
				uuid_list_api: this.props.location.state.uuid,
				list_layanan_desa_mobile: JSON.stringify(dataLayananDesaMobile),
				list_layanan_capil_mobile: JSON.stringify(dataLayananCapilMobile),
				list_layanan_kua_mobile: JSON.stringify(dataLayananKuaMobile),
				list_layanan_desa_web: JSON.stringify(dataLayananDesaWeb),
				list_layanan_capil_web: JSON.stringify(dataLayananCapilWeb),
				list_layanan_kua_web: JSON.stringify(dataLayananKuaWeb),
				koneksi_web: JSON.stringify(dataKoneksiWeb),
				version_desa_app: version_desa_app,
				version_min_desa_app: version_min_desa_app,
				ketersedian_ttd: ttd_digital,
				status_tte: tte_digital,
				ketersediaan_info: ketersediaan_info,
				ketersediaan_pengumuman: ketersediaan_pengumuman,
				ketersediaan_info_bupati: ketersediaan_info_bupati,
				riwayat_disposisi: riwayat_disposisi,
				layanan_capil : layanan_capil,
				layanan_kua : layanan_kua,
				layanan_ppob : layanan_ppob,
				pembayaran : pembayaran,
				list_layanan_ppob : JSON.stringify(dataLayananPPOB),
				status_absensi : this.state.config_list_layanan_desa.status_absensi,
				status_todolist : this.state.config_list_layanan_desa.status_todolist,
			}
			psn = "Ubah";
			resstat = 200;
			metode = "update";
			allLayanan.uuid = this.state.data_layanan.uuid
		}

		// console.log('Simpan allLayanan')
		// console.log(allLayanan)
		Post('list-layanan-desa/' + metode, allLayanan.uuid, allLayanan, (res) => {
			this.forceUpdate();
			if (res.status === resstat) {
				if(this.state.konfig_umum == "inherit"){
					this.setState({ show: true, basicType: 'success', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.state.konfig_umum = "none"
				}
				if(this.state.konfig_web == "inherit"){
					this.setState({ show: true, basicType: 'success', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.state.konfig_web = "none"
				}
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	// ======================= END SIMPAN Layanan =====================

	// ============================ Hapus Data Layanan ==========================

	konfirmHapusLayanan = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusDataLayanan(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusDataLayanan = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('invoice-pembelian/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Lisensi', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Lisensi', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	// ============================ End Hapus Data Lisensi ==========================

	bukaForm = (e) => {
		if (e == 'tambah') {

			this.state.id_desa = '';
			this.state.jenis_pembayaran = [];
			this.state.uuid_bank = '';
			this.state.data_pembayaran.nomor_rekening = '';
			this.state.data_pembayaran.uuid = '';
			this.state.data_pembayaran.kode_pembayaran = '';
			this.state.data_pembayaran.atas_nama = '';
			this.state.status.form = true;
			this.forceUpdate();
		} 
		else if (e == 'ubah_lisensi') {
			this.state.status.formLisensi = true;
			this.forceUpdate();
		} 
		else {
			this.state.status.form = true;
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.state.status.formLisensi = false;
		this.forceUpdate();
	}

	bukaForm2 = (e) => {
		if (e == 'tambah') {

			this.state.id_desa = '';
			this.state.id_jenis_pengiriman = [];
			this.state.status.form2 = true;
			this.forceUpdate();
		} else {
			this.state.status.form2 = true;
			this.forceUpdate();
		}

	}

	tutupForm2 = () => {
		this.state.status.form2 = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	tombolAksi2 = (cell, row) => {
		return (
			<>
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapus2(row.uuid)} disabled={this.state.status.btnAksi2}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	tombolAksiLisensi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataLisensi(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapusLisensi(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	tombolAksiLayanan = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahDataLayanan(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapusLayanan(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	componentDidMount() {

		GetDigidesa('jenis-surat/list', null, (data) => {
			// console.log("Coba Tembak Desa")
			// console.log(data)
			if (data.results) {
				let list_layanan_desa_web = {};
				data.results.forEach(dt => {
					let jenis = dt.nama.toLowerCase().replace(/ /g, "_");
					// console.log('DATA TEMBAK DESA')
					// console.log(jenis)
					list_layanan_desa_web = {
						...list_layanan_desa_web,
						[jenis] : false
					}
				});
				// console.log('Hasil DATA TEMBAK DESA')
				// console.log(list_layanan_desa_web)
				// this.setState({
				// 	list_layanan_desa_web
				// })

				// DATA LAYANAN DESA
				Get('list-layanan-desa/' + id_bumdes, null, (data) => {
					if(data.result){
						this.setState({ config_list_layanan_desa: data.result })

						let layananDesaWebnya = ''
						let layananKiosK = ''
						// let layananCetakKiosK = ''
						if(data.result.list_layanan_desa_web==='' || data.result.list_layanan_desa_web===null){
							layananDesaWebnya = list_layanan_desa_web
						}
						else{
							layananDesaWebnya = JSON.parse(data.result.list_layanan_desa_web)
							layananKiosK = layananDesaWebnya.layanan_kiosk
							// layananCetakKiosK = layananDesaWebnya.cetak_layanan_kiosk
						}

						// console.log('layananDesaWebnya')
						// console.log(layananDesaWebnya)
						// console.log(layananKiosK)
						// console.log(layananCetakKiosK)
						
						let layananDesaMobilenya = JSON.parse(data.result.list_layanan_desa_mobile)
						let layananCapilMobilenya = JSON.parse(data.result.list_layanan_capil_mobile)
						let layananKuaMobilenya = JSON.parse(data.result.list_layanan_kua_mobile)
						let layananCapilWebnya = JSON.parse(data.result.list_layanan_capil_web)
						let layananKuaWebnya = JSON.parse(data.result.list_layanan_kua_web)
						let layananPPOB = JSON.parse(data.result.list_layanan_ppob)
						let koneksiWeb = JSON.parse(data.result.koneksi_web)
						let ttd_digital = ""
						let tte_digital = ""
						let ketersediaan_info = ""
						let ketersediaan_pengumuman = ""
						let ketersediaan_info_bupati = ""
						let disposisi = ""
						let layanan_capil = ""
						let layanan_kua = ""
						let layanan_ppob = ""
						let pembayaran = ""

						if(data.result.ketersedian_ttd==1){
							ttd_digital = true
						}
						else if(data.result.ketersedian_ttd==0){
							ttd_digital = false
						}

						if(data.result.status_tte==1){
							tte_digital = true
						}
						else if(data.result.status_tte==0){
							tte_digital = false
						}

						if(data.result.ketersediaan_info==1){
							ketersediaan_info = true
						}
						else if(data.result.ketersediaan_info==0){
							ketersediaan_info = false
						}

						if(data.result.ketersediaan_pengumuman==1){
							ketersediaan_pengumuman = true
						}
						else if(data.result.ketersediaan_pengumuman==0){
							ketersediaan_pengumuman = false
						}

						if(data.result.ketersediaan_info_bupati==1){
							ketersediaan_info_bupati = true
						}
						else if(data.result.ketersediaan_info_bupati==0){
							ketersediaan_info_bupati = false
						}

						if(data.result.riwayat_disposisi==1){
							disposisi = true
						}
						else if(data.result.riwayat_disposisi==0){
							disposisi = false
						}

						if(data.result.layanan_capil==1){
							layanan_capil = true
						}
						else if(data.result.layanan_capil==0){
							layanan_capil = false
						}

						if(data.result.layanan_kua==1){
							layanan_kua = true
						}
						else if(data.result.layanan_kua==0){
							layanan_kua = false
						}

						if(data.result.layanan_ppob==1){
							layanan_ppob = true
						}
						else if(data.result.layanan_ppob==0){
							layanan_ppob = false
						}

						if(data.result.pembayaran==1){
							pembayaran = true
						}
						else if(data.result.pembayaran==0){
							pembayaran = false
						}
						
						Object.keys(layananDesaWebnya).forEach((val) => {
							if(layananDesaWebnya[val] === true){
								list_layanan_desa_web = {
									...list_layanan_desa_web,
									[val] : true
								}
							}
							else{
								list_layanan_desa_web = {
									...list_layanan_desa_web,
									[val] : false
								}
							}
							
							// if(val===)
							// let labelUsia = attribute.replace('_', ' ')
			
							// option_usia.push({
							// 	value: attribute,
							// 	label: labelUsia.charAt(0).toUpperCase() + labelUsia.slice(1)
							// });
						})
						
						this.setState({
							data_layanan: data.result,
							list_layanan_desa_mobile: layananDesaMobilenya,
							list_layanan_capil_mobile: layananCapilMobilenya,
							list_layanan_kua_mobile: layananKuaMobilenya,
							list_layanan_ppob: layananPPOB,
							list_layanan_desa_web: {
								...list_layanan_desa_web, 
								layanan_kiosk: layananKiosK,
								// cetak_layanan_kiosk: layananCetakKiosK
							},
							list_layanan_capil_web: layananCapilWebnya,
							list_layanan_kua_web: layananKuaWebnya,
							koneksi_web: koneksiWeb,
							version_desa_app: data.result.version_desa_app,
							version_min_desa_app: data.result.version_min_desa_app,
							ketersedian_ttd: ttd_digital,
							status_tte: tte_digital,
							ketersediaan_info: ketersediaan_info,
							ketersediaan_pengumuman: ketersediaan_pengumuman,
							ketersediaan_info_bupati: ketersediaan_info_bupati,
							riwayat_disposisi: disposisi,
							layanan_capil: layanan_capil,
							layanan_kua: layanan_kua,
							layanan_ppob: layanan_ppob,
							pembayaran: pembayaran,
						});
					}
					else{
						this.setState({
							list_layanan_desa_mobile: {
								tidak_mampu: true,
								domisili: true,
								kelahiran: true,
								belum_memiliki_rumah: true,
								jalan: true,
								kematian: true,
								kepemilikan_rumah: true,
								penghasilan: true,
								skck: true,
								kehilangan: true,
								usaha: true,
								skgr: true,
								belum_menikah: true,
								tidak_menikah: true,
								pindah_menikah: true,
								pernah_menikah: true
							},
							list_layanan_capil_mobile: {
								kelahiran: true,
								pindah_datang: true,
								akta_kematian: true,
								penambahan_anak: true,
								pecah_kk: true,
								perubahan_data: true
							},
							list_layanan_kua_mobile: {
								pernikahan: true
							},
							list_layanan_ppob: {
								top_up_bumdes: true,
								transfer_bank: true,
								va: true,
							},
							list_layanan_desa_web: list_layanan_desa_web,
							// list_layanan_desa_web: {
							// 	tidak_mampu: true,
							// 	domisili: true,
							// 	domisili_usaha: true,
							// 	kelahiran: true,
							// 	belum_memiliki_rumah: true,
							// 	jalan: true,
							// 	kematian: true,
							// 	kepemilikan_rumah: true,
							// 	penghasilan: true,
							// 	skck: true,
							// 	kehilangan: true,
							// 	usaha: true,
							// 	skgr: true,
							// 	belum_menikah: true,
							// 	tidak_menikah: true,
							// 	pindah_menikah: true,
							// 	pindah_datang_wni: true,
							// 	pernah_menikah: true,
							// 	keramaian: true,
							// 	umum: true,
							// 	ahli_waris: true,
							// 	kuasa: true,
							// 	undangan: true,
							// 	penyaksian_tanah: true,
							// 	penghasilan_orangtua: true,
							// 	penguburan: true,
							// },
							list_layanan_capil_web: {
								kelahiran: true,
								pindah_datang: true,
								akta_kematian: true,
								penambahan_anak: true,
								pecah_kk: true,
								perubahan_data: true
							},
							list_layanan_kua_web: {
								pernikahan: true
							},
							koneksi_web: {
								jenis_desa: "",
								jenis_dusun: "",
								kepala_polisi_kecamatan: "",
								kantor_polisi_kecamatan: "",
								kepala_polisi_kabupaten: "",
								kantor_polisi_kabupaten: "",
								kepala_polisi_provinsi: "",
								kantor_polisi_provinsi: "",
							},
							version_desa_app: "-",
							version_min_desa_app: "-",
							ketersedian_ttd: true,
							ketersedian_tte: true,
							ketersediaan_info: true,
							ketersediaan_pengumuman: true,
							ketersediaan_info_bupati: true,
							riwayat_disposisi: true,
							layanan_capil: true,
							layanan_kua: true,
							layanan_ppob: true,
							pembayaran: false,
						})
					}
				});
			}
		});

		// ================== detail toko =================
		// console.log("====================")
		// console.log("DATA DETAIL API")
		// console.log(this.props.location.state)
		// console.log("DATA DETAIL API")
		// console.log("========UUIDNYA============")
		// console.log(this.props.location.state.uuid)

		//Tampil Data Jenis Pembayaran

		let id = this.props.location.state.id_desa;

		Get('jenis-pengiriman-desa/by-id-desa/' + id, null, (lihat) => {
			// console.log("Data Jenis Pengiriman Desa")
			// console.log(lihat)
			// console.log("Data Jenis Pengiriman Desa")
			if (lihat.result) {
				this.setState({ data2: lihat.result, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});

		// DATA BANK

		Get('bank/list', null, (data) => {
			if (data.result) {
				let data_bank = [];
				data.result.data.map(dt => {
					data_bank.push({ value: dt.uuid, label: dt.nama_bank });
				})
				this.setState({
					data_bank
				});
			}
		});


		// DATA Jenis pengiriman

		Get('jenis-pengiriman', null, (data) => {
			if (data.result) {
				let jenis_pengiriman = [];
				data.result.data.map(dt => {
					jenis_pengiriman.push({ value: dt.uuid, label: dt.jenis_pengiriman });
				})
				this.setState({
					jenis_pengiriman
				});
			}
		});


		var id_bumdes = this.props.location.state.id_desa
		// DATA AKUN BUMDES
		Get('bumdes/desa/' + id_bumdes, null, (data) => {
			if (data.result) {
				// console.log("Data Akun Bumdes")
				// console.log(data.result)
				let data_akun = [];
				this.setState({
					data_akun: data.result
				});
			}
		});
		// DATA LISENSI DESA
		Get('invoice-pembelian/list-bydesa/' + id_bumdes, null, (data) => {
			if (data.result) {
				// console.log("Data Lisensi Desa")
				// console.log(data.result)
				let data_lisensi = [];
				this.setState({
					data_lisensi: data.result.data
				});
			}
		});

		this.fetch({ page: null, where: [] });
	}

	onChangeBank = (e) => {
		if (e) {
			this.setState({ uuid_bank: { value: e.value, label: e.label } });
		}
	}

	getBank = (e) => {
		if (e) {
			let addBank = {
				q: e
			};
			Post('bank/list', null, addBank, (data) => {
				if (data.data.result) {
					let data_bank = [];
					data.data.result.data.map(dt => {
						data_bank.push({ value: dt.uuid, label: dt.nama_bank });
					});
					this.setState({ data_bank });
				}
			})
		}
		else {
			Get('bank/list', null, (data) => {
				if (data.result) {
					let data_bank = [];
					data.result.data.map(dt => {
						data_bank.push({ value: dt.uuid, label: dt.nama_bank });
					})
					this.setState({
						data_bank
					});
				}
			});

		}
	}

	onChangePengiriman = (e) => {
		if (e) {
			this.setState({ id_jenis_pengiriman: { value: e.value, label: e.label } });
		}
	}

	getPengiriman = (e) => {
		if (e) {
			let addPengiriman = {
				q: e
			};
			Post('jenis-pengiriman', null, addPengiriman, (data) => {
				if (data.data.result) {
					let jenis_pengiriman = [];
					data.data.result.data.map(dt => {
						jenis_pengiriman.push({ value: dt.uuid, label: dt.jenis_pengiriman });
					});
					this.setState({ jenis_pengiriman });
				}
			})
		}
		else {
			Get('bank/list', null, (data) => {
				if (data.result) {
					let data_bank = [];
					data.result.data.map(dt => {
						data_bank.push({ value: dt.uuid, label: dt.nama_bank });
					})
					this.setState({
						data_bank
					});
				}
			});

		}
	}

	onChangePembayaran = (e) => {
		if (e) {
			this.state.jenis_pembayaran = { value: e.value, label: e.label };
			this.forceUpdate();
		}
		else {
			this.state.jenis_pembayaran = '';
			this.forceUpdate();
		}
	}

	// ============================== Ubah Data Lisensi ===============================

	tambahDataLisensi = (status, uuid) => {
		this.state.status.formLisensi = true;
		this.state.data_lisensi.uuid = ""
		this.state.data_lisensi.tgl_pembelian = ""
		this.state.data_lisensi.tgl_expired = ""
		this.state.data_lisensi.durasi_expired = ""
		this.state.judul = 'Pendaftaran Lisensi Desa';
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};
	// ========================================================================


	// ============================== Ubah Konfig ===============================

	ubahKonfig = (e) => {
		e.preventDefault();
		this.setState({ konfig_umum: 'inherit'});
	};
	// ========================================================================

	// ============================== Ubah Konfig ===============================

	ubahKonfigWeb = (e) => {
		e.preventDefault();
		this.setState({ konfig_web: 'inherit'});
	};
	// ========================================================================

	capitalizeFirstLetter = (string) => {
		return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());;
		// return string.charAt(0).toUpperCase() + string.slice(1);
	}

	render() {
		// console.log("Data Render")
		// console.log(this.state.list_layanan_desa_web)
		// console.log(this.state.list_layanan_desa_mobile.tidak_mampu)
		// console.log("Data Lisensi");
		// console.log(this.state.data_lisensi);
		// console.log("Data Layanan Pertama");
		// console.log(this.state.data_layanan);
		// console.log(this.state.list_layanan_desa_mobile);
		// console.log(this.state.list_layanan_capil_mobile);
		// console.log(this.state.list_layanan_kua_mobile);
		// console.log(this.state.list_layanan_desa_web);
		// console.log(this.state.list_layanan_capil_web);
		// console.log(this.state.list_layanan_kua_web);
		// console.log("Tambahan Umum")
		// console.log(this.state.konfig_umum);
		// console.log('desa app '+this.state.version_desa_app);
		// console.log('min desa app '+this.state.version_min_desa_app);
		// console.log('ttd '+this.state.ketersedian_ttd);
		// console.log('disposisi '+this.state.riwayat_disposisi);
		// console.log('capil '+this.state.layanan_capil);
		// console.log('kua '+this.state.layanan_kua);
		// console.log("Data Parsing");
		// console.log(this.props.location.state)
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var data2 = this.state.data2;
		var data_lisensi = this.state.data_lisensi;
		var data_layanan = this.state.data_layanan;
		var list_layanan_desa_mobile = [];
		var list_layanan_capil_mobile = [];
		var list_layanan_kua_mobile = [];
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'kode_pembayaran',
				text: 'Kode Pembayaran',
				sort: true
			},
			{
				dataField: 'jenis_pembayaran',
				text: 'Jenis Pembayaran',
				sort: true
			},
			{
				dataField: 'data_bank.nama_bank',
				text: 'Nama Bank',
				sort: true
			},
			{
				dataField: 'nomor_rekening',
				text: 'Nomor Rekening',
				sort: true
			},

			{
				dataField: 'atas_nama',
				text: 'Atas Nama',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];


		//Data table jenis pengiriman
		const columns2 = [
			{
				dataField: 'data_jenis_pengiriman.kode_jenis_pengiriman',
				text: 'Kode Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'data_jenis_pengiriman.jenis_pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi2,
			},
		];

		//Data table jenis pengiriman
		const columns3 = [
			{
				dataField: 'data_jenis_pengiriman.kode_jenis_pengiriman',
				text: 'Kode Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'data_jenis_pengiriman.jenis_pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
			},
		];

		const columnsLisensi = [
			{
				dataField: 'no_invoice',
				text: 'No. Invoice',
				sort: true
			},
			{
				dataField: 'lisensi',
				text: 'Lisensi',
				sort: true
			},
			{
				dataField: 'tgl_pembelian',
				text: 'Tanggal Pembelian',
				sort: true
			},
			{
				dataField: 'tgl_expired',
				text: 'Tanggal Expired',
				sort: true
			},
			// {
			// 	dataField: 'durasi_expired',
			// 	text: 'Durasi (Tahun)',
			// 	sort: true
			// },
			
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksiLisensi,
			},
		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Konfigurasi Desa" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>

									<br/><br/>
									<div>
										<Nav tabs className="tabs-color">
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '1' })}
													onClick={() => { this.toggle_icon('1'); }} >
													<i className="icofont icofont-pay"></i>Jenis Pembayaran
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
													onClick={() => { this.toggle_icon('2'); }} >
													<i className="icofont icofont-truck-alt"></i>Jenis Pengiriman
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
													onClick={() => { this.toggle_icon('3'); }} >
													<i className="icofont icofont-user"></i>Akun Bumdes
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '4' })}
													onClick={() => { this.toggle_icon('4'); }} >
													<i className="icofont icofont-key"></i>Pendaftaran Lisensi
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '5' })}
													onClick={() => { this.toggle_icon('5'); }} >
													<i className="icofont icofont-gears"></i>Layanan Desa
												</NavLink>
											</NavItem>
										</Nav>
										{/* ===============================================================										 */}
										<TabContent activeTab={this.state.active_tab_icon}>
											<TabPane tabId="1">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />
															<div align="left">
																<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Pembayaran Desa", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
															</div>
															<div><br /><br /><h2 align="center">Jenis Pembayaran Desa</h2><hr /><br /><br /></div>

															<BootstrapTable
																keyField="uuid"
																data={data}
																columns={columns}
															/>
														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
											<TabPane tabId="2">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />
															<div align="left">
																<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Pengiriman Desa", dt: frmDef }); this.bukaForm2('tambah'); }}>Tambah Data</Button>
															</div>
															<div><br /><br /><h2 align="center">Jenis Pengiriman Desa</h2><hr /><br /><br /></div>
															<BootstrapTable
																keyField="uuid"
																data={data2}
																columns={columns2}
															/>
														</Col>
													</Row>

												</div>
											</TabPane>
											{/* =========================================================================== */}
											<TabPane tabId="3">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />

															<div><br /><br /><h2 align="center">Akun Bumdes</h2><hr /><br /><br /></div>

															<Form className="theme-form" onSubmit={this.simpan_akun}>
																<div className="modal-body">
																	<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data_akun.uuid} />
																	<input className="form-control" id="id_desa" type="hidden" placeholder="id Desa" defaultValue={this.props.location.state.id_desa} />
																	<input className="form-control" id="role" type="hidden" placeholder="Role" defaultValue='2' />

																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Desa</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama_desa" type="text" placeholder="Nama Desa" defaultValue={this.props.location.state.data_desa.nama} disabled />
																		</Col>
																	</FormGroup>


																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Username Bumdes</Label>
																		<Col sm='9'>
																			<input className="form-control" id="username" type="text" placeholder="Username Bumdes" defaultValue={this.state.data_akun.username} required />
																		</Col>
																	</FormGroup>

																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Password Bumdes</Label>
																		<Col sm='9'>
																			<input className="form-control" id="password" type="password" placeholder="Password Bumdes" required />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">No. Handphone</Label>
																		<Col sm='9'>
																			<input className="form-control" id="no_hp" type="text" placeholder="No. Handphone Pengguna" defaultValue={this.state.data_akun.no_hp} required />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Email</Label>
																		<Col sm='9'>
																			<input className="form-control" id="email" type="text" placeholder="Email Pengguna" defaultValue={this.state.data_akun.email} />
																		</Col>
																	</FormGroup>
																</div>
																<div className="modal-footer">
																	<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan_akun}>Simpan</button>
																</div>
															</Form>
														</Col>
													</Row>

												</div>
											</TabPane>
											{/* =========================================================================== */}
											<TabPane tabId="4">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />

															<h2 align="center">Pendaftaran Lisensi</h2>
															<br/><br/>
															<div className="col-md-12 text-right">

																	<Button  size="md" color="success" className="btn-icon" onClick={() => this.tambahDataLisensi('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Data Lisensi</Button> 
															</div>
															<div>
																<br/>&nbsp;<br/>
															</div>
																{
																this.state.data_lisensi
																?
																	<>
																		<div className="col-md-12 text-center">
																		<BootstrapTable
																			keyField="uuid"
																			data={data_lisensi}
																			columns={columnsLisensi}
																		/>
																		</div>
																	</>
																:
																(
																	<div className="col-md-12 text-center">
																		<h4 align="center">Data Tidak Dapat Ditemukan</h4>
																	</div>
																)
																}
														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
	<TabPane tabId="5">
		<div style={{ marginTop: 15 }}>
			<Row>
				<Col sm="12">
					<div className="card-body">
						<div className="row">
							<div className="col-sm-3 col-xs-12">
								<Nav  className={'nav flex-column nav-pills'}>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
												onClick={() => { this.toggle_vertical('1'); }}>
											Umum
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '11' })}
												onClick={() => { this.toggle_vertical('11'); }}>
											Konfigurasi Web
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: this.state.active_tab_vertical === '5' })}
											onClick={() => { this.toggle_vertical('5'); }} >
											Layanan Mobile
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '6' })}
												onClick={() => { this.toggle_vertical('6'); }} >
											Disdukcapil Mobile
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '7' })}
												onClick={() => { this.toggle_vertical('7'); }}>
											KUA Mobile
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: this.state.active_tab_vertical === '8' })}
											onClick={() => { this.toggle_vertical('8'); }} >
											Layanan Web
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '9' })}
												onClick={() => { this.toggle_vertical('9'); }} >
											Disdukcapil Web
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '10' })}
												onClick={() => { this.toggle_vertical('10'); }}>
											KUA Web
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab_vertical === '12' })}
												onClick={() => { this.toggle_vertical('12'); }}>
											Layanan PPOB
										</NavLink>
									</NavItem>
								</Nav>
							</div>
							<div className="col-sm-9 col-xs-12">
								<TabContent activeTab={this.state.active_tab_vertical}>
									<TabPane tabId="1">
										<Row>
											<Col sm="12">
												<p>
													<div>
														{ 
															this.state.konfig_umum == 'none'
															?
															<div className="row">
																<div className="col-md-12 text-right">
																		<Button  size="md" color="primary" className="btn-icon" onClick={this.ubahKonfig}><i className="fa fa-pencil"></i> Ubah Konfigurasi</Button> 
																</div>
																
																<br/>
																<div className="col-sm-12" align="center">
																	<b>Konfigurasi Umum</b>
																</div>
																<br/>

																<div className="col-sm-6">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Version Desa App
																	</Label>
																	<input
																		className="form-control"
																		id="version_desa_app"
																		type="text"
																		placeholder="Version Desa App"
																		defaultValue={this.state.version_desa_app}
																		readOnly
																	/>
																</div>
																<div className="col-sm-6">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Version Desa Min Desa App
																	</Label>
																	<input
																		className="form-control"
																		id="version_min_desa_app"
																		type="text"
																		placeholder="Version Min Desa App"
																		defaultValue={this.state.version_min_desa_app}
																		readOnly
																	/>
																</div>
																<br/>
																<div className="col-sm-9" align="left">
																	Tanda Tangan Digital
																</div>
																<div className="col-sm-3" align="right">
																	{/* <Switch 
																		onChange={(e) => {
																		this.handleChange();
																		}}
																		checked='true' 
																	/> */}
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_ttd');
																		}}
																		checked={this.state.ketersedian_ttd} 
																		disabled
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Tanda Tangan Elektronik Kominfo
																</div>
																<div className="col-sm-3" align="right">
																	{/* <Switch 
																		onChange={(e) => {
																		this.handleChange();
																		}}
																		checked='true' 
																	/> */}
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'status_tte');
																		}}
																		checked={this.state.status_tte} 
																		disabled
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Riwayat Disposisi
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'riwayat_disposisi');
																		}}
																		checked={this.state.riwayat_disposisi} 
																		disabled
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Aplikasi Berbayar
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'pembayaran');
																		}}
																		checked={this.state.pembayaran} 
																		disabled
																	/>
																</div>	
																
																<div className="col-sm-9" align="left">
																	Aplikasi Absensi
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeConfigLayananDesa(e,'status_absensi');
																		}}
																		checked={this.state.config_list_layanan_desa ? this.state.config_list_layanan_desa.status_absensi : false} 
																		disabled
																	/>
																</div>	
																
																<div className="col-sm-9" align="left">
																	Aplikasi To Do List
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeConfigLayananDesa(e,'status_todolist');
																		}}
																		checked={this.state.config_list_layanan_desa ? this.state.config_list_layanan_desa.status_todolist : false} 
																		disabled
																	/>
																</div>	

																<br/>
																<div className="col-sm-12" align="center">
																	<b>Konfigurasi Layanan</b>
																</div>
																<br/>
																
																<div className="col-sm-9" align="left">
																	Layanan Capil
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'layanan_capil');
																		}}
																		checked={this.state.layanan_capil} 
																		disabled
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Layanan KUA
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'layanan_kua');
																		}}
																		checked={this.state.layanan_kua} 
																		disabled
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Layanan PPOB
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'layanan_ppob');
																		}}
																		checked={this.state.layanan_ppob} 
																		disabled
																	/>
																</div>

																<br/>
																<div className="col-sm-12" align="center">
																	<b>Info dan Pengumuman</b>
																</div>
																<br/>

																<div className="col-sm-9" align="left">
																	Ketersediaan Info
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_info');
																		}}
																		checked={this.state.ketersediaan_info} 
																		disabled
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Ketersediaan Info Bupati
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_info_bupati');
																		}}
																		checked={this.state.ketersediaan_info_bupati} 
																		disabled
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Ketersediaan Pengumuman
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_pengumuman');
																		}}
																		checked={this.state.ketersediaan_pengumuman} 
																		disabled
																	/>
																</div>
																
															</div>
															:
															<div className="row">
																<div className="col-md-12 text-right">
																		<Button  size="md" color="success" className="btn-icon" onClick={() => this.simpanLayanan()}><i className="fa fa-save"></i> Simpan</Button> 
																</div>
																
																<br/>
																<div className="col-sm-12" align="center">
																	<b>Konfigurasi Umum</b>
																</div>
																<br/>

																<div className="col-sm-6">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Version Desa App
																	</Label>
																	<input
																		className="form-control"
																		id="version_desa_app"
																		type="text"
																		placeholder="Version Desa App"
																		defaultValue={this.state.version_desa_app}
																		required
																	/>
																</div>
																<div className="col-sm-6">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Version Desa Min Desa App
																	</Label>
																	<input
																		className="form-control"
																		id="version_min_desa_app"
																		type="text"
																		placeholder="Version Min Desa App"
																		defaultValue={this.state.version_min_desa_app}
																		required
																	/>
																</div>
																<br/>
																<div className="col-sm-9" align="left">
																	Tanda Tangan Digital
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_ttd');
																		}}
																		checked={this.state.ketersedian_ttd} 
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Tanda Tangan Elektronik Kominfo
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'status_tte');
																		}}
																		checked={this.state.status_tte} 
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Riwayat Disposisi
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'riwayat_disposisi');
																		}}
																		checked={this.state.riwayat_disposisi} 
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Aplikasi Berbayar
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'pembayaran');
																		}}
																		checked={this.state.pembayaran} 
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Aplikasi Absensi
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeConfigLayananDesa(e,'status_absensi');
																		}}
																		checked={this.state.config_list_layanan_desa ? this.state.config_list_layanan_desa.status_absensi : false}
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Aplikasi To Do List
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeConfigLayananDesa(e,'status_todolist');
																		}}
																		checked={this.state.config_list_layanan_desa ? this.state.config_list_layanan_desa.status_todolist : false}
																	/>
																</div>

																<br/>
																<div className="col-sm-12" align="center">
																	<b>Konfigurasi Layanan</b>
																</div>
																<br/>

																<div className="col-sm-9" align="left">
																	Layanan Capil
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'layanan_capil');
																		}}
																		checked={this.state.layanan_capil} 
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Layanan KUA
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'layanan_kua');
																		}}
																		checked={this.state.layanan_kua} 
																	/>
																</div>
																
																<div className="col-sm-9" align="left">
																	Layanan PPOB
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'layanan_ppob');
																		}}
																		checked={this.state.layanan_ppob} 
																	/>
																</div>

																<br/>
																<div className="col-sm-12" align="center">
																	<b>Info dan Pengumuman</b>
																</div>
																<br/>

																<div className="col-sm-9" align="left">
																	Ketersediaan Info
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_info');
																		}}
																		checked={this.state.ketersediaan_info} 
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Ketersediaan Info Bupati
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_info_bupati');
																		}}
																		checked={this.state.ketersediaan_info_bupati} 
																	/>
																</div>

																<div className="col-sm-9" align="left">
																	Ketersediaan Pengumuman
																</div>
																<div className="col-sm-3" align="right">
																	<Switch 
																		onChange={(e) => {
																		this.handleChangeUmum(e,'ketersediaan_pengumuman');
																		}}
																		checked={this.state.ketersediaan_pengumuman} 
																	/>
																</div>
																
															</div>
														}
													
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="11">
										<Row>
											<Col sm="12">
												<p>
													<div>
														{ 
															this.state.konfig_web == 'none'
															?
															<div className="row">
																<div className="col-md-12 text-right">
																		<Button  size="md" color="primary" className="btn-icon" onClick={this.ubahKonfigWeb}><i className="fa fa-pencil"></i> Ubah Konfigurasi</Button> 
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Jenis Desa
																	</Label>
																	<input
																		className="form-control"
																		id="jenis_desa"
																		type="text"
																		placeholder="Contoh: Desa, Kelurahan, Nagari, Dll"
																		defaultValue={this.state.koneksi_web.jenis_desa}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Jenis Dusun
																	</Label>
																	<input
																		className="form-control"
																		id="jenis_dusun"
																		type="text"
																		placeholder="Contoh: Dusun, Jorong, Dll"
																		defaultValue={this.state.koneksi_web.jenis_dusun}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kepala Polisi Kecamatan
																	</Label>
																	<input
																		className="form-control"
																		id="kepala_polisi_kecamatan"
																		type="text"
																		placeholder="Contoh: Kapolsek"
																		defaultValue={this.state.koneksi_web.kepala_polisi_kecamatan}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kantor Polisi Kecamatan
																	</Label>
																	<input
																		className="form-control"
																		id="kantor_polisi_kecamatan"
																		type="text"
																		placeholder="Contoh: Sungai Pagar"
																		defaultValue={this.state.koneksi_web.kantor_polisi_kecamatan}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kepala Polisi Kabupaten
																	</Label>
																	<input
																		className="form-control"
																		id="kepala_polisi_kabupaten"
																		type="text"
																		placeholder="Contoh: Kapolres"
																		defaultValue={this.state.koneksi_web.kepala_polisi_kabupaten}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kantor Polisi Kabupaten
																	</Label>
																	<input
																		className="form-control"
																		id="kantor_polisi_kabupaten"
																		type="text"
																		placeholder="Contoh: Bangkinang"
																		defaultValue={this.state.koneksi_web.kantor_polisi_kabupaten}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kepala Polisi Provinsi
																	</Label>
																	<input
																		className="form-control"
																		id="kepala_polisi_provinsi"
																		type="text"
																		placeholder="Contoh: Kapolda"
																		defaultValue={this.state.koneksi_web.kepala_polisi_provinsi}
																		readOnly
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kantor Polisi Provinsi
																	</Label>
																	<input
																		className="form-control"
																		id="kantor_polisi_provinsi"
																		type="text"
																		placeholder="Contoh: Pekanbaru"
																		defaultValue={this.state.koneksi_web.kantor_polisi_provinsi}
																		readOnly
																	/>
																</div>
																

															</div>
															:
															<div className="row">
																<div className="col-md-12 text-right">
																		<Button  size="md" color="success" className="btn-icon" onClick={() => this.simpanLayanan()}><i className="fa fa-save"></i> Simpan</Button> 
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Jenis Desa
																	</Label>
																	<input
																		className="form-control"
																		id="jenis_desa"
																		type="text"
																		placeholder="Contoh: Desa, Kelurahan, Nagari, Dll"
																		defaultValue={this.state.koneksi_web.jenis_desa}
																		required
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Jenis Dusun
																	</Label>
																	<input
																		className="form-control"
																		id="jenis_dusun"
																		type="text"
																		placeholder="Contoh: Dusun, Jorong, Dll"
																		defaultValue={this.state.koneksi_web.jenis_dusun}
																		required
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kepala Polisi Kecamatan
																	</Label>
																	<input
																		className="form-control"
																		id="kepala_polisi_kecamatan"
																		type="text"
																		placeholder="Contoh: Kapolsek"
																		defaultValue={this.state.koneksi_web.kepala_polisi_kecamatan}
																		
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kantor Polisi Kecamatan
																	</Label>
																	<input
																		className="form-control"
																		id="kantor_polisi_kecamatan"
																		type="text"
																		placeholder="Contoh: Sungai Pagar"
																		defaultValue={this.state.koneksi_web.kantor_polisi_kecamatan}
																		
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kepala Polisi Kabupaten
																	</Label>
																	<input
																		className="form-control"
																		id="kepala_polisi_kabupaten"
																		type="text"
																		placeholder="Contoh: Kapolres"
																		defaultValue={this.state.koneksi_web.kepala_polisi_kabupaten}
																		
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kantor Polisi Kabupaten
																	</Label>
																	<input
																		className="form-control"
																		id="kantor_polisi_kabupaten"
																		type="text"
																		placeholder="Contoh: Bangkinang"
																		defaultValue={this.state.koneksi_web.kantor_polisi_kabupaten}
																		
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kepala Polisi Provinsi
																	</Label>
																	<input
																		className="form-control"
																		id="kepala_polisi_provinsi"
																		type="text"
																		placeholder="Contoh: Kapolda"
																		defaultValue={this.state.koneksi_web.kepala_polisi_provinsi}
																		
																	/>
																</div>
																<div className="col-sm-12">
																	<Label className="col-form-label" htmlFor="inputEmail3">
																		Kantor Polisi Provinsi
																	</Label>
																	<input
																		className="form-control"
																		id="kantor_polisi_provinsi"
																		type="text"
																		placeholder="Contoh: Pekanbaru"
																		defaultValue={this.state.koneksi_web.kantor_polisi_provinsi}
																		
																	/>
																</div>
																
															</div>
														}
													
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="5">
										<Row>
											<Col sm="12">
												<p>
													<div className="card-body datatable-react">
														<div>
														{
															Object.keys(this.state.list_layanan_desa_mobile).map((val, index) => {
																let list_layanan_desa_mobile = this.state.list_layanan_desa_mobile
																let jenis_layanan_desa_mobile = 'Jenis Layanan Desa Mobile'
																let value_jenis_layanan_mobile = ''
																switch (val) {
																	case 'tidak_mampu':
																		jenis_layanan_desa_mobile = "Surat Tidak Mampu"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.tidak_mampu
																		break;
																	case 'domisili':
																		jenis_layanan_desa_mobile = "Surat Domisili"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.domisili
																		break;
																	case 'kelahiran':
																		jenis_layanan_desa_mobile = "Surat Kelahiran"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.kelahiran
																		break;
																	case 'belum_memiliki_rumah':
																		jenis_layanan_desa_mobile = "Surat Belum Memiliki Rumah"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.belum_memiliki_rumah
																		break;
																	case 'jalan':
																		jenis_layanan_desa_mobile = "Surat Jalan"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.jalan
																		break;
																	case 'kematian':
																		jenis_layanan_desa_mobile = "Surat Kematian"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.kematian
																		break;
																	case 'kepemilikan_rumah':
																		jenis_layanan_desa_mobile = "Surat Kepemilikan Rumah"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.kepemilikan_rumah
																		break;
																	case 'penghasilan':
																		jenis_layanan_desa_mobile = "Surat Penghasilan"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.penghasilan
																		break;
																	case 'skck':
																		jenis_layanan_desa_mobile = "Surat SKCK"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.skck
																		break;
																	case 'kehilangan':
																		jenis_layanan_desa_mobile = "Surat Kehilangan"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.kehilangan
																		break;
																	case 'usaha':
																		jenis_layanan_desa_mobile = "Surat Usaha"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.usaha
																		break;
																	case 'skgr':
																		jenis_layanan_desa_mobile = "Surat SKGR"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.skgr
																		break;
																	case 'belum_menikah':
																		jenis_layanan_desa_mobile = "Surat Belum Menikah"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.belum_menikah
																		break;
																	case 'tidak_menikah':
																		jenis_layanan_desa_mobile = "Surat Tidak Menikah"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.tidak_menikah
																		break;
																	case 'pindah_menikah':
																		jenis_layanan_desa_mobile = "Surat Pindah Menikah"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.pindah_menikah
																		break;
																	case 'pernah_menikah':
																		jenis_layanan_desa_mobile = "Surat Pernah Menikah"
																		value_jenis_layanan_mobile = this.state.list_layanan_desa_mobile.pernah_menikah
																		break;
																}

																return (
																	
																<div className="row">
																	<div className="col-sm-6" align="center">
																		{jenis_layanan_desa_mobile}
																	</div>
																	<div className="col-sm-4" align="center">
																		<Switch 
																			onChange={(e) => {
																			this.handleChange(e, val);
																			}}
																			checked={value_jenis_layanan_mobile} 
																		/>
																	</div>
																</div>
																)
															})
														}
														</div>
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="6">
										<Row>
											<Col sm="12">
												<p>
												<div className="card-body datatable-react">
														<div>
														{
															Object.keys(this.state.list_layanan_capil_mobile).map((val, index) => {
																let list_layanan_capil_mobile = this.state.list_layanan_capil_mobile
																let jenis_layanan_capil_mobile = 'Jenis Layanan Desa Mobile'
																let value_jenis_layanan_capil_mobile = ''
																switch (val) {
																	case 'kelahiran':
																		jenis_layanan_capil_mobile = "Kelahiran"
																		value_jenis_layanan_capil_mobile = this.state.list_layanan_capil_mobile.kelahiran
																		break;
																	case 'pindah_datang':
																		jenis_layanan_capil_mobile = "Pindah Datang"
																		value_jenis_layanan_capil_mobile = this.state.list_layanan_capil_mobile.pindah_datang
																		break;
																	case 'akta_kematian':
																		jenis_layanan_capil_mobile = "Akta Kematian"
																		value_jenis_layanan_capil_mobile = this.state.list_layanan_capil_mobile.akta_kematian
																		break;
																	case 'penambahan_anak':
																		jenis_layanan_capil_mobile = "Penambahan Anak"
																		value_jenis_layanan_capil_mobile = this.state.list_layanan_capil_mobile.penambahan_anak
																		break;
																	case 'pecah_kk':
																		jenis_layanan_capil_mobile = "Pecah Kartu Keluarga"
																		value_jenis_layanan_capil_mobile = this.state.list_layanan_capil_mobile.pecah_kk
																		break;
																	case 'perubahan_data':
																		jenis_layanan_capil_mobile = "Perubahan Data"
																		value_jenis_layanan_capil_mobile = this.state.list_layanan_capil_mobile.perubahan_data
																		break;
																}

																return (
																	
																<div className="row">
																	<div className="col-sm-6" align="center">
																		{jenis_layanan_capil_mobile}
																	</div>
																	<div className="col-sm-4" align="center">
																		<Switch 
																			onChange={(e) => {
																			this.handleChange(e, val+'_capil_mobile');
																			}}
																			checked={value_jenis_layanan_capil_mobile} 
																		/>
																	</div>
																</div>
																)
															})
														}
														</div>
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="7">
										<Row>
											<Col sm="12">
												<p>
													<div className="card-body datatable-react">
														<div>
														{
															Object.keys(this.state.list_layanan_kua_mobile).map((val, index) => {
																let list_layanan_kua_mobile = this.state.list_layanan_kua_mobile
																let jenis_layanan_kua_mobile = 'Jenis Layanan KUA Mobile'
																let value_jenis_layanan_kua_mobile = ''
																switch (val) {
																	case 'pernikahan':
																		jenis_layanan_kua_mobile = "pernikahan"
																		value_jenis_layanan_kua_mobile = this.state.list_layanan_kua_mobile.pernikahan
																		break;
																}

																return (
																	
																<div className="row">
																	<div className="col-sm-6" align="center">
																		{jenis_layanan_kua_mobile}
																	</div>
																	<div className="col-sm-4" align="center">
																		<Switch 
																			onChange={(e) => {
																			this.handleChange(e, val);
																			}}
																			checked={value_jenis_layanan_kua_mobile} 
																		/>
																	</div>
																</div>
																)
															})
														}
														</div>
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="8">
										<Row>
											<Col sm="12">
												<p>
													<div className="card-body datatable-react">
														<div>
														{
															Object.keys(this.state.list_layanan_desa_web).map((val, index) => {
																let list_layanan_desa_web = this.state.list_layanan_desa_web
																let jenis = val.replace(/_/g, " ");
																let jenis_layanan_desa_web = this.capitalizeFirstLetter(jenis);
																let value_jenis_layanan_web = this.state.list_layanan_desa_web[val]
														
																// switch (val) {
																// 	case 'tidak_mampu':
																// 		jenis_layanan_desa_web = "Surat Tidak Mampu"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.tidak_mampu
																// 		break;
																// 	case 'domisili':
																// 		jenis_layanan_desa_web = "Surat Domisili"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.domisili
																// 		break;
																// 	case 'domisili_usaha':
																// 		jenis_layanan_desa_web = "Surat Domisili Usaha"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.domisili_usaha
																// 		break;
																// 	case 'kelahiran':
																// 		jenis_layanan_desa_web = "Surat Kelahiran"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.kelahiran
																// 		break;
																// 	case 'belum_memiliki_rumah':
																// 		jenis_layanan_desa_web = "Surat Belum Memiliki Rumah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.belum_memiliki_rumah
																// 		break;
																// 	case 'jalan':
																// 		jenis_layanan_desa_web = "Surat Jalan"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.jalan
																// 		break;
																// 	case 'kematian':
																// 		jenis_layanan_desa_web = "Surat Kematian"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.kematian
																// 		break;
																// 	case 'kepemilikan_rumah':
																// 		jenis_layanan_desa_web = "Surat Kepemilikan Rumah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.kepemilikan_rumah
																// 		break;
																// 	case 'penghasilan':
																// 		jenis_layanan_desa_web = "Surat Penghasilan"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.penghasilan
																// 		break;
																// 	case 'skck':
																// 		jenis_layanan_desa_web = "Surat SKCK"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.skck
																// 		break;
																// 	case 'kehilangan':
																// 		jenis_layanan_desa_web = "Surat Kehilangan"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.kehilangan
																// 		break;
																// 	case 'usaha':
																// 		jenis_layanan_desa_web = "Surat Usaha"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.usaha
																// 		break;
																// 	case 'skgr':
																// 		jenis_layanan_desa_web = "Surat SKGR"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.skgr
																// 		break;
																// 	case 'belum_menikah':
																// 		jenis_layanan_desa_web = "Surat Belum Menikah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.belum_menikah
																// 		break;
																// 	case 'tidak_menikah':
																// 		jenis_layanan_desa_web = "Surat Tidak Menikah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.tidak_menikah
																// 		break;
																// 	case 'pindah_menikah':
																// 		jenis_layanan_desa_web = "Surat Pindah Menikah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.pindah_menikah
																// 		break;
																// 	case 'pindah_datang_wni':
																// 		jenis_layanan_desa_web = "Surat Pindah Datang WNI"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.pindah_datang_wni
																// 		break;
																// 	case 'pernah_menikah':
																// 		jenis_layanan_desa_web = "Surat Pernah Menikah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.pernah_menikah
																// 		break;
																// 	case 'keramaian':
																// 		jenis_layanan_desa_web = "Surat Keramaian"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.keramaian
																// 		break;
																// 	case 'umum':
																// 		jenis_layanan_desa_web = "Surat Umum"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.umum
																// 		break;
																// 	case 'ahli_waris':
																// 		jenis_layanan_desa_web = "Surat Ahli Waris"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.ahli_waris
																// 		break;
																// 	case 'kuasa':
																// 		jenis_layanan_desa_web = "Surat Kuasa"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.kuasa
																// 		break;
																// 	case 'undangan':
																// 		jenis_layanan_desa_web = "Surat Undangan"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.undangan
																// 		break;
																// 	case 'penyaksian_tanah':
																// 		jenis_layanan_desa_web = "Surat Penyaksian Tanah"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.penyaksian_tanah
																// 		break;
																// 	case 'penghasilan_orangtua':
																// 		jenis_layanan_desa_web = "Surat Penghasilan Orang Tua"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.penghasilan_orangtua
																// 		break;
																// 	case 'penguburan':
																// 		jenis_layanan_desa_web = "Surat Penguburan"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.penguburan
																// 		break;

																// 	case 'layanan_kiosk':
																// 		jenis_layanan_desa_web = "Layanan Kiosk"
																// 		value_jenis_layanan_web = this.state.list_layanan_desa_web.layanan_kiosk
																// 		break;
																// }

																return (
																	
																<div className="row">
																{
																	jenis_layanan_desa_web === "Layanan Kiosk" || jenis_layanan_desa_web === 'Jenis Layanan Desa Web'
																	? <> </>
																	:
																	<>
																		<div className="col-sm-6" align="center">
																			{jenis_layanan_desa_web}
																		</div>
																		<div className="col-sm-4" align="center">
																			<Switch 
																				onChange={(e) => {
																				this.handleChangeWeb(e, val);
																				}}
																				checked={value_jenis_layanan_web} 
																			/>
			
																		</div>
																	</>
																}
																</div>
																)
															})
														}
														</div>
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="9">
										<Row>
											<Col sm="12">
												<p>
												<div className="card-body datatable-react">
														<div>
														{
															Object.keys(this.state.list_layanan_capil_web).map((val, index) => {
																let list_layanan_capil_web = this.state.list_layanan_capil_web
																let jenis_layanan_capil_web = 'Jenis Layanan Capil Web'
																let value_jenis_layanan_capil_web = ''
																switch (val) {
																	case 'kelahiran':
																		jenis_layanan_capil_web = "Kelahiran"
																		value_jenis_layanan_capil_web = this.state.list_layanan_capil_web.kelahiran
																		break;
																	case 'pindah_datang':
																		jenis_layanan_capil_web = "Pindah Datang"
																		value_jenis_layanan_capil_web = this.state.list_layanan_capil_web.pindah_datang
																		break;
																	case 'akta_kematian':
																		jenis_layanan_capil_web = "Akta Kematian"
																		value_jenis_layanan_capil_web = this.state.list_layanan_capil_web.akta_kematian
																		break;
																	case 'penambahan_anak':
																		jenis_layanan_capil_web = "Penambahan Anak"
																		value_jenis_layanan_capil_web = this.state.list_layanan_capil_web.penambahan_anak
																		break;
																	case 'pecah_kk':
																		jenis_layanan_capil_web = "Pecah Kartu Keluarga"
																		value_jenis_layanan_capil_web = this.state.list_layanan_capil_web.pecah_kk
																		break;
																	case 'perubahan_data':
																		jenis_layanan_capil_web = "Perubahan Data"
																		value_jenis_layanan_capil_web = this.state.list_layanan_capil_web.perubahan_data
																		break;
																}

																return (
																	
																<div className="row">
																	<div className="col-sm-6" align="center">
																		{jenis_layanan_capil_web}
																	</div>
																	<div className="col-sm-4" align="center">
																		<Switch 
																			onChange={(e) => {
																			this.handleChange(e, val+'_capil_web');
																			}}
																			checked={value_jenis_layanan_capil_web} 
																		/>
																	</div>
																</div>
																)
															})
														}
														</div>
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="10">
										<Row>
											<Col sm="12">
												<p>
													<div className="card-body datatable-react">
														<div>
														{
															Object.keys(this.state.list_layanan_kua_web).map((val, index) => {
																let list_layanan_kua_web = this.state.list_layanan_kua_web
																let jenis_layanan_kua_web = 'Jenis Layanan KUA Web'
																let value_jenis_layanan_kua_web = ''
																switch (val) {
																	case 'pernikahan':
																		jenis_layanan_kua_web = "pernikahan"
																		value_jenis_layanan_kua_web = this.state.list_layanan_kua_web.pernikahan
																		break;
																}

																return (
																	
																<div className="row">
																	<div className="col-sm-6" align="center">
																		{jenis_layanan_kua_web}
																	</div>
																	<div className="col-sm-4" align="center">
																		<Switch 
																			onChange={(e) => {
																			this.handleChange(e, val+'_kua_web');
																			}}
																			checked={value_jenis_layanan_kua_web} 
																		/>
																	</div>
																</div>
																)
															})
														}
														</div>
													</div>
												</p>
											</Col>
										</Row>
									</TabPane>
				<TabPane tabId="12">
					<Row>
						<Col sm="12">
							<p>
								<div className="card-body datatable-react">
									<div>
									{
										Object.keys(this.state.list_layanan_ppob).map((val, index) => {
											let list_layanan_ppob = this.state.list_layanan_ppob
											let jenis_layanan_ppob = 'Jenis Layanan PPOB'
											let value_jenis_layanan_ppob = ''
											switch (val) {
												case 'top_up_bumdes':
													jenis_layanan_ppob = "Top Up BUMDes"
													value_jenis_layanan_ppob = this.state.list_layanan_ppob.top_up_bumdes
													break;
												case 'transfer_bank':
													jenis_layanan_ppob = "Transfer Bank"
													value_jenis_layanan_ppob = this.state.list_layanan_ppob.transfer_bank
													break;
												case 'va':
													jenis_layanan_ppob = "Virtual Account"
													value_jenis_layanan_ppob = this.state.list_layanan_ppob.va
													break;
											}

											return (
												
											<div className="row">
												<div className="col-sm-6" align="center">
													{jenis_layanan_ppob}
												</div>
												<div className="col-sm-4" align="center">
													<Switch 
														onChange={(e) => {
														this.handleChange(e, val);
														}}
														checked={value_jenis_layanan_ppob} 
													/>
												</div>
											</div>
											)
										})
									}
									</div>
								</div>
							</p>
						</Col>
					</Row>
				</TabPane>
								</TabContent>
							</div>
						</div>
					</div>
						{/* {
						this.state.data_layanan
						?
							<>
								<div className="col-md-12 text-center">
								<BootstrapTable
									keyField="uuid"
									data={data_layanan}
									columns={columnsLayanan}
								/>
								</div>
							</>
						:
						(
							<div className="col-md-12 text-center">
								<h4 align="center">Data Tidak Dapat Ditemukan</h4>
							</div>
						)
						} */}
				</Col>
			</Row>

		</div>
	</TabPane>

											{/* =========================================================================== */}
										</TabContent>

									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
				<br />


				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid_pembayaran_desa" type="hidden" placeholder="UUID" defaultValue={this.state.data_pembayaran.uuid} />
							<input className="form-control" id="id_desa" type="hidden" placeholder="Id Desa" defaultValue={this.props.location.state.id_desa} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kode Pembayaran</Label>
								<Col sm='9'>
									<input className="form-control" id="kode_pembayaran" type="text" placeholder="Kode Pembayaran" defaultValue={this.state.data_pembayaran.kode_pembayaran} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Pembayaran</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={this.onChangePembayaran}
										value={this.state.jenis_pembayaran}
										name="jenis_pembayaran"
										options={[
											{ value: 'Transfer Bank', label: 'Transfer Bank' },
											{ value: 'Virtual Account', label: 'Virtual Account' },
											{ value: 'Pembayaran Ditempat', label: 'Pembayaran Ditempat' },
										]}
										placeholder="Pilih Jenis Pembayaran"
										isClearable
									/>
								</Col>
							</FormGroup>
							{
								this.state.jenis_pembayaran.value == ''
									?
									<>
									</>
									: this.state.jenis_pembayaran.value == 'Pembayaran Ditempat'
										?
										<>
											<input className="form-control" id="uuid_bank_blank" type="hidden" value="" />
											<input className="form-control" id="nomor_rekening" type="hidden" value="" />
											<input className="form-control" id="atas_nama" type="hidden" value="" />
										</>
										:
										<>
											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Bank</Label>
												<Col sm='9'>
													<Select classNamePrefix="select" onChange={this.onChangeBank}
														value={this.state.uuid_bank}
														name="uuid_bank"
														options={this.state.data_bank}
														placeholder="Pilih Bank"
														onInputChange={this.getBank}
														isClearable />
												</Col>
											</FormGroup>

											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor Rekening</Label>
												<Col sm='9'>
													<input className="form-control" id="nomor_rekening" type="text" placeholder="Nomor Rekening" defaultValue={this.state.data_pembayaran.nomor_rekening} />
												</Col>
											</FormGroup>

											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Atas Nama</Label>
												<Col sm='9'>
													<input className="form-control" id="atas_nama" type="text" placeholder="Atas Nama" defaultValue={this.state.data_pembayaran.atas_nama} />
												</Col>
											</FormGroup>
										</>
							}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>





				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form2} onClose={this.tutupForm2} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan2}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid_pengiriman_desa" type="hidden" placeholder="UUID JENIS PENGIRIMAN" defaultValue={this.state.jenis_pengiriman.uuid} />
							<input className="form-control" id="id_desa" type="hidden" placeholder="Id Desa" defaultValue={this.props.location.state.id_desa} />


							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Pengiriman Desa</Label>
								<Col sm='9'>
									<Select classNamePrefix="select" onChange={this.onChangePengiriman}
										value={this.state.id_jenis_pengiriman}
										name="uuid_jenis_pengiriman"
										options={this.state.jenis_pengiriman}
										placeholder="Pilih Jenis Pengiriman"
										onInputChange={this.getPengiriman}
										isClearable />
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm2} onClick={this.tutupForm2}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm2} onClick={this.simpan2}>Simpan</button>
						</div>
					</Form>
				</Modal>

				<Modal
					open={this.state.status.formLisensi}
					onClose={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form" onSubmit={this.simpanLisensi}>
						<div className="modal-header">
							<h4 className="modal-title">{this.state.judul}</h4>
						</div>

						<div className="modal-body">
							<input className="form-control" id="uuid_lisensi" type="hidden" placeholder="UUID" defaultValue={this.state.data_lisensi.uuid}/>

							<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Pembelian</Label>
									<Col sm='9'>
											<input className="form-control" id="tgl_pembelian" type="date" placeholder="Tanggal Pembelian" defaultValue={this.state.data_lisensi.tgl_pembelian} required/>
									</Col>
							</FormGroup>

							<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Expired</Label>
									<Col sm='9'>
											<input className="form-control" id="tgl_expired" type="date" placeholder="Tanggal Expired" defaultValue={this.state.data_lisensi.tgl_expired} required/>
									</Col>
							</FormGroup>

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Durasi Expired</Label>
								<Col sm='6'>
										<input className="form-control" id="durasi_expired" type="number" placeholder="Durasi Expired" defaultValue={this.state.data_lisensi.durasi_expired} required/>
								</Col>
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tahun</Label>
						</FormGroup> */}
						</div>

						<div className="modal-footer">
							<button type="button" className="btn btn-warning" onClick={this.tutupForm}>
								Batal
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnFormAcc}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
								

			</div>
		)
	}
}


export default DetailAPI;