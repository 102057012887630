import React, { Component } from 'react';
import Select from 'react-select'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
	username: '',
	password: '',
	role: '0',
	no_hp: '',
	email: '',
}

class Pengguna extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Pengguna PPD",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			name: '',

			level_user: [],
			role_user: '',
			//dropdown kecamatan
			data_kecamatan: [],
			id_kecamatan: '',

			//dropdown kabupaten
			data_kabkota: [],
			id_kabkota: '',
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('pengguna-grafik' + link, null, (lihat) => {
			if (lihat.result.data) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}

		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}
	// Cari Data Table
	cariData = e => {
		let addPengguna = {
			q: e.target.value,
		};
		Post('pengguna-grafik', null, addPengguna, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}

	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let wilayahnya = "";
		let cek_pass = document.getElementById("password").value
		let cek_username = document.getElementById("username").value
		let cek_no_hp = document.getElementById("no_hp").value
		let cek_email = document.getElementById("email").value
		let cek_wilayah = false;
		let cek_role = false;
		let role = "";
		if (this.state.level_user.value == "Admin Kabupaten") {
			wilayahnya = this.state.id_kabkota.value
			if(this.state.id_kabkota){
				cek_wilayah = true
				if(this.state.role_user){
					role = this.state.role_user.label
					cek_role = true
				}
			}
		}
		else if (this.state.level_user.value == "Admin Kecamatan") {
			wilayahnya = this.state.id_kecamatan.value
			if(this.state.id_kecamatan){
				cek_wilayah = true
				role = 'Pegawai'
				cek_role = true
			}
		}

		if(
			cek_pass != '' &&
			cek_username != ''  &&
			cek_no_hp != ''  &&
			cek_email != '' &&
			this.state.level_user &&
			cek_wilayah &&
			role != '' &&
			cek_role
		)
		{
			let addPengguna = {
				uuid: document.getElementById("uuid").value,
				username: document.getElementById("username").value,
				no_hp: document.getElementById("no_hp").value,
				email: document.getElementById("email").value,
				wilayah: wilayahnya,
				role: role,
			};
	
	
			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (addPengguna.uuid === '') {
				psn = "Tambah";
				resstat = 200;
				addPengguna.uuid = null;
				addPengguna.password = document.getElementById("password").value
			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
	
				if (cek_pass != '') {
					addPengguna.password = document.getElementById("password").value
				}
			}
	
			Post('pengguna-grafik/' + metode, addPengguna.uuid, addPengguna, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pengguna PPD', pesanAlert: 'Berhasil ' + psn + ' Data' });
				}
				else {
					this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengguna PPD', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			});
		}
		else {
			this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengguna PPD', pesanAlert: 'Data Tidak Lengkap' });
			// this.componentDidMount();
		}

	}


	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Pengguna PPD";
		this.forceUpdate();

		// Get list kab kota for select dropdown
		Get('kabkota', null, (data) => {
			if (data.result) {
				let data_kabkota = [];
				data.result.data.map(dt => {
					data_kabkota.push({ value: dt.id, label: dt.nama + '-' + dt.data_provinsi.nama });
				})
				this.setState({
					data_kabkota
				});
			}
		});

		// Get list kecamatan for select dropdown
		Get('kecamatan', null, (data) => {
			if (data.result) {
				let data_kecamatan = [];
				data.result.data.map(dt => {
					data_kecamatan.push({ value: dt.id, label: dt.nama + '-' + dt.data_kabkota.nama + '-' + dt.data_kabkota.data_provinsi.nama });
				})
				this.setState({
					data_kecamatan
				});
			}
		});

		Get('pengguna-grafik/find', id, (data) => {
			if (data.result) {
				this.state.dt.uuid = data.result.uuid;
				this.state.dt.username = data.result.username;
				this.state.dt.password = data.result.password;
				this.state.dt.no_hp = data.result.no_hp;
				this.state.dt.email = data.result.email;
				this.state.role_user = { value: data.result.role, label: data.result.role };

				if (data.result.data_kabkota == null) {
					this.state.id_kecamatan = { value: data.result.data_kecamatan.id, label: data.result.data_kecamatan.nama };
					this.state.level_user = { value: "Admin Kecamatan", label: "Admin Kecamatan" };
				}
				else {
					this.state.id_kabkota = { value: data.result.data_kabkota.id, label: data.result.data_kabkota.nama };
					this.state.level_user = { value: "Admin Kabupaten", label: "Admin Kabupaten" };
				}

			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('pengguna-grafik/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pengguna PPD', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengguna PPD', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.dt.uuid = '';
			this.state.dt.username = '';
			this.state.dt.password = '';
			this.state.dt.no_hp = '';
			this.state.dt.email = '';
			this.state.level_user = '';
			this.state.role_user = '';
			this.state.id_kabkota = "";
			this.state.id_kecamatan = "";
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	componentDidMount() {
		this.fetch({ page: null, where: [] });


	}

	onChangeKecamatan = (e) => {
		if (e) {
			this.setState({ id_kecamatan: { value: e.value, label: e.label } });
		}
		else {
			this.setState({ id_kecamatan: { value: '', label: '' } })
		}
	}

	getKecamatan = (e) => {
		if (e) {
			let addKecamatan = {
				q: e
			};
			Post('kecamatan', null, addKecamatan, (data) => {
				if (data.data.result) {
					let data_kecamatan = [];
					data.data.result.data.map(dt => {
						data_kecamatan.push({ value: dt.id, label: dt.nama + '-' + dt.data_kabkota.nama + '-' + dt.data_kabkota.data_provinsi.nama });
					});
					this.setState({ data_kecamatan });
				}
			})
		}
		else {
			Get('kecamatan', null, (data) => {
				if (data.result) {
					let data_kecamatan = [];
					data.result.data.map(dt => {
						data_kecamatan.push({ value: dt.id, label: dt.nama + '-' + dt.data_kabkota.nama + '-' + dt.data_kabkota.data_provinsi.nama });
					})
					this.setState({
						data_kecamatan
					});
				}
			});
		}
	}


	onChangeKabkota = (e) => {
		if (e) {
			this.setState({ id_kabkota: { value: e.value, label: e.label } });
		}
		else {
			this.setState({ id_kabkota: { value: '', label: '' } })
		}
	}

	getKabkota = (e) => {
		if (e) {
			let addKabkota = {
				q: e
			};
			Post('kabkota', null, addKabkota, (data) => {
				if (data.data.result) {
					let data_kabkota = [];
					data.data.result.data.map(dt => {
						data_kabkota.push({ value: dt.id, label: dt.nama + '-' + dt.data_provinsi.nama });
					});
					this.setState({ data_kabkota });
				}
			})
		}
		else {
			Get('kabkota', null, (data) => {
				if (data.result) {
					let data_kabkota = [];
					data.result.data.map(dt => {
						data_kabkota.push({ value: dt.id, label: dt.nama + '-' + dt.data_provinsi.nama });
					})
					this.setState({
						data_kabkota
					});
				}
			});

		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addPengguna = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('pengguna-grafik', null, addPengguna, (data) => {
			//    console.log("Datanyaa")
			//    console.log(data.data.result.data)
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});

		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}


	onChangeLevel = (e) => {
		if (e) {
			this.state.level_user = { value: e.value, label: e.label };
			this.state.data_kecamatan = [];
			this.state.id_kecamatan = "";
			this.state.data_kabkota = [];
			this.state.id_kabkota = "";

			// Get list kab kota for select dropdown
			Get('kabkota', null, (data) => {
				console.log("DATA Kab")
				console.log(data.result)
				if (data.result) {
					let data_kabkota = [];
					data.result.data.map(dt => {
						data_kabkota.push({ value: dt.id, label: dt.nama + '-' + dt.data_provinsi.nama });
					})
					this.setState({
						data_kabkota
					});
				}
			});

			// Get list kecamatan for select dropdown
			Get('kecamatan', null, (data) => {
				console.log("DATA Kec")
				console.log(data.result)
				if (data.result) {
					let data_kecamatan = [];
					data.result.data.map(dt => {
						data_kecamatan.push({ value: dt.id, label: dt.nama + '-' + dt.data_kabkota.nama + '-' + dt.data_kabkota.data_provinsi.nama });
					})
					this.setState({
						data_kecamatan
					});
				}
			});
			this.forceUpdate();
		}
		else {
			this.state.level_user = '';
			this.forceUpdate();
		}
	}

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'role_user':
					this.setState({ 
						role_user: e,
					});
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'role_user':
					this.setState({ 
						role_user: 0
					});
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			username: '',
			no_hp: '',
			email: '',
		}
		const { SearchBar } = Search;
		const columns = [
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },
			{
				dataField: 'username',
				text: 'Username',
				sort: true
			},
			{
				dataField: 'no_hp',
				text: 'No. Handphone',
				sort: true
			},
			{
				dataField: 'email',
				text: 'Email',
				sort: true
			},
			{
				dataField: 'data_kabkota.nama',
				text: 'Kabupaten',
				sort: true
			},
			{
				dataField: 'data_kecamatan.nama',
				text: 'Kecamatan',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Pengguna PPD" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
													/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>
											</form>
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Pengguna PPD", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div>
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Pengguna" defaultValue={this.state.dt.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Username Pengguna</Label>
								<Col sm='9'>
									<input className="form-control" id="username" type="text" placeholder="Username Pengguna" defaultValue={this.state.dt.username} required/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Password Pengguna</Label>
								<Col sm='9'>
									<input className="form-control" id="password" type="password" placeholder="Password Pengguna" defaultValue={this.state.dt.password} required/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">No. Handphone</Label>
								<Col sm='9'>
									<input className="form-control" id="no_hp" type="text" placeholder="No. Handphone Pengguna" defaultValue={this.state.dt.no_hp} required/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Email</Label>
								<Col sm='9'>
									<input className="form-control" id="email" type="text" placeholder="Email Pengguna" defaultValue={this.state.dt.email} required/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Level User</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={this.onChangeLevel}
										value={this.state.level_user}
										name="level_user"
										options={[
											{ value: 'Admin Kabupaten', label: 'Admin Kabupaten' },
											{ value: 'Admin Kecamatan', label: 'Admin Kecamatan' },
										]}
										placeholder="Pilih Level User"
										isClearable
									/>
								</Col>
							</FormGroup>

							{
								this.state.level_user.value == ''
									?
									<>
									</>
									: this.state.level_user.value == 'Admin Kabupaten'
										?
										<>
											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kab Kota</Label>
												<Col sm='9'>
													<Select classNamePrefix="select" onChange={this.onChangeKabkota}
														value={this.state.id_kabkota}
														name="id_kabkota"
														options={this.state.data_kabkota}
														placeholder="Pilih Kabupaten Kota"
														onInputChange={this.getKabkota}
														isClearable />
												</Col>
											</FormGroup>
											
											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Role User</Label>
												<Col sm='9'>
													<Select
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'role_user');
														}}
														value={this.state.role_user}
														name="role_user"
														options={[
															{ value: 'Bupati', label: 'Bupati' },
															{ value: 'Pegawai', label: 'Pegawai' },
														]}
														placeholder="Pilih Role User"
														isClearable
													/>
												</Col>
											</FormGroup>
										</>
										: this.state.level_user.value == 'Admin Kecamatan'
											?
											<>
												<FormGroup className="row">
													<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kecamatan</Label>
													<Col sm='9'>
														<Select
															classNamePrefix="select"
															onChange={this.onChangeKecamatan}
															value={this.state.id_kecamatan}
															name="id_kecamatan"
															options={this.state.data_kecamatan}
															placeholder="Pilih Kecamatan"
															onInputChange={this.getKecamatan}
															isClearable />

													</Col>
												</FormGroup>
											</>
											:
											<>
											</>
							}


						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div >
		)
	}
}


export default Pengguna;
