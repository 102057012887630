import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


const frmDef = {
	uuid: '',
}

class DetailKoperasi extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_pengiriman: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				form2: false,
				btnForm2: false,
				btnAksi2: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			jenis_pembayaran: [],
			uuid_bank: '',
			data_bank: [],
			uuid_bank_blank: '',
			id_bank: '',

			data_pembayaran: {
				uuid: '',
				nomor_rekening: '',
				atas_nama: '',
				kode_pembayaran: '',
			},

			id_jenis_pengiriman: '',
			jenis_pengiriman: [],

			data_akun: [],

			//Tab Pane
			active_tab_icon: '1',
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {

		let id = this.props.location.state.uuid;

		Get('jenis-pembayaran-badan/' + id, null, (lihat) => {
			if (lihat.result) {
				console.log("Jenis Pembayaran Koperasi")
				console.log(lihat.result)
				this.setState({ data: lihat.result.data });
			}
		});
	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		var id = null;
		if (this.state.jenis_pembayaran.value == 'Pembayaran Ditempat') {
			this.state.id_bank = document.getElementById("uuid_bank_blank").value;
		}
		else {
			this.state.id_bank = (this.state.uuid_bank.value).toString();
		}
		let addPembayaran = {
			uuid: document.getElementById("uuid_pembayaran").value,
			jenis_pembayaran: this.state.jenis_pembayaran.value,
			id_badan: document.getElementById("id_badan").value,
			uuid_bank: this.state.id_bank,
			nomor_rekening: document.getElementById("nomor_rekening").value,
			atas_nama: document.getElementById("atas_nama").value,
			kode_pembayaran: document.getElementById("kode_pembayaran").value,
		};

		console.log("DATA SIMPAN")
		console.log(addPembayaran)

		// console.log("COBA TAMPILKAN")
		// console.log(addPembayaran.kode_pembayaran)

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPembayaran.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addPembayaran.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('jenis-pembayaran-badan/' + metode, addPembayaran.uuid, addPembayaran, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pembayaran Badan usaha', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pembayaran Badan usaha', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}


	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Pembayaran Badan usaha";
		this.forceUpdate();

		Get('jenis-pembayaran-badan/find', id, (data) => {
			console.log("UBAH DATA")
			console.log(data.result)
			if (data.result) {
				this.state.data_pembayaran.uuid = id;

				if (data.result.nomor_rekening != '') {
					this.state.data_pembayaran.nomor_rekening = data.result.nomor_rekening;
				}
				else {
					this.state.data_pembayaran.nomor_rekening = '';
				}

				if (data.result.uuid_bank != '') {
					this.state.uuid_bank = { value: data.result.uuid_bank, label: data.result.data_bank.nama_bank };
				}
				else {
					this.state.uuid_bank = '';
				}

				this.state.data_pembayaran.atas_nama = data.result.atas_nama;

				this.state.data_pembayaran.kode_pembayaran = data.result.kode_pembayaran;

				this.state.data_pembayaran.uuid = data.result.uuid;

				this.state.jenis_pembayaran = {
					value: data.result.jenis_pembayaran, label: (
						data.result.jenis_pembayaran == 'Transfer Bank' ? 'Transfer Bank'
							: data.result.jenis_pembayaran == 'Virtual Account' ? 'Virtual Account'
								: data.result.jenis_pembayaran == 'Pembayaran Ditempat' ? 'Pembayaran Ditempat'
									: ''
					)
				};
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}


	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('jenis-pembayaran-badan/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Jenis Pembayaran', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Jenis Pembayaran', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}


	konfirmHapus2 = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData2(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData2 = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('jenis-pengiriman-badan/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Jenis Pengiriman', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Jenis Pengiriman', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}




	simpan2 = e => {
		e.preventDefault();
		this.state.status.btnForm2 = true;
		this.forceUpdate();

		var id = null;
		let addPengiriman = {
			uuid: document.getElementById("uuid").value,
			uuid_jenis_pengiriman: this.state.id_jenis_pengiriman.value,
			id_badan: document.getElementById("id_badan").value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPengiriman.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addPengiriman.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('jenis-pengiriman-badan/' + metode, addPengiriman.uuid, addPengiriman, (res) => {
			this.state.status.btnForm2 = false;
			this.state.status.form2 = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pengiriman Badan Usaha', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pengiriman Badan Usaha', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}





	bukaForm = (e) => {
		if (e == 'tambah') {

			this.state.id_badan = '';
			this.state.jenis_pembayaran = [];
			this.state.uuid_bank = '';
			this.state.data_pembayaran.nomor_rekening = '';
			this.state.data_pembayaran.uuid = '';
			this.state.data_pembayaran.kode_pembayaran = '';
			this.state.data_pembayaran.atas_nama = '';
			this.state.status.form = true;
			this.forceUpdate();
		} else {
			this.state.status.form = true;
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	bukaForm2 = (e) => {
		if (e == 'tambah') {

			this.state.id_badan = '';
			this.state.id_jenis_pengiriman = [];
			this.state.status.form2 = true;
			this.forceUpdate();
		} else {
			this.state.status.form2 = true;
			this.forceUpdate();
		}

	}

	tutupForm2 = () => {
		this.state.status.form2 = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	tombolAksi2 = (cell, row) => {
		return (
			<>
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapus2(row.uuid)} disabled={this.state.status.btnAksi2}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	componentDidMount() {

		//Tampil Data Jenis Pembayaran

		let id = this.props.location.state.uuid;


		Get('jenis-pengiriman-badan/' + id, null, (lihat) => {
			console.log("Data Jenis Pengiriman Koperasi")
			console.log(lihat)
			if (lihat.result) {
				this.setState({ data_pengiriman: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});

		// DATA BANK

		Get('bank/list', null, (data) => {
			if (data.result) {
				let data_bank = [];
				data.result.data.map(dt => {
					data_bank.push({ value: dt.uuid, label: dt.nama_bank });
				})
				this.setState({
					data_bank
				});
			}
		});


		// DATA Jenis pengiriman

		Get('jenis-pengiriman', null, (data) => {
			if (data.result) {
				let jenis_pengiriman = [];
				data.result.data.map(dt => {
					jenis_pengiriman.push({ value: dt.uuid, label: dt.jenis_pengiriman });
				})
				this.setState({
					jenis_pengiriman
				});
			}
		});




		this.fetch({ page: null, where: [] });
	}

	onChangeBank = (e) => {
		if (e) {
			this.setState({ uuid_bank: { value: e.value, label: e.label } });
		}
	}

	getBank = (e) => {
		if (e) {
			let addBank = {
				q: e
			};
			Post('bank/list', null, addBank, (data) => {
				if (data.data.result) {
					let data_bank = [];
					data.data.result.data.map(dt => {
						data_bank.push({ value: dt.uuid, label: dt.nama_bank });
					});
					this.setState({ data_bank });
				}
			})
		}
		else {
			Get('bank/list', null, (data) => {
				if (data.result) {
					let data_bank = [];
					data.result.data.map(dt => {
						data_bank.push({ value: dt.uuid, label: dt.nama_bank });
					})
					this.setState({
						data_bank
					});
				}
			});

		}
	}

	onChangePengiriman = (e) => {
		if (e) {
			this.setState({ id_jenis_pengiriman: { value: e.value, label: e.label } });
		}
	}

	getPengiriman = (e) => {
		if (e) {
			let addPengiriman = {
				q: e
			};
			Post('jenis-pengiriman', null, addPengiriman, (data) => {
				if (data.data.result) {
					let jenis_pengiriman = [];
					data.data.result.data.map(dt => {
						jenis_pengiriman.push({ value: dt.uuid, label: dt.jenis_pengiriman });
					});
					this.setState({ jenis_pengiriman });
				}
			})
		}
		else {
			Get('bank/list', null, (data) => {
				if (data.result) {
					let data_bank = [];
					data.result.data.map(dt => {
						data_bank.push({ value: dt.uuid, label: dt.nama_bank });
					})
					this.setState({
						data_bank
					});
				}
			});

		}
	}

	onChangePembayaran = (e) => {
		if (e) {
			this.state.jenis_pembayaran = { value: e.value, label: e.label };
			this.forceUpdate();
		}
		else {
			this.state.jenis_pembayaran = '';
			this.forceUpdate();
		}
	}

	render() {
		console.log("Data Parsing");
		console.log(this.props.location.state)
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var data_pengiriman = this.state.data_pengiriman;

		console.log("Data Pengiriman")
		console.log(data_pengiriman)
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'kode_pembayaran',
				text: 'Kode Pembayaran',
				sort: true
			},
			{
				dataField: 'jenis_pembayaran',
				text: 'Jenis Pembayaran',
				sort: true
			},
			{
				dataField: 'data_bank.nama_bank',
				text: 'Nama Bank',
				sort: true
			},
			{
				dataField: 'nomor_rekening',
				text: 'Nomor Rekening',
				sort: true
			},

			{
				dataField: 'atas_nama',
				text: 'Atas Nama',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];


		//Data table jenis pengiriman
		const columns2 = [
			{
				dataField: 'data_jenis_pengiriman.kode_jenis_pengiriman',
				text: 'Kode Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'data_jenis_pengiriman.jenis_pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi2,
			},
		];

		//Data table jenis pengiriman
		const columns3 = [
			{
				dataField: 'data_jenis_pengiriman.kode_jenis_pengiriman',
				text: 'Kode Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'data_jenis_pengiriman.jenis_pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
			},
		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Detail Konfigurasi Koperasi" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>


									<div>
										<Nav tabs className="tabs-color">
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '1' })}
													onClick={() => { this.toggle_icon('1'); }} >
													<i className="icofont icofont-user"></i>Detail Badan Usaha
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
													onClick={() => { this.toggle_icon('2'); }} >
													<i className="icofont icofont-pay"></i>Jenis Pembayaran
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
													onClick={() => { this.toggle_icon('3'); }} >
													<i className="icofont icofont-truck-alt"></i>Jenis Pengiriman
											</NavLink>
											</NavItem>
										</Nav>
										{/* ===============================================================										 */}
										<TabContent activeTab={this.state.active_tab_icon}>
											{/* =========================================================================== */}
											<TabPane tabId="1">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />

															<div><br /><br /><h2 align="center">Detail Badan Usaha</h2><hr /><br /><br /></div>

															<Form className="theme-form">
																<div className="modal-body">
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Badan Usaha</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama" type="text" placeholder="Nama Badan Usaha" defaultValue={this.props.location.state.nama} readOnly />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Berdiri</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama" type="text" placeholder="Tanggal Berdiri" defaultValue={this.props.location.state.tanggal_pendirian} readOnly />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor Badan Hukum</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama" type="text" placeholder="Tanggal Berdiri" defaultValue={this.props.location.state.nomor_badan_hukum} readOnly />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama" type="text" placeholder="Tanggal Berdiri" defaultValue={this.props.location.state.alamat} readOnly />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Desa</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama" type="text" placeholder="Tanggal Berdiri" defaultValue={this.props.location.state.data_keldes.nama} readOnly />
																		</Col>
																	</FormGroup>
																	<FormGroup className="row">
																		<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Badan Usaha</Label>
																		<Col sm='9'>
																			<input className="form-control" id="nama" type="text" placeholder="Tanggal Berdiri" defaultValue={this.props.location.state.type_badan} readOnly />
																		</Col>
																	</FormGroup>
																	{
																		this.props.location.state.kelompok_koperasi == "Koperasi"
																			?
																			<div>
																				<FormGroup className="row">
																					<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelompok Koperasi</Label>
																					<Col sm='9'>
																						<input className="form-control" id="nama" type="text" placeholder="Kelompok koperasi" defaultValue={this.props.location.state.kelompok_koperasi} readOnly />
																					</Col>
																				</FormGroup>
																				<FormGroup className="row">
																					<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Sektor Usaha</Label>
																					<Col sm='9'>
																						<input className="form-control" id="nama" type="text" placeholder="Sektor Usaha" defaultValue={this.props.location.state.sektor_usaha} readOnly />
																					</Col>
																				</FormGroup>
																			</div>
																			:
																			<>
																			</>
																	}

																</div>
															</Form>


														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
											<TabPane tabId="2">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />
															<div align="left">
																<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Pembayaran Badan Usaha", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
															</div>
															<div><br /><br /><h2 align="center">Jenis Pembayaran Badan Usaha</h2><hr /><br /><br /></div>

															{
																data == []
																	?
																	<h2>Data Belum Ditambahkan</h2>
																	:
																	<BootstrapTable
																		keyField="uuid"
																		data={data}
																		columns={columns}
																	/>
															}
														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
											<TabPane tabId="3">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<br />
															<div align="left">
																<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Pengiriman Badan Usaha", dt: frmDef }); this.bukaForm2('tambah'); }}>Tambah Data</Button>
															</div>
															<div><br /><br /><h2 align="center">Jenis Pengiriman Badan Usaha</h2><hr /><br /><br /></div>
															{
																data_pengiriman === []
																	?
																	<h2>Data Belum Ditambahkan</h2>
																	:
																	<BootstrapTable
																		keyField="uuid"
																		data={data_pengiriman}
																		columns={columns2}
																	/>
															}
														</Col>
													</Row>

												</div>
											</TabPane>
											{/* =========================================================================== */}
										</TabContent>

									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
				<br />


				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid_pembayaran" type="hidden" placeholder="UUID" defaultValue={this.state.data_pembayaran.uuid} />
							<input className="form-control" id="id_badan" type="hidden" placeholder="Id Badan" defaultValue={this.props.location.state.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kode Pembayaran</Label>
								<Col sm='9'>
									<input className="form-control" id="kode_pembayaran" type="text" placeholder="Kode Pembayaran" defaultValue={this.state.data_pembayaran.kode_pembayaran} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Pembayaran</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={this.onChangePembayaran}
										value={this.state.jenis_pembayaran}
										name="jenis_pembayaran"
										options={[
											{ value: 'Transfer Bank', label: 'Transfer Bank' },
											{ value: 'Virtual Account', label: 'Virtual Account' },
											{ value: 'Pembayaran Ditempat', label: 'Pembayaran Ditempat' },
										]}
										placeholder="Pilih Jenis Pembayaran"
										isClearable
									/>
								</Col>
							</FormGroup>
							{
								this.state.jenis_pembayaran.value == ''
									?
									<>
									</>
									: this.state.jenis_pembayaran.value == 'Pembayaran Ditempat'
										?
										<>
											<input className="form-control" id="uuid_bank_blank" type="hidden" value="" />
											<input className="form-control" id="nomor_rekening" type="hidden" value="" />
											<input className="form-control" id="atas_nama" type="hidden" value="" />
										</>
										:
										<>
											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Bank</Label>
												<Col sm='9'>
													<Select classNamePrefix="select" onChange={this.onChangeBank}
														value={this.state.uuid_bank}
														name="uuid_bank"
														options={this.state.data_bank}
														placeholder="Pilih Bank"
														onInputChange={this.getBank}
														isClearable />
												</Col>
											</FormGroup>

											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor Rekening</Label>
												<Col sm='9'>
													<input className="form-control" id="nomor_rekening" type="text" placeholder="Nomor Rekening" defaultValue={this.state.data_pembayaran.nomor_rekening} />
												</Col>
											</FormGroup>

											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Atas Nama</Label>
												<Col sm='9'>
													<input className="form-control" id="atas_nama" type="text" placeholder="Atas Nama" defaultValue={this.state.data_pembayaran.atas_nama} />
												</Col>
											</FormGroup>
										</>
							}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>





				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form2} onClose={this.tutupForm2} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan2}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Promo" defaultValue={this.state.jenis_pengiriman.uuid} />
							<input className="form-control" id="id_badan" type="hidden" placeholder="Id Badan" defaultValue={this.props.location.state.uuid} />


							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Pengiriman Badan Usaha</Label>
								<Col sm='9'>
									<Select classNamePrefix="select" onChange={this.onChangePengiriman}
										value={this.state.id_jenis_pengiriman}
										name="uuid_jenis_pengiriman"
										options={this.state.jenis_pengiriman}
										placeholder="Pilih Jenis Pengiriman"
										onInputChange={this.getPengiriman}
										isClearable />
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm2} onClick={this.tutupForm2}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm2} onClick={this.simpan2}>Simpan</button>
						</div>
					</Form>
				</Modal>

			</div>
		)
	}
}


export default DetailKoperasi;