import React, { Component } from 'react';

class Footer extends Component {
    render(){
        return (
            <div>
                <footer className="main-footer">
                    <p>2020 © Marketplace Digidesa </p>
                    
                </footer>
            </div>
        );
    }
}

export default Footer