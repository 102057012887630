import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import InfiniteScroll from "react-infinite-scroll-component";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import classnames from 'classnames';


// koneksi
import { Post, Get, Delete, cekLogin, Patch } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
  uuid: '',
  jenis_pembayaran: '',
}

class VerifikasiTransaksi extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        formDetail: false,
        btnForm: false,
        btnAksi: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Toko",
      alert: null,
      show: false,
      basicTitle: '',
      basicType: "default",
      pesanAlert: '',

      // Data Etalase
      id_jenis_etalase: '',
      data_etalase: [],


      // produk proses
      set_idProduk: [],
      data_produk: [],
      set_idProduk22: [],
      data_produk22: [],
      pageNumber: null,
      hasMore: '',
      style_class: {
        button: 'btn btn-default btn-sm',
      },
      style_class22: {
        button: 'btn btn-default btn-sm',
      },
      data_detail_pembayaran: [],

      // produk tayang
      set_idProduk2: [],
      data_produk2: [],
      pageNumber2: null,
      hasMore2: '',
      style_class2: {
        button: 'btn btn-success btn-sm',
      },

      // produk tolak tayang
      set_idProduk3: [],
      data_produk3: [],
      pageNumber3: null,
      hasMore3: '',
      style_class3: {
        button: 'btn btn-success btn-sm',
      },

      //Tab Pane
      active_tab_icon: '1',

      // Search

      name: '',
      name2: '',
      name3: '',

    };
  }

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      this.setState({
        active_tab_icon: tab
      });
    }
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  }

  onRecieveInput = (value) => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  }
  // =================================================

  fetch = (params = {}) => {
    let link = "";
    let where = [];
    let id_category = null;
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
        case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
        case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
        case '<<': link = "?page=1"; break;
        default: link = "?page=" + params.page; break;
      }
    }

  }

  // Pagination
  pagination = (data, type = null) => {
    // const data = this.state.pagination;
    // console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      }
      else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger"
        }
        if (type == null) {
          hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        } else {
          hal.push(<a key={i} onClick={() => this.fetch({ page: i, category: type })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
        }

      }
      if (p > 0) {
        if (type == null) {
          sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        } else {
          sebelum = (<a onClick={() => this.fetch({ page: p, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
        }

      }
      if (n > 0) {
        if (type == null) {
          setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        } else {
          setelah = (<a onClick={() => this.fetch({ page: n, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
        }

      }

      if (type == null) {
        awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
      } else {
        awal = (<a onClick={() => this.fetch({ page: 1, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
        akhir = (<a onClick={() => this.fetch({ page: data.last_page, category: type })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
      }

    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  }

  // Cari Data Table
  cariData = e => {
    if (e) {
      let addToko = {
        q: e.target.value,
      };
      // Post('toko', null, addToko, (data) => {
      //   if (data.data.result) {
      //     this.setState({ data: data.data.result.data, pagination: data.data.result });
      //   }
      // });
    } else {
      // Get('toko', null, (lihat) => {
      //   if (lihat.result) {
      //     this.setState({ data: lihat.result.data, pagination: lihat.result });
      //     this.pagination(lihat.result);
      //   }
      // });
    }
  }


  // ===========================================================================
  // ============================ SET PRODUK SELECTED ==========================
  // ===========================================================================
  pilihProduk = (id) => {
    let newArray = [...this.state.set_idProduk, id];
    let newArray22 = this.state.set_idProduk22;

    if (this.state.set_idProduk.includes(id)) {
      newArray = newArray.filter(day => day !== id);

      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-success")
      kondisi.classList.add("btn-default")

      var kondisi2 = document.getElementById("silent" + id);
      kondisi2.classList.remove("btn-danger")
      kondisi2.classList.add("btn-default")

    }
    else {
      if (this.state.set_idProduk22.includes(id)) {
        newArray22 = newArray22.filter(day => day !== id);
      }

      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-default")
      kondisi.classList.add("btn-success")

      var kondisi2 = document.getElementById("silent" + id);
      kondisi2.classList.remove("btn-danger")
      kondisi2.classList.add("btn-default")

    }

    this.setState({
      set_idProduk: newArray,
      set_idProduk22: newArray22,
      style_class: { button: 'btn btn-success btn-sm' },
    });


    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }

  pilihProduk22 = (id) => {
    let newArray = this.state.set_idProduk;
    let newArray22 = [...this.state.set_idProduk22, id];

    if (this.state.set_idProduk22.includes(id)) {
      newArray22 = newArray22.filter(day22 => day22 !== id);

      var kondisi = document.getElementById("silent" + id);
      kondisi.classList.remove("btn-danger")
      kondisi.classList.add("btn-default")

      var kondisi2 = document.getElementById(id);
      kondisi2.classList.remove("btn-success")
      kondisi2.classList.add("btn-default")

    }
    else {
      if (this.state.set_idProduk.includes(id)) {
        newArray = newArray.filter(day => day !== id);
      }

      var kondisi = document.getElementById("silent" + id);
      kondisi.classList.remove("btn-default")
      kondisi.classList.add("btn-danger")

      var kondisi2 = document.getElementById(id);
      kondisi2.classList.remove("btn-success")
      kondisi2.classList.add("btn-default")

    }

    this.setState({
      set_idProduk22: newArray22,
      set_idProduk: newArray,
      style_class22: { button: 'btn btn-danger btn-sm' },
    });


    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }



  pilihProduk2 = (id) => {
    let newArray = [...this.state.set_idProduk2, id];

    if (this.state.set_idProduk2.includes(id)) {
      newArray = newArray.filter(day => day !== id);
      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-danger")
      kondisi.classList.add("btn-success")
    }
    else {
      var kondisi = document.getElementById(id);
      kondisi.classList.remove("btn-success")
      kondisi.classList.add("btn-danger")
    }

    this.setState({
      set_idProduk2: newArray,
      style_class2: { button: 'btn btn-danger btn-sm' },
    });


    // for(var i=0;i<newArray.length;i++){
    //   console.log("Produk Ke-"+i)
    //   console.log("Data Dipisah")
    //   console.log(newArray[i])
    // }

  }


  // ===========================================================================
  // ============================ END SET PRODUK SELECTED ======================
  // ===========================================================================	  


  // ===========================================================================
  // ============================ SIMPAN DATA SELECTED =========================
  // ===========================================================================	  

  simpan = e => {
    e.preventDefault();
    this.forceUpdate();

    let newArray = this.state.set_idProduk
    let newArray2 = this.state.set_idProduk22

    for (var i = 0; i < newArray.length; i++) {

      var idnya = newArray[i]
      let addProduk = {
        status: 'Sudah dibayar',
      };

      let psn = 'Dilakukan';
      let resstat = 200;
      let metode = "create";

      Post('transaksi/verifikasi-pembayaran', idnya, addProduk, (res) => {
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({ pageNumber: 0, set_idProduk: [], show: true, basicType: 'success', basicTitle: 'Verifikasi Pembayaran Transaksi', pesanAlert: 'Berhasil ' + psn });
        }
        else {
          this.setState({ pageNumber: 0, set_idProduk: [], show: true, basicType: 'danger', basicTitle: 'Verifikasi Pembayaran Transaksi', pesanAlert: 'Gagal ' + psn });
        }
        this.componentDidMount();
      });

    }


    for (var i = 0; i < newArray2.length; i++) {

      var idnya = newArray2[i]
      let addProduk = {
        status: 'Belum bayar',
      };

      let psn = 'Dilakukan';
      let resstat = 200;
      let metode = "create";

      Post('transaksi/verifikasi-pembayaran', idnya, addProduk, (res) => {
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({ pageNumber2: 0, set_idProduk22: [], show: true, basicType: 'success', basicTitle: 'Verifikasi Pembayaran Transaksi', pesanAlert: 'Berhasil ' + psn });
        }
        else {
          this.setState({ pageNumber2: 0, set_idProduk22: [], show: true, basicType: 'danger', basicTitle: 'Verifikasi Pembayaran Transaksi', pesanAlert: 'Gagal ' + psn });
        }
        this.componentDidMount();
      });

    }


  }


  simpan2 = e => {
    e.preventDefault();
    this.forceUpdate();

    let newArray = this.state.set_idProduk2

    for (var i = 0; i < newArray.length; i++) {

      var idnya = newArray[i]
      let addProduk = {
        status: 'Sedang diverifikasi',
      };

      let psn = 'Dibatalkan';
      let resstat = 200;
      let metode = "create";

      Post('transaksi/verifikasi-pembayaran', idnya, addProduk, (res) => {
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({ pageNumber: 0, set_idProduk2: [], show: true, basicType: 'success', basicTitle: 'Data Pembayaran Transaksi', pesanAlert: 'Berhasil ' + psn });
        }
        else {
          this.setState({ pageNumber: 0, set_idProduk2: [], show: true, basicType: 'danger', basicTitle: 'Data Pembayaran Transaksi', pesanAlert: 'Gagal ' + psn });
        }
        this.componentDidMount();
      });

    }

  }


  // ===========================================================================
  // ============================ END SIMPAN DATA SELECTED =====================
  // ===========================================================================


  detailData = (data) => {
    this.state.status.formDetail = true;
    this.state.judul = "Detail Transaksi";
    this.forceUpdate();


    // console.log("PARSING DATA DETAIL")
    // console.log(data)

    if (data.data_pembayaran.status == "Belum bayar" || data.data_pembayaran.data_jenis_pembayaran.jenis_pembayaran == "Pembayaran Ditempat") {
      var final_bukti = null
      var nominal_bayar = null
    }
    else {
      var bukti = data.data_pembayaran.bukti_bayar
      var explode_bukti = JSON.parse(bukti);
      var final_bukti = explode_bukti[0];

      var nominal_bayar = data.data_pembayaran.nominal_bayar
    }

    this.state.data_detail_pembayaran.kode_transaksi = data.kode_transaksi;
    this.state.data_detail_pembayaran.tanggal_transaksi = data.updated_at;
    this.state.data_detail_pembayaran.waktu_transaksi = data.waktu_transaksi;
    this.state.data_detail_pembayaran.pembeli = data.data_pembeli.username;
    this.state.data_detail_pembayaran.alamat = data.alamat;
    this.state.data_detail_pembayaran.status_pembayaran = data.data_pembayaran.status;
    this.state.data_detail_pembayaran.nominal_bayar = nominal_bayar;
    this.state.data_detail_pembayaran.bukti_pembayaran = final_bukti;
    this.state.data_detail_pembayaran.jenis_pembayaran = data.data_pembayaran.data_jenis_pembayaran.jenis_pembayaran;
    this.state.data_detail_pembayaran.atas_nama = data.data_pembayaran.data_jenis_pembayaran.atas_nama;
    this.state.data_detail_pembayaran.nomor_rekening = data.data_pembayaran.data_jenis_pembayaran.nomor_rekening;
  }

  konfirmHapus = (id) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  }

  hapusData = (id) => {
    this.hideAlert();
    let psn = "Hapus";
    Delete('toko/delete', id, (res) => {
      if (res == 200) {
        this.setState({ show: true, basicType: 'success', basicTitle: 'Data Pembayaran Transaksi', pesanAlert: 'Berhasil ' + psn + ' Data' });
      }
      else {
        this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Pembayaran Transaksi', pesanAlert: 'Gagal ' + psn + ' Data' });
      }
      this.componentDidMount();
    })
  }

  bukaForm = (e) => {
    if (e == 'tambah') {
      this.state.data_toko.id_toko = '';
      this.state.id_kategori_usaha = '';
      this.state.id_desa = '';
      this.state.id_pengguna = '';
      this.state.data_toko.nama_toko = '';
      this.state.data_toko.telepon_toko = '';
      this.state.data_toko.alamat_toko = '';
      this.state.data_toko.lat_toko = '';
      this.state.data_toko.lang_toko = '';
      this.state.data_toko.jam_buka_toko = '';
      this.state.data_toko.jam_tutup_toko = '';
      this.state.id_status_toko = '';
      this.state.status.form = true;
      this.forceUpdate();
    } else if (e == 'edit') {
      this.state.status.form = true;
      this.forceUpdate();
    }

  }

  tutupForm = () => {
    this.state.status.form = false;
    this.state.status.formDetail = false
    this.forceUpdate();
  }


  tombolAksiDetail = (cell, row) => {
    return (
      <>
        <Button color="primary" className="btn-icon" onClick={() => this.detailData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
      </>
    )
  }

  tombolAksi2 = (cell, row) => {
    return (
      <>
        <button type="button" id={row.data_pembayaran.uuid} className={this.state.style_class.button} onClick={() => this.pilihProduk(row.data_pembayaran.uuid)} disabled={this.state.status.btnAksi2}><i className="fa fa-check"></i> Setuju</button>
      </>
    )
  }

  tombolAksi22 = (cell, row) => {
    return (
      <>
        <button type="button" id={"silent" + row.data_pembayaran.uuid} className={this.state.style_class22.button} onClick={() => this.pilihProduk22(row.data_pembayaran.uuid)} disabled={this.state.status.btnAksi2}><i className="fa fa-times"></i> Tolak</button>
      </>
    )
  }

  tombolAksi3 = (cell, row) => {
    return (
      <>
        <button type="button" id={row.data_pembayaran.uuid} className={this.state.style_class2.button} onClick={() => this.pilihProduk2(row.data_pembayaran.uuid)} disabled={this.state.status.btnAksi3}><i className="fa fa-check"></i></button>
      </>
    )
  }

  componentDidMount() {
    this.fetch({ page: null, where: [] });

    let params = null

    this.state.pageNumber = 1

    Post(`transaksi/sedang-verifikasi?page=${this.state.pageNumber}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POSTNYA")
        // console.log(data.data.result)
        this.state.data_produk = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk: data.data.result.data,
          hasMore: status_hasMore,
          pageNumber: 1,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
        });
      }
    });


    this.state.pageNumber2 = 1

    Post(`transaksi/sudah-bayar?page=${this.state.pageNumber2}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POSTNYA")
        // console.log(data.data.result)
        this.state.data_produk2 = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk2: data.data.result.data,
          hasMore2: status_hasMore,
          pageNumber2: 1,
          style_class2: { button: 'btn btn-success btn-sm' },
        });
      }
    });

    this.state.pageNumber3 = 1

    Post(`transaksi/belum-bayar?page=${this.state.pageNumber3}`, null, params, (data) => {
      if (data.data.result) {
        // console.log("DATA POST 3 NYA")
        // console.log(data.data.result)
        this.state.data_produk3 = [];

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk3: data.data.result.data,
          hasMore3: status_hasMore,
          pageNumber3: 1,
          style_class3: { button: 'btn btn-success btn-sm' },
        });
      }
    });


  }

  // =============================================================================
  // ===============================LOAD DATA SCROLL==============================
  // =============================================================================
  fetchMoreData = () => {

    let newpageNumber = this.state.pageNumber + 1

    this.setState({ pageNumber: newpageNumber });

    let params = null

    Post(`transaksi/sedang-verifikasi?page=${this.state.pageNumber}`, null, params, (data) => {

      if (newpageNumber >= data.data.result.last_page) {
        this.setState({
          data_produk: [...this.state.data_produk, ...data.data.result.data],
          hasMore: false,
          style_class: { button: 'btn btn-default btn-sm' },
          style_class22: { button: 'btn btn-default btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk: [...this.state.data_produk, ...data.data.result.data],
            pageNumber: newpageNumber,
            style_class: { button: 'btn btn-default btn-sm' },
            style_class22: { button: 'btn btn-default btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }

  fetchMoreData2 = () => {

    let newpageNumber2 = this.state.pageNumber2 + 1

    this.setState({ pageNumber2: newpageNumber2 });

    let params = null

    Post(`transaksi/sudah-bayar?page=${this.state.pageNumber2}`, null, params, (data) => {

      if (newpageNumber2 >= data.data.result.last_page) {
        this.setState({
          data_produk2: [...this.state.data_produk2, ...data.data.result.data],
          hasMore2: false,
          style_class2: { button: 'btn btn-success btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk2: [...this.state.data_produk2, ...data.data.result.data],
            pageNumber2: newpageNumber2,
            style_class2: { button: 'btn btn-success btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }


  fetchMoreData3 = () => {

    let newpageNumber3 = this.state.pageNumber3 + 1

    this.setState({ pageNumber3: newpageNumber3 });

    let params = null

    Post(`transaksi/belum-bayar?page=${this.state.pageNumber3}`, null, params, (data) => {

      if (newpageNumber3 >= data.data.result.last_page || data.data.result.last_page == 1) {
        this.setState({
          data_produk3: [...this.state.data_produk3, ...data.data.result.data],
          hasMore3: false,
          style_class3: { button: 'btn btn-success btn-sm' },
        });
      }
      else {
        setTimeout(() => {
          this.setState({
            data_produk3: [...this.state.data_produk3, ...data.data.result.data],
            pageNumber3: newpageNumber3,
            style_class3: { button: 'btn btn-success btn-sm' },
          });
        }, 1500);
      }

    });
    this.forceUpdate();
  }

  // =============================================================================
  // ===============================END LOAD DATA SCROLL==============================
  // =============================================================================



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault();

    let addProduk = {
      q: this.state.name,
    };

    // console.log("Searching")
    // console.log(addProduk)
    this.state.data_produk = [];
    // Post('verifikasi-produk/proses?q='+addProduk.q, null, null, (data) => {
    Post('verifikasi-produk/proses', null, addProduk, (data) => {
      // if (data.data.result.data) {
      //   this.setState({ data: data.data.result.data, pagination: data.data.result });
      // }

      if (data.data.result) {
        // console.log("DATA Searchnya")
        // console.log(data.data.result)

        if (data.data.result.current_page == data.data.result.last_page) {
          var status_hasMore = false
        }
        else {
          var status_hasMore = true
        }

        this.setState({
          data_produk: data.data.result.data,
          hasMore: status_hasMore,
          pageNumber: 1,
          style_class: { button: 'btn btn-default btn-sm' },
        });
      }
    });
  }

  render() {
    // console.log("COBA WORK");
    // console.log(this.state.set_idProduk);
    // console.log(this.state.set_idProduk22);
    // console.log(this.state.set_idProduk2);
    // console.log(this.state.set_idProduk3);

    var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    // var data = this.state.data;
    var data = this.state.data_produk;
    var data2 = this.state.data_produk2;
    var data3 = this.state.data_produk3;

    const frmDef = {
      uuid: '',
      jenis_pembayaran: '',
    }
    const { SearchBar } = Search;

    const columns = [
      {
        dataField: 'kode_transaksi',
        text: 'Kode Transaksi',
        // sort: true
      },
      {
        dataField: 'data_pembeli.username',
        text: 'Pembeli',
        // sort: true
      },
      {
        dataField: 'data_pembayaran.status',
        text: 'Status Pembayaran',
        // sort: true
      },
      {
        dataField: 'aksiii',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },
      {
        dataField: 'aksii',
        text: 'Setuju Pembayaran',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi2,
      },
      {
        dataField: 'aksi',
        text: 'Tolak Pembayaran',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi22,
      },
    ];

    const columns2 = [
      {
        dataField: 'kode_transaksi',
        text: 'Kode Transaksi',
        // sort: true
      },
      {
        dataField: 'data_pembeli.username',
        text: 'Pembeli',
        // sort: true
      },
      {
        dataField: 'data_pembayaran.status',
        text: 'Status Pembayaran',
        // sort: true
      },
      {
        dataField: 'aksii',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },
      // {
      //   dataField: 'aksi',
      //   text: 'Aksi Batal verifikasi',
      //   isDummyField: true,
      //   csvExport: false,
      //   formatter: this.tombolAksi3,
      // },
    ];

    const columns3 = [
      {
        dataField: 'kode_transaksi',
        text: 'Kode Transaksi',
        // sort: true
      },
      {
        dataField: 'data_pembeli.username',
        text: 'Pembeli',
        // sort: true
      },
      {
        dataField: 'data_pembayaran.status',
        text: 'Status Pembayaran',
        // sort: true
      },
      {
        dataField: 'aksii',
        text: 'Detail',
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiDetail,
      },
    ];

    const defaultSorted = [{
      dataField: 'jenis_pembayaran',
      order: 'asc'
    }];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = '';
      classname = 'btn btn-success';
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing { from} to { to} of { total} Result
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };

    return (
      <div>
        <Breadcrumb title="Data Pembayaran Transaksi" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  {/* <div className="row" style={{ marginBottom: "10px", display: 'flex', justifyContent: "space-between" }}> */}
                  <div className="row">
                    <div className="col-sm-12">
                      <Nav tabs className="tabs-color">
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.active_tab_icon === '1' })}
                            onClick={() => { this.toggle_icon('1'); }}
                          ><i className="icofont icofont-checked"></i>
                          Proses Verifikasi
                        </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
                            onClick={() => { this.toggle_icon('2'); }} >
                            <i className="icofont icofont-bill"></i>Sudah Bayar
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
                            onClick={() => { this.toggle_icon('3'); }} >
                            <i className="icofont icofont-close"></i>Belum Bayar
                          </NavLink>
                        </NavItem>
                      </Nav>
                      {/* ===============================================================										 */}
                      <TabContent activeTab={this.state.active_tab_icon}>
                        <TabPane tabId="1">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                {/* <div className="col-sm-12" align="right">
                                <form>
                                  <label>
                                    <input
                                      name='name'
                                      value={this.state.name}
                                      onChange={e => this.handleChange(e)}
                                      style={{ marginBottom: "10px" }}
                                      className="form-control"
                                      placeholder="Ketikkan sesuatu..."
                                      />
                                  </label>
                                  <button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>         
                                </form>
                              </div> */}
                                <div><br /><br /><h2 align="center">Pembayaran Dalam Proses Verifikasi</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk.length}
                                    next={this.fetchMoreData}
                                    hasMore={this.state.hasMore}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data}
                                      columns={columns}
                                    />
                                  </InfiniteScroll>
                                  <div className="pull-right text-white">
                                    <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </TabPane>
                        {/* ==================================================================== */}
                        <TabPane tabId="2">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                <div><br /><br /><h2 align="center">Telah Melakukan Pembayaran</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv2" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk2.length}
                                    next={this.fetchMoreData2}
                                    hasMore={this.state.hasMore2}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv2"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data2}
                                      columns={columns2}
                                    />
                                  </InfiniteScroll>

                                  {/* <div className="pull-right text-white">
                                    <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan2}>Simpan</button>
                                  </div> */}
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </TabPane>

                        {/* =========================================================================== */}
                        <TabPane tabId="3">
                          <div style={{ marginTop: 15 }}>
                            <Row>
                              <Col sm="12">
                                <div><br /><br /><h2 align="center">Belum Melakukan Pembayaran</h2><hr /><br /><br /></div>
                                <div id="scrollableDiv3" style={{ height: 600, overflow: "auto" }}>
                                  <InfiniteScroll
                                    dataLength={this.state.data_produk3.length}
                                    next={this.fetchMoreData3}
                                    hasMore={this.state.hasMore3}
                                    loader={<h4>Loading...</h4>}
                                    scrollableTarget="scrollableDiv3"
                                  >
                                    <BootstrapTable
                                      keyField="uuid"
                                      data={data3}
                                      columns={columns3}
                                    />
                                  </InfiniteScroll>
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </TabPane>

                        {/* =========================================================================== */}
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">

              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12">
                    <b>Kode Transaksi</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail_pembayaran.kode_transaksi}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
									</div>

                  <div className="col-md-12">
                    <b>Tanggal Transaksi</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail_pembayaran.tanggal_transaksi}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
									</div>

                  <div className="col-md-12">
                    <b>Waktu Transaksi</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail_pembayaran.waktu_transaksi}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
									</div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12">
                    <b>Pembeli</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail_pembayaran.pembeli}
                  </div>

                  <div className="col-md-12">
                    <b>&nbsp;</b>
                  </div>
                  <div className="col-md-12">
                    &nbsp;
									</div>

                  <div className="col-md-12">
                    <b>Alamat</b>
                  </div>
                  <div className="col-md-12">
                    {this.state.data_detail_pembayaran.alamat}
                  </div>
                </div>
                <hr />
                {
                  this.state.data_detail_pembayaran.jenis_pembayaran == "Pembayaran Ditempat" || this.state.data_detail_pembayaran.status_pembayaran == "Belum bayar"
                    ?
                    <>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <b>Status Pembayaran</b>
                        </div>
                        <div className="col-md-12">
                          {this.state.data_detail_pembayaran.status_pembayaran}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <b>Jenis Pembayaran</b>
                        </div>
                        <div className="col-md-12">
                          {this.state.data_detail_pembayaran.jenis_pembayaran}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <b>&nbsp;</b>
                      </div>
                      <div className="col-md-12">
                        &nbsp;
                    </div>
                    </>
                    :
                    <>
                      <div className="col-md-4">
                        <div className="col-md-12">
                          <b>Total Pembayaran</b>
                        </div>
                        <div className="col-md-12">
                          {this.state.data_detail_pembayaran.nominal_bayar}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="col-md-12">
                          <b>Status Pembayaran</b>
                        </div>
                        <div className="col-md-12">
                          {this.state.data_detail_pembayaran.status_pembayaran}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="col-md-12">
                          <b>Jenis Pembayaran</b>
                        </div>
                        <div className="col-md-12">
                          {this.state.data_detail_pembayaran.jenis_pembayaran}
                          <br />(No. rek: {this.state.data_detail_pembayaran.nomor_rekening}, a/n {this.state.data_detail_pembayaran.atas_nama})
                        </div>
                      </div>
                      <div className="col-md-12">
                        <b>&nbsp;</b>
                      </div>
                      <div className="col-md-12">
                        &nbsp;
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <b>Bukti Pembayaran</b>
                        </div>
                        <div className="col-md-12">
                          <img src={this.state.data_detail_pembayaran.bukti_pembayaran} alt="" style={{ width: "450px" }} />
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
            </div>
          </Form>
        </Modal>
      </div>
    )
  }
}


export default VerifikasiTransaksi;
