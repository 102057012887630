import React, { Component } from 'react';
import Select from 'react-select'
import { Link } from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';
import { PostMain, GetMain, DeleteMain } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
	id_desa: '',
	url_api: '',
}
class ListAPI extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Konfigurasi Desa",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			api: { uuid: '', url_api: '', url_api_web: '', url_website:'', lat: '', lang: '', id_desa: '', id_kecamatan: '', id_kabkota: '', id_provinsi: '' },
			data_desa: [],
			id_desa: '',


			id_provinsi: '',  //CountryId
			dataProvinsi: [],  //CountryData

			id_kabkota: '',  //StateId
			kabkotaData: [],  //StateData

			id_kecamatan: '',
			kecamatanData: [], //CityData

			id_kelurahan: '',
			kelurahanData: [],

			id_desa: '',
			keldesData: [],

			name: '',

			level_desa: null,
			jenis_desa: null,
			get_jenis_app: '',
			jenis_app: [],
			get_jenis_app_perangkat: '',
			jenis_app_perangkat: [],

			dataCari: {
				q: '',
			},
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case ">>":
				  link =
					"?page=" +
					Math.ceil(
					  this.state.pagination.total / this.state.pagination.per_page
					);
				  break;
				case ">":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
				  break;
				case "<":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
				  break;
				case "<<":
				  link = "?page=1";
				  break;
				default:
				  link = "?page=" + params.page;
				  break;
			  }
		}
		Post('list-api' + link, null, this.state.dataCari, (lihat) => {
			console.log('list')
			console.log(lihat.data.result)
			if (lihat.data.result) {
				this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
				this.pagination(lihat.data.result);
			}
		});
	}

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "success";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		
		this.setState({ awal, sebelum, hal, setelah, akhir });
		
	};

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			
			this.setState({ 
				dataCari:{
					q: e.target.value,
				}, 
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	simpan = e => {
		e.preventDefault();

		if (this.state.id_desa.value == undefined) {
			var id_desa = this.state.id_desa;
		}
		else {
			var id_desa = this.state.id_desa.value;
		}

		this.state.status.btnForm = true;
		this.forceUpdate();

		if(!this.state.get_jenis_app || !this.state.get_jenis_app_perangkat){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				show: true, basicType: 'danger', basicTitle: 'Konfigurasi Desa', pesanAlert: 'Jenis Aplikasi dan Jenis Aplikasi Perangkat Wajib Diisi !' 
			});
		}
		else if(!this.state.level_desa){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				show: true, basicType: 'danger', basicTitle: 'Konfigurasi Desa', pesanAlert: 'Level Desa Wajib Diisi !' 
			});
		}
		else if(!this.state.jenis_desa){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				show: true, basicType: 'danger', basicTitle: 'Konfigurasi Desa', pesanAlert: 'Jenis Desa Wajib Diisi !' 
			});
		}
		else if(!this.state.id_desa){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				show: true, basicType: 'danger', basicTitle: 'Konfigurasi Desa', pesanAlert: 'Desa Wajib Diisi !' 
			});
		}
		else if(!document.getElementById("url_api").value || !document.getElementById("url_api_web").value || !document.getElementById("url_website").value || !document.getElementById("lat").value || !document.getElementById("lang").value){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				show: true, basicType: 'danger', basicTitle: 'Konfigurasi Desa', pesanAlert: 'Data Tidak Boleh Kosong !' 
			});
		}
		else{

			let addListAPI = {
				uuid: this.state.api.uuid,
				id_desa: id_desa,
				url_api: document.getElementById("url_api").value,
				url_api_web: document.getElementById("url_api_web").value,
				lat: document.getElementById("lat").value,
				lang: document.getElementById("lang").value,
				level_desa: this.state.level_desa.value,
				jenis_desa: this.state.jenis_desa.value,
				jenis_app: this.state.get_jenis_app.value,
				jenis_app_perangkat: this.state.get_jenis_app_perangkat.value,
				url_website: document.getElementById("url_website").value,
			};

			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (addListAPI.uuid === '') {
				psn = "Tambah";
				resstat = 200;
				addListAPI.uuid = null;

			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}

			// console.log(addListAPI)

			Post('list-api/' + metode, addListAPI.uuid, addListAPI, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ show: true, basicType: 'success', basicTitle: 'Data Konfigurasi Desa', pesanAlert: 'Berhasil ' + psn + ' Data' });
				}
				else {
					this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Konfigurasi Desa', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			});
		}
	}

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Konfigurasi Desa";
		this.forceUpdate();

		Get('list-api/find', id, (data) => {
			console.log("DATA AWaLNYA COY")
			console.log(data.result)
			if (data.result) {
				this.state.api.uuid = data.result.uuid;

				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_desa.nama };
				this.state.api.id_desa = data.result.id_desa;

				this.state.id_kecamatan = { value: data.result.data_desa.id_kecamatan, label: data.result.data_desa.data_kecamatan.nama };
				this.state.api.id_kecamatan = data.result.data_desa.id_kecamatan;

				this.state.id_kabkota = { value: data.result.data_desa.data_kecamatan.id_kabkota, label: data.result.data_desa.data_kecamatan.data_kabkota.nama };
				this.state.api.id_kabkota = data.result.data_desa.data_kecamatan.id_kabkota;

				this.state.id_provinsi = { value: data.result.data_desa.data_kecamatan.data_kabkota.id_provinsi, label: data.result.data_desa.data_kecamatan.data_kabkota.data_provinsi.nama };
				// this.state.api.id_provinsi = data.result.data_desa.data_kecamatan.data_kabkota.id_provinsi;

				Get('provinsi/listKabkota/' + data.result.data_desa.data_kecamatan.data_kabkota.id_provinsi, null, (data_kota) => {
					// console.log("Kabupatennya");
					// console.log(data_kota.result);
					this.setState({
						kabkotaData: data_kota.result,
					});

					Get('kabkota/listKecamatan/' + data.result.data_desa.data_kecamatan.id_kabkota, null, (data_kecamatan) => {
						// console.log("Kecamatannya");
						// console.log(data_kecamatan.result);
						this.setState({
							kecamatanData: data_kecamatan.result
						});

						Get('kecamatan/listKecamatan/' + data.result.data_desa.id_kecamatan, null, (data_kelurahan) => {
							// console.log("Kelurahannya");
							// console.log(data_kelurahan.result);
							this.setState({
								kelurahanData: data_kelurahan.result
							});
						});
					});
				});


				this.state.api.url_api = data.result.url_api;
				this.state.api.url_api_web = data.result.url_api_web;
				this.state.api.url_website = data.result.url_website;
				this.state.api.lat = data.result.lat;
				this.state.api.lang = data.result.lang;
				// this.state.jenis_app = {
				// 	value: data.result.jenis_app, label: (
				// 		data.result.jenis_app == 'Digidesa' ? 'Digidesa'
				// 			: data.result.jenis_app == 'Amanat Nagari' ? 'Amanat Nagari'
				// 				: 'Halaman Kampung'
				// 	)
				// };

				this.state.get_jenis_app = { value: data.result.get_jenis_app.uuid, label: data.result.get_jenis_app.nama };
				this.state.get_jenis_app_perangkat = { value: data.result.get_jenis_app_perangkat.uuid, label: data.result.get_jenis_app_perangkat.nama };
				
				this.state.level_desa = {
					value: data.result.level_desa, label: (
						data.result.level_desa == 'Desa' ? 'Desa'
							: data.result.level_desa == 'Kelurahan' ? 'Kelurahan'
								: 'Nagari'
					)
				};
				this.state.jenis_desa = {
					value: data.result.jenis_desa, label: (
						data.result.jenis_desa == 'Berbayar' ? 'Berbayar'
							: data.result.jenis_desa == 'Uji Coba' ? 'Uji Coba'
								: 'Demo'
					)
				};
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('list-api/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Konfigurasi Desa', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Konfigurasi Desa', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.api.uuid = '';
			this.state.id_desa = '';
			this.state.id_provinsi = '';
			this.state.id_kabkota = '';
			this.state.id_kecamatan = '';
			this.state.kabkotaData = [];
			this.state.kecamatanData = [];
			this.state.kelurahanData = [];
			this.state.api.url_api = '';
			this.state.api.url_api_web = '';
			this.state.api.url_website = '';
			this.state.api.lat = '';
			this.state.api.lang = '';
			this.state.get_jenis_app = '';
			this.state.get_jenis_app_perangkat = '';
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Link to={{ pathname: "/detail-api/", state: row }}>
					<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
				</Link>
				{/* <Button title="View detail" onPress={() => this.navigateToDetailPage(row.uuid)} /> */}
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	componentDidMount() {
		this.fetch({ page: null, where: [] });

		//COBA PROVINSI
		Get('provinsi/list', null, (data) => {
			this.setState({
				dataProvinsi: data.result
			});
		});

		// Data for Jenis App
		GetMain("main/web/jenis-aplikasi/list", null, data => {
		// Get("jenis-aplikasi/list", null, data => {
			console.log("Data Enum")
			console.log(data);
			if (data) {
				let jenis_app = [];
				data.result.map(dt => {
					jenis_app.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ jenis_app });
			}
		});

		GetMain("main/web/jenis-aplikasi-perangkat/list", null, data => {
		// Get("jenis-aplikasi-perangkat/list", null, data => {
			console.log("Data Enum")
			console.log(data);
			if (data) {
				let jenis_app_perangkat = [];
				data.result.map(dt => {
					jenis_app_perangkat.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ jenis_app_perangkat });
			}
		});

	}
	// =======================COBA PROVINSI=======================	
	ChangeProvinsi = (e) => {
		if (e) {
			this.setState({ id_provinsi: e.target.value });

			// console.log("Mari lihat")
			// console.log(e.target.value)
			if (e.target.value == '') {
				this.setState({
					kabkotaData: [],
					kecamatanData: [],
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('provinsi/listKabkota/' + e.target.value, null, (data) => {
					this.setState({
						kabkotaData: data.result,
					});
				});
			}
		}
		else {
			this.setState({ id_provinsi: '' });
		}
	}

	ChangeKabkota = (e) => {
		if (e) {
			this.setState({ id_kabkota: e.target.value });

			if (e.target.value == '') {
				this.setState({
					kecamatanData: [],
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('kabkota/listKecamatan/' + e.target.value, null, (data) => {
					this.setState({
						kecamatanData: data.result
					});
				});
			}
		}
		else {
			this.setState({ id_kabkota: '' });
		}
	}

	ChangeKecamatan = (e) => {
		if (e) {
			this.setState({ id_kecamatan: e.target.value });

			if (e.target.value == '') {
				this.setState({
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('kecamatan/listKecamatan/' + e.target.value, null, (data) => {
					this.setState({
						kelurahanData: data.result
					});
				});
			}
		}
		else {
			this.setState({ id_kecamatan: '' });
		}
	}


	ChangeKeldes = (e) => {
		if (e) {
			this.setState({ id_desa: e.target.value });

			if (e.target.value == '') {
				this.setState({
					keldesData: [],
					id_desa: '',
				});
			}
			else {
				let idnya = e.target.value;
				Get('keldes/find/' + e.target.value, null, (data) => {
					this.setState({
						keldesData: data.result,
						id_desa: idnya,
					});
				});
			}
		}
		else {
			this.setState({ id_desa: '' });
		}

	}



	// =======================END COBA PROVINSI=======================	

	onChangeDesa = (e) => {
		if (e) {
			this.setState({ id_desa: { value: e.value, label: e.label } });
		}
		else {
			this.setState({ id_desa: '' });
		}
	}

	getDesa = (e) => {
		if (e) {
			let addDesa = {
				q: e
			};
			Post('keldes', null, addDesa, (data) => {
				if (data.data.result) {
					let data_desa = [];
					data.data.result.data.map(dt => {
						data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama + ' - ' + dt.data_kecamatan.data_kabkota.nama });
					});
					this.setState({ data_desa });
				}
			})
		}
		else {
			Get('keldes', null, (data) => {
				if (data.result) {
					let data_desa = [];
					data.result.data.map(dt => {
						data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama + ' - ' + dt.data_kecamatan.data_kabkota.nama });
					});
					this.setState({
						data_desa
					});
				}
			});
		}
	}



	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addListAPI = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('list-api', null, addListAPI, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}


	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'jenis_app':
					this.setState({ 
						// jenis_app: { value: e.value, label: e.label },
						get_jenis_app: { value: e.value, label: e.label }
					});
					break;

				case 'jenis_app_perangkat':
					this.setState({ 
						// jenis_app_perangkat: { value: e.value, label: e.label },
						get_jenis_app_perangkat: { value: e.value, label: e.label }
					});
					break;

				case 'level_desa':
					this.setState({ level_desa: { value: e.value, label: e.label } });
					break;

				case 'jenis_desa':
					this.setState({ jenis_desa: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'jenis_app':
					this.setState({ jenis_app: '', get_jenis_app: '' });
					break;

				case 'jenis_app_perangkat':
					this.setState({ jenis_app_perangkat: '', get_jenis_app_perangkat: '' });
					break;

				case 'level_desa':
					this.setState({ level_desa: '' });
					break;

				case 'jenis_desa':
					this.setState({ jenis_desa: '' });
					break;
			}
		}
	}

	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
      	}
      
		Post(table, null, find_data, (data) => {

		if (data.data.result) {
			switch (table) {
				case 'jenis-aplikasi':
					let jenis_app = [];
					data.data.results.data.forEach(dt => {
						jenis_app.push({ value: dt.uuid, label: dt.nama });
					});
					this.setState({ jenis_app });
					break;
				case 'jenis-aplikasi-perangkat':
					let jenis_app_perangkat = [];
					data.data.results.data.forEach(dt => {
						jenis_app_perangkat.push({ value: dt.uuid, label: dt.nama });
					});
					this.setState({ jenis_app_perangkat });
					break;

				default:
					// no default
					break;

			}}
		});
		} else {
			// Get(table, null, data => {
			// 	if (data.result) {
			// 		switch (table) {
			// 			case 'suku':
			// 				let dataSuku = [];
			// 				data.result.data.forEach(dt => {
			// 					dataSuku.push({ value: dt.nama, label: dt.nama });
			// 				});
			// 				this.setState({ dataSuku });
			// 				break;
            // default:
            //   // no default
            //   break;

			// 		}
			// 	}
			// });
		}
	}


	// =================== END COMBO BOX JENIS PANDUAN ====================

	// =========== LINK DETAIL ================
	navigateToDetailPage = (row) => {
		const { history } = this.props;
		history.push(`${process.env.PUBLIC_URL}/detail_tutor`, { id: row.uuid });
	}
	// =========== END LINK DETAIL ================

	render() {
		console.log('Data Edit')
		console.log(this.state.get_jenis_app)
		console.log(this.state.get_jenis_app_perangkat)
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;

		var data = this.state.data;
		const frmDef = {
			uuid: '',
			id_desa: '',
			url_api: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
						align: 'center',
				formatter: (key, obj, index) =>{          
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index+1);
					let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					return serial_num; 
				}
			},
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },
			{
				dataField: "data_desa",
				text: "Desa",
				sort: true,
				formatter: (cell, obj) => {
				  console.log('obj')
				  console.log(obj)
				  if(obj.data_desa){
					return (obj.data_desa.nama+' - '+obj.data_desa.id);
				  }
				  else{
					return ('-');
				  }
				},
			},
			{
				dataField: "data_desa",
				text: "Kecamatan",
				sort: true,
				formatter: (cell, obj) => {
				  console.log('obj')
				  console.log(obj)
				  if(obj.data_desa && obj.data_desa.data_kecamatan){
					return (obj.data_desa.data_kecamatan.nama);
				  }
				  else{
					return ('-');
				  }
				},
			},
			{
				dataField: "data_desa",
				text: "Kab/Kota",
				sort: true,
				formatter: (cell, obj) => {
				  console.log('obj')
				  console.log(obj)
				  if(obj.data_desa && obj.data_desa.data_kecamatan && obj.data_desa.data_kecamatan.data_kabkota){
					return (obj.data_desa.data_kecamatan.data_kabkota.nama);
				  }
				  else{
					return ('-');
				  }
				},
			},
			{
				dataField: "data_desa",
				text: "Provinsi",
				sort: true,
				formatter: (cell, obj) => {
				  console.log('obj')
				  console.log(obj)
				  if(obj.data_desa && obj.data_desa.data_kecamatan && obj.data_desa.data_kecamatan.data_kabkota && obj.data_desa.data_kecamatan.data_kabkota.data_provinsi){
					return (obj.data_desa.data_kecamatan.data_kabkota.data_provinsi.nama);
				  }
				  else{
					return ('-');
				  }
				},
			},
			// {
			// 	dataField: 'url_api',
			// 	text: 'Url API Mobile',
			// 	sort: true
			// },
			{
				dataField: 'url_api_web',
				text: 'Url API Web',
				sort: true
			},
			{
				dataField: 'url_website',
				text: 'Url Website',
				sort: true
			},
			{
				dataField: 'get_jenis_app.nama',
				text: 'Jenis Aplikasi',
				sort: true
			},
			{
				dataField: 'get_jenis_app_perangkat.nama',
				text: 'Jenis Aplikasi Perangkat',
				sort: true
			},
			{
				dataField: 'jenis_desa',
				text: 'Jenis Desa',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		return (
			<div>
				<Breadcrumb title="Konfigurasi Desa" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Disini..."
												defaultValue={this.state.dataCari.q}
												onKeyPress={this.cariData}
											/>
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Konfigurasi Desa", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div>
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.api.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Provinsi</Label>
								<Col sm='9'>
									<select
										className="form-control"
										name="id_provinsi"
										value={this.state.id_provinsi.value}
										onChange={this.ChangeProvinsi}
									>
										<option value="">Pilih Provinsi</option>
										{this.state.dataProvinsi.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kabupaten/Kota</Label>
								<Col sm='9'>
									<select
										className="form-control slct"
										name="id_kabkota"
										value={this.state.id_kabkota.value}
										onChange={this.ChangeKabkota}
									>
										<option value="">Pilih Kabupaten/Kota</option>
										{this.state.kabkotaData.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kecamatan</Label>
								<Col sm='9'>
									<select
										className="form-control slct"
										name="id_kecamatan"
										value={this.state.id_kecamatan.value}
										onChange={this.ChangeKecamatan}
									>
										<option value="">Pilih Kecamatan</option>
										{this.state.kecamatanData.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelurahan/Desa</Label>
								<Col sm='9'>
									<select
										className="form-control slct"
										name="id_desa"
										value={this.state.id_desa.value}
										onChange={this.ChangeKeldes}
									>
										<option value="">Pilih Kelurahan/Desa</option>
										{this.state.kelurahanData.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">URL API Mobile</Label>
								<Col sm='9'>
									<input className="form-control" id="url_api" type="text" placeholder="URL API Mobile" defaultValue={this.state.api.url_api} required />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">URL API Web</Label>
								<Col sm='9'>
									<input className="form-control" id="url_api_web" type="text" placeholder="URL API Web" defaultValue={this.state.api.url_api_web} required />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">URL Website Desa</Label>
								<Col sm='9'>
									<input className="form-control" id="url_website" type="text" placeholder="URL Website" defaultValue={this.state.api.url_website} required />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Latitude</Label>
								<Col sm='9'>
									<input type="text" id="lat" className="form-control" defaultValue={this.state.api.lat} required />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Langitude</Label>
								<Col sm='9'>
									<input type="text" id="lang" className="form-control" defaultValue={this.state.api.lang} required/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Aplikasi</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											this.onChangeSelect(e, 'jenis_app');
										}}
										defaultValue={this.state.get_jenis_app}
										value={this.state.get_jenis_app}
										name="Agama"
										options={this.state.jenis_app}
										placeholder="Pilih Jenis Aplikasi"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Aplikasi Perangkat</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											this.onChangeSelect(e, 'jenis_app_perangkat');
										}}
										defaultValue={this.state.get_jenis_app_perangkat}
										value={this.state.get_jenis_app_perangkat}
										name="jenis_app_perangkat"
										options={this.state.jenis_app_perangkat}
										placeholder="Pilih Jenis Aplikasi Perangkat"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Level Desa</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.onChangeSelect(e, 'level_desa')}
										value={this.state.level_desa}
										name="level_desa"
										options={[
											{ value: 'Desa', label: 'Desa' },
											{ value: 'Kelurahan', label: 'Kelurahan' },
											{ value: 'Nagari', label: 'Nagari' },
										]}
										placeholder="Pilih Level Desa"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Desa</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.onChangeSelect(e, 'jenis_desa')}
										value={this.state.jenis_desa}
										name="jenis_desa"
										options={[
											{ value: 'Berbayar', label: 'Berbayar' },
											{ value: 'Uji Coba', label: 'Uji Coba' },
											{ value: 'Demo', label: 'Demo' },
										]}
										placeholder="Pilih Jenis Desa"
										isClearable
									/>
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default ListAPI;
