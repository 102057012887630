import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
	uuid: '',
}

class DetailToko extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_detail: [],
			foto_produk: [],
			data2: [],
			data3: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				formDetail: false,
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',



			// data for select
			list_desa: [],
			id_desa: '',
			list_kategori_usaha: [],
			id_kategori_usaha: '',
			list_pengguna: [],
			id_pengguna: '',
			id_status_toko: null,

			//Tab Pane
			active_tab_icon: '1',
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}

		let id = this.props.location.state.uuid;

		Get('produk-toko/list/' + id + '' + link, null, (lihat) => {
			console.log("GeT BY PROMO")
			console.log(lihat.result)
			console.log("GeT BY PROMO")
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}


	detailData = (data) => {
		this.state.status.formDetail = true;
		this.state.judul = "Detail Produk";
		this.forceUpdate();

		console.log("PARSING DATA DETAIL")
		console.log(data)


		var bukti = data.foto_produk
		var data_foto = JSON.parse(bukti);

		// console.log("FINAL FOTO")
		// console.log(data_foto)

		if (data.selalu_tersedia == 0) {
			var stoknya = "Selalu Tersedia";
			var tersedianya = "Ya";
		}
		else {
			var stoknya = data.stok;
			var tersedianya = "Tidak";
		}

		this.state.data_detail.is_active =
			data.is_active == '0' ? 'Aktif'
				: data.is_active == '1' ? 'Tidak Aktif'
					: data.is_active == '2' ? 'Blokir'
						: ''
			;

		this.state.data_detail.nama_produk = data.nama_produk;
		this.state.data_detail.jenis_etalase = data.data_jenis_etalase.jenis_etalase;
		this.state.data_detail.harga_produk = data.harga_produk;
		this.state.data_detail.diskon = data.diskon;
		this.state.data_detail.selalu_tersedia = tersedianya;
		this.state.data_detail.stok = stoknya;
		this.state.data_detail.status = data.status;
		this.state.foto_produk = data_foto;
		this.state.data_detail.berat = data.berat;
		this.state.data_detail.tanggal = data.created_at;
		this.state.data_detail.id_produk = data.uuid;
		this.state.data_detail.deskripsi = data.deskripsi;
	}


	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		let addProduk = {
			q: e.target.value,
		};
		// let id = this.props.match.params.postID;
		Post('produk-toko/list', null, addProduk, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}


	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Data Toko";
		this.forceUpdate();

		Get('toko/find', id, (data) => {
			if (data.result) {
				this.state.data_toko.id_toko = id;
				this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

				this.state.data_toko.nama_toko = data.result.nama_toko;
				this.state.data_toko.telepon_toko = data.result.telepon;
				this.state.data_toko.alamat_toko = data.result.alamat;
				this.state.data_toko.lat_toko = data.result.lat;
				this.state.data_toko.lang_toko = data.result.lang;
				this.state.data_toko.jam_buka_toko = data.result.jam_buka;
				this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
				this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}




	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_toko.id_toko = '';
			this.state.id_kategori_usaha = '';
			this.state.id_desa = '';
			this.state.id_pengguna = '';
			this.state.data_toko.nama_toko = '';
			this.state.data_toko.telepon_toko = '';
			this.state.data_toko.alamat_toko = '';
			this.state.data_toko.lat_toko = '';
			this.state.data_toko.lang_toko = '';
			this.state.data_toko.jam_buka_toko = '';
			this.state.data_toko.jam_tutup_toko = '';
			this.state.id_status_toko = '';
			this.state.status.form = true;
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.state.status.formDetail = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button>
			</>
		)
	}

	tombolAksiDetail = (cell, row) => {
		return (
			<>
				<button onClick={() => this.detailData(row)} color="primary" className="btn btn-primary"><i className="fa fa-eye"></i>  Detail</button>
			</>
		)
	}

	componentDidMount() {

		// ================== detail toko =================
		// console.log(this.props.match.params.postID)
		// console.log("====================")
		// console.log("DATA DETAIL TOKO")
		// console.log(this.props.location.state)
		// console.log("DATA DETAIL TOKO")
		// console.log("========UUIDNYA============")
		// console.log(this.props.location.state.uuid)

		let id = this.props.location.state.uuid;

		//Tampil Data Jenis Pembayaran

		Get('jenis-pembayaran-toko/list', id, (lihat) => {
			// console.log(lihat)
			if (lihat.result) {
				this.setState({ data2: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});

		//Tampil Data Jenis Pengiriman

		Get('jenis-pengiriman-toko/list', id, (lihat) => {
			// console.log(lihat)
			if (lihat.result) {
				this.setState({ data3: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});


		// ============== end detail toko =================

		this.fetch({ page: null, where: [] });

		// Data for select
		Get("kategori-usaha", null, data => {
			if (data.result) {
				let list_kategori_usaha = [];
				data.result.data.map(dt => {
					list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
				});
				this.setState({ list_kategori_usaha });
			}
		});
		Get("keldes", null, data => {
			if (data.result) {
				let list_desa = [];
				data.result.data.map(dt => {
					list_desa.push({ value: dt.id, label: dt.nama });
				});
				this.setState({ list_desa });
			}
		});
		Get("pengguna", null, data => {
			if (data.result) {
				let list_pengguna = [];
				data.result.data.map(dt => {
					list_pengguna.push({ value: dt.uuid, label: dt.username });
				});
				this.setState({ list_pengguna });
			}
		});

	}

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: { value: e.value, label: e.label } });
					break;

				case 'id_desa':
					this.setState({ id_desa: { value: e.value, label: e.label } });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: { value: e.value, label: e.label } });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: '' });
					break;

				case 'id_desa':
					this.setState({ id_desa: '' });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: '' });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: '' });
					break;
			}
		}
	}
	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
			}
			Post(table, null, find_data, (data) => {
				if (data.data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;
					}
				}
			});
		} else {
			Get(table, null, data => {
				if (data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;

					}
				}
			});
		}
	}

	render() {

		// console.log("Parsing Data")
		// console.log(this.props.location.state)

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var data2 = this.state.data2;
		var data3 = this.state.data3;
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },

			{
				dataField: 'nama_produk',
				text: 'Nama Produk',
				sort: true
			},

			{
				dataField: 'data_jenis_etalase.jenis_etalase',
				text: 'Jenis Etalase',
				sort: true
			},

			{
				dataField: 'status',
				text: 'Status Tayang',
				sort: true
			},
			{
				dataField: 'aksidetail',
				text: 'Detail',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksiDetail,
			},
		];


		//Data table jenis pembayaran
		const columns2 = [
			{
				dataField: 'data_jenis_pembayaran_toko.kode_jenis_pembayaran',
				text: 'Kode Jenis Pembayaran',
				sort: true
			},
			{
				dataField: 'data_jenis_pembayaran_toko.jenis_pembayaran',
				text: 'Jenis Pembayaran',
				sort: true
			},
		];

		//Data table jenis pengiriman
		const columns3 = [
			{
				dataField: 'data_jenis_pengiriman.kode_jenis_pengiriman',
				text: 'Kode Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'data_jenis_pengiriman.jenis_pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
			},
		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Detail Toko" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>


									<div>
										<Nav tabs className="tabs-color">
											<NavItem>
												<NavLink
													className={classnames({ active: this.state.active_tab_icon === '1' })}
													onClick={() => { this.toggle_icon('1'); }}
												><i className="icofont icofont-data"></i>
											Data Toko
										</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
													onClick={() => { this.toggle_icon('2'); }} >
													<i className="icofont icofont-pay"></i>Jenis Pembayaran
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
													onClick={() => { this.toggle_icon('3'); }} >
													<i className="icofont icofont-truck-alt"></i>Jenis Pengiriman
											</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab_icon === '4' })}
													onClick={() => { this.toggle_icon('4'); }} >
													<i className="icofont icofont-listine-dots"></i>List Produk Toko
											</NavLink>
											</NavItem>
										</Nav>
										{/* ===============================================================										 */}
										<TabContent activeTab={this.state.active_tab_icon}>
											<TabPane tabId="1">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<div><br /><br /><h2 align="center">Data Toko</h2><hr /><br /><br /></div>
															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Toko</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Nama Toko" defaultValue={this.props.location.state.nama_toko} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Pengguna</Label>
																<Col sm='9'>
																	{
																		this.props.location.state.data_pengguna.nama_lengkap == ""
																			?
																			<>
																				<input className="form-control" id="nama_pengguna" type="text" placeholder="Nama Pengguna" defaultValue={this.props.location.state.data_pengguna.username} disabled />
																			</>
																			:
																			<>
																				<input className="form-control" id="nama_pengguna" type="text" placeholder="Nama Pengguna" defaultValue={this.props.location.state.data_pengguna.nama_lengkap} disabled />
																			</>

																	}
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">No. Telepon Toko</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Telepon Toko" defaultValue={this.props.location.state.telepon} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat Toko</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Alamat Toko" defaultValue={this.props.location.state.alamat} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelurahan / Desa</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Kelurahan Desa" defaultValue={this.props.location.state.data_keldes.nama} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Latitude</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Latitude Toko" defaultValue={this.props.location.state.lat} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Langitude</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Langitude Toko" defaultValue={this.props.location.state.lang} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jam Buka</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Jam Buka Toko" defaultValue={this.props.location.state.jam_buka} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jam Tutup</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Jam Tutup Toko" defaultValue={this.props.location.state.jam_tutup} disabled />
																</Col>
															</FormGroup>

															<FormGroup className="row">
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Toko</Label>
																<Col sm='9'>
																	<input className="form-control" id="nama_toko" type="text" placeholder="Jenis Toko" defaultValue={this.props.location.state.jenis_toko} disabled />
																</Col>
															</FormGroup>
															{
																this.props.location.state.data_kategori_usaha == null || this.props.location.state.jenis_toko == "pribadi"
																	?
																	<>
																	</>
																	:
																	<>
																		<FormGroup className="row">
																			<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kategori Usaha</Label>
																			<Col sm='9'>
																				<input className="form-control" id="nama_toko" type="text" placeholder="Kategori Usaha" defaultValue={this.props.location.state.data_kategori_usaha.kategori_usaha} disabled />
																			</Col>
																		</FormGroup>
																	</>
															}
														</Col>
													</Row>

												</div>
											</TabPane>
											{/* ==================================================================== */}
											<TabPane tabId="2">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<div><br /><br /><h2 align="center">Jenis Pembayaran</h2><hr /><br /><br /></div>

															<BootstrapTable
																keyField="uuid"
																data={data2}
																columns={columns2}
															/>
															<div className="pull-right text-white">
																{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
															</div>
														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
											<TabPane tabId="3">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<div><br /><br /><h2 align="center">Jenis Pengiriman</h2><hr /><br /><br /></div>

															<BootstrapTable
																keyField="uuid"
																data={data3}
																columns={columns3}
															/>
															<div className="pull-right text-white">
																{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
															</div>
														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
											<TabPane tabId="4">
												<div style={{ marginTop: 15 }}>
													<Row>
														<Col sm="12">
															<div><br /><br /><h2 align="center">List Data Produk</h2><hr /><br /><br /></div>
															{/* <div className="row">
														<div className="col-sm-4">
															<input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} />
														</div>
													</div> */}
															<BootstrapTable
																keyField="uuid"
																data={data}
																columns={columns}
															/>
															<div className="pull-right text-white">
																{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
															</div>
														</Col>
													</Row>

												</div>
											</TabPane>

											{/* =========================================================================== */}
										</TabContent>

									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
				<br />


				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={true} closeOnOverlayClick={true}>

					<div className="modal-header">
						<h5 className="modal-title" align="center">Detail Produk</h5>
					</div>


					<div className="modal-body">
						<div className="row">
							<div className="col-md-6">
								<div className="col-md-12">
									<b>Nama Produk</b>
								</div>
								<div className="col-md-12">
									{this.state.data_detail.nama_produk}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                     	 			</div>

								<div className="col-md-12">
									<b>Jenis Etalase</b>
								</div>
								<div className="col-md-12">
									{this.state.data_detail.jenis_etalase}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                      			</div>


								<div className="col-md-12">
									<b>Berat</b>
								</div>
								<div className="col-md-12">
									{this.state.data_detail.berat}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                  					</div>

							</div>
							{/* ============================= */}
							<div className="col-md-6">
								<div className="col-md-12">
									<b>Harga Produk</b>
								</div>
								<div className="col-md-12">
									Rp. {new Intl.NumberFormat(['ban', 'id']).format(this.state.data_detail.harga_produk)}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                  				</div>

								<div className="col-md-12">
									<b>Diskon</b>
								</div>
								<div className="col-md-12">
									{this.state.data_detail.diskon}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                  				</div>


								<div className="col-md-12">
									<b>Stok</b>
								</div>
								<div className="col-md-12">
									{this.state.data_detail.stok}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                      				</div>
							</div>
							{/* ================================ */}
							<hr />
							<div className="col-md-4">
								<div className="col-md-12" align="center">
									<b>Tanggal Input</b>
								</div>
								<div className="col-md-12" align="center">
									{this.state.data_detail.tanggal}
								</div>
							</div>
							<div className="col-md-4">
								<div className="col-md-12" align="center">
									<b>Status Produk</b>
								</div>
								<div className="col-md-12" align="center">
									{this.state.data_detail.is_active}
								</div>
							</div>
							<div className="col-md-4">
								<div className="col-md-12">
									<b>Status Tayang</b>
								</div>
								<div className="col-md-12">
									{this.state.data_detail.status}
								</div>
							</div>
							{/* ================================ */}
							<hr />
							<div className="col-md-12">
								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                      			</div>

								<div className="col-md-12" align="center">
									<b>Deskripsi Produk</b>
								</div>
								<div className="col-md-12" align="center">
									{this.state.data_detail.deskripsi}
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                      			</div>
							</div>
							{/* ================================ */}
							<hr />
							<div className="col-md-12">
								<div className="col-md-12" align="center">
									<b>Foto Produk</b>
								</div>
								<div className="col-md-12" align="center">
									<div className="row">
										{this.state.foto_produk.map(e => {
											return <div className="col-md-4"><img src={e} style={{ width: 150 }} alt="" /></div>;
										})}
									</div>
								</div>

								<div className="col-md-12">
									<b>&nbsp;</b>
								</div>
								<div className="col-md-12">
									&nbsp;
                      			</div>
							</div>
							{/* ================================ */}

						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
					</div>
				</Modal>



			</div>
		)
	}
}


export default DetailToko;
