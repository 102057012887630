import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
    uuid: '',
    jenis_pembayaran: '',
}
class Wilayah extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form: false,
                btnForm: false,
                btnAksi: false
            },
            awal: "",
            sebelum: "",
            hal: [],
            setelah: "",
            akhir: "",
            dt: frmDef,
            judul: "Tambah Jenis Pembayaran",
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            pesanAlert: '',
        };
    }

    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = (params = {}) => {
        let link = "";
        let where = [];
        if (params.where) {
            where = params.where;
        }
        if (params.page) {
            switch (params.page) {
                case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
                case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
                case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
                case '<<': link = "?page=1"; break;
                default: link = "?page=" + params.page; break;
            }
        }
        Get('jenis-pembayaran' + link, null, (lihat) => {
            // console.log(lihat)
            this.setState({ data: lihat.result.data, pagination: lihat.result });
            this.pagination(lihat.result);

            // "lihat" adalah nama variabel, terserah kita menamakan apa
            // "result" tergantung penamaan yg diberikan oleh pembuat API (Backend)  untuk menampilkan data
        });
    }

    // Pagination
    pagination = (data) => {
        // const data = this.state.pagination;
        // console.log(data);
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0) {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3) {
                start = 1;
                end = 5;
                if (data.last_page > data.current_page) {
                    n = data.current_page + 1;
                }
            }
            else {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2) {
                p = data.current_page - 1;
                if (start >= 5) {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i = start; i <= end; i++) {
                let warna = "success";
                if (i == data.current_page) {
                    warna = "danger"
                }
                hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }
            if (p > 0) {
                sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }
            if (n > 0) {
                setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }
            awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }
        this.setState({ awal, sebelum, hal, setelah, akhir });
    }
    // Cari Data Table
    cariData = e => {
        if (e.key == "Enter") {
            let addJenisPembayaran = {
                jenis_pembayaran: e.target.value,
            };
            Post('jenis-pembayaran', null, addJenisPembayaran, (data) => {
                this.setState({ data: data.data.result.data, pagination: data.data.result });
            });
        }
    }

    // ========================================================================

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();
        this.state.status.btnForm = true;
        this.forceUpdate();
        let addJenisPembayaran = {
            uuid: document.getElementById("uuid").value,
            jenis_pembayaran: document.getElementById("jenis_pembayaran").value,
        };

        let psn = '';
        let resstat = 204;
        let metode = "create";
        if (addJenisPembayaran.uuid === '') {
            psn = "Tambah";
            resstat = 200;
            addJenisPembayaran.uuid = null;

        }
        else {
            psn = "Ubah";
            resstat = 200;
            metode = "update";
        }

        // console.log(addJenisPembayaran)
        
        Post('jenis-pembayaran/' + metode, addJenisPembayaran.uuid, addJenisPembayaran, (res) => {
            this.state.status.btnForm = false;
            this.state.status.form = false;
            this.forceUpdate();
            if (res.status === resstat) {
                this.setState({ show: true, basicType: 'success', basicTitle: 'Data Wilayah', pesanAlert: 'Berhasil ' + psn + ' Data' });
            }
            else {
                this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Wilayah', pesanAlert: 'Gagal ' + psn + ' Data' });
            }
            this.componentDidMount();
        });
    }
    // ========================================================================

    // ============================== Ubah Data ===============================

    ubahData = (id) => {
        this.state.status.btnAksi = true;
        this.state.judul = "Ubah Jenis Pembayaran";
        this.forceUpdate();
        
        Get('jenis-pembayaran/find', id, (data) => {

            // console.log(data)

            this.state.dt.uuid = data.result.uuid;
            this.state.dt.jenis_pembayaran = data.result.jenis_pembayaran;
            this.state.status.btnAksi = false;
            this.forceUpdate();
            this.bukaForm();
        });
    }
    // ========================================================================
    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="Hapus"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    type="danger"
                    title="Yakin ingin hapus data ?"
                    onCancel={this.hideAlert}
                    onConfirm={() => this.hapusData(id)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    hapusData = (id) => {
        this.hideAlert();
        let psn = "Hapus";
        Delete('jenis-pembayaran/delete', id, (res) => {
            if (res == 200) {
                this.setState({ show: true, basicType: 'success', basicTitle: 'Data Wilayah', pesanAlert: 'Berhasil ' + psn + ' Data' });
            }
            else {
                this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Wilayah', pesanAlert: 'Gagal ' + psn + ' Data' });
            }
            this.componentDidMount();
        })
    }
    // ========================================================================

    bukaForm = () => {
        this.state.status.form = true;
        this.forceUpdate();
    }

    tutupForm = () => {
        this.state.status.form = false;
        this.forceUpdate();
    }

    tombolAksi = (cell, row) => {
        return (
            <>
                <Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
                <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
            </>
        )
    }
    componentDidMount() {
        this.fetch({ page: null, where: [] });
    }

    render() {
        var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
        // var size = this.state.pagination.total;
        var from = this.state.pagination.from;
        var page = this.state.pagination.current;
        var to = this.state.pagination.to;
        var total = this.state.pagination.total;
        var data = this.state.data;
        const frmDef = {
            uuid: '',
            jenis_pembayaran: '',
        }
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: 'uuid',
                text: 'ID',
                sort: false
            },
            {
                dataField: 'jenis_pembayaran',
                text: 'Jenis Pembayaran',
                sort: true
            },
            {
                dataField: 'aksi',
                text: 'Aksi',
                isDummyField: true,
                csvExport: false,
                formatter: this.tombolAksi,
            },
        ];

        const defaultSorted = [{
            dataField: 'jenis_pembayaran',
            order: 'asc'
        }];

        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                this.fetch({ where: [], page: page });
            };
            var classname = '';
            classname = 'btn btn-success';
            return (
                <li className="page-item pl-1" key={page}>
                    <a href="#" onClick={handleClick} className={classname}>{page}</a>
                </li>
            );
        };

        const options = {
            alwaysShowAllBtns: true,
            pageButtonRenderer,
            // custom: true,
            paginationTotalRenderer: () => (
                <span className="react-bootstrap-table-pagination-total">
                    Showing { from} to { to} of { total} Result
                </span>
            ),
            paginationSize: size,
            totalSize: total
        };

        return (
            <div>
                <Breadcrumb title="Data Wilayah" parent="Admin" />
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5>Data Wilayah</h5>
                                            {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Jenis Pembayaran", dt: frmDef }); this.bukaForm(); }}>Tambah Data</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body datatable-react">
                                    <BootstrapTable
                                        keyField="id"
                                        data={data}
                                        columns={columns}
                                    />
                                    <div className="pull-right text-white">
                                        {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
                    <Form className="theme-form" onSubmit={this.simpan}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.judul}</h5>
                        </div>
                        <div className="modal-body">
                            <input className="form-control" id="uuid" type="hidden" placeholder="Jenis Pembayaran" defaultValue={this.state.dt.uuid} />
                            
                            <FormGroup className="row">  
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Pembayaran</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="jenis_pembayaran" type="text" placeholder="Jenis Pembayaran" defaultValue={this.state.dt.jenis_pembayaran} />
                                </Col>
                            </FormGroup>  
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
                            <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}


export default Wilayah;
