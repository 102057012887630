import React, { Component } from 'react';
import Select from 'react-select'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Switch from "react-switch";

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
}
class KonfigurasiNotifikasi extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				formDetail: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Konfigurasi Global PPOB",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			list_layanan_ppob: [],

			name: '',
		};
		this.onImageChange = this.onImageChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	handleChange(checked, jenis_konfig) {
		console.log("CHANGE JENIS Konfig")
		console.log(jenis_konfig)
		switch (jenis_konfig) {
			// PPOB MOBILE
			case "top_up_bumdes":
				if(this.state.list_layanan_ppob.top_up_bumdes == true){
					this.state.list_layanan_ppob.top_up_bumdes = checked
				}
				else{
					this.state.list_layanan_ppob.top_up_bumdes = checked
				}
			break;
			case "transfer_bank":
				if(this.state.list_layanan_ppob.transfer_bank == true){
					this.state.list_layanan_ppob.transfer_bank = checked
				}
				else{
					this.state.list_layanan_ppob.transfer_bank = checked
				}
			break;
			case "va":
				if(this.state.list_layanan_ppob.va == true){
					this.state.list_layanan_ppob.va = checked
				}
				else{
					this.state.list_layanan_ppob.va = checked
				}
			break;
		  }

		this.forceUpdate();
		this.setState({ checked });
	  }

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('konfigurasi-ppob' + link, null, (lihat) => {
			if (lihat.result) {
				this.setState({ dt: lihat.result, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		let addListAPI = {
			q: e.target.value,
		};
		Post('list-api', null, addListAPI, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}

	// Upload Image

	uploadFile = ({ target: { files } }) => {
		// console.log( files[0])
		let data = new FormData();
		data.append('files[]', files[0])

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total)
				// console.log(`${loaded} kb of ${total}kb | ${percent}%` );

				if (percent < 100) {
					this.setState({ uploadPercentage: percent })
				}
			}
		}


		axios.post(localStorage.getItem('API_URL') + "upload-file-multiple/logo", data, options).then(res => {
			// console.log(res)

			this.setState({
				image: URL.createObjectURL(files[0])
			})

			this.setState({ avatar: res.data.url, uploadPercentage: 100 }, () => {
				setTimeout(() => {
					this.setState({ uploadPercentage: 0 })
				}, 1000);
			})

			if (res.data.results) {
				// console.log("HAHA Gambarnya adalah")
				// console.log(res.data.results[0])

				this.state.gambarnya = res.data.results[0];
			}
		})

	}

	simpan = (e) => {
		e.preventDefault()

		this.state.status.btnForm = true;
		this.forceUpdate();

		let bumdes = ""
		let bank = ""
		let virtual_account = ""

		if(this.state.list_layanan_ppob.top_up_bumdes == true){
			bumdes = 1
		}
		else if(this.state.list_layanan_ppob.top_up_bumdes == false){
			bumdes = 0
		}

		if(this.state.list_layanan_ppob.transfer_bank == true){
			bank = 1
		}
		else if(this.state.list_layanan_ppob.transfer_bank == false){
			bank = 0
		}

		if(this.state.list_layanan_ppob.va == true){
			virtual_account = 1
		}
		else if(this.state.list_layanan_ppob.va == false){
			virtual_account = 0
		}

		let addData = {
			uuid: document.getElementById("uuid").value,
			bumdes: bumdes,
			bank: bank,
			virtual_account: virtual_account,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addData.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addData.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('konfigurasi-ppob/' + metode, addData.uuid, addData, (res) => {
			this.state.status.btnForm = false;
			// this.state.status.form = false;
			this.tutupForm();
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ 
					show: true, 
					basicType: 'success', 
					basicTitle: 'Data Konfigurasi Global PPOB', 
					pesanAlert: 'Berhasil ' + psn + ' Data' 
				});
			}
			else {
				this.setState({ 
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Data Konfigurasi Global PPOB', 
					pesanAlert: 'Gagal ' + psn + ' Data' 
				});
			}
			this.componentDidMount();
		});
	}

	ubahData = (data) => {
		this.state.status.form = true;
		this.state.judul = "Ubah Konfigurasi Global PPOB";

		this.setState({
			dt: {
				uuid:data.uuid
			},
		})
				
		this.state.status.btnAksi = false;
		this.forceUpdate();
	}
	detailData = (data) => {
		this.state.status.formDetail = true;
		this.state.judul = "Detail Konfigurasi Global PPOB";

		this.setState({
			tujuan: data.tujuan == 'layanan_desa' ? 'Layanan Desa'
						: data.tujuan == 'marketplace' ? 'Marketplace'
						: data.tujuan == 'ppob' ? 'PPOB'
						: '',
			status_notifikasi: 
						data.status == '0' ? 'Aktif'
						: 'Tidak Aktif',
			image: data.gambar,
			dt:{
				uuid: data.uuid,
				judul: data.judul,
				konten: data.konten,
				routing_mobile: data.routing_mobile,
				logo: data.gambar,
			}
		})
				
		this.state.status.btnAksi = false;
		this.forceUpdate();
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('konfigurasi-notifikasi/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Konfigurasi Global PPOB', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Konfigurasi Global PPOB', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.tujuan = null;
			this.state.dt = [];
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		// this.state.dt=frmDef;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				{/* <Link to={{ pathname: "/detail-api/", state: row }}>
					<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
				</Link> */}
				<Button size="xs" color="primary" className="btn-icon" onClick={() => this.detailData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	componentDidMount() {
		this.fetch({ page: null, where: [] });

		//COBA PROVINSI
		// DATA LAYANAN DESA
		Get('konfigurasi-ppob', null, (data) => {
			console.log("Data Layanan PPOB abc")
			console.log(data.result)
			if (data.result) {
				this.setState({
					list_layanan_ppob: {
						top_up_bumdes: data.result.bumdes,
						transfer_bank: data.result.bank,
						va: data.result.virtual_account,
					},
					dt:{
						uuid: data.result.uuid
					}
				});
			}
			else{
				this.setState({
					list_layanan_ppob: {
						top_up_bumdes: true,
						transfer_bank: true,
						va: true,
					},
				})
			}
		});

	}

	// Image Change
	
	onImageChange = event => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			this.setState({
				image: URL.createObjectURL(img)
			});
		}
	};
	// =======================COBA PROVINSI=======================	
	ChangeProvinsi = (e) => {
		if (e) {
			this.setState({ id_provinsi: e.target.value });

			// console.log("Mari lihat")
			// console.log(e.target.value)
			if (e.target.value == '') {
				this.setState({
					kabkotaData: [],
					kecamatanData: [],
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('provinsi/listKabkota/' + e.target.value, null, (data) => {
					this.setState({
						kabkotaData: data.result,
					});
				});
			}
		}
		else {
			this.setState({ id_provinsi: '' });
		}
	}

	ChangeKabkota = (e) => {
		if (e) {
			this.setState({ id_kabkota: e.target.value });

			if (e.target.value == '') {
				this.setState({
					kecamatanData: [],
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('kabkota/listKecamatan/' + e.target.value, null, (data) => {
					this.setState({
						kecamatanData: data.result
					});
				});
			}
		}
		else {
			this.setState({ id_kabkota: '' });
		}
	}

	ChangeKecamatan = (e) => {
		if (e) {
			this.setState({ id_kecamatan: e.target.value });

			if (e.target.value == '') {
				this.setState({
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('kecamatan/listKecamatan/' + e.target.value, null, (data) => {
					this.setState({
						kelurahanData: data.result
					});
				});
			}
		}
		else {
			this.setState({ id_kecamatan: '' });
		}
	}


	ChangeKeldes = (e) => {
		if (e) {
			this.setState({ id_desa: e.target.value });

			if (e.target.value == '') {
				this.setState({
					keldesData: [],
					id_desa: '',
				});
			}
			else {
				let idnya = e.target.value;
				Get('keldes/find/' + e.target.value, null, (data) => {
					this.setState({
						keldesData: data.result,
						id_desa: idnya,
					});
				});
			}
		}
		else {
			this.setState({ id_desa: '' });
		}

	}



	// =======================END COBA PROVINSI=======================	

	onChangeDesa = (e) => {
		if (e) {
			this.setState({ id_desa: { value: e.value, label: e.label } });
		}
		else {
			this.setState({ id_desa: '' });
		}
	}

	getDesa = (e) => {
		if (e) {
			let addDesa = {
				q: e
			};
			Post('keldes', null, addDesa, (data) => {
				if (data.data.result) {
					let data_desa = [];
					data.data.result.data.map(dt => {
						data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama + ' - ' + dt.data_kecamatan.data_kabkota.nama });
					});
					this.setState({ data_desa });
				}
			})
		}
		else {
			Get('keldes', null, (data) => {
				if (data.result) {
					let data_desa = [];
					data.result.data.map(dt => {
						data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama + ' - ' + dt.data_kecamatan.data_kabkota.nama });
					});
					this.setState({
						data_desa
					});
				}
			});
		}
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addListAPI = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('konfigurasi-notifikasi', null, addListAPI, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}


	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'tujuan':
					this.setState({ 
						tujuan: { value: e.value, label: e.label },
					});
					break;
				case 'status_notifikasi':
					this.setState({ 
						status_notifikasi: { value: e.value, label: e.label },
					});
					break;
			}
		} else {
			switch (data) {
				case 'tujuan':
					this.setState({ 
						tujuan: null,
					});
					break;
				case 'status_notifikasi':
					this.setState({ 
						status_notifikasi: null,
					});
					break;
			}
		}
	}

	// =================== COMBO BOX JENIS PANDUAN ====================
	ChangeJenis = (e) => {
		if (e) {
			this.setState({ get_jenis_app: e.target.value });

		}
		else {
			this.setState({ get_jenis_app: '' });
		}

	}

	// =================== END COMBO BOX JENIS PANDUAN ====================

	// =========== LINK DETAIL ================
	navigateToDetailPage = (row) => {
		const { history } = this.props;
		history.push(`${process.env.PUBLIC_URL}/detail_tutor`, { id: row.uuid });
	}
	// =========== END LINK DETAIL ================

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
		}
		const { SearchBar } = Search;
		const columns = [
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },
			{
				dataField: 'judul',
				text: 'Judul',
				sort: true
			},
			{
				dataField: 'tujuan',
				text: 'Tujuan',
				sort: true
			},
			{
				dataField: 'konten',
				text: 'Konten',
				sort: true
			},
			{
				dataField: 'routing_mobile',
				text: 'Routing Mobile',
				sort: true
			},
			{
				dataField: "status",
				text: "Status",
				sort: true,
				formatter: (cell, obj) => {
				  let isinya = obj.status
				  if(isinya=='0'){
					return ('Aktif');
				  }
				  else{
					return ('Tidak Aktif');
				  }
				},
			  },
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'url_api',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		const { uploadPercentage } = this.state;
		return (
			<div>
				<Breadcrumb title="Konfigurasi Global PPOB" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
								<div
										className="row"
										style={{ border: '3px outset black', borderRadius: '25px', padding: '20px' }}
									>
											<div className="col-md-12 text-right">
											{
												this.state.dt.uuid
												?
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData(this.state.dt)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Konfigurasi</Button> 
												:
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Konfigurasi</Button> 
											}
											</div>
											<div>
												<br/>&nbsp;<br/>
											</div>
										{
										this.state.dt.uuid
										?
											<>
												<div className="col-md-12" align="center">
													{/* <img src={this.state.data.foto_desa} style={{ width: '350px' }} /> */}
													<br/><br/>
												</div>

												<div className="col-12">
												{
													Object.keys(this.state.list_layanan_ppob).map((val, index) => {
														let list_layanan_ppob = this.state.list_layanan_ppob
														let jenis_layanan_ppob = 'Jenis Layanan PPOB'
														let value_jenis_layanan_ppob = ''
														switch (val) {
															case 'top_up_bumdes':
																jenis_layanan_ppob = "Top Up BUMDes"
																value_jenis_layanan_ppob = this.state.list_layanan_ppob.top_up_bumdes
																break;
															case 'transfer_bank':
																jenis_layanan_ppob = "Transfer Bank"
																value_jenis_layanan_ppob = this.state.list_layanan_ppob.transfer_bank
																break;
															case 'va':
																jenis_layanan_ppob = "Virtual Account"
																value_jenis_layanan_ppob = this.state.list_layanan_ppob.va
																break;
														}

														return (
															
														<div className="row">
															<div className="col-sm-6" align="center">
																{jenis_layanan_ppob}
															</div>
															<div className="col-sm-4" align="center">
																<Switch 
																	onChange={(e) => {
																	this.handleChange(e, val);
																	}}
																	checked={value_jenis_layanan_ppob} 
																	disabled
																/>
															</div>
														</div>
														)
													})
												}
												</div>
											</>
										:
										(
											<div className="col-md-12 text-center">
												<h4 align="center">Konfigurasi Global PPOB Belum Ada</h4>
											</div>
										)
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				
				<Modal 
					open={this.state.status.form} 
					onClose={this.tutupForm} 
					closeOnEsc={false} 
					closeOnOverlayClick={false} 
					styles={{ 
						modal: 
						{
							width: '50%',
							maxWidth: 'unset',
						}
					}}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.dt.uuid} />
							<div className="row">
								<div className="col-6">
									<FormGroup className="row">
										<Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">Top Up BUMDes</Label>
										<Col sm='6'>
											<Switch 
												onChange={(e) => {
												this.handleChange(e,'top_up_bumdes');
												}}
												checked={this.state.list_layanan_ppob.top_up_bumdes} 
											/>
										</Col>
									</FormGroup>	
								</div>
								<div className="col-6">
									<FormGroup className="row">
										<Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">Transfer Bank</Label>
										<Col sm='6'>
											<Switch 
												onChange={(e) => {
												this.handleChange(e,'transfer_bank');
												}}
												checked={this.state.list_layanan_ppob.transfer_bank} 
											/>
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup className="row">
										<Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">Virtual Account</Label>
										<Col sm='6'>
											<Switch 
												onChange={(e) => {
												this.handleChange(e,'va');
												}}
												checked={this.state.list_layanan_ppob.va} 

											/>
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			
				
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.dt.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Judul</b></Label>
								<Col sm='9'>
									{this.state.dt.judul}
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Konten</b></Label>
								<Col sm='9'>
									{this.state.dt.konten} 
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Tujuan</b></Label>
								<Col sm='9'>
									{this.state.tujuan}
								</Col>
							</FormGroup>
							
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Routing Mobile</b></Label>
								<Col sm='9'>
									{this.state.dt.routing_mobile} 
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Status</b></Label>
								<Col sm='9'>
									{this.state.status_notifikasi}	
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"> <b>Gambar</b></Label>
								<Col sm='9'>
									<img src={this.state.image} style={{ width: "250px" }} /><br />
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
						</div>
					</Form>
				</Modal>
			
			</div>
		)
	}
}


export default KonfigurasiNotifikasi;
