import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

// ** Import custom components **
import store from './store';
import App from './components/app';

// Dashboards
import Default from './components/dashboard/default/index_component';

// Pages
import Error404 from './components/fitur/error-404';
import Login from './components/fitur/login';

// Data Master
import KategoriUsaha from './components/kategori/kategori-usaha';
import JenisEtalase from './components/kategori/jenis-etalase';
import JenisPengiriman from './components/kategori/jenis-pengiriman';
import JenisPembayaran from './components/kategori/jenis-pembayaran';
import Register from './components/kategori/register';
import Penduduk from './components/data/penduduk';
import Wilayah from './components/data/wilayah';
import Toko from './components/data/toko';
import DetailToko from './components/data/detail-toko';
import Provinsi from './components/wilayah/provinsi';
import KabKota from './components/wilayah/kab-kota';
import Kecamatan from './components/wilayah/kecamatan';
import Keldes from './components/wilayah/keldes';
import Pengguna from './components/data/pengguna';
import VerifikasiHapusPengguna from './components/pengguna/verifikasi-hapus-pengguna';
import PenggunaBelanja from './components/data/pengguna_belanja';
import ListAPI from './components/data/list-api';
import KonfigurasiNotifikasi from './components/data/konfigurasi-notifikasi';
import KonfigurasiPPOB from './components/data/konfigurasi-ppob';
// import KonfigurasiAdmin from './components/data/konfigurasi-admin';
import DetailAPI from './components/data/detail-api';
import Koperasi from './components/data/koperasi';
import DetailKoperasi from './components/data/detail-koperasi';
import JenisAplikasi from './components/aplikasi/jenis-aplikasi';
import JenisAplikasiPerangkat from './components/aplikasi/jenis-aplikasi-perangkat';

import Produk from './components/data/produk';
import Promo from './components/data/promo';
import ProdukPromo from './components/data/produk-promo';
import DetailPromo from './components/data/detail-promo';
import Transaksi from './components/data/transaksi';
import DetailTransaksi from './components/data/detail-transaksi';
import Bank from './components/data/bank';

import { DataToken } from './function/Format';
import Komplain from './components/data/komplain';
import Pembayaran from './components/data/verifikasi_transaksi';

import TutorialList from './components/tutorial/tutor_list';
import TutorialAdd from './components/tutorial/tutor_add';
import DetailTutorial from './components/tutorial/detail_tutor';
import VerifikasiDesaWisata from './components/data/verifikasi-desa-wisata';
import KonfigurasiAppWisata from './components/data/konfigurasi-app-wisata';

import JenisSurat from './components/main/jenis-surat';
import VersionApp from './components/main/version-app';
import KonfigurasiAlamat from './components/main/konfigurasi-alamat';

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('access_token') ? (
				<Redirect
					to={{
						pathname: '/dashboard',
						state: { from: props.location }
					}}
				/>
			) : (
					<Component {...props} /> // apa maksudnya ... props ?
				)}
	/>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('access_token') ? (
				<Component {...props} />
			) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location }
						}}
					/>
				)}
	/>
);

class Root extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter basename={'/'}>
					<ScrollContext>
						<Switch>
							<PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />

							<Route path={`${process.env.PUBLIC_URL}/pages/error-404`} component={Error404} />

							<Route path={`${process.env.PUBLIC_URL}/kategori/register`} component={Register} />

							<Route path={`${process.env.PUBLIC_URL}/detail_tutor/:id`} component={DetailTutorial} />

							<App>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/dashboard`} component={Default} />

								{/* Data Master */}
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/fitur/kategori-usaha`}
									component={KategoriUsaha}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/fitur/jenis-etalase`}
									component={JenisEtalase}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/fitur/jenis-pengiriman`}
									component={JenisPengiriman}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/fitur/jenis-pembayaran`}
									component={JenisPembayaran}
								/>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/pengguna`} component={Pengguna} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/pengguna/verifikasi-hapus-pengguna`} component={VerifikasiHapusPengguna} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/pengguna_belanja`} component={PenggunaBelanja} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/list-api`} component={ListAPI} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/konfigurasi-app-wisata`} component={KonfigurasiAppWisata} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/konfigurasi-notifikasi`} component={KonfigurasiNotifikasi} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/konfigurasi-ppob`} component={KonfigurasiPPOB} />
								{/* <PrivateRoute path={`${process.env.PUBLIC_URL}/data/konfigurasi-admin`} component={KonfigurasiAdmin} /> */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/detail-api`} component={DetailAPI} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/bank`} component={Bank} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/koperasi`} component={Koperasi} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data/detail-koperasi`} component={DetailKoperasi} />
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/aplikasi/jenis-aplikasi`}
									component={JenisAplikasi}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/aplikasi/jenis-aplikasi-perangkat`}
									component={JenisAplikasiPerangkat}
								/>
								{/* End Data Master */}

								{/* Data Wilayah */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/fitur/provinsi`} component={Provinsi} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/fitur/kab-kota`} component={KabKota} />
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/fitur/kecamatan`}
									component={Kecamatan}
								/>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/fitur/keldes`} component={Keldes} />
								{/* End Data Wilayah */}

								{/* Data Penduduk */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/data-penduduk`} component={Penduduk} />
								{/* End Data Penduduk */}

								{/* Data Penduduk */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/verifikasi-desa-wisata`} component={VerifikasiDesaWisata} />
								{/* End Data Penduduk */}

								{/* Data Toko */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/toko`} component={Toko} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/produk`} component={Produk} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/detail-toko`} component={DetailToko} />
								{/* End Data Toko */}

								{/* Data Promo */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/promo`} component={Promo} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/produk-promo`} component={ProdukPromo} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/detail-promo`} component={DetailPromo} />
								{/* End Data Promo */}
								{/* Data Transaksi */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/transaksi`} component={Transaksi} />
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/detail-transaksi`}
									component={DetailTransaksi}
								/>
								{/* End Data Transaksi */}
								{/* Data Komplain */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/komplain`} component={Komplain} />
								{/* End Data Komplain */}
								{/* Data Pembayaran */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/pembayaran`} component={Pembayaran} />
								{/* End Data Pembayaran */}

								{/* Data Tutorial */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/tutor_list`} component={TutorialList} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/tutor_add`} component={TutorialAdd} />
								{/* End Data Komplain */}

								{/* Data BO Main */}
								<PrivateRoute path={`${process.env.PUBLIC_URL}/main/jenis-surat`} component={JenisSurat} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/main/version-app`} component={VersionApp} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/main/konfigurasi-alamat`} component={KonfigurasiAlamat} />
								{/* End Data BO Main */}
							</App>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
