import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/css/site.css';

const frmDef = {
	uuid: '',
	kategori_usaha: '',
}
class KategoriUsaha extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Kategori Usaha",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			name: '',

			dataCari: {
				q: '',
			},
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Post('kategori-usaha' + link, null, this.state.dataCari, (lihat) => {
			if (lihat.data.result.data) {
				this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
				this.pagination(lihat.data.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}
	

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let addKeldes = {
				q: e.target.value,
			};
			this.setState({ 
				dataCari:{
					q: e.target.value,
				}, 
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let addKategoriUsaha = {
			uuid: document.getElementById("uuid").value,
			kategori_usaha: document.getElementById("kategori_usaha").value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addKategoriUsaha.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addKategoriUsaha.uuid = null;

		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('kategori-usaha/' + metode, addKategoriUsaha.uuid, addKategoriUsaha, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Kategori Usaha', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Kategori Usaha', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Kategori Usaha";
		this.forceUpdate();
		Get('kategori-usaha/find', id, (data) => {
			if (data.result) {
				this.state.dt.uuid = data.result.uuid;
				this.state.dt.kategori_usaha = data.result.kategori_usaha;
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm();
		});
	}
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('kategori-usaha/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Kategori Usaha', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Kategori Usaha', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}
	// ========================================================================

	bukaForm = () => {
		if ('LANJUTIN BOS') {
			
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	
	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

   onSubmit = (e) => {
	e.preventDefault();
	   
	   let addKategoriUsaha = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)
		
	   Post('kategori-usaha', null, addKategoriUsaha, (data) => {
		//    console.log("dataaa")
		//    console.log(data.data.result.data)
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
         /* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}
	

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			kategori_usaha: '',
		}

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				style:{
					width: '15px',
				},
				formatter: (key, obj, index) =>{          
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index+1);
					let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					return serial_num; 
				}
			},
			{
				dataField: 'kategori_usaha',
				text: 'Kategori Usaha',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				style:{
					width: '120px',
				},
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'kategori_usaha',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Kategori Usaha" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Kategori Usaha..."
												defaultValue={this.state.dataCari.q}
												onKeyPress={this.cariData}
											/>
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Kategori Usaha", dt: frmDef }); this.bukaForm(); }}>Tambah Data</Button>
										</div>
									</div>

									<ToolkitProvider
										keyField="uuid"
										data={data}
										columns={columns}
										search >
										{
											props => (
												<div>
													<BootstrapTable
														{...props.baseProps}
													/>
												</div>
											)
										}
									</ToolkitProvider>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Kategori Usaha" defaultValue={this.state.dt.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kategori Usaha</Label>
								<Col sm='9'>
									<input className="form-control" id="kategori_usaha" type="text" placeholder="Kategori Usaha" defaultValue={this.state.dt.kategori_usaha} />
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default KategoriUsaha;
