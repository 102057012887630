export const MENUITEMS = [
	{
		path: '/dashboard',
		icon: 'desktop',
		title: 'Dashboard',
		type: 'link'
	},
	{
		title: 'Data BO Main Master',
		icon: 'blackboard',
		type: 'sub',
		active: false,
		children: [
			{ path: '/main/jenis-surat', title: 'Data Jenis Surat', type: 'link' },
			{ path: '/main/version-app', title: 'Version App', type: 'link' },
			{ path: '/main/konfigurasi-alamat', title: 'Konfigurasi Alamat', type: 'link' },
		]
	},
	{
		title: 'Data Master',
		icon: 'blackboard',
		type: 'sub',
		active: false,
		children: [
			{ path: '/aplikasi/jenis-aplikasi', title: 'Data Jenis Aplikasi', type: 'link' },
			{ path: '/aplikasi/jenis-aplikasi-perangkat', title: 'Data Jenis Aplikasi Perangkat', type: 'link' },
			{ path: '/fitur/kategori-usaha', title: 'Kategori Usaha', type: 'link' },
			{ path: '/fitur/jenis-etalase', title: 'Jenis Etalase', type: 'link' },
			{ path: '/fitur/jenis-pengiriman', title: 'Jenis Pengiriman', type: 'link' },
			{ path: '/fitur/jenis-pembayaran', title: 'Jenis Pembayaran', type: 'link' },
			{ path: '/data/bank', title: 'Data Bank', type: 'link' },
			{ path: '/data/koperasi', title: 'Data Koperasi', type: 'link' },
		]
	},
	{
		title: 'Konfigurasi',
		icon: 'hummer',
		type: 'sub',
		active: false,
		children: [
			{ path: '/data/list-api', title: 'Konfigurasi Desa', type: 'link' },
			{ path: '/data/konfigurasi-notifikasi', title: 'Konfigurasi Notifikasi', type: 'link' },
			{ path: '/data/konfigurasi-ppob', title: 'Konfigurasi Global PPOB', type: 'link' },
			{ path: '/data/konfigurasi-admin', title: 'Konfigurasi Admin', type: 'link' },
			{ path: '/data/konfigurasi-app-wisata', title: 'Konfigurasi Aplikasi Wisata', type: 'link' },
		]
	},
	{
		title: 'Data Wilayah',
		icon: 'map-alt',
		type: 'sub',
		active: false,
		children: [
			{
				path: '/fitur/provinsi',
				title: 'Provinsi',
				type: 'link'
			},
			{
				path: '/fitur/kab-kota',
				title: 'Kab Kota',
				type: 'link'
			},
			{
				path: '/fitur/kecamatan',
				title: 'Kecamatan',
				type: 'link'
			},
			{
				path: '/fitur/keldes',
				title: 'Kelurahan Desa',
				type: 'link'
			}
		]
	},
	{
		title: 'Pengguna', 
		icon: 'user',
		type: 'sub', 
		active: false , 
		children: [
			{ path: '/data/pengguna', title: 'Data Pengguna', type: 'link' },
			{ path: '/pengguna/verifikasi-hapus-pengguna', title: 'Verifikasi Hapus Pengguna', type: 'link' },
			{ path: '/data/pengguna_belanja', title: 'Data Pengguna PPD', type: 'link' },
		]
	},
	{
		path: '/data-penduduk',
		icon: 'user',
		active: false,
		title: 'Data Penduduk',
		type: 'link'
	},
	{
		path: '/verifikasi-desa-wisata',
		icon: 'user',
		active: false,
		title: 'Verifikasi Desa Wisata',
		type: 'link'
	},
	{
		path: '/toko',
		icon: 'shopping-cart',
		active: false,
		title: 'Toko / UMKM',
		type: 'link'
	},
	{
		path: '/transaksi',
		icon: 'shopping-cart-full',
		active: false,
		title: 'Transaksi',
		type: 'link'
	},
	{
		path: '/pembayaran',
		icon: 'money',
		active: false,
		title: 'Pembayaran',
		type: 'link'
	},
	{
		path: '/komplain',
		icon: 'info',
		active: false,
		title: 'Komplain',
		type: 'link'
	},
	{
		path: '/produk',
		icon: 'support',
		active: false,
		title: 'Produk',
		type: 'link'
	},
	{
		path: '/promo',
		icon: 'alert',
		active: false,
		title: 'Promo',
		type: 'link'
	},

	{
		path: '/tutor_add',
		icon: 'gallery',
		active: false,
		title: 'Tutorial',
		type: 'link'
	},


	// {
	// 	title: 'Dokumentasi',
	// 	icon: 'gallery',
	// 	type: 'sub',
	// 	active: false,
	// 	children: [
	// 		{
	// 			path: '/tutor_list',
	// 			title: 'Gallery',
	// 			type: 'link'
	// 		},
	// 		{
	// 			path: '/tutor_add',
	// 			title: 'Data Tutorial',
	// 			type: 'link'
	// 		},
	// 	]
	// },

	// {
	// 	path: '/produk-promo',
	// 	icon: 'alert',
	// 	active: false,
	// 	title: 'Produk Promo',
	// 	type: 'link'
	// }
];
