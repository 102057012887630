import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap';
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";

// import InfiniteScroll from 'react-infinite-scroller';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';

// Timeline
// import { Timeline, Event } from "react-timeline-scribble";
import { Timeline, Event } from "react-timeline-scribble"


// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
	uuid: '',
	id_produk: '',
}

function imageFormatter(cell, row){
	// return "<img src='"+cell+"'/>" ;
	return (
		<>
		<img src={cell} alt="" style={{width: "100px"}}/>
		</>
	)
  }

function checkk(cell, row){
return (
	<>
	<input type="checkbox" value={row.value} 
          onChange={this.handleCheckboxChange} id={row.value}/>{row.value}
	</>
)
}

class DetailPromo extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			pagination: [],
			status: {
				form: false,
				formDetail: false,
				btnForm: false,
				btnAksi: false,
				btnAksi2: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			toko: { id_toko: '', nama_toko: '' },

			// data produk
			data_promo: {
				id: '',
				id_promo: '',
				id_produk: '',
				foto_produk: '',
				deskripsi: '',
				diskon: '',
				harga_produk: '',
			},

			set_idProduk: [],

			data_produk: [],
			id_produk: '',
			nama_produk: '',

			pageNumber: 1,
			hasMore: '',

			style_class:{
				button:'btn btn-success btn-sm',
			},

			// DEPENDENT DROPDOWN
			data_desa: [],
			id_desa: {
				value:'',
				label:'',
			},
			id_jenis_etalase: {
				value: '',
				label: '',
			},
			dataJenisEtalase: [],
			diskon_produk: [],

			url_desa:'',
			url_etalase:'',
			url_diskon:'',
			next_page: '',
			
			name:'',

		};
	}


	// handleCheckboxChange = event => {
	// 	let newArray = [...this.state.set_idProduk, event.target.id];
	// 	if (this.state.set_idProduk.includes(event.target.id)) {
	// 	  newArray = newArray.filter(day => day !== event.target.id);
	// 	}
	// 	this.setState({
	// 	  set_idProduk: newArray
	// 	});
	//   };
	
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}

		// let id = this.props.match.params.postID;
		let id = this.props.location.state.uuid;

		Get('produk-promo/byIdPromo/'+id + '' + link, null, (lihat) => {
			// console.log("GeT BY PROMO")
			// console.log(lihat)
			// console.log("GeT BY PROMO")
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table

	// let id = this.props.location.state.uuid;

	// 	Get('produk-promo/byIdPromo/'+id + '' + link, null, (lihat) => {
	// 		console.log("GeT BY PROMO")
	// 		console.log(lihat)
	// 		console.log("GeT BY PROMO")
	// 		if (lihat.result) {
	// 			this.setState({ data: lihat.result.data, pagination: lihat.result });
	// 			this.pagination(lihat.result);
	// 		}
	// 	});

	cariData = e => {
		let addProduk = {
			q: e.target.value,
		};

		let id = this.props.location.state.uuid;
		Post('produk-promo/byIdPromo/'+id , null, addProduk, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}


	setProduk = (e) => {  
		if (e) {
			this.setState({ id_provinsi: e.target.value });  
			// console.log("id PRODUK SetNYA: ")
			// console.log(e.target.value)
			// console.log(e.target.value)
			// axios.get('https://ws-marketplace-desa-univ.digidesa.com/api/v1/web/provinsi/listKabkota/' +e.target.value ).then(response => {  
			// 		console.log(response.data);  
			// 		this.setState({  
			// 				kabkotaData: response.data.result,  
			// 		});  
			// });  
		}
		else{

			// console.log("GAGAL SET ID PRODUK")
			this.setState({ id_provinsi: '' });
		} 
	}  
// ===========================================================================
// ============================ SET PRODUK SELECTED ==========================
// ===========================================================================
	pilihProduk = (id) => {
		let newArray = [...this.state.set_idProduk, id];
		
		if (this.state.set_idProduk.includes(id)) {
			newArray = newArray.filter(day => day !== id);
			var kondisi = document.getElementById(id);
			kondisi.classList.remove("btn-danger")
			kondisi.classList.add("btn-success")
		}
		else{
			var kondisi = document.getElementById(id);
			kondisi.classList.remove("btn-success")
			kondisi.classList.add("btn-danger")
		}
		
		this.setState({
		  set_idProduk: newArray,
		  style_class: {button:'btn btn-danger btn-sm'},
		});

		
		for(var i=0;i<newArray.length;i++){
			// console.log("Produk Ke-"+i)
			// console.log("Data Dipisah")
			// console.log(newArray[i])
		}
		
	}
// ===========================================================================
// ============================ END SET PRODUK SELECTED ======================
// ===========================================================================	

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let newArray = this.state.set_idProduk

		for(var i=0;i<newArray.length;i++){
			// console.log("==================")
			// console.log("==================")
			// console.log("DATA INPUTAN")

			// console.log("Kode Promo")
			// console.log(this.props.location.state.uuid)
			// console.log("Produk Ke-"+i)
			// console.log("Data Dipisah")
			// console.log(newArray[i])

			// console.log("END DATA INPUTAN")
			// console.log("==================")
			// console.log("==================")

			var id = null;

			let addProduk = {
				uuid: id,
				id_promo: this.props.location.state.uuid,
				id_produk: newArray[i],
			};

			let psn = 'Tambah';
			let resstat = 200;
			let metode = "create";

			Post('produk-promo/' + metode, id, addProduk, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ show: true, basicType: 'success', basicTitle: 'Data Produk Promo', pesanAlert: 'Berhasil ' + psn + ' Data' });
				}
				else {
					this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Produk Promo', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			});

		}

	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('produk-promo/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Produk Promo', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Produk Promo', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}
	
	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_promo.id = '';
			this.state.data_promo.id_promo = '';
			this.state.id_produk = '';
			this.state.id_desa.value = '';
			this.state.id_desa.label = '';
			this.state.id_jenis_etalase.value = '';
			this.state.diskon_produk.value= '';
			this.state.diskon_produk.label='';
			this.state.set_idProduk=[];
			this.state.style_class.button='btn btn-success btn-sm';
			
			this.state.form_status = 'tambah';
		} else {
			this.state.form_status = 'edit';
		}

		this.state.status.form = true;
		this.forceUpdate();
	}



	tutupForm = () => {
		this.state.status.form = false;
		this.state.status.formDetail = false
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	tombolAksi2 = (cell, row) => {
		return (
			<>
				<button type="button" id={row.uuid} className={this.state.style_class.button} onClick={() => this.pilihProduk(row.uuid)} disabled={this.state.status.btnAksi2}><i className="fa fa-check"></i></button>
			</>
		)
	}

	componentDidMount() {

		// console.log("====================")
		// console.log("DATA DETAIL PROMO")
		// console.log(this.props.location.state)
		// console.log("DATA DETAIL PROMO")
		// console.log("========UUIDNYA============")
		// console.log(this.props.location.state.uuid)
		// console.log("Status Has More")
		// console.log(this.state.hasMore)

		this.fetch({ page: null, where: [] });

		// axios.get(`https://ws-marketplace-desa-univ.digidesa.com/api/v1/web/produk/list?page=${this.state.pageNumber}`).then(response => {  
		// 	console.log("Data Scroll Awal")
		// 	console.log(response.data);  
		// 	setTimeout(() => {
		// 		this.setState({
		// 			data_produk: response.data.result.data,
		// 			pageNumber: 2,
		// 		});
		// 	}, 1500);
		// }); 
		
		// console.log("ID DESA ApAkAH TEr sEt")
		// console.log(this.state.id_desa.value)
		// if(this.state.id_desa.value!=''){
		// 	this.setState({
		// 		url_desa: `?id_desa=${this.state.id_desa.value}&page=1`
		// 	})

		// 	console.log("COBA TAMPILKAN URL DESA")
		// 	console.log(this.state.url_desa)
		// 	Get('produk-promo/filter-promosi' + this.state.url_desa, null, (data) => {
		// 		if (data.result) {
		// 			let data_produk = [];
		// 			let newpageNumber = this.state.pageNumber + 1

		// 			console.log("Baru")
		// 			console.log(newpageNumber)
		// 			console.log(data.result.last_page)

		// 			if(newpageNumber>data.result.last_page){
		// 				this.setState({
		// 					data_produk: [...this.state.data_produk, ...data.result.data],
		// 					hasMore: false,
		// 				});
		// 			}
		// 			else{
		// 				this.setState({
		// 					data_produk: [...this.state.data_produk, ...data.result.data],
		// 					pageNumber: newpageNumber,
		// 				});
		// 			}
		// 		}
		// 	});
		// }
		// else{
			
	// console.log("PAgE Did Mount -")
	// console.log(this.state.pageNumber)
			Get(`produk-promo/filter-promosi?page=${this.state.pageNumber}`+`${this.state.url_desa}`+`${this.state.url_etalase}`+`${this.state.url_diskon}`, null, (data) => {
				if (data.result) {
					this.state.data_produk = [];

						this.setState({
							data_produk: data.result.data,
							hasMore: true,
						});
				}
			});
		// }

		// get data desa for select dropdown
		Get('list-api', null, (data) => {
			if (data.result) {
				let data_desa = [];
				data.result.data.map(dt => {
					data_desa.push({ value: dt.id_desa, label: dt.data_desa.nama + ' - ' + dt.data_desa.data_kecamatan.nama + ' - ' + dt.data_desa.data_kecamatan.data_kabkota.nama });
				})
				this.setState({
					data_desa
				});
			}
		});

		// get data jenis etalase for select dropdown
		Get('jenis-etalase', null, (data) => {
			if (data.result) {
				let dataJenisEtalase = [];
				data.result.data.map(dt => {
					dataJenisEtalase.push({ value: dt.uuid, label: dt.jenis_etalase });
				})
				this.setState({
					dataJenisEtalase
				});
			}
		});

	}

// =============================================================================
// ===============================LOAD DATA SCROLL==============================
// =============================================================================
fetchMoreData = () => {

	// console.log("URL DESA FETCH ADALAH")
	// console.log(this.state.url_desa)
	// console.log("URL ETALASE FETCH ADALAH")
	// console.log(this.state.url_etalase)
	// console.log("URL DISKON FETCH ADALAH")
	// console.log(this.state.url_diskon)
	// console.log("PAgE SAAT INI -")
	// console.log(this.state.pageNumber)

	let newpageNumber = this.state.pageNumber + 1

	// console.log("Page Selanjutnya -")
	// console.log(newpageNumber)

	// console.log("Ada Lanjutan?")
	// console.log(this.state.hasMore)
	
	Get(`produk-promo/filter-promosi?page=${newpageNumber}`+`${this.state.url_desa}`+`${this.state.url_etalase}`+`${this.state.url_diskon}`, null, (data) => {
		// console.log("Data Scroll Awal")
		// console.log(data.result);  
		// console.log("Page Akhirnya")
		// console.log(data.result.last_page); 

		if(newpageNumber>data.result.last_page){
				this.setState({
					data_produk: [...this.state.data_produk, ...data.result.data],
					hasMore: false,
					style_class: {button:'btn btn-success btn-sm'},
				});
		}
		else{
			setTimeout(() => {
				this.setState({
					data_produk: [...this.state.data_produk, ...data.result.data],
					pageNumber: newpageNumber,
					style_class: {button:'btn btn-success btn-sm'},
				});
			}, 1500);
		}

		// console.log("Status Has More")
		// console.log(this.state.hasMore)

	}); 
	this.forceUpdate();
}

// =============================================================================
// ===============================END LOAD DATA SCROLL==============================
// =============================================================================



// =================================================================================
// ============================= DEPENDENT DROPDOWN ================================
// =================================================================================	

// ==================	DESA DROPDOWN DEPENDENT ==================

	onChangeDesa = (e) => {
		// console.log("onchange desa")
		if (e) {
			let set_url_desa= `&id_desa=${e.value}`
			
			this.state.pageNumber=1;
			this.state.data_produk = [];

			Get(`produk-promo/filter-promosi?page=${this.state.pageNumber}`+ set_url_desa, null, (data) => {
				if (data.result) {
					// console.log("DAFTAR PRODUK DESA ADALAH")
					// console.log(data.result)

						let newpageNumber = this.state.pageNumber + 1
						if(newpageNumber>data.result.last_page){
							this.setState({
								hasMore: false,
							});
						}
						else{
							this.setState({
								hasMore: true,
							});
						}

						this.setState({
							data_produk: data.result.data,
							url_desa: set_url_desa,
							id_desa: { value: e.value, label: e.label } ,
							id_jenis_etalase: { value: '', label: '' },
							style_class: {button:'btn btn-success btn-sm'},
							pageNumber:1,
						});
			
				}
			})
		}
		else{

			this.state.pageNumber = 1;
			this.state.data_produk = [];

			Get('produk-promo/filter-promosi' + `?page=${this.state.pageNumber}`, null, (data) => {
				if (data.result) {

					this.state.data_produk = [];

						this.setState({
							data_produk: data.result.data,
							id_kabkota: '',
							id_desa: { value: '', label: '' },
							id_jenis_etalase: { value: '', label: '' },
							style_class: {button:'btn btn-success btn-sm'},
							pageNumber:1,
							hasMore:true,
							url_desa: '',
						});
				}
			});
		}  
	}

	getDesa = (e) => {
		if (e) {
			let addDesa = {
				q: e
			};
			Post('list-api', null, addDesa, (data) => {
				if (data.data.result) {
					let data_desa = [];
					data.data.result.data.map(dt => {
						data_desa.push({ value: dt.id_desa, label: dt.data_desa.nama + ' - ' + dt.data_desa.data_kecamatan.nama + ' - ' + dt.data_desa.data_kecamatan.data_kabkota.nama });
					});
					this.setState({ data_desa, style_class: {button:'btn btn-success btn-sm'}, });
				}
			})
		}
		else {
			Get('list-api', null, (data) => {
				if (data.result) {
					let data_desa = [];
					data.result.data.map(dt => {
						data_desa.push({ value: dt.id_desa, label: dt.data_desa.nama + ' - ' + dt.data_desa.data_kecamatan.nama + ' - ' + dt.data_desa.data_kecamatan.data_kabkota.nama });
					})
					this.setState({
						data_desa,
						style_class: {button:'btn btn-success btn-sm'},
					});
				}
			});
		}
	}

// ================== JENIS ETALASE DROPDOWN DEPENDENT ==================

	onChangeJenisEtalase = (e) => {
	// console.log("onchange elatase")
	if (e) {
		let set_url_etalase = `&id_jenis_etalase=${e.value}`

		// console.log("id Jenis Etalase Changed: ")
		// console.log(e.value) 

			this.state.pageNumber =1;
			this.state.data_produk = [];

			Get(`produk-promo/filter-promosi?page=${this.state.pageNumber}`+ this.state.url_desa + set_url_etalase, null, (data) => {
				if (data.result) {
					// console.log("DAFTAR PRODUK ETALASE")
					// console.log(data.result)

						let newpageNumber = this.state.pageNumber + 1
						if(newpageNumber>data.result.last_page){
							this.setState({
								hasMore: false,
							});
						}
						else{
							this.setState({
								hasMore: true,
							});
						}

						this.setState({
							data_produk: data.result.data,
							url_etalase: set_url_etalase,
							style_class: {button:'btn btn-success btn-sm'},
							id_jenis_etalase: { value: e.value, label: e.label },
							pageNumber:1,
							diskon_produk: { value: '', label: '' },
						});
				}
			})
		}
		else{
			this.setState({ 
				id_jenis_etalase: { value: '', label: '' },
				style_class: {button:'btn btn-success btn-sm'},
				pageNumber:1,
			});

			this.state.pageNumber = 1;
			this.state.data_produk = [];

			Get('produk-promo/filter-promosi' + `?page=${this.state.pageNumber}`+ this.state.url_desa, null, (data) => {
				if (data.result) {

					this.setState({
						data_produk: data.result.data,
						hasMore: true,
						url_etalase:'',
						diskon_produk: { value: '', label: '' },
					});
				}
			});
		}  
	}

getJenisEtalase = (e) => {
	if (e) {
		let addJenisEtalase = {
			q: e
		};
		Post('jenis-etalase', null, addJenisEtalase, (data) => {
			if (data.data.result) {
				let dataJenisEtalase = [];
				data.data.result.data.map(dt => {
					dataJenisEtalase.push({ value: dt.uuid, label: dt.jenis_etalase });
				});
				this.setState({ dataJenisEtalase, style_class: {button:'btn btn-success btn-sm'}, });
			}
		})
	}
	else {
		Get('jenis-etalase', null, (data) => {
			if (data.result) {
				let dataJenisEtalase = [];
				data.result.data.map(dt => {
					dataJenisEtalase.push({ value: dt.uuid, label: dt.jenis_etalase });
				})
				this.setState({
					dataJenisEtalase, style_class: {button:'btn btn-success btn-sm'},
				});
			}
		});
	}
}


	// onChangeJenisEtalase = (e) => {
	// 	if (e) {
	// 		this.setState({ id_jenis_etalase: { value: e.value, label: e.label }, style_class: {button:'btn btn-success btn-sm'}, });
	// 		console.log("id Jenis Etalase Changed: ")
	// 		console.log(e.value) 

	// 		Get('produk-promo/filter-promosi?id_desa='+ this.state.id_desa.value + '&id_jenis_etalase='+ e.value, null, (data) => {
	// 			if (data.result) {
	// 				console.log("DAFTAR PRODUK JENIS ETAlASE")
	// 				console.log(data.result)
	// 				let data_produk = [];
	// 				data.result.data.map(dt => {
	// 					data_produk.push({ 
	// 						value: dt.uuid, 
	// 						label: dt.nama_produk,
	// 						foto_produk: dt.foto_produk,
	// 						deskripsi: dt.deskripsi,
	// 						diskon: dt.diskon,
	// 						harga_produk: dt.harga_produk,
	// 					});
	// 				});
	// 				this.setState({ data_produk });
	// 			}
	// 		})
	// 	}
	// 	else{
	// 		this.setState({ 
	// 			id_jenis_etalase: { value: '', label: '' },
	// 			style_class: {button:'btn btn-success btn-sm'},
	// 		});

	// 		Get('produk-promo/filter-promosi?id_desa='+ this.state.id_desa.value, null, (data) => {
	// 			if (data.result) {
	// 				console.log("DAFTAR PRODUK DESA")
	// 				console.log(data.result)
	// 				let data_produk = [];
	// 				data.result.data.map(dt => {
	// 					data_produk.push({ 
	// 						value: dt.uuid, 
	// 						label: dt.nama_produk,
	// 						foto_produk: dt.foto_produk,
	// 						deskripsi: dt.deskripsi,
	// 						diskon: dt.diskon,
	// 						harga_produk: dt.harga_produk,
	// 					});
	// 				});
	// 				this.setState({ data_produk });
	// 			}
	// 		})
	// 	}  
	// }

	// getJenisEtalase = (e) => {
	// 	if (e) {
	// 		let addJenisEtalase = {
	// 			q: e
	// 		};
	// 		Post('jenis-etalase', null, addJenisEtalase, (data) => {
	// 			if (data.data.result) {
	// 				let dataJenisEtalase = [];
	// 				data.data.result.data.map(dt => {
	// 					dataJenisEtalase.push({ value: dt.uuid, label: dt.jenis_etalase });
	// 				});
	// 				this.setState({ dataJenisEtalase, style_class: {button:'btn btn-success btn-sm'}, });
	// 			}
	// 		})
	// 	}
	// 	else {
	// 		Get('jenis-etalase', null, (data) => {
	// 			if (data.result) {
	// 				let dataJenisEtalase = [];
	// 				data.result.data.map(dt => {
	// 					dataJenisEtalase.push({ value: dt.uuid, label: dt.jenis_etalase });
	// 				})
	// 				this.setState({
	// 					dataJenisEtalase, style_class: {button:'btn btn-success btn-sm'},
	// 				});
	// 			}
	// 		});
	// 	}
	// }

// ================== DISKON PRODUK DROPDOWN DEPENDENT ==================

	onChangeDiskonProduk = (e) => {
		// console.log("onchange diskon")
		if (e) {
			let min_diskon= e.value.min
			let max_diskon= e.value.max
			let set_url_diskon = `&diskon_min=${min_diskon}&diskon_max=${max_diskon}`

			this.state.pageNumber =1;
			this.state.data_produk = [];
			
			Get(`produk-promo/filter-promosi?page=${this.state.pageNumber}`+ this.state.url_desa + this.state.url_etalase + set_url_diskon, null, (data) => {
				if (data.result) {
					// console.log("DAFTAR PRODUK DISKON ADALAH")
					// console.log(data.result)

						let newpageNumber = this.state.pageNumber + 1
						if(newpageNumber>data.result.last_page){
							this.setState({
								hasMore: false,
							});
						}
						else{
							this.setState({
								hasMore: true,
							});
						}

						this.setState({
							data_produk: data.result.data,
							url_diskon: set_url_diskon,
							diskon_produk: { value: e.value, label: e.label }, 
							pageNumber:1,
							style_class: {button:'btn btn-success btn-sm'},
						});
				}
			})
		}
		else{

			this.state.pageNumber = 1;
			this.state.data_produk = [];

			Get(`produk-promo/filter-promosi?page=${this.state.pageNumber}`+ this.state.url_desa + this.state.url_etalase, null, (data) => {
				if (data.result) {
					// console.log("DAFTAR PRODUK ETALASE")
					// console.log(data.result)
						this.setState({
							data_produk: data.result.data,
							hasMore: true,
							pageNumber:1,
							url_diskon: '',
							diskon_produk: { value: '', label: '' },
							style_class: {button:'btn btn-success btn-sm'},
						});
				}
			})
		}  
	}

// =================================================================================
// ============================= END DEPENDENT DROPDOWN ============================
// =================================================================================
	
	// onChangeProduk = (e) => {
	// 	if (e) {
	// 		this.setState({ 
	// 			id_produk: { value: e.value, label: e.label },
	// 			foto_produk: e.foto_produk,
	// 			deskripsi: e.deskripsi,
	// 			diskon: e.diskon,
	// 			harga_produk: e.harga_produk,
	// 		});
	// 	}
	// }

	// getProduk = (e) => {
	// 	if (e) {
	// 		let addProduk = {
	// 			q: e
	// 		};
	// 		Post('produk/list', null, addProduk, (data) => {
	// 			if (data.data.result) {
	// 				let data_produk = [];
	// 				data.data.result.data.map(dt => {
	// 					data_produk.push({ 
	// 						value: dt.uuid, label: dt.nama_produk,
	// 						foto_produk: dt.foto_produk,
	// 						deskripsi: dt.deskripsi,
	// 						diskon: dt.diskon,
	// 						harga_produk: dt.harga_produk,
	// 					});
	// 				});
	// 				this.setState({ 
	// 					data_produk
	// 				});
	// 			}
	// 		})
	// 	}
	// 	else {
	// 		Get('produk/list', null, (data) => {
	// 			if (data.result) {
	// 				let data_produk = [];
	// 				data.result.data.map(dt => {
	// 					data_produk.push({ 
	// 						value: dt.uuid, label: dt.nama_produk,
	// 						foto_produk: dt.foto_produk,
	// 						deskripsi: dt.deskripsi, 
	// 						diskon: dt.diskon, 
	// 						harga_produk: dt.harga_produk, 
	// 					});
	// 				})
	// 				this.setState({
	// 					data_produk
	// 				});
	// 			}
	// 		});
	// 	}
	// }


	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

   onSubmit = (e) => {
	e.preventDefault();
	   
	   let addProduk = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)
		
		let id = this.props.location.state.uuid;
		Post('produk-promo/byIdPromo/'+id , null, addProduk, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
         /* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	render() {
		// console.log("COBA WORK");
		// console.log(this.state.set_idProduk);
		// console.log("GET ID DSA ADALAH")
		// console.log(this.state.id_desa.value)
		// console.log("FUSIOOONNN !!!!")
		// console.log(this.state.set_idProduk)
		// console.log("sekarang di page")
		// console.log(this.state.pageNumber)
		// console.log("Status Has More KINI")
		// 			console.log(this.state.hasMore)

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;

		var data = this.state.data;
		var data2 = this.state.data_produk;

		const frmDef = {
			uuid: '',
			id_produk: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'data_produk.nama_produk',
				text: 'Nama Produk',
				sort: true
			},
			{
				dataField: 'data_produk.harga_produk',
				text: 'Harga',
				sort: true
			},
			{
				dataField: 'data_produk.stok',
				text: 'Stok',
				sort: true
			},
			{
				dataField: 'data_produk.diskon',
				text: 'Diskon',
				sort: true
			},

			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},

		];
	
		const columns2 = [
			{
				dataField: 'nama_produk',
				text: 'Produk',
				sort: true
			},

			{
				dataField: 'diskon',
				text: 'Diskon',
				sort: true
			},
			{
				dataField: 'harga_produk',
				text: 'Harga Produk',
				sort: true
			},

			{
				dataField: 'deskripsi',
				text: 'Deskripsi',
				sort: true
			},

			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi2,
			},

		];

		const columns3 = [
			{
				dataField: 'id',
				text: 'Id Keldes',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama Keldes',
				sort: true
			},

			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi2,
			},

		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Detail Produk Promo" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
														/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>         
											</form>
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Produk Promo", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
											&nbsp;&nbsp;
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>

										<BootstrapTable
											keyField="uuid"
											data={data}
											columns={columns}
										/>

									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="cek" type="hidden" defaultValue="simpan" />
							<input className="form-control" id="id_promo" type="hidden" placeholder="Id Promo" defaultValue={this.props.location.state.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pilih Desa</Label>
								<Col sm='9'>
									<Select 
										classNamePrefix="select" 
										onChange={this.onChangeDesa}
										value={this.state.id_desa}
										name="id_desa"
										options={this.state.data_desa}
										placeholder="Pilih Desa"
										onInputChange={this.getDesa}
										isClearable 
									/>
								</Col>
							</FormGroup>
						{
							this.state.id_desa.value==''
						?
							<>
							</>
						: 
							<>
								<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pilih Jenis Etalase</Label>
								<Col sm='9'>
									<Select 
										classNamePrefix="select" 
										onChange={this.onChangeJenisEtalase}
										value={this.state.id_jenis_etalase}
										name="id_jenis_etalase"
										options={this.state.dataJenisEtalase}
										placeholder="Pilih Jenis Etalase"
										onInputChange={this.getJenisEtalase}
										isClearable 
									/>
								</Col>
							</FormGroup>
						
							{
								this.state.id_desa.value!='' && this.state.id_jenis_etalase.value!=''
							?
								<>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Diskon Produk</Label>
									<Col sm='9'>
										<Select
											classNamePrefix="select"
											onChange={this.onChangeDiskonProduk}
											value={this.state.diskon_produk}
											name="diskon_produk"
											options={[
												{ value: {min: '0', max:'9'}, label: '< 10%' },
												{ value: {min:'10', max:'25'}, label: '10% - 25%' },
												{ value: {min:'26', max:'100'}, label: '>25' },
											]}
											placeholder="Pilih Diskon Produk"
											isClearable
										/>
									</Col>
								</FormGroup>
								</>
							: 
								<>
								</>
							}
									
							</>
						}

							<div id="scrollableDiv" style={{ height: 300, overflow: "auto" }}>
								<InfiniteScroll
									dataLength={this.state.data_produk.length}
									next={this.fetchMoreData}
									hasMore={this.state.hasMore}
									loader={<h4>Loading...</h4>}
									scrollableTarget="scrollableDiv"
								>
									<BootstrapTable
										keyField="uuid"
										data={data2}
										columns={columns2}
									/>
								</InfiniteScroll>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>

			</div>
		)
	}
}


export default DetailPromo;
