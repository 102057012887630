import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Select from 'react-select';

const frmDef = {
	uuid: '',
	jenis_pembayaran: '',
}

class Penduduk extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Jenis Pembayaran",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			// data penduduk
			data_penduduk: {
				uuid: '',
				nik: '',
				id_desa: '',
				uuid_pengguna: '',
			},
			data_pengguna: [],
			data_desa: [],

			name:'',
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('penduduk' + link, null, (lihat) => {
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		let addJenisPembayaran = {
			q: e.target.value,
		};
		Post('penduduk', null, addJenisPembayaran, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let addPenduduk = {
			uuid: document.getElementById("uuid").value,
			nik: document.getElementById("nik").value,
			uuid_pengguna: this.state.data_penduduk.uuid_pengguna.value,
			id_desa: this.state.data_penduduk.id_desa.value,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addPenduduk.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addPenduduk.uuid = null;

		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('penduduk/' + metode, addPenduduk.uuid, addPenduduk, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Penduduk', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Penduduk', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Jenis Pembayaran";
		this.forceUpdate();

		Get('penduduk/find', id, (data) => {
			if (data.result) {
				this.state.data_penduduk.uuid = data.result.uuid;
				this.state.data_penduduk.nik = data.result.nik;
				this.state.data_penduduk.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.data_penduduk.uuid_pengguna = { value: data.result.uuid_pengguna, label: data.result.data_pengguna.username };
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('jenis-pembayaran/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Penduduk', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Penduduk', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {

		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				{/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button> */}
			</>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });
		// Get list desa for select dropdown
		Get('keldes', null, (data) => {
			if (data.result) {
				let data_desa = [];
				data.result.data.map(dt => {
					data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama });
				})
				this.setState({
					data_desa
				});
			}
		});
	}

	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'uuid_pengguna':
					this.state.data_penduduk.uuid_pengguna = { value: e.value, label: e.label };
					break;

				case 'id_desa':
					this.state.data_penduduk.id_desa = { value: e.value, label: e.label };
					break;
			}
		} else {
			switch (data) {
				case 'uuid_pengguna':
					this.state.data_penduduk.uuid_pengguna = '';
					break;

				case 'id_desa':
					this.state.data_penduduk.id_desa = '';
					break;
			}
		}
	}

	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
			}
			Post(table, null, find_data, (data) => {
				if (data.data.result) {
					switch (table) {
						case 'pengguna':
							let data_pengguna = [];
							data.data.result.data.map(dt => {
								data_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ data_pengguna });
							break;

						case 'keldes':
							let data_desa = [];
							data.data.result.data.map(dt => {
								data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama });
							});
							this.setState({ data_desa });
							break;
					}
				}

			});
		} else {
			Get(table, null, data => {
				if (data.result) {
					switch (table) {
						case 'pengguna':
							let data_pengguna = [];
							data.result.data.map(dt => {
								data_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ data_pengguna });
							break;

						case 'keldes':
							let data_desa = [];
							data.result.data.map(dt => {
								data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama });
							});
							this.setState({ data_desa });
							break;
					}
				}
			});
		}
	}


	handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

   onSubmit = (e) => {
	e.preventDefault();
	   
	   let addPenduduk = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)
		
	   Post('penduduk', null, addPenduduk, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
         /* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'data_pengguna.username',
				text: 'Username',
				sort: true
			},
			{
				dataField: 'nik',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'data_keldes.nama',
				text: 'Nama Desa',
				sort: true
			},
			// {
			// 	dataField: 'aksi',
			// 	text: 'Aksi',
			// 	isDummyField: true,
			// 	csvExport: false,
			// 	formatter: this.tombolAksi,
			// },
		];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Penduduk" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
									<div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
														/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>         
											</form>
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										</div>
									</div>
									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Data Penduduk" defaultValue={this.state.data_penduduk.uuid} />
							<input className="form-control" id="nik" type="hidden" placeholder="Data Penduduk" defaultValue={this.state.data_penduduk.nik} />

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK</Label>
								<Col sm='9'>
									<input className="form-control" id="nik" type="text" placeholder="Jenis Pembayaran" defaultValue={this.state.data_penduduk.nik} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Username</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.onChangeSelect(e, 'uuid_pengguna')}
										value={this.state.data_penduduk.uuid_pengguna}
										name="uuid_pengguna"
										options={this.state.data_pengguna}
										placeholder="Pilih Username"
										onInputChange={(e) => this.getSelect(e, 'pengguna')}
										isClearable
									/>
								</Col>
							</FormGroup> */}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Desa</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.onChangeSelect(e, 'id_desa')}
										value={this.state.data_penduduk.id_desa}
										name="id_desa"
										options={this.state.data_desa}
										placeholder="Pilih Desa"
										onInputChange={(e) => this.getSelect(e, 'keldes')}
										isClearable
									/>
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default Penduduk;
