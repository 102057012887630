import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';


// ================
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

//Image
import { ProgressBar } from 'react-bootstrap';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
	uuid: '',
	jenis_pembayaran: '',
}

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new TutorialAdd(loader)
	}
}


class TutorialAdd extends Component {

	constructor(props) {
		super(props);
		this.loader = props;
		// URL where to send files.
		this.url = localStorage.getItem('API_URL') + `upload-file-multiple/galery`;
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				tipe: '',
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Tutorial",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',



			//ckeditor
			content: '',
			konten: '',
			responnya: [],

			//Gambar
			data_gallery: [],
			image: null,
			avatar: '',
			gambarnya: '',
			preview_gambar: null,
			uploadPercentage: 0,


			// data for select
			id_jenis: '',
			list_jenis_panduan: [],

			get_jenis_app: '',
			jenis_app: [],

			name: '',
		};

	}

	// =========== CKEDITOR 5 =============
	upload() {
		return new Promise((resolve, reject) => {

			const data = new FormData();
			console.log("=======================")
			console.log("Form Datanya")
			console.log(data)
			console.log("=======================")

			this.loader.file.then(result => {
				data.append('files[]', result);
				// this.xhr.send(data);

				axios.post(localStorage.getItem('API_URL') + "upload-file-multiple/galery", data).then(res => {
					console.log(res)

					if (res.data.results) {
						console.log("HAHA Gambarnya adalah")
						console.log(res.data.results[0])

						// this.state.gambarnya = res.data.results[0];


						const xhr = res.data;
						const loader = this.loader;
						const genericErrorText = 'Couldn\'t upload file:' + ` ${loader.file.name}.`;

						// xhr.code('404', () => reject(genericErrorText));
						// xhr.addEventListener('abort', () => reject());
						const response = xhr.code;

						if (response != 200) {
							reject(genericErrorText)
						}
						else if (this.state.responnya.resp == 'Hapus') {
							reject()
						}
						else if (!response || response != 200) {
							return reject(response && response != 200 ? response.error.message : genericErrorText);
						}
						// If the upload is successful, resolve the upload promise with an object containing
						// at least the "default" URL, pointing to the image on the server.
						else if (response == 200)
							resolve({
								default: xhr.results[0]
							});
					}
				})
			}
			)

			// if ( xhr.upload ) {
			//     xhr.upload.addEventListener( 'progress', evt => {
			//         if ( evt.lengthComputable ) {
			//             loader.uploadTotal = evt.total;
			//             loader.uploaded = evt.loaded;
			//         }
			//     } );
			// }
		})
	}

	// Aborts the upload process.
	abort() {
		if (this.xhr) {
			this.xhr.abort();

			this.setState({
				responnya: { code: '406', resp: 'Hapus' }
			});
		}
	}

	// =========== END CKEDITOR 5 =============

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('panduan' + link, null, (lihat) => {
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		if (e) {
			let addToko = {
				q: e.target.value,
			};
			Post('toko', null, addToko, (data) => {
				if (data.data.result) {
					this.setState({ data: data.data.result.data, pagination: data.data.result });
				}
			});
		} else {
			Get('toko', null, (lihat) => {
				if (lihat.result) {
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		}
	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let linknya = `${window.location.origin.toString()}/detail_tutor/`

		let addData = {
			uuid: document.getElementById("uuid").value,
			judul: document.getElementById("judul").value,
			konten: this.state.konten,
			jenis: this.state.id_jenis,
			jenis_app: this.state.get_jenis_app,
			url_web: linknya,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addData.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addData.uuid = null;
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		Post('panduan/' + metode, addData.uuid, addData, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Tutorial', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else if (res.status === 422) {
				this.setState({ show: true, basicType: 'warning', basicTitle: 'Data Tutorial', pesanAlert: 'Kategori Panduan Sudah Ada, Silahkan Pilih Kategori Panduan Yang Lain !' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Tutorial', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}


	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Data Tutorial";
		this.forceUpdate();

		Get('panduan/find', id, (data) => {
			console.log("Ubah Data")
			console.log(data.result)
			if (data.result) {
				this.state.data.uuid = id;
				this.state.id_jenis = data.result.jenis;
				this.state.content = data.result.konten;
				this.state.konten = data.result.konten;
				this.state.data.judul = data.result.judul;
				this.state.get_jenis_app = data.result.jenis_app;
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});

	}

	detailData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Detail Data Tutorial";
		this.forceUpdate();

		Get('panduan/find', id, (data) => {
			if (data.result) {
				this.state.data.uuid = id;
				this.state.id_jenis = data.result.jenis;
				this.state.content = data.result.konten;
				this.state.konten = data.result.konten;
				this.state.data.judul = data.result.judul;
				this.state.data.dibuat = data.result.created_at;
				this.state.data.diubah = data.result.updated_at;
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('detail');
		});

	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('panduan/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Tutorial', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Tutorial', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data.uuid = '';
			this.state.id_jenis = '';
			this.state.content = '';
			this.state.konten = '';
			this.state.data.judul = '';
			this.state.get_jenis_app = '';
			this.state.status.form = true;
			this.state.status.tipe = 'tambah';
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.state.status.tipe = 'edit';
			this.forceUpdate();
		}
		else if (e == 'detail') {
			this.state.status.form = true;
			this.state.status.tipe = 'detail';
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				{/* <Button size="xs" color="primary" className="btn-icon" onClick={() => this.detailData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-eye"></i></Button> &nbsp;&nbsp; */}
				<Link to={{ pathname: `/detail_tutor/${row.uuid}`, state: row.uuid }}>
					<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
				</Link>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });


		// Data for select
		Get("../select-enum/panduan/jenis", null, data => {
			if (data) {
				let list_jenis_panduan = [];
				data.map(dt => {
					list_jenis_panduan.push({ value: dt, label: dt });
				});
				this.setState({ list_jenis_panduan });
			}
		});

		// Data for  Jenis Panduan
		Get("../select-enum/list_api/jenis_app", null, data => {
			console.log("Data Enum")
			console.log(data);
			if (data) {
				let jenis_app = [];
				data.map(dt => {
					jenis_app.push({ value: dt, label: dt });
				});
				this.setState({ jenis_app });
			}
		});


	}


	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addToko = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('toko', null, addToko, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}


	// =================== COMBO BOX JENIS PANDUAN ====================
	ChangeJenis = (e) => {
		if (e) {
			this.setState({ id_jenis: e.target.value });

		}
		else {
			this.setState({ id_jenis: '' });
		}

	}

	// =================== END COMBO BOX JENIS PANDUAN ====================

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'jenis_app':
					this.setState({ get_jenis_app: e.target.value });
					break;
				case 'jenis_panduan':
					this.setState({ id_jenis: e.target.value });
					break;
			}
		} else {
			switch (data) {
				case 'jenis_app':
					this.setState({ get_jenis_app: '' });
					break;
				case 'jenis_panduan':
					this.setState({ id_jenis: '' });
					break;
			}
		}
	}


	// =================== CKEDITOR5 ==========================
	handleCKEditor = (event, editor) => {
		const data = editor.getData();
		console.log("DATA KONTEN")
		console.log(data)

		this.setState({
			konten: data
		})
	}
	// =================== END CKEDITOR5 ==========================

	//============== SHOW DETAIL CKEDITOR RESULT ==================
	createMarkup = () => {
		return { __html: this.state.content };
	}
	//============= END SHOW DETAIL CKEDITOR RESULT===========

	render() {
		// console.log("Data CKEDITOR")
		// console.log(this.state.konten)
		// console.log("Loader")
		// console.log(this.loader)

		const custom_config = {
			extraPlugins: [MyCustomUploadAdapterPlugin],
			// plugins: [ImageResize],
			toolbar: {
				items: [
					'heading',
					'|',
					'bold',
					'italic',
					'link',
					'bulletedList',
					'numberedList',
					'|',
					'blockQuote',
					'insertTable',
					'|',
					'imageUpload',
					'undo',
					'redo'
				]
			},
			image: {
				// resizeUnit: 'px',
				toolbar: [
					'imageStyle:full',
					'imageStyle:side',
					'|',
					'imageTextAlternative',
				]
			},
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
			}
		}

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },

			{
				dataField: 'jenis',
				text: 'Jenis Panduan',
				sort: true
			},
			{
				dataField: 'judul',
				text: 'Judul',
				sort: true
			},
			{
				dataField: 'created_at',
				text: 'Dibuat Tanggal',
				sort: true
			},
			{
				dataField: 'updated_at',
				text: 'Diubah Tanggal',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};


		return (
			<div>
				<Breadcrumb title="Data Tutorial" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										{/* <div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
													/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>
											</form> */}
										{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										{/* </div> */}
										<div className="col-sm-6 text-left">
											<Button size="sm" color="success" className="btn-square" onClick={() => {
												this.setState({ judul: "Tambah Tutorial", dt: frmDef }); 
												this.bukaForm('tambah'); 
												}}>
													 Tambah Data
											</Button>
										</div>
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "100%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					{
						this.state.status.tipe == 'tambah' || this.state.status.tipe == 'edit'
							?
							<Form className="theme-form" onSubmit={this.simpan}>
								<div className="modal-header">
									<h5 className="modal-title">{this.state.judul}</h5>
								</div>
								<div className="modal-body">
									<input id="uuid" type="hidden" defaultValue={this.state.data.uuid} />
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Aplikasi</Label>
										<Col sm='9'>
											<select
												className="form-control"
												name="jenis_app"
												value={this.state.get_jenis_app}
												// onChange={this.ChangeJenis}
												onChange={(e) => this.onChangeSelect(e, 'jenis_app')}
											>
												<option value="">Pilih Jenis Aplikasi</option>
												{this.state.jenis_app.map((e, key) => {
													return <option key={key} value={e.value}>{e.value}</option>;
												})}
											</select>
											{/* <Select
										classNamePrefix="select"
										onChange={(e) => this.onChangeSelect(e, 'jenis_app')}
										value={this.state.jenis_app}
										name="jenis_app"
										options={[
											{ value: 'Digidesa', label: 'Digidesa' },
											{ value: 'Amanat Nagari', label: 'Amanat Nagari' },
											{ value: 'Halaman Kampung', label: 'Halaman Kampung' },
										]}
										placeholder="Pilih Jenis Aplikasi"
										isClearable
									/> */}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Judul</Label>
										<Col sm='9'>
											<input className="form-control" id="judul" type="text" placeholder="Judul" defaultValue={this.state.data.judul} />
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Panduan</Label>
										<Col sm='9'>
											<select
												className="form-control"
												name="jenis"
												value={this.state.id_jenis}
												// onChange={this.ChangeJenis}
												onChange={(e) => this.onChangeSelect(e, 'jenis_panduan')}
											>
												<option value="">Pilih Jenis Panduan</option>
												{this.state.list_jenis_panduan.map((e, key) => {
													return <option key={key} value={e.value}>{e.value}</option>;
												})}
											</select>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Isi Panduan</Label>
										<br />
										<Col sm='12'>
											<CKEditor
												required
												editor={Editor}
												config={custom_config}
												onChange={this.handleCKEditor}
												data={this.state.konten}
											// onBlur={(event, editor) => {
											// 	console.log('Blur.', editor);
											// }}
											// onFocus={(event, editor) => {
											// 	console.log('Focus.', editor);
											// }}
											/>
											{/* <CKEditor
												required
												editor={ClassicEditor}
												config={custom_config}
												data={this.state.konten}
												onChange={this.handleCKEditor}

											// onChange={(event, editor) => {
											// 	const data = editor.getData()
											// 	onChange(data)
											// }}
											/> */}
										</Col>
									</FormGroup>

								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
									<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
								</div>
							</Form>
							:
							<div>
								<Form className="theme-form" onSubmit={this.simpan}>
									<div className="modal-header">
										<h5 className="modal-title">{this.state.judul}</h5>
									</div>
									<div className="modal-body">
										<h3 align="center">{this.state.data.judul}</h3>
										<br />
										<p align="left"><b>Dibuat Pada:</b> {this.state.data.dibuat} </p>
										<p align="left"><b>Diubah Pada:</b> {this.state.data.diubah} </p>
										<p align="left"><b>Kategori Panduan:</b> {this.state.id_jenis}</p>
										<br /><br />

										<div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div>


									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
									</div>
								</Form>
							</div>
					}

				</Modal>
			</div>
		)
	}
}


export default TutorialAdd;
