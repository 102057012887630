import React, { Component } from 'react';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { cekLogin } from '../../function/Koneksi';
import SweetAlert from 'react-bootstrap-sweetalert';

var images = require.context('../../assets/images/', true);

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      style: {},
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
    };
  }

  // ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

  componentDidMount() {
    setTimeout(function () {
      this.setState({ style: { display: 'none' } });
    }.bind(this), 1000)
  }

  handleSubmit = event => {
    event.preventDefault();
    let cekLog = {
      username: document.getElementById("username").value,
      password: document.getElementById("password").value
    };
    console.log(cekLog);
    let resstat = 200;

    cekLogin('auth/login', cekLog, (res) => {

      console.log(res);
      if (res.status === resstat) {

        // localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("access_token", res.data.result.token_marketplace);

        this.props.history.push("/"); // apa gunanya ini ?

      }
      else if (res.status === 401) {
        this.setState({ show: true, basicType: 'danger', basicTitle: 'Login', pesanAlert: 'Akun belum diverifikasi, Silahkan hubungi admin!' });

      }
      else {

        this.setState({ show: true, basicType: 'danger', basicTitle: 'Login', pesanAlert: 'Username atau password salah!' });
      }
    })
  }

  render() {
    let style = this.state.style;
    const background = require('../../assets/images/auth-layer.png')

    return (
      <div>
        <SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>
        {/* Loader starts */}
        <div className="loader-wrapper" style={style}>
          <div className="loader bg-white">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <h4>Have a great day at work today <span>&#x263A;</span></h4>
          </div>
        </div>
        {/* Loader ends */}

        {/*page-wrapper Start*/}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/*login page start*/}
            <div className="authentication-main" >
              <div className="row">
                <div className="col-md-4 p-0">
                  <div className="auth-innerleft" style={{ backgroundImage: "url(" + background + ")" }}>
                    <div className="text-center">
                      <img src={require('./../../assets/images/logs.png')}
                        className="logo-login" alt="" title="This is login logo" />
                      <hr />
                     
                    </div>
                  </div>
                </div>
                <div className="col-md-8 p-0">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <h4>LOGIN <br/>Digidesa Marketplace</h4>
                      <h6>Masukkan username dan password anda untuk login ke dalam sistem</h6>
                      <div className="card mt-4 p-4 mb-0">
                        <Form className="theme-form" onSubmit={this.handleSubmit} >
                          <div className="form-group">
                            <label className="col-form-label pt-0">Username</label>
                            <input required id="username" type="text" className="form-control form-control-lg" autoFocus />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input required id="password" type="password" className="form-control form-control-lg" />
                          </div>
                          <div className="form-group form-row mt-3">
                            <div className="col-md-3">
                              <button type="submit" className="btn btn-secondary">LOGIN</button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*login page end*/}
          </div>
        </div>
        {/*page-wrapper Ends*/}

      </div>

    )
  }
}


export default Login;
