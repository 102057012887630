import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin, PostWisata } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { HariTanggalJam, Tanggal } from '../../function/Format';

const frmDef = {
	uuid: '',
	jenis_pembayaran: '',
}
const frmInit = {
	uuid: '',
	status: null,
}

class VerifikasiDesaWisata extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				formDetail: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmInit,
			detailData: null,
			judul: "Detail Verifikasi",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			loading: false,

			kategori_status: '',

			// data toko
			data_toko: {
				id_toko: '',
				nama_toko: '',
				jenis_toko: '',
				telepon_toko: '',
				alamat_toko: '',
				lat_toko: '',
				lang_toko: '',
				jam_buka_toko: '',
				jam_tutup_toko: '',
				is_active: 0,
			},

			//kirim id


			// data for select
			list_desa: [],
			id_desa: '',
			list_kategori_usaha: [],
			id_kategori_usaha: '',
			list_pengguna: [],
			id_pengguna: '',
			id_status_toko: null,

			name: '',
			textCari: ''
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		this.setState({ loading: true })

		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		PostWisata('pengaturan/pengajuan-desa-wisata' + link, null, {
			status: this.state.kategori_status,
			q: this.state.textCari,
		}, (lihat) => {
			// console.log(lihat);
			// console.log(lihat.data.results.data);

			if (lihat.data.results) {
				this.setState({
					data: lihat.data.results.data,
					pagination: lihat.data.results,
					loading: false,
				});
				this.pagination(lihat.data.results);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			this.setState({
				textCari: e.target.value,
			});
			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	simpan = e => {
		e.preventDefault();
		// this.state.status.btnForm = true;
		// this.forceUpdate();

		this.setState({
			status: {
				...this.state.status,
				btnForm: true,
			}
		})

		if (this.state.dt && this.state.dt.uuid !== '' && this.state.dt.status) {
			const bodyData = {
				uuid: this.state.dt.uuid,
				status: this.state.dt.status.value,
			}

			PostWisata("pengaturan/pengajuan-desa-wisata/update", bodyData.uuid, bodyData, res => {
				if (res.status === 201) {
					this.setState({
						show: true,
						basicType: "success",
						basicTitle: "Data Verifikasi Desa Wisata",
						pesanAlert: "Berhasil Update Data Verifikasi",
						loadingPreview: false,
						status: {
							...this.state.status,
							form: false,
							btnForm: false,
						}
					});
					this.fetch()
				} else {
					this.setState({
						show: true,
						basicType: "danger",
						basicTitle: "Data Verifikasi Desa Wisata",
						pesanAlert: "Gagal Update Data Verifikasi",
						loadingPreview: false,
						status: {
							...this.state.status,
							btnForm: false,
						}
					});
				}
			})

		} else {
			this.setState({
				show: true, 
				basicType: 'warning', 
				basicTitle: 'Data Verifikasi Desa Wisata', 
				pesanAlert: 'Isi seluruh data dengan benar',
				status: {
					...this.state.status,
					btnForm: false,
				}
			});
		}

	}

	// handleDetail= (id) =>{
	// 	this.props.history.push(`/detail-toko/${id}`);
	// }

	goDetail = (id) => {
		this.props.history.push(`/detail-toko/${id}`);

		Get('toko/find', id, (data) => {
			if (data.result) {
				this.state.data_toko.id_toko = id;
				this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

				this.state.data_toko.nama_toko = data.result.nama_toko;
				this.state.data_toko.telepon_toko = data.result.telepon;
				this.state.data_toko.alamat_toko = data.result.alamat;
				this.state.data_toko.lat_toko = data.result.lat;
				this.state.data_toko.lang_toko = data.result.lang;
				this.state.data_toko.jam_buka_toko = data.result.jam_buka;
				this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
				this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	ubahData = (id) => {
		// this.state.status.btnAksi = true;
		// this.state.judul = "Ubah Data Toko";
		// this.forceUpdate();

		// Buka form
		this.setState({
			status: {
				...this.state.status,
				form: true,
			}
		})
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('toko/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Toko', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Toko', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_toko.id_toko = '';
			this.state.id_kategori_usaha = '';
			this.state.id_desa = '';
			this.state.id_pengguna = '';
			this.state.data_toko.nama_toko = '';
			this.state.data_toko.jenis_toko = '';
			this.state.data_toko.telepon_toko = '';
			this.state.data_toko.alamat_toko = '';
			this.state.data_toko.lat_toko = '';
			this.state.data_toko.lang_toko = '';
			this.state.data_toko.jam_buka_toko = '';
			this.state.data_toko.jam_tutup_toko = '';
			this.state.id_status_toko = '';
			this.state.status.form = true;
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.forceUpdate();
		}

	}

	tutupForm = () => {
		this.state.status.form = false;
		this.state.status.formDetail = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<div className='d-flex justify-content-center'>
				<Button size="xs" color="info" title="Detail" className="btn-eye mr-2" onClick={(e) => {
					this.setState({
						detailData: row,
						status: {
							...this.state.status,
							formDetail: true,
						}
					})
				}}>
					<i className="fa fa-eye"></i>
				</Button>
				<Button size="xs" color="success" title="Update Status" className="btn-icon mr-2" onClick={() => {
					this.setState({
						judul: 'Update Status Verifikasi',
						detailData: row,
						dt: {
							uuid: row.uuid,
							status: '',
						},
						status: {
							...this.state.status,
							form: true,
						}
					})
				}} disabled={this.state.status.btnAksi}>
					<i className="fa fa-refresh"></i>
				</Button>
				{/* <Button size="xs" color="danger" className="btn-trash" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button> */}
			</div>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: { value: e.value, label: e.label } });
					break;

				case 'id_desa':
					this.setState({ id_desa: { value: e.value, label: e.label } });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: { value: e.value, label: e.label } });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: '' });
					break;

				case 'id_desa':
					this.setState({ id_desa: '' });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: '' });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: '' });
					break;
			}
		}
	}
	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
			}
			Post(table, null, find_data, (data) => {
				if (data.data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;
					}
				}
			});
		} else {
			Get(table, null, data => {
				if (data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;

					}
				}
			});
		}
	}


	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addToko = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('toko', null, addToko, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}


	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;

		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },
			{
				dataField: 'created_at',
				text: 'Tanggal Pengajuan',
				headerAlign: 'center',
				align: 'center',
				sort: true,
				formatter: (key, obj, index) => {
					return HariTanggalJam(obj.created_at)
				}
			},
			{
				dataField: 'data_desa_wisata.nama',
				text: 'Nama Desa',
				headerAlign: 'center',
				align: 'center',
				sort: true
			},
			{
				dataField: 'keterangan',
				text: 'Keterangan',
				headerAlign: 'center',
				align: 'center',
				sort: true
			},
			{
				dataField: 'status',
				text: 'Status',
				headerAlign: 'center',
				align: 'center',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing {from} to {to} of {total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Verifikasi Desa Wisata" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											{/* <label style={{ margin: 0 }}>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
													/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button> */}
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}

											<select id='selectJenis' className='form-control mb-3' defaultValue={this.state.kategori_status} onChange={(e) => {
												let kategori_status = ''
												if (e.target.value !== '') {
													kategori_status = e.target.value
												}

												this.setState({
													kategori_status,
												})

												setTimeout(
													function () {
														this.fetch();
													}.bind(this),
													500
												);
											}}>
												<option value="">Semua Status</option>
												<option value="Menunggu Verifikasi">Menunggu Verifikasi</option>
												<option value="Verifikasi">Verifikasi</option>
												<option value="Ditolak">Ditolak</option>
											</select>

											<div style={{ position: 'relative' }}>
												<input id='cariTable' type="text" onKeyPress={this.cariData} defaultValue={this.state.textCari} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} />
												<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : 'none', position: 'absolute', top: '9px', right: '9px' }}>
													<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
														this.setState({
															textCari: '',
															loading: true
														});
														document.getElementById('cariTable').value = ''
														setTimeout(
															function () {
																this.fetch();
															}.bind(this),
															500
														);
													}} ></span>
												</div>
											</div>
										</div>
										<div className="col-sm-6 text-right">
											{/* <Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Toko", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button> */}
										</div>
									</div>

									{this.state.loading ? (
										<div align="center">
											<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
											<br />
											<img
												alt="loading"
												src={require("../../assets/images/tes-loading.gif")}
												style={{
													// width: "100%",
													borderRadius: "10px",
													width: '150px'
												}}
											/>
											<br /><br />
										</div>
									) : (
										<>
											<BootstrapTable
												keyField="uuid"
												data={data}
												columns={columns}
											/>
											<div className="pull-right text-white">
												{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
											</div>
										</>
									)}


								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal styles={{ modal: { width: "80%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Desa</Label>
								<Col sm='9'>
									<input className="form-control" type="text" value={this.state.detailData ? this.state.detailData.data_desa_wisata.nama : '-'} readOnly />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
								<Col sm='9'>
									<input className="form-control" type="text" value={this.state.detailData ? this.state.detailData.data_desa_wisata.alamat : '-'} readOnly />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											this.setState({
												dt: {
													...this.state.dt,
													status: e,
												}
											})
										}}
										defaultValue={this.state.dt.status}
										value={this.state.dt.status}
										name="status"
										options={[
											{
												value: 'Ditolak',
												label: 'Ditolak',
											},
											{
												value: 'Verifikasi',
												label: 'Verifikasi',
											},
										]}
										placeholder="Pilih Status"
									// onInputChange={(e) => this.getSelect(e, 'pengguna')}
									// isClearable
									/>
								</Col>
							</FormGroup>

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor Telepon</Label>
								<Col sm='9'>
									<input type="number" id="telepon" className="form-control" defaultValue={this.state.data_toko.telepon_toko} />
								</Col>
							</FormGroup> */}

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>

				{/* Modal Detail */}
				<Modal styles={{ modal: { width: "60%", maxWidth: 'unset' } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={true}>
					<div className="modal-header">
						<h5 className="modal-title">Detail Data Verifikasi</h5>
					</div>
					<div className="modal-body">
						{/* <input id="uuid" type="hidden" defaultValue={this.state.dt.uuid} /> */}

						<table>
							<tr>
								<td style={{ fontWeight: 'bold' }}>Nama Desa</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData && this.state.detailData.data_desa_wisata ? this.state.detailData.data_desa_wisata.nama : '-'}</td>
							</tr>
							<tr>
								<td style={{ fontWeight: 'bold' }}>Alamat</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData && this.state.detailData.data_desa_wisata ? this.state.detailData.data_desa_wisata.alamat : '-'}</td>
							</tr>
							<tr>
								<td style={{ fontWeight: 'bold' }}>Email</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData && this.state.detailData.data_desa_wisata ? this.state.detailData.data_desa_wisata.email : '-'}</td>
							</tr>
							<tr>
								<td style={{ fontWeight: 'bold' }}>No. Telpon</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData && this.state.detailData.data_desa_wisata ? this.state.detailData.data_desa_wisata.no_telp : '-'}</td>
							</tr>
							<tr>
								<td style={{ fontWeight: 'bold' }}>Lampiran Berkas</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData ? (
									<a target="_blank" href={this.state.detailData.file}>Download berkas</a>
								) : '-'}</td>
							</tr>
							<tr>
								<td style={{ fontWeight: 'bold' }}>Keterangan</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData && this.state.detailData.data_desa_wisata ? this.state.detailData.data_desa_wisata.keterangan : '-'}</td>
							</tr>
							<tr>
								<td style={{ fontWeight: 'bold' }}>Status</td>
								<td className='text-center' style={{ width: '50px' }}>:</td>
								<td>{this.state.detailData && this.state.detailData.data_desa_wisata ? this.state.detailData.data_desa_wisata.status : '-'}</td>
							</tr>
							{/* <tr>
								<td style={{ fontWeight: 'bold' }}>
									<ul>
										<li>a</li>
										<li>b</li>
										<li>c</li>
									</ul>
								</td>
								<td className='text-center' style={{ width: '50px' }}></td>
								<td></td>
							</tr> */}
						</table>

					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
					</div>
				</Modal>
			</div>
		)
	}
}


export default VerifikasiDesaWisata;
