import React, { Component } from 'react';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/css/site.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
	nama: '',
	diskon: '',
	logo: '',
	image: '',
}

function imageFormatter(cell, row) {
	// return "<img src='"+cell+"'/>" ;
	return (
		<>
			<img src={cell} alt="" style={{ width: "250px" }} />
		</>
	)
}

class Bank extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			image: null,
			avatar: '',
			gambarnya: '',
			uploadPercentage: 0,
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Bank",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			name: '',
		};
		this.onImageChange = this.onImageChange.bind(this);
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('bank/list' + link, null, (lihat) => {
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}


	uploadFile = ({ target: { files } }) => {
		// console.log( files[0])
		let data = new FormData();
		data.append('files[]', files[0])

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total)
				// console.log(`${loaded} kb of ${total}kb | ${percent}%` );

				if (percent < 100) {
					this.setState({ uploadPercentage: percent })
				}
			}
		}


		axios.post(localStorage.getItem('API_URL') + "upload-file-multiple/logo", data, options).then(res => {
			// console.log(res)

			this.setState({
				image: URL.createObjectURL(files[0])
			})

			this.setState({ avatar: res.data.url, uploadPercentage: 100 }, () => {
				setTimeout(() => {
					this.setState({ uploadPercentage: 0 })
				}, 1000);
			})

			if (res.data.results) {
				// console.log("HAHA Gambarnya adalah")
				// console.log(res.data.results[0])

				this.state.gambarnya = res.data.results[0];
			}
		})

	}

	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let addBank = {
			uuid: document.getElementById("uuid").value,
			kode_bank: document.getElementById("kode_bank").value,
			nama_bank: document.getElementById("nama_bank").value,
			nama_resmi_bank: document.getElementById("nama_resmi_bank").value,
			logo: this.state.gambarnya,
			logo_lama: document.getElementById("logo_lama").value,
		};


		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addBank.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addBank.uuid = null;

		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
			if (addBank.logo === '') {
				addBank.logo = addBank.logo_lama;
			}
			else {
				axios.get(`${addBank.logo_lama}/hapus`).then(resp => {
					// console.log(resp.data);
				});
				addBank.logo = addBank.logo;
			}
		}

		Post('bank/' + metode, addBank.uuid, addBank, (res) => {
			// console.log(addBank)
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Bank', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Bank', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Bank";
		this.forceUpdate();

		Get('bank/find', id, (data) => {
			// console.log("ABC")
			// 	console.log(data)
			if (data.result) {
				this.state.dt.uuid = data.result.uuid;
				this.state.dt.kode_bank = data.result.kode_bank;
				this.state.dt.nama_bank = data.result.nama_bank;
				this.state.dt.nama_resmi_bank = data.result.nama_resmi_bank;
				this.state.dt.logo = data.result.logo;
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm();
		});
	}
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('bank/delete', id.id_hapus, (res) => {
			if (res == 200) {

				axios.get(`${id.foto_hapus}/hapus`).then(resp => {
					// console.log(resp.data);
				});

				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Bank', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Bank', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.state.kode_bank = '';
		this.state.nama_bank = '';
		this.state.nama_resmi_bank = '';
		this.state.logo = '';
		this.state.image = '';
		this.state.gambarnya = '';
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus({ id_hapus: row.uuid, foto_hapus: row.logo })} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}


	onImageChange = event => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			this.setState({
				image: URL.createObjectURL(img)
			});
		}
	};

	onDrop = (acceptedFiles) => {
		// console.log(acceptedFiles);
	}


	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();

		let addPromosi = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('bank/list', null, addPromosi, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			kode_bank: '',
			nama_bank: '',
			nama_resmi_bank: '',
			logo: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'kode_bank',
				text: 'Kode Bank',
				sort: true
			},
			{
				dataField: 'nama_bank',
				text: 'Nama Bank',
				sort: true
			},
			{
				dataField: 'nama_resmi_bank',
				text: 'Nama Resmi Bank',
				sort: true
			},
			{
				dataField: 'logo',
				text: 'Logo Bank',
				sort: true,
				formatter: imageFormatter,
				align: "center",
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'nama',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		const { uploadPercentage } = this.state;
		return (
			<div>
				<Breadcrumb title="Data Bank" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
													/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>
											</form>
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Bank", dt: frmDef }); this.bukaForm(); }}>Tambah Data</Button>
										</div>
									</div>

									<ToolkitProvider
										keyField="uuid"
										data={data}
										columns={columns}
										search
									>
										{
											props => (
												<div>
													{/* <SearchBar {...props.searchProps} /> */}
													<BootstrapTable
														{...props.baseProps}
													/>
												</div>
											)
										}
									</ToolkitProvider>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan} encType="multipart/form-data">
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="Bank" defaultValue={this.state.dt.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kode Bank</Label>
								<Col sm='9'>
									<input className="form-control" id="kode_bank" type="text" placeholder="Kode Bank" defaultValue={this.state.dt.kode_bank} maxLength="3" required />
								</Col>
							</FormGroup>


							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Bank</Label>
								<Col sm='9'>
									<input className="form-control" id="nama_bank" type="text" placeholder="Nama Bank" defaultValue={this.state.dt.nama_bank} required />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Resmi Bank</Label>
								<Col sm='9'>
									<input className="form-control" id="nama_resmi_bank" type="text" placeholder="Nama Resmi Bank" defaultValue={this.state.dt.nama_resmi_bank} required />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"> Logo</Label>
								<Col sm='9'>
									<input className="form-control" placeholder="Logo lama" id="logo_lama" type="hidden" defaultValue={this.state.dt.logo} />
									<input className="form-control" id="logo" type="file" onChange={this.uploadFile} placeholder="Logo" />

									<img src={this.state.image} style={{ width: "250px" }} /><br />

									{uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}

								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							{
								uploadPercentage > 100 || this.state.gambarnya != '' || this.state.dt.logo != ''
									?
									<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
									: this.state.dt.logo != '' && this.state.dt.uuid != ''
										?
										<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
										:
										<button disabled className="btn btn-success">Simpan</button>
							}
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default Bank;
