import React, { Component } from 'react';
import Select from 'react-select'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { PostMain, GetMain, DeleteMain } from '../../function/Koneksi';

const formInput = {
	uuid:"",
	nama: "",
	format: "",
}

class KonfigurasiAlamat extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				detail: false,
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: formInput,
			judul: "Tambah Konfigurasi Alamat",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			dataCari: '',
			loadingPreview: false,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		this.setState({
			loadingPreview: true,
		})
		let link = "";
		
		if (params.page) {
			switch (params.page) {
				case ">>":
				  link =
					"?page=" +
					Math.ceil(
					  this.state.pagination.total / this.state.pagination.per_page
					);
				  break;
				case ">":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
				  break;
				case "<":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
				  break;
				case "<<":
				  link = "?page=1";
				  break;
				default:
				  link = "?page=" + params.page;
				  break;
			  }
		}

		let cari = "";
		if(params.page){
		  cari = '&q='+this.state.dataCari
		}
		else{
		  if(this.state.dataCari !== ""){
			cari = '?q='+this.state.dataCari
		  }
		}

		GetMain('master/format-alamat' + link + cari, null, (lihat) => {
			if (lihat.results) {
				this.setState({ 
					data: lihat.results.data, 
					pagination: lihat.results,
					loadingPreview: false
				});
				this.pagination(lihat.results);
			}
			else{
				this.setState({ loadingPreview: false, });
			}
		});
	}

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "success";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		
		this.setState({ awal, sebelum, hal, setelah, akhir });
		
	};

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			
			this.setState({ 
				dataCari: e.target.value,
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	// Making dynamic function
	handleInputChange = (e, fld) => {
		this.setState({
			dt:{
				...this.state.dt,
				[fld]: e.target.value
			}
		});
	};
	// Making dynamic function

	simpan = e => {
		e.preventDefault();

		this.setState({
			loadingPreview: true,
			status:{
				...this.state.status,
				btnForm: true,
			}
		})

		this.forceUpdate();

		if(
			this.state.dt.nama ===  "" ||
			this.state.dt.format ===  ""
		){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				loadingPreview: false,
				show: true, 
				basicType: 'danger', 
				basicTitle: 'Data Konfigurasi Alamat', 
				pesanAlert: 'Data Tidak Lengkap!' 
			});
		}
		else{
			let addData = {
				...this.state.dt,
			};

			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (addData.uuid === '') {
				psn = "Tambah";
				resstat = 200;
				addData.uuid = null;

			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}

			PostMain('master/format-alamat/' + metode, addData.uuid, addData, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ 
						loadingPreview: false,
						show: true, 
						basicType: 'success', 
						basicTitle: 'Data Konfigurasi Alamat', 
						pesanAlert: 'Berhasil ' + psn + ' Data' 
					});
				}
				else {
					this.setState({ 
						loadingPreview: false,
						show: true, 
						basicType: 'danger', 
						basicTitle: 'Data Konfigurasi Alamat', 
						pesanAlert: 'Gagal ' + psn + ' Data' 
					});
				}
				this.componentDidMount();
			});
		}
	}

	ubahData = (data) => {
		this.state.judul = "Edit Konfigurasi Alamat";
		this.setState({
			status:{
				form: true,
			},
			dt:{
				uuid:data.uuid,
				nama: data.nama,
				format: data.format,
			}
		})
		this.forceUpdate();
		this.bukaForm('edit');

	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		this.setState({
			loadingPreview: true
		})
		DeleteMain('master/format-alamat/delete', id, (res) => {
			if (res == 200) {
				this.setState({ 
					loadingPreview: false,
					show: true, 
					basicType: 'success', 
					basicTitle: 'Data Konfigurasi Alamat', 
					pesanAlert: 'Berhasil ' + psn + ' Data' 
				});
			}
			else {
				this.setState({ 
					loadingPreview: false, 
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Data Konfigurasi Alamat', 
					pesanAlert: 'Gagal ' + psn + ' Data' 
				});
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.setState({
				dt:formInput,
			})
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.setState({
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				detail: false,
			},
			dt: formInput,
		})
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon mr-1" onClick={() => this.ubahData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon mr-1" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}
	
	// =================== END COMBO BOX JENIS PANDUAN ====================

	render() {

		var data = this.state.data;
		const formInput = {
			uuid: '',
			id_desa: '',
			url_api: '',
		}

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
						align: 'center',
				formatter: (key, obj, index) =>{          
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index+1);
					let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					return serial_num; 
				}
			},
			{
				dataField: 'nama',
				text: 'Nama Format',
				sort: true
			},
			{
				dataField: 'format',
				text: 'Format',
				sort: true
			},
			
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		return (
			<div>
				<Breadcrumb title="Konfigurasi Alamat" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Disini..."
												defaultValue={this.state.dataCari}
												onKeyPress={this.cariData}
											/>
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Konfigurasi Alamat", dt: formInput }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div>
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				{/* Loading preview */}
				<SweetAlert
					show={this.state.loadingPreview}
					showCancel = {false}
					showConfirm={false}
				>
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
						<br/>
						<img 
							alt="loading"
							src={require("../../assets/images/tes-loading.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/> 
						<br/><br/>
					</div>
				</SweetAlert>
				
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.dt.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Format</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="nama" 
										type="text" 
										placeholder="Contoh: Alamat Lengkap" 
										defaultValue={this.state.dt.nama} 
										onChange={e => this.handleInputChange(e, "nama")}
										required 
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Format</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="format" 
										type="text" 
										placeholder="Contoh: rt/rw, dusun, desa, kecamatan, kabkota, provinsi" 
										defaultValue={this.state.dt.format} 
										onChange={e => this.handleInputChange(e, "format")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							{
								this.state.status.detail?
								<></>
								:
								<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
							}
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default KonfigurasiAlamat;
