import React, { Component } from 'react';
import Select from 'react-select'
import { Link } from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

import Datetime from "react-datetime";

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
	// id_desa: '',
	// url_api: '',
}
class Koperasi extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				jenis: '',
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Koperasi",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			data_detail: [],
			tanggal_pendirian: '',
			type_badan: null,
			// api: { uuid: '', url_api: '', url_api_web: '', lat: '', lang: '', id_desa: '', id_kecamatan: '', id_kabkota: '', id_provinsi: '' },
			data_desa: [],
			id_desa: '',


			id_provinsi: '',  //CountryId
			dataProvinsi: [],  //CountryData

			id_kabkota: '',  //StateId
			kabkotaData: [],  //StateData

			id_kecamatan: '',
			kecamatanData: [], //CityData

			id_kelurahan: '',
			kelurahanData: [],

			id_desa: '',
			keldesData: [],

			name: '',

			level_desa: null,

		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('badan-usaha' + link, null, (lihat) => {
			// console.log("Data Badan Usaha")
			// console.log(lihat)
			if (lihat.result) {
				this.setState({ data: lihat.result.data, pagination: lihat.result });
				this.pagination(lihat.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		let addData = {
			q: e.target.value,
		};
		Post('list-api', null, addData, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}

	simpan = e => {
		e.preventDefault();

		if (this.state.id_desa.value == undefined) {
			var id_desa = this.state.id_desa;
		}
		else {
			var id_desa = this.state.id_desa.value;
		}

		let jenis = ''
		let kelompok_koperasi = ''
		let sektor_usaha = ''
		if (this.state.type_badan.value == 'Koperasi') {
			jenis = 'Koperasi'
			kelompok_koperasi = 'Koperasi'
			sektor_usaha = 'Koperasi'
		}

		this.state.status.btnForm = true;
		this.forceUpdate();
		let addData = {
			uuid: this.state.data_detail.uuid,
			id_desa: id_desa,
			nama: document.getElementById("nama").value,
			alamat: document.getElementById("alamat").value,
			nomor_badan_hukum: document.getElementById("nomor_badan_hukum").value,
			tanggal_pendirian: document.getElementById("tanggal_pendirian").value,
			type_badan: this.state.type_badan.value,
			jenis: jenis,
			kelompok_koperasi: kelompok_koperasi,
			sektor_usaha: sektor_usaha,
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (addData.uuid === '') {
			psn = "Tambah";
			resstat = 200;
			addData.uuid = null;
			addData.username = document.getElementById("username").value
			addData.password = document.getElementById("password").value
			addData.no_hp = document.getElementById("no_hp").value
			addData.email = document.getElementById("email").value
		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
		}

		// console.log(addData)

		Post('badan-usaha/' + metode, addData.uuid, addData, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Koperasi', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Koperasi', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Koperasi";
		this.forceUpdate();

		Get('badan-usaha/find', id, (data) => {
			console.log("DATA AWaLNYA COY")
			console.log(data.result)
			if (data.result) {
				this.state.data_detail.uuid = data.result.uuid;

				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				// this.state.api.id_desa = data.result.id_desa;

				this.state.id_kecamatan = { value: data.result.data_keldes.id_kecamatan, label: data.result.data_keldes.data_kecamatan.nama };
				// this.state.api.id_kecamatan = data.result.data_desa.id_kecamatan;

				this.state.id_kabkota = { value: data.result.data_keldes.data_kecamatan.id_kabkota, label: data.result.data_keldes.data_kecamatan.data_kabkota.nama };
				// this.state.api.id_kabkota = data.result.data_desa.data_kecamatan.id_kabkota;

				this.state.id_provinsi = { value: data.result.data_keldes.data_kecamatan.data_kabkota.id_provinsi, label: data.result.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama };
				// this.state.api.id_provinsi = data.result.data_desa.data_kecamatan.data_kabkota.id_provinsi;

				Get('provinsi/listKabkota/' + data.result.data_keldes.data_kecamatan.data_kabkota.id_provinsi, null, (data_kota) => {
					// console.log("Kabupatennya");
					// console.log(data_kota.result);
					this.setState({
						kabkotaData: data_kota.result,
					});

					Get('kabkota/listKecamatan/' + data.result.data_keldes.data_kecamatan.id_kabkota, null, (data_kecamatan) => {
						// console.log("Kecamatannya");
						// console.log(data_kecamatan.result);
						this.setState({
							kecamatanData: data_kecamatan.result
						});

						Get('kecamatan/listKecamatan/' + data.result.data_keldes.id_kecamatan, null, (data_kelurahan) => {
							// console.log("Kelurahannya");
							// console.log(data_kelurahan.result);
							this.setState({
								kelurahanData: data_kelurahan.result
							});
						});
					});
				});



				this.state.data_detail.nama = data.result.nama
				this.state.data_detail.alamat = data.result.alamat
				this.state.data_detail.nomor_badan_hukum = data.result.nomor_badan_hukum
				this.state.tanggal_pendirian = data.result.tanggal_pendirian
				this.state.type_badan = {
					value: data.result.type_badan, label: (
						data.result.type_badan == 'Bumdes' ? 'BUMDes'
							: 'Koperasi'
					)
				};
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('badan-usaha/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Koperasi', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Koperasi', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_detail.uuid = '';
			this.state.id_desa = '';
			this.state.id_provinsi = '';
			this.state.id_kabkota = '';
			this.state.id_kecamatan = '';
			this.state.kabkotaData = [];
			this.state.kecamatanData = [];
			this.state.kelurahanData = [];
			this.state.data_detail.nama = '';
			this.state.data_detail.alamat = '';
			this.state.data_detail.nomor_badan_hukum = '';
			this.state.status.jenis = 'Tambah';
			this.state.type_badan = null;
		}
		else {
			this.state.status.jenis = 'Edit';
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Link to={{ pathname: "/data/detail-koperasi/", state: row }}>
					<Button size="xs" color="primary" className="btn-eye"><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
				</Link>
				{/* <Button title="View detail" onPress={() => this.navigateToDetailPage(row.uuid)} /> */}
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				{/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button> */}
			</>
		)
	}
	componentDidMount() {
		this.fetch({ page: null, where: [] });

		//COBA PROVINSI
		Get('provinsi/list', null, (data) => {
			this.setState({
				dataProvinsi: data.result
			});
		});

	}
	// =======================COBA PROVINSI=======================	
	ChangeProvinsi = (e) => {
		if (e) {
			this.setState({ id_provinsi: e.target.value });

			// console.log("Mari lihat")
			// console.log(e.target.value)
			if (e.target.value == '') {
				this.setState({
					kabkotaData: [],
					kecamatanData: [],
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('provinsi/listKabkota/' + e.target.value, null, (data) => {
					this.setState({
						kabkotaData: data.result,
					});
				});
			}
		}
		else {
			this.setState({ id_provinsi: '' });
		}
	}

	ChangeKabkota = (e) => {
		if (e) {
			this.setState({ id_kabkota: e.target.value });

			if (e.target.value == '') {
				this.setState({
					kecamatanData: [],
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('kabkota/listKecamatan/' + e.target.value, null, (data) => {
					this.setState({
						kecamatanData: data.result
					});
				});
			}
		}
		else {
			this.setState({ id_kabkota: '' });
		}
	}

	ChangeKecamatan = (e) => {
		if (e) {
			this.setState({ id_kecamatan: e.target.value });

			if (e.target.value == '') {
				this.setState({
					kelurahanData: [],
					keldesData: [],
				});
			}
			else {
				Get('kecamatan/listKecamatan/' + e.target.value, null, (data) => {
					this.setState({
						kelurahanData: data.result
					});
				});
			}
		}
		else {
			this.setState({ id_kecamatan: '' });
		}
	}


	ChangeKeldes = (e) => {
		if (e) {
			this.setState({ id_desa: e.target.value });

			if (e.target.value == '') {
				this.setState({
					keldesData: [],
					id_desa: '',
				});
			}
			else {
				let idnya = e.target.value;
				Get('keldes/find/' + e.target.value, null, (data) => {
					this.setState({
						keldesData: data.result,
						id_desa: idnya,
					});
				});
			}
		}
		else {
			this.setState({ id_desa: '' });
		}

	}



	// =======================END COBA PROVINSI=======================	

	onChangeDesa = (e) => {
		if (e) {
			this.setState({ id_desa: { value: e.value, label: e.label } });
		}
		else {
			this.setState({ id_desa: '' });
		}
	}

	getDesa = (e) => {
		if (e) {
			let addDesa = {
				q: e
			};
			Post('keldes', null, addDesa, (data) => {
				if (data.data.result) {
					let data_desa = [];
					data.data.result.data.map(dt => {
						data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama + ' - ' + dt.data_kecamatan.data_kabkota.nama });
					});
					this.setState({ data_desa });
				}
			})
		}
		else {
			Get('keldes', null, (data) => {
				if (data.result) {
					let data_desa = [];
					data.result.data.map(dt => {
						data_desa.push({ value: dt.id, label: dt.nama + ' - ' + dt.data_kecamatan.nama + ' - ' + dt.data_kecamatan.data_kabkota.nama });
					});
					this.setState({
						data_desa
					});
				}
			});
		}
	}



	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault();
		console.log("Data cari")
		console.log(this.state.name)

		let addData = {
			q: this.state.name,
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('badan-usaha', null, addData, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}


	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'type_badan':
					this.setState({ type_badan: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'type_badan':
					this.setState({ type_badan: '' });
					break;
			}
		}
	}


	// ================== Tanggal ===================

	gantiTgl = (date, fld) => {
		switch (fld) {
			case "tanggal_pendirian":
				this.setState({ tanggal_pendirian: date });
				break;
			//   case "tanggal_acara":
			// 	this.setState({ tanggal_acara: date });
			// 	break;
		}
	};

	// ==============================================

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			// id_desa: '',
			// url_api: '',
		}
		const { SearchBar } = Search;
		const columns = [
			// {
			// 	dataField: 'uuid',
			// 	text: 'ID',
			// 	sort: false
			// },
			{
				dataField: 'data_keldes.nama',
				text: 'Desa',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama Badan Usaha',
				sort: true
			},
			{
				dataField: 'alamat',
				text: 'Alamat',
				sort: true
			},
			{
				dataField: 'type_badan',
				text: 'Jenis Badan Usaha',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'nama',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Koperasi" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<form>
												<label>
													<input
														name='name'
														value={this.state.name}
														onChange={e => this.handleChange(e)}
														style={{ marginBottom: "10px" }}
														className="form-control"
														placeholder="Ketikkan sesuatu..."
													/>
												</label>
												<button onClick={(e) => this.onSubmit(e)} color="primary" className="btn btn-primary">Cari Data</button>
											</form>
											{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Koperasi", dt: frmDef }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div>
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data_detail.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Provinsi</Label>
								<Col sm='9'>
									<select
										className="form-control"
										name="id_provinsi"
										value={this.state.id_provinsi.value}
										onChange={this.ChangeProvinsi}
									>
										<option value="">Pilih Provinsi</option>
										{this.state.dataProvinsi.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kabupaten/Kota</Label>
								<Col sm='9'>
									<select
										className="form-control slct"
										name="id_kabkota"
										value={this.state.id_kabkota.value}
										onChange={this.ChangeKabkota}
									>
										<option value="">Pilih Kabupaten/Kota</option>
										{this.state.kabkotaData.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kecamatan</Label>
								<Col sm='9'>
									<select
										className="form-control slct"
										name="id_kecamatan"
										value={this.state.id_kecamatan.value}
										onChange={this.ChangeKecamatan}
									>
										<option value="">Pilih Kecamatan</option>
										{this.state.kecamatanData.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelurahan/Desa</Label>
								<Col sm='9'>
									<select
										className="form-control slct"
										name="id_desa"
										value={this.state.id_desa.value}
										onChange={this.ChangeKeldes}
									>
										<option value="">Pilih Kelurahan/Desa</option>
										{this.state.kelurahanData.map((e, key) => {
											return <option key={key} value={e.id}>{e.nama}</option>;
										})}
									</select>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Badan Usaha</Label>
								<Col sm='9'>
									<input className="form-control" id="nama" type="text" placeholder="Nama Badan Usaha" defaultValue={this.state.data_detail.nama} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
								<Col sm='9'>
									<input className="form-control" id="alamat" type="text" placeholder="Alamat" defaultValue={this.state.data_detail.alamat} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Pendirian</Label>
								<Col sm='9'>
									<input className="form-control" id="tanggal_pendirian" type="date" placeholder="Tanggal Pendirian" defaultValue={this.state.tanggal_pendirian} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor Badan Hukum</Label>
								<Col sm='9'>
									<input className="form-control" id="nomor_badan_hukum" type="text" placeholder="Nomor Badan Hukum" defaultValue={this.state.data_detail.nomor_badan_hukum} />
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Badan Usaha</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.onChangeSelect(e, 'type_badan')}
										value={this.state.type_badan}
										name="type_badan"
										options={[
											{ value: 'Bumdes', label: 'BUMDes' },
											{ value: 'Koperasi', label: 'Koperasi' },
										]}
										placeholder="Pilih Jenis Badan Usaha"
										isClearable
									/>
								</Col>
							</FormGroup>

							{
								this.state.status.jenis == 'Tambah'
									?
									<div>
										<hr />
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Username</Label>
											<Col sm='9'>
												<input className="form-control" id="username" type="text" placeholder="Username" />
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Password</Label>
											<Col sm='9'>
												<input className="form-control" id="password" type="text" placeholder="Password" />
											</Col>
										</FormGroup>

										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">No. HP</Label>
											<Col sm='9'>
												<input className="form-control" id="no_hp" type="text" placeholder="No. HP" />
											</Col>
										</FormGroup>

										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Email</Label>
											<Col sm='9'>
												<input className="form-control" id="email" type="text" placeholder="Email" />
											</Col>
										</FormGroup>
									</div>
									:
									<>
									</>
							}

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default Koperasi;
