import React, { Component } from 'react';
import Select from 'react-select'
import { Link } from 'react-router-dom'

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';


// koneksi
import { PostMain, GetMain, DeleteMain } from '../../function/Koneksi';

// css files
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const formInput = {
	uuid:"",
	nama_surat: "",
	nama_alias: "",
	nama_mobile: "",
	route_ws: "",
	route_mobile: "/",
	route_web:"/",
	route_surat: "/",
	field_inputan: [{
		key: "",
		label: "",
		type: "",
		model: "",
		option: "",
		value: "",
	}],
	nama_template: "",
	keterangan: "",
	icon_web: "",
	icon_mobile: "",
	icon_kiosk: "",
	akronim: "",
	jenis_layanan: "",
	status_generate_form: "",
}

let jumlahField = 0;
let jumlahOption = 0;

class JenisSurat extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				detail: false,
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: formInput,
			judul: "Tambah Jenis Surat",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			fieldInputan: [],
			formDataJenisLayanan:[],
			formDataTypeInputan:[
				{ value: 'text', label: 'text' },
				{ value: 'number', label: 'number' },
				{ value: 'date', label: 'date' },
				{ value: 'datetime', label: 'datetime' },
				{ value: 'time', label: 'time' },
				{ value: 'currency', label: 'currency' },
				{ value: 'file', label: 'file' },
				{ value: 'image', label: 'image' },
				{ value: 'select', label: 'select' },
			],
			selectedTypeInputan: [],
			selectedJenisOption: [],
			option_field: [],
			dataCari: {
				q: '',
			},
			loadingPreview: false,
			loadingIconWeb: false,
			loadingIconMobile: false,
			loadingIconKiosk: false,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		this.setState({
			loadingPreview: true,
		})
		let link = "";
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case ">>":
				  link =
					"?page=" +
					Math.ceil(
					  this.state.pagination.total / this.state.pagination.per_page
					);
				  break;
				case ">":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
				  break;
				case "<":
				  link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
				  break;
				case "<<":
				  link = "?page=1";
				  break;
				default:
				  link = "?page=" + params.page;
				  break;
			  }
		}
		PostMain('master/jenis-surat' + link, null, this.state.dataCari, (lihat) => {
			console.log('list')
			console.log(lihat.data.results)
			if (lihat.data.results) {
				this.setState({ 
					data: lihat.data.results.data, 
					pagination: lihat.data.results,
					loadingPreview: false
				});
				this.pagination(lihat.data.results);
			}
			else{
				this.setState({ loadingPreview: false, });
			}
		});
	}

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "success";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		
		this.setState({ awal, sebelum, hal, setelah, akhir });
		
	};

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			
			this.setState({ 
				dataCari:{
					q: e.target.value,
				}, 
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	// Making dynamic function
	handleInputChange = (e, fld) => {
		this.setState({
			dt:{
				...this.state.dt,
				[fld]: e.target.value
			}
		});
	};

	handleInputChangeDetail = (e, fld, urutan) => {
		let fieldInputan = []
		// this.state.dt.field_inputan.map(dt => {
		// 	console.log('coba')
		// 	console.log(dt.key)
		// 	console.log(this.state.dt.field_inputan[0].key)
			// fieldInputan.push({ 
			// 	key: e.target.value, 
			// });

			this.setState({
				fieldInputan:{
					...this.state.fieldInputan[urutan],
					key: e.target.value, 
				}
			});
		// });

	};

	handleSelectChange = (e, data) => {
		if (e) {
			this.setState({ 
				dt:{
					...this.state.dt,
					[data]: { value: e.value, label: e.label }
				}
			});
		} else {
			this.setState({ 
				dt:{
					...this.state.dt,
					[data]: "",
				}
			});
		}
	}
	handleSelectChangeOptional = (e, jenis, data) => {
		if (e) {
			switch(jenis){
				case "selectedTypeInputan":
					this.setState({ 
						selectedTypeInputan:{
							...this.state.selectedTypeInputan,
							[jumlahField]: { value: e.value, label: e.label }
						}
					});
					break;
				case "selectedJenisOption":
					this.setState({ 
						selectedJenisOption:{
							...this.state.selectedJenisOption,
							[jumlahField]: { value: e.value, label: e.label }
						}
					});
					break;
				default:
					// no default
					break;
			}
		} else {
			this.setState({ 
				[data]: "",
			});
		}
	}
	// Making dynamic function

	tambahField = e => {
		jumlahField++;
		let field_inputan = [];
		let check = true;
		for (let i = 1; i < jumlahField; i++) {
			let key_inputan = "";
			let label_inputan = "";
			let type_inputan = "";
			if(document.getElementById("key_inputan" + i).value === "" || document.getElementById("label_inputan" + i).value === "" || this.state.selectedTypeInputan[i] === undefined){
				this.setState({
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Tambah Field Gagal', 
					pesanAlert: "Lengkapi Data Terlebih Dahulu"
				})
				check = false;
			}
			else{
				key_inputan = document.getElementById("key_inputan" + i).value;
				label_inputan = document.getElementById("label_inputan" + i).value;
				type_inputan = this.state.selectedTypeInputan[i].value;
			}

			if(type_inputan==='select' && this.state.selectedJenisOption[i] === undefined){
				this.setState({
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Tambah Field Gagal', 
					pesanAlert: "Lengkapi Data Terlebih Dahulu"
				})
				check = false;
			}
			else if(this.state.selectedJenisOption[i] && this.state.selectedJenisOption[i].value === "optional" && document.getElementById("option_inputan" + i).value === "" || this.state.selectedJenisOption[i] && this.state.selectedJenisOption[i].value === "model" && document.getElementById("model_inputan" + i).value === ""){
				this.setState({
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Tambah Field Gagal', 
					pesanAlert: "Lengkapi Data Terlebih Dahulu"
				})
				check = false;
			}
			else{
				let model_inputan = ""
				let option_inputan = []
				if(type_inputan==='select' && this.state.selectedJenisOption[i] && this.state.selectedJenisOption[i].value === "optional"){
					option_inputan = "[" + document.getElementById("option_inputan" + i).value + "]";
				}
				else if(type_inputan==='select' && this.state.selectedJenisOption[i] && this.state.selectedJenisOption[i].value === "model"){
					model_inputan = document.getElementById("model_inputan" + i).value;
				}
				
				field_inputan.push({ 
					key: key_inputan, 
					label: label_inputan,
					type: type_inputan,
					model: model_inputan,
					option: option_inputan,
					value: "",
				});
			}

		}
		if(check){
			field_inputan.push({ 
				key: "",
				label: "",
				type: "",
				model: "",
				option: "",
				value: "",
			});
			this.state.dt.field_inputan = field_inputan;
			this.forceUpdate();
		}
	};
	
	tambahOption = e => {
		jumlahOption++;
		let option_field = [];
		for (let i = 1; i < jumlahOption; i++) {
		  let option_value = document.getElementById("option_value" + i).value;

			option_field.push({ 
				[i]: option_value, 
			});
		}
		option_field.push({ 
			[jumlahOption]: "",
		});
		this.state.option_field = option_field;
		this.forceUpdate();
	};


	processGbr = (d, jenis) => {
		if (d.length === 1) {
			let type = d[0].type.split('/');
				if (
					type[1] === 'jpg' ||
					type[1] === 'jpeg' ||
					type[1] === 'png' ||
					type[1] === 'bmp' ||
					type[1] === 'tiff' ||
					type[1] === 'webp'
				) {
		
					if(jenis === 'icon_web'){
						this.setState({
							loadingIconWeb: true,
							status:{
								...this.state.status,
								btnForm: true,
							},
						})
					}
					else if(jenis === 'icon_mobile'){
						this.setState({
							loadingIconMobile: true,
							status:{
								...this.state.status,
								btnForm: true,
							},
						})
					}
					else if(jenis === 'icon_kiosk'){
						this.setState({
							loadingIconKiosk: true,
							status:{
								...this.state.status,
								btnForm: true,
							},
						})
					}
	
					let bodyFormData = new FormData();
					bodyFormData.append('files', d[0]);
	
					PostMain('master/upload-file/'+jenis, null, bodyFormData, (data) => {
						Resizer.imageFileResizer(
							d[0],
							300,
							300,
							type[1],
							100,
							0,
							(uri) => {
								console.log('data upload')
								console.log(data)
								if(data.status === 200){
									if(jenis === 'icon_web'){
										this.setState({
											link_file_upload: uri,
											alert_icon_web: '',
											dt: {
												...this.state.dt,
												icon_web: data.data.results,
											},
											status:{
												...this.state.status,
												btnForm: false,
											},
											loadingIconWeb: false,
										});
									}
									else if(jenis === 'icon_mobile'){
										this.setState({
											link_file_upload: uri,
											alert_icon_mobile: '',
											dt: {
												...this.state.dt,
												icon_mobile: data.data.results,
											},
											status:{
												...this.state.status,
												btnForm: false,
											},
											loadingIconMobile: false,
										});
									}
									else if(jenis === 'icon_kiosk'){
										this.setState({
											link_file_upload: uri,
											alert_icon_kiosk: '',
											dt: {
												...this.state.dt,
												icon_kiosk: data.data.results,
											},
											status:{
												...this.state.status,
												btnForm: false,
											},
											loadingIconKiosk: false,
										});
									}
								}
								else{
									if(jenis === 'icon_web'){
										this.setState({
											status:{
												...this.state.status,
												btnForm: false,
											},
											loadingIconWeb: false,

											show: true, 
											basicType: 'danger', 
											basicTitle: 'Gagal Upload File Icon Web', 
											pesanAlert: data.data.message 
										});
									}
									else if(jenis === 'icon_mobile'){
										this.setState({
											status:{
												...this.state.status,
												btnForm: false,
											},
											loadingIconMobile: false,

											show: true, 
											basicType: 'danger', 
											basicTitle: 'Gagal Upload File Icon Mobile', 
											pesanAlert: data.data.message 
										});
									}
									else if(jenis === 'icon_kiosk'){
										this.setState({
											status:{
												...this.state.status,
												btnForm: false,
											},
											loadingIconKiosk: false,

											show: true, 
											basicType: 'danger', 
											basicTitle: 'Gagal Upload File Icon KiosK', 
											pesanAlert: data.data.message 
										});
									}
								}
							},
							'base64'
						);
					});
				} else {
					if(jenis === 'icon_web'){
						this.setState({
							link_file_upload: '',
							alert_icon_web: <div className="text-danger font-size-10">Type file tidak valid</div>
						}); 
					}
					else if(jenis === 'icon_mobile'){
						this.setState({
							link_file_upload: '',
							alert_icon_mobile: <div className="text-danger font-size-10">Type file tidak valid</div>
						}); 
					}
					else if(jenis === 'icon_kiosk'){
						this.setState({
							link_file_upload: '',
							alert_icon_kiosk: <div className="text-danger font-size-10">Type file tidak valid</div>
						}); 
					}
				}
			} else {
				if(jenis === 'icon_web'){
					this.setState({
						link_file_upload: '',
						alert_icon_web: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
					});
				}
				else if(jenis === 'icon_mobile'){
					this.setState({
						link_file_upload: '',
						alert_icon_mobile: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
					});
				}
				else if(jenis === 'icon_kiosk'){
					this.setState({
						link_file_upload: '',
						alert_icon_kiosk: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
					});
				}
			}
		};

	simpan = e => {
		e.preventDefault();

		console.log('simpan')
		console.log(this.state.dt)
		this.setState({
			loadingPreview: true,
			status:{
				...this.state.status,
				btnForm: true,
			}
		})

		this.forceUpdate();

		if(
			this.state.dt.nama_surat ===  "" ||
			this.state.dt.nama_alias ===  "" ||
			this.state.dt.nama_mobile ===  "" ||
			this.state.dt.route_ws ===  "" ||
			this.state.dt.route_mobile ===  "" ||
			this.state.dt.route_web === "" ||
			this.state.dt.route_surat ===  "" ||
			this.state.dt.nama_template ===  "" ||
			this.state.dt.icon_web ===  "" ||
			this.state.dt.icon_mobile ===  "" ||
			this.state.dt.icon_kiosk ===  "" ||
			this.state.dt.akronim ===  "" ||
			this.state.dt.jenis_layanan ===  "" ||
			this.state.dt.status_generate_form ===  ""
		){
			this.setState({ 
				status:{
					...this.state.status,
					btnForm: false,
				},
				loadingPreview: false,
				show: true, 
				basicType: 'danger', 
				basicTitle: 'Data Jenis Surat', 
				pesanAlert: 'Data Tidak Lengkap!' 
			});
		}
		else{
			let addData = {
				...this.state.dt,
				jenis_layanan: this.state.dt.jenis_layanan.value,
				status_generate_form: this.state.dt.status_generate_form.value,
				// field_inputan: JSON.stringify(this.state.dt.field_inputan),
				field_inputan: null,
			};

			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (addData.uuid === '') {
				psn = "Tambah";
				resstat = 200;
				addData.uuid = null;

			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}

			PostMain('master/jenis-surat/' + metode, addData.uuid, addData, (res) => {
				console.log('respon simpan')
				console.log(res)
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ 
						loadingPreview: false,
						show: true, 
						basicType: 'success', 
						basicTitle: 'Data Jenis Surat', 
						pesanAlert: 'Berhasil ' + psn + ' Data' 
					});
				}
				else {
					this.setState({ 
						loadingPreview: false,
						show: true, 
						basicType: 'danger', 
						basicTitle: 'Data Jenis Surat', 
						pesanAlert: 'Gagal ' + psn + ' Data' 
					});
				}
				this.componentDidMount();
			});
		}
	}

	detailData = (data) => {
		// this.state.status.btnAksi = true;
		this.state.judul = "Detail Jenis Surat";
		this.setState({
			status:{
				form: true,
				detail: true,
			},
			dt:{
				uuid:data.uuid,
				nama_surat: data.nama_surat,
				nama_alias: data.nama_alias,
				nama_mobile: data.nama_mobile,
				route_ws: data.route_ws,
				route_mobile: data.route_mobile,
				route_web: data.route_web,
				route_surat: data.route_surat,
				// field_inputan: data.field_inputan,
				// field_inputan: JSON.parse(data.field_inputan),
				nama_template: data.nama_template,
				keterangan: data.keterangan,
				icon_web: data.icon_web,
				icon_mobile: data.icon_mobile,
				icon_kiosk: data.icon_kiosk,
				akronim: data.akronim,
				jenis_layanan: { value: data.jenis_layanan, label: data.jenis_layanan },
				status_generate_form: { value: data.status_generate_form, label: data.status_generate_form === "true"?"Aktif":"Tidak Aktif"},	
			}
		})
		this.forceUpdate();
		this.bukaForm('detail');
	}

	ubahData = (data) => {
		this.state.judul = "Edit Jenis Surat";
		this.setState({
			status:{
				form: true,
			},
			dt:{
				uuid:data.uuid,
				nama_surat: data.nama_surat,
				nama_alias: data.nama_alias,
				nama_mobile: data.nama_mobile,
				route_ws: data.route_ws,
				route_mobile: data.route_mobile,
				route_web: data.route_web,
				route_surat: data.route_surat,
				// field_inputan: JSON.parse(data.field_inputan),
				nama_template: data.nama_template,
				keterangan: data.keterangan,
				icon_web: data.icon_web,
				icon_mobile: data.icon_mobile,
				icon_kiosk: data.icon_kiosk,
				akronim: data.akronim,
				jenis_layanan: { value: data.jenis_layanan, label: data.jenis_layanan },
				status_generate_form: { value: data.status_generate_form, label: data.status_generate_form === "true"?"Aktif":"Tidak Aktif"},	
			}
		})
		this.forceUpdate();
		this.bukaForm('edit');

	}

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		this.setState({
			loadingPreview: true
		})
		DeleteMain('master/jenis-surat/delete', id, (res) => {
			if (res == 200) {
				this.setState({ 
					loadingPreview: false,
					show: true, 
					basicType: 'success', 
					basicTitle: 'Data Jenis Surat', 
					pesanAlert: 'Berhasil ' + psn + ' Data' 
				});
			}
			else {
				this.setState({ 
					loadingPreview: false, 
					show: true, 
					basicType: 'danger', 
					basicTitle: 'Data Jenis Surat', 
					pesanAlert: 'Gagal ' + psn + ' Data' 
				});
			}
			this.componentDidMount();
		})
	}

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.setState({
				dt:formInput,
			})
		}
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.setState({
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				detail: false,
			},
			dt: formInput,
		})
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				
				<Button size="xs" color="primary" className="btn-eye" onClick={() => this.detailData(row)}><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
				{/* <Button title="View detail" onPress={() => this.navigateToDetailPage(row.uuid)} /> */}
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}
	componentDidMount() {
		this.fetch({ page: null, where: [] });

		GetMain('master/select-enum-master/jenis_surat/jenis_layanan', null, (data) => {
			console.log("Data Enum")
			console.log(data);
			if (data) {
				let formDataJenisLayanan = [];
				data.map(dt => {
					formDataJenisLayanan.push({ value: dt, label: dt });
				});
				this.setState({ formDataJenisLayanan });
			}
		});
	}
	
	// =================== END COMBO BOX JENIS PANDUAN ====================

	render() {
		console.log('Data Render')
		console.log(this.state.dataCari)
		// console.log(this.state.fieldInputan)
		// console.log(this.state.selectedTypeInputan)
		// console.log(this.state.selectedJenisOption)
		// console.log(this.state.dt)
		// console.log(jumlahOption)

		var data = this.state.data;
		const formInput = {
			uuid: '',
			id_desa: '',
			url_api: '',
		}

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
						align: 'center',
				formatter: (key, obj, index) =>{          
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index+1);
					let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
					return serial_num; 
				}
			},
			{
				dataField: 'nama_surat',
				text: 'Nama Surat',
				sort: true
			},
			{
				dataField: 'nama_alias',
				text: 'Alias',
				sort: true
			},
			{
				dataField: 'nama_mobile',
				text: 'Nama Mobile',
				sort: true
			},
			{
				dataField: 'jenis_layanan',
				text: 'Jenis Layanan',
				sort: true
			},
			{
				dataField: "status_generate_form",
				text: "Status Generate Form",
				sort: true,
				formatter: (cell, obj) => {
				  console.log('obj')
				  console.log(obj)
				  if(obj.status_generate_form === "true"){
					return "Aktif";
				  }
				  else{
					return "Tidak Aktif";
				  }
				},
			},
			{
				dataField: 'keterangan',
				text: 'Keterangan',
				sort: true
			},
			// {
			// 	dataField: "data_desa",
			// 	text: "Kecamatan",
			// 	sort: true,
			// 	formatter: (cell, obj) => {
			// 	  console.log('obj')
			// 	  console.log(obj)
			// 	  if(obj.data_desa && obj.data_desa.data_kecamatan){
			// 		return (obj.data_desa.data_kecamatan.nama);
			// 	  }
			// 	  else{
			// 		return ('-');
			// 	  }
			// 	},
			// },
			// {
			// 	dataField: "data_desa",
			// 	text: "Kab/Kota",
			// 	sort: true,
			// 	formatter: (cell, obj) => {
			// 	  console.log('obj')
			// 	  console.log(obj)
			// 	  if(obj.data_desa && obj.data_desa.data_kecamatan && obj.data_desa.data_kecamatan.data_kabkota){
			// 		return (obj.data_desa.data_kecamatan.data_kabkota.nama);
			// 	  }
			// 	  else{
			// 		return ('-');
			// 	  }
			// 	},
			// },
			// {
			// 	dataField: "data_desa",
			// 	text: "Provinsi",
			// 	sort: true,
			// 	formatter: (cell, obj) => {
			// 	  console.log('obj')
			// 	  console.log(obj)
			// 	  if(obj.data_desa && obj.data_desa.data_kecamatan && obj.data_desa.data_kecamatan.data_kabkota && obj.data_desa.data_kecamatan.data_kabkota.data_provinsi){
			// 		return (obj.data_desa.data_kecamatan.data_kabkota.data_provinsi.nama);
			// 	  }
			// 	  else{
			// 		return ('-');
			// 	  }
			// 	},
			// },
			
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		jumlahField = 0;
		jumlahOption = 0;
		
		// const field_inputan = this.state.dt.field_inputan.map((dt, i) => {
		// 	let no = i + 1;
		// 	jumlahField++;
		// 	return <>
		// 				<div className='row'>
		// 					<div className='col-3'>
		// 						Key
		// 					</div>
		// 					<div className='col-9'>
		// 						<input
		// 							name={"key_inputan" + no}
		// 							className="form-control mb-2"
		// 							key={i}
		// 							id={"key_inputan" + no}
		// 							type="text"
		// 							placeholder={"Key " + no}
		// 							defaultValue={dt.key_inputan}
		// 							onChange={e => this.handleInputChangeDetail(e, "key"+no, no)}
		// 						/>
		// 					</div>
		// 					<div className='col-3'>
		// 						Label
		// 					</div>
		// 					<div className='col-9'>
		// 						<input
		// 							name={"label_inputan" + no}
		// 							className="form-control mb-2"
		// 							key={i}
		// 							id={"label_inputan" + no}
		// 							type="text"
		// 							// required
		// 							placeholder={"Label " + no}
		// 							defaultValue={dt.label_inputan}
		// 						/>
		// 					</div>
		// 					<div className='col-3'>
		// 						Type
		// 					</div>
		// 					<div className='col-9'>
		// 						<Select
		// 							classNamePrefix="select"
		// 							onChange={e => { this.handleSelectChangeOptional(e,"selectedTypeInputan","selectedTypeInputan"+no)}}
		// 							value={this.state.selectedTypeInputan[no]}
		// 							name="type_inputan"
		// 							options={this.state.formDataTypeInputan}
		// 							placeholder="Pilih Type Inputan"
		// 							isClearable
		// 						/>
		// 					</div>
		// 					{
		// 						this.state.selectedTypeInputan[no] && this.state.selectedTypeInputan[no].value === 'select'
		// 						?
		// 							<>
		// 								<br/><br/>
		// 								<div className='col-3'>
		// 									Jenis Option
		// 								</div>
		// 								<div className='col-9'>
		// 									<Select
		// 										classNamePrefix="select"
		// 										onChange={e => { this.handleSelectChangeOptional(e,"selectedJenisOption","selectedJenisOption"+no)}}
		// 										value={this.state.selectedJenisOption[no]}
		// 										name="type_layanan"
		// 										options={[
		// 											{ value: 'optional', label: 'Optional' },
		// 											{ value: 'model', label: 'Dari Model Tabel' },
		// 										]}
		// 										placeholder="Pilih Jenis Option Form Select"
		// 										isClearable
		// 									/>
		// 								</div>
		// 								{
		// 									this.state.selectedJenisOption[no] && this.state.selectedJenisOption[no].value === "optional"
		// 									?
		// 										<>
		// 											{/* <br/><br/>
		// 											<div className='col-3'>
		// 												Option
		// 											</div>
		// 											<div className='col-9'>
		// 												{option_field}
		// 												<button
		// 													data-testid="btnTambahKeterangan"
		// 													type="button"
		// 													className="btn btn-success"
		// 													onClick={this.tambahOption}
		// 												>
		// 													Tambah Field Inputan
		// 												</button>
		// 											</div> */}
		// 											<br/><br/>
		// 											<div className='col-3'>
		// 												Option
		// 											</div>
		// 											<div className='col-9'>
		// 												<input
		// 													name={"option_inputan" + no}
		// 													className="form-control mb-2"
		// 													key={i}
		// 													id={"option_inputan" + no}
		// 													type="text"
		// 													required={this.state.selectedJenisOption[no] && this.state.selectedJenisOption[no].value === "optional"?true:false}
		// 													defaultValue={dt.option_inputan}
		// 												/>
		// 												<small><b><font color="red">* Pisahkan antar option dengan tanda koma. Contoh: Wiraswasta, Guru, Pilot, Petani</font></b></small>
		// 											</div>
		// 										</>
		// 									:
		// 									this.state.selectedJenisOption[no] && this.state.selectedJenisOption[no].value === "model"
		// 									?
		// 										<>
		// 											<br/><br/>
		// 											<div className='col-3'>
		// 												Model
		// 											</div>
		// 											<div className='col-9'>
		// 												<input
		// 													name={"model_inputan" + no}
		// 													className="form-control mb-2"
		// 													key={i}
		// 													id={"model_inputan" + no}
		// 													type="text"
		// 													required={this.state.selectedJenisOption[no] && this.state.selectedJenisOption[no].value === "model"?true:false}
		// 													defaultValue={dt.model_inputan}
		// 												/>
		// 											</div>
		// 										</>
		// 									:
		// 									<></>
		// 								}
		// 							</>
		// 						:
		// 						<></>
		// 					}
		// 					<div className='col-12'>
		// 						<hr/>
		// 					</div>
		// 				</div>
		// 	</>
		// });

		// const option_field = this.state.option_field.map((dt, i) => {
		// 	let no = i + 1;
		// 	jumlahOption++;
		// 	return <>
		// 				<div className='row'>
		// 					<div className='col-3'>
		// 						Option {no}
		// 					</div>
		// 					<div className='col-9'>
		// 						<input
		// 							name={"option_value" + no}
		// 							className="form-control mb-2"
		// 							key={i}
		// 							id={"option_value" + no}
		// 							type="text"
		// 							// required
		// 							placeholder={"Option Value " + no}
		// 							defaultValue={dt}
		// 						/>
		// 					</div>
		// 					<div className='col-12'>
		// 						<hr/>
		// 					</div>
		// 				</div>
		// 	</>
		// });

		return (
			<div>
				<Breadcrumb title="Jenis Surat" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											<input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Disini..."
												defaultValue={this.state.dataCari.q}
												onKeyPress={this.cariData}
											/>
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Tambah Jenis Surat", dt: formInput }); this.bukaForm('tambah'); }}>Tambah Data</Button>
										</div>
									</div>

									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				{/* Loading preview */}
				<SweetAlert
					show={this.state.loadingPreview}
					showCancel = {false}
					showConfirm={false}
				>
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
						<br/>
						<img 
							alt="loading"
							src={require("../../assets/images/tes-loading.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/> 
						<br/><br/>
					</div>
				</SweetAlert>
				
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.dt.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Surat</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="nama_surat" 
										type="text" 
										placeholder="Contoh: Surat Keterangan Umum" 
										defaultValue={this.state.dt.nama_surat} 
										onChange={e => this.handleInputChange(e, "nama_surat")}
										required 
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Alias</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="nama_alias" 
										type="text" 
										placeholder="Contoh: keterangan_umum_v2" 
										defaultValue={this.state.dt.nama_alias} 
										onChange={e => this.handleInputChange(e, "nama_alias")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Mobile</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="nama_mobile" 
										type="text" 
										placeholder="Contoh: Surat Umum" 
										defaultValue={this.state.dt.nama_mobile} 
										onChange={e => this.handleInputChange(e, "nama_mobile")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Akronim</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="akronim" 
										type="text" 
										placeholder="Contoh: SKU" 
										defaultValue={this.state.dt.akronim} 
										onChange={e => this.handleInputChange(e, "akronim")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Route WS</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="route_ws" 
										type="text" 
										placeholder="" 
										defaultValue={this.state.dt.route_ws} 
										onChange={e => this.handleInputChange(e, "route_ws")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Route Mobile</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="route_mobile" 
										type="text" 
										placeholder="" 
										defaultValue={this.state.dt.route_mobile} 
										onChange={e => this.handleInputChange(e, "route_mobile")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Route Web</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="route_web" 
										type="text" 
										placeholder="" 
										defaultValue={this.state.dt.route_web} 
										onChange={e => this.handleInputChange(e, "route_web")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Route Surat</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="route_surat" 
										type="text" 
										placeholder="" 
										defaultValue={this.state.dt.route_surat} 
										onChange={e => this.handleInputChange(e, "route_surat")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Template</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="nama_template" 
										type="text" 
										placeholder="" 
										defaultValue={this.state.dt.nama_template} 
										onChange={e => this.handleInputChange(e, "nama_template")}
										required={true}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Icon Web
								</Label>
								<Col sm="9">
								{this.state.loadingIconWeb ? (
									<div className="row">
										<div className="col-sm-12" align="center">
											<img
											alt="loading"
											src={require("../../assets/images/tes-loading.gif")}
											style={{
												borderRadius: "10px",
												width: '100px'
											}} />
										</div>
									</div>
									)
								:
									(
									<Dropzone disabled={this.state.status.detail?true:false} onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'icon_web')} maxFiles={12}>
										{({ getRootProps, getInputProps }) => (
										<section>
											<div
											{...getRootProps()}
											style={{
												minHeight: '100px',
												paddingTop: '40px',
												padding: '5px 5px 5px 5px',
												border: '1px solid #d2d2d2'
											}}
											>
											<input {...getInputProps()} />
											<div
												style={{
												justifyContent: 'center',
												alignItems: 'center'
												}}
											>
												<p className="text-center">
												{this.state.dt.icon_web ? (
													<img
													src={this.state.dt.icon_web}
													style={{
														width: '470px'
													}}
													alt=""
													/>
												) : (
													<Label
													className="col-form-label text-center"
													htmlFor="inputEmail3"
													>
													Drag 'n' drop some files here, or click to select
													files
													</Label>
												)}
												{this.state.alert_icon_web ? this.state.alert_icon_web : ''}
												</p>
											</div>
											</div>
										</section>
										)}
									</Dropzone>
									)
								}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Icon Mobile
								</Label>
								<Col sm="9">
								{this.state.loadingIconMobile ? (
									<div className="row">
										<div className="col-sm-12" align="center">
											<img
											alt="loading"
											src={require("../../assets/images/tes-loading.gif")}
											style={{
												borderRadius: "10px",
												width: '100px'
											}} />
										</div>
									</div>
									)
								:
									(
									<Dropzone disabled={this.state.status.detail?true:false} onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'icon_mobile')} maxFiles={12}>
										{({ getRootProps, getInputProps }) => (
										<section>
											<div
											{...getRootProps()}
											style={{
												minHeight: '100px',
												paddingTop: '40px',
												padding: '5px 5px 5px 5px',
												border: '1px solid #d2d2d2'
											}}
											>
											<input {...getInputProps()} />
											<div
												style={{
												justifyContent: 'center',
												alignItems: 'center'
												}}
											>
												<p className="text-center">
												{this.state.dt.icon_mobile ? (
													<img
													src={this.state.dt.icon_mobile}
													style={{
														width: '470px'
													}}
													alt=""
													/>
												) : (
													<Label
													className="col-form-label text-center"
													htmlFor="inputEmail3"
													>
													Drag 'n' drop some files here, or click to select
													files
													</Label>
												)}
												{this.state.alert_icon_mobile ? this.state.alert_icon_mobile : ''}
												</p>
											</div>
											</div>
										</section>
										)}
									</Dropzone>
									)
								}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Icon KiosK
								</Label>
								<Col sm="9">
								{this.state.loadingIconKiosk ? (
									<div className="row">
										<div className="col-sm-12" align="center">
											<img
											alt="loading"
											src={require("../../assets/images/tes-loading.gif")}
											style={{
												borderRadius: "10px",
												width: '100px'
											}} />
										</div>
									</div>
									)
								:
									(
									<Dropzone disabled={this.state.status.detail?true:false} onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'icon_kiosk')} maxFiles={12}>
										{({ getRootProps, getInputProps }) => (
										<section>
											<div
											{...getRootProps()}
											style={{
												minHeight: '100px',
												paddingTop: '40px',
												padding: '5px 5px 5px 5px',
												border: '1px solid #d2d2d2'
											}}
											>
											<input {...getInputProps()} />
											<div
												style={{
												justifyContent: 'center',
												alignItems: 'center'
												}}
											>
												<p className="text-center">
												{this.state.dt.icon_kiosk ? (
													<img
													src={this.state.dt.icon_kiosk}
													style={{
														width: '470px'
													}}
													alt=""
													/>
												) : (
													<Label
													className="col-form-label text-center"
													htmlFor="inputEmail3"
													>
													Drag 'n' drop some files here, or click to select
													files
													</Label>
												)}
												{this.state.alert_icon_kiosk ? this.state.alert_icon_kiosk : ''}
												</p>
											</div>
											</div>
										</section>
										)}
									</Dropzone>
									)
								}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Keterangan</Label>
								<Col sm='9'>
									<input 
										className="form-control" 
										id="keterangan" 
										type="text" 
										placeholder="*Isi Jika Diperlukan" 
										defaultValue={this.state.dt.keterangan} 
										onChange={e => this.handleInputChange(e, "keterangan")}
										readOnly={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Layanan</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={e => { this.handleSelectChange(e,"jenis_layanan")}}
										value={this.state.dt.jenis_layanan}
										name="jenis_layanan"
										options={this.state.formDataJenisLayanan}
										placeholder="Pilih Status Jenis Layanan"
										isClearable
										isDisabled={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Generate Form</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={(e) => this.handleSelectChange(e, 'status_generate_form')}
										value={this.state.dt.status_generate_form}
										name="status_generate_form"
										options={[
											{ value: 'true', label: 'Aktif' },
											{ value: 'false', label: 'Tidak Aktif' },
										]}
										placeholder="Pilih Status Generate Form"
										isClearable
										isDisabled={this.state.status.detail?true:false}
									/>
								</Col>
							</FormGroup>

							{/* <div style={{backgroundColor:'grey',paddingTop:'15px',paddingBottom:'15px',paddingLeft:'15px',color:'white',marginBottom:'15px'}}>
								<b>Field Inputan</b>
							</div>
							<FormGroup className="row">
								<Col sm="12">
									{field_inputan}
									<button
										data-testid="btnTambahKeterangan"
										type="button"
										className="btn btn-success"
										onClick={this.tambahField}
									>
										Tambah Field Inputan
									</button>
								</Col>
							</FormGroup> */}

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							{
								this.state.status.detail?
								<></>
								:
								<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
							}
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default JenisSurat;
