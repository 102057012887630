import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin, GetWisata, PostWisata, PatchWisata } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/css/site.css';

const frmDef = {
	uuid: '',
	nama: '',
	min_version_app: '',
	new_version_app: '',
	link_playstore: '',
	link_appstore: '',
}
class KonfigurasiAppWisata extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Jenis Aplikasi",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			loading: false,

			name: '',

			dataCari: {
				q: '',
			},
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}

		Post('jenis-aplikasi' + link, null, this.state.dataCari, (lihat) => {
			if (lihat.data.result.data) {
				this.setState({ data: lihat.data.result.data, pagination: lihat.data.result });
				this.pagination(lihat.data.result);
			}
		});
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a key={i} onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}


	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let addKeldes = {
				q: e.target.value,
			};
			this.setState({
				dataCari: {
					q: e.target.value,
				},
			});

			setTimeout(
				function () {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		if (this.state.dt && this.state.dt.uuid && this.state.dt.min_version_app && this.state.dt.new_version_app && this.state.dt.link_playstore && this.state.dt.link_appstore) {

			this.setState({ loading: true })

			const bodyData = {
				// uuid: this.state.dt.uuid,
				min_version_app: this.state.dt.min_version_app,
				new_version_app: this.state.dt.new_version_app,
				link_playstore: this.state.dt.link_playstore,
				link_appstore: this.state.dt.link_appstore,
			}

			PatchWisata("pengaturan/aplikasi/update", null, bodyData, res => {
				if (res.status === 201) {
					this.setState({
						show: true,
						basicType: "success",
						basicTitle: "Data Konfig App Wisata",
						pesanAlert: "Berhasil Update Data Verifikasi",
						loadingPreview: false,
						loading: false,
						status: {
							...this.state.status,
							form: false,
							btnForm: false,
						}
					});
					// this.fetch()
					this.componentDidMount()
				} else {
					this.setState({
						show: true,
						basicType: "danger",
						basicTitle: "Data Konfig App Wisata",
						pesanAlert: "Gagal Update Data Verifikasi",
						loadingPreview: false,
						loading: false,
						status: {
							...this.state.status,
							btnForm: false,
						}
					});
				}
			})

		} else {
			this.setState({
				show: true, 
				basicType: 'warning', 
				basicTitle: 'Data Konfigurasi Aplikasi Wisata', 
				pesanAlert: 'Isi seluruh data dengan benar',
				status: {
					...this.state.status,
					btnForm: false,
				}
			});
		}


	}
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		this.bukaForm();
		this.setState({
			judul: "Ubah Jenis Aplikasi",
			status: {
				...this.state.status,
				btnAksi: false,
			},
			dt: {
				uuid: data.uuid,
				nama: data.nama,
				version_desa_app: data.version_desa_app,
				version_min_desa: data.version_min_desa,
				link_playstore: data.link_playstore,
				link_appstore: data.link_appstore,
			}
		})
		this.forceUpdate();

	}
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('jenis-aplikasi/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Jenis Aplikasi', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Jenis Aplikasi', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}
	// ========================================================================

	bukaForm = () => {
		// this.state.status.form = true;
		// this.forceUpdate();
		this.setState({
			status: {
				...this.state.status,
				form: true,
			}
		})
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	componentDidMount() {
		// this.fetch({ page: null, where: [] });

		this.setState({ loading: true })

		GetWisata('pengaturan/aplikasi', null, (lihat) => {
			// console.log(lihat);

			if (lihat.results) {
				this.setState({
					dt: {
						uuid: lihat.results.uuid,
						min_version_app: lihat.results.min_version_app,
						new_version_app: lihat.results.new_version_app,
						link_playstore: lihat.results.link_playstore,
						link_appstore: lihat.results.link_appstore,
					},
					loading: false,
				})
			}
		});
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}


	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			uuid: '',
			min_version_app: '',
			new_version_app: '',
			link_playstore: '',
			link_appstore: '',
		}

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				style: {
					width: '15px',
				},
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'nama',
				text: 'Nama Aplikasi',
				sort: true
			},
			{
				dataField: 'version_desa_app',
				text: 'Version Desa App',
				sort: true
			},
			{
				dataField: 'version_min_desa',
				text: 'Version Min Desa',
				sort: true
			},
			{
				dataField: 'link_playstore',
				text: 'Playstore',
				sort: true
			},
			{
				dataField: 'link_appstore',
				text: 'Appstore',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				style: {
					width: '120px',
				},
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'nama',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing {from} to {to} of {total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Konfigurasi Aplikasi Wisata" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-6">
											{/* <input
												type="search"
												className="form-control"
												id="cariTable"
												placeholder="Cari Jenis Aplikasi..."
												defaultValue={this.state.dataCari.q}
												onKeyPress={this.cariData}
											/> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => { this.setState({ judul: "Update Data Konfigurasi Aplikasi Wisata" }); this.bukaForm(); }}>Update Data</Button>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											{this.state.loading ? (
												<div align="center">
													<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
													<br />
													<img
														alt="loading"
														src={require("../../assets/images/tes-loading.gif")}
														style={{
															// width: "100%",
															borderRadius: "10px",
															width: '150px'
														}}
													/>
													<br /><br />
												</div>
											) : (
												<>
													<table>
														<tr>
															<td>Min Version App</td>
															<td className='text-center' style={{ width: '50px' }}>:</td>
															<td>{this.state.dt ? this.state.dt.min_version_app : '-'}</td>
														</tr>
														<tr>
															<td>New Version App</td>
															<td className='text-center' style={{ width: '50px' }}>:</td>
															<td>{this.state.dt ? this.state.dt.new_version_app : '-'}</td>
														</tr>
														<tr>
															<td>Link Playstore</td>
															<td className='text-center' style={{ width: '50px' }}>:</td>
															<td>{this.state.dt ? (
																<a href={this.state.dt.link_playstore} target="_blank">{this.state.dt.link_playstore}</a>
															) : '-'}</td>
														</tr>
														<tr>
															<td>Link Appstore</td>
															<td className='text-center' style={{ width: '50px' }}>:</td>
															<td>{this.state.dt ? (
																<a href={this.state.dt.link_appstore} target="_blank">{this.state.dt.link_appstore}</a>
															) : '-'}</td>
														</tr>
													</table>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Min Version App </Label>
								<Col sm='9'>
									<input className="form-control" id="min_version_app" type="text" placeholder="Contoh: 1.0.0" defaultValue={this.state.dt.min_version_app} value={this.state.dt.min_version_app} onChange={e => this.setState({
										dt: {
											...this.state.dt,
											min_version_app: e.target.value
										}
									})} required />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">New Version App </Label>
								<Col sm='9'>
									<input className="form-control" id="new_version_app" type="text" placeholder="Contoh: 1.0.0" defaultValue={this.state.dt.new_version_app} value={this.state.dt.new_version_app} onChange={e => this.setState({
										dt: {
											...this.state.dt,
											new_version_app: e.target.value
										}
									})} required />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link PlayStore </Label>
								<Col sm='9'>
									<input className="form-control" id="link_playstore" type="text" placeholder="Contoh: https://play.google.com/store/apps/details?id=com.solusidatamadani.digidesa" defaultValue={this.state.dt.link_playstore} value={this.state.dt.link_playstore} onChange={e => this.setState({
										dt: {
											...this.state.dt,
											link_playstore: e.target.value
										}
									})} required />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link AppStore </Label>
								<Col sm='9'>
									<input className="form-control" id="link_appstore" type="text" placeholder="Contoh: https://apps.apple.com/id/app/digidesa/id1577733972" defaultValue={this.state.dt.link_appstore} value={this.state.dt.link_appstore} onChange={e => this.setState({
										dt: {
											...this.state.dt,
											link_appstore: e.target.value
										}
									})} required />
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>
			</div>
		)
	}
}


export default KonfigurasiAppWisata;
