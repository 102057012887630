import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Progress } from 'reactstrap';
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';

// Timeline
// import { Timeline, Event } from "react-timeline-scribble";
import { Timeline, Event } from "react-timeline-scribble"


// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import classnames from 'classnames';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const frmDef = {
	uuid: '',
}

class DetailToko extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			data6: [],
			pagination: [],
			status: {
				form: false,
				formDetail: false,
				formDetail2: false,
				btnForm: false,
				btnAksi: false
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Toko",
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',

			toko: { id_toko: '', nama_toko: '' },

			// data toko
			data_toko: {
				id_toko: '',
				nama_toko: '',
				telepon_toko: '',
				alamat_toko: '',
				lat_toko: '',
				lang_toko: '',
				jam_buka_toko: '',
				jam_tutup_toko: '',
				is_active: 0,
			},

			// data detail
			data_detail: {
				id_jenis_pengiriman: '',
				jenis_pengiriman: '',
				id_jenis_pembayaran: '',
				jenis_pembayaran: '',
			},

			// data for select
			list_desa: [],
			id_desa: '',
			list_kategori_usaha: [],
			id_kategori_usaha: '',
			list_pengguna: [],
			id_pengguna: '',
			id_status_toko: null,

			//Tab Pane
			active_tab_icon: '1',

			//Detail Transaksi
			data_produk: [],

			dataDetail: {
				uuid: '',
				username: '',
				id_toko: '',
				data_toko: '',
				data_pengiriman: {
					data_jenis_pengiriman: '',
				},
				data_pembayaran: {
					data_jenis_pembayaran: '',
				},
				data_transaksi_detail: [],
				data_status_transaksi: [],
			},

			state_detail: null,
			data_detail_pembayaran: [],
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// =================================================

	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}

		// let id = this.props.match.params.postID;

		// Get('produk-toko/list', id, (lihat) => {
		// 	console.log(lihat)
		// 	if (lihat.result) {
		// 		this.setState({ data: lihat.result.data, pagination: lihat.result });
		// 		this.pagination(lihat.result);
		// 	}
		// });
	}

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}

	// Cari Data Table
	cariData = e => {
		let addProduk = {
			q: e.target.value,
		};
		// let id = this.props.match.params.postID;
		Post('produk-toko/list', null, addProduk, (data) => {
			if (data.data.result) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
	}

	detailTransaksi = (data) => {
		// console.log("TAMPIL PARSING DATANYA")
		// console.log(data)

		var id = data.id_toko
		var status_transaksinya = data.data_status_transaksi

		// console.log("ID TOKO PARSING")
		// console.log(id)

		// console.log("Status Transaksi")
		// console.log(status_transaksinya)

		this.setState({
			dataDetail: data,
			status: {
				...this.state.status,
				formDetail: true,
			}
		})

		// Get('toko/find', id, (data) => {
		// 	console.log(data)
		// 	if (data.result) {
		// 		this.state.data_toko.id_toko = id;
		// 		this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
		// 		this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
		// 		this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

		// 		this.state.data_toko.nama_toko = data.result.nama_toko;
		// 		this.state.data_toko.telepon_toko = data.result.telepon;
		// 		this.state.data_toko.alamat_toko = data.result.alamat;
		// 		this.state.data_toko.lat_toko = data.result.lat;
		// 		this.state.data_toko.lang_toko = data.result.lang;
		// 		this.state.data_toko.jam_buka_toko = data.result.jam_buka;
		// 		this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
		// 		this.state.data_toko.jenis_toko = data.result.jenis_toko;
		// 		this.state.data_toko.kategori_usaha_toko = data.result.data_kategori_usaha.kategori_usaha;
		// 		this.state.data_toko.keldes = data.result.data_keldes.nama;
		// 		this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
		// 	}
		// });

		// Get('jenis-pengiriman-toko/list', id, (lihat) => {
		// 	console.log(lihat)
		// 	if (lihat.result) {
		// 		this.setState({ data3: lihat.result.data, pagination: lihat.result });
		// 		this.pagination(lihat.result);
		// 	}
		// });

		// Get('produk-toko/list', id, (lihat) => {
		// 	console.log(lihat)
		// 	if (lihat.result) {
		// 		this.setState({ data: lihat.result.data, pagination: lihat.result });
		// 		this.pagination(lihat.result);
		// 	}
		// });

		// console.log(row.data_status_transaksi)
	};

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.api.uuid = '';
			this.state.id_desa = '';
			this.state.api.url_api = '';
		}
		this.state.status.form = true;
		this.forceUpdate();
	}



	tutupForm = () => {
		this.state.status.form = false;
		this.state.status.formDetail = false
		this.state.status.formDetail2 = false
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: "Detail Transaksi Toko",
							state_detail: row.data_status_transaksi,
							statDetail: false
						});
						this.detailTransaksi(row);
					}}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-eye"></i>
				</Button>
			</>
		)
	}

	componentDidMount() {

		// console.log("Id Transaksi")

		// console.log(this.props.location.state)
		// console.log("Jenis Pembayaran")
		// console.log(this.props.location.state.data_pembayaran.data_jenis_pembayaran.jenis_pembayaran)

		// this.setState({ data_produk: this.props.location.state, loading: false });


		// ================== detail toko =================
		// console.log(this.props.match.params.postID)

		// let id = this.props.match.params.postID;

		// Get('toko/find', id, (data) => {
		// 	console.log(data)
		// 	if (data.result) {
		// 		this.state.data_toko.id_toko = id;
		// 		this.state.id_kategori_usaha = { value: data.result.id_kategori_usaha, label: data.result.data_kategori_usaha.kategori_usaha };
		// 		this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
		// 		this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

		// 		this.state.data_toko.nama_toko = data.result.nama_toko;
		// 		this.state.data_toko.telepon_toko = data.result.telepon;
		// 		this.state.data_toko.alamat_toko = data.result.alamat;
		// 		this.state.data_toko.lat_toko = data.result.lat;
		// 		this.state.data_toko.lang_toko = data.result.lang;
		// 		this.state.data_toko.jam_buka_toko = data.result.jam_buka;
		// 		this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
		// 		this.state.data_toko.jenis_toko = data.result.jenis_toko;
		// 		this.state.data_toko.kategori_usaha_toko = data.result.data_kategori_usaha.kategori_usaha;
		// 		this.state.data_toko.keldes = data.result.data_keldes.nama;
		// 		this.state.id_status_toko = { value: data.result.is_active, label: (data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif') };
		// 	}
		// });


		//Tampil Data Jenis Pengiriman

		// Get('jenis-pengiriman-toko/list', id, (lihat) => {
		// 	console.log(lihat)
		// 	if (lihat.result) {
		// 		this.setState({ data3: lihat.result.data, pagination: lihat.result });
		// 		this.pagination(lihat.result);
		// 	}
		// });

		// ============== end detail toko =================

		this.fetch({ page: null, where: [] });

		// Data for select
		Get("kategori-usaha", null, data => {
			if (data.result) {
				let list_kategori_usaha = [];
				data.result.data.map(dt => {
					list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
				});
				this.setState({ list_kategori_usaha });
			}
		});
		Get("keldes", null, data => {
			if (data.result) {
				let list_desa = [];
				data.result.data.map(dt => {
					list_desa.push({ value: dt.id, label: dt.nama });
				});
				this.setState({ list_desa });
			}
		});
		Get("pengguna", null, data => {
			if (data.result) {
				let list_pengguna = [];
				data.result.data.map(dt => {
					list_pengguna.push({ value: dt.uuid, label: dt.username });
				});
				this.setState({ list_pengguna });
			}
		});

	}

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: { value: e.value, label: e.label } });
					break;

				case 'id_desa':
					this.setState({ id_desa: { value: e.value, label: e.label } });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: { value: e.value, label: e.label } });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: '' });
					break;

				case 'id_desa':
					this.setState({ id_desa: '' });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: '' });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: '' });
					break;
			}
		}
	}
	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
			}
			Post(table, null, find_data, (data) => {
				if (data.data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;
					}
				}
			});
		} else {
			Get(table, null, data => {
				if (data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.result.data.map(dt => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.result.data.map(dt => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.result.data.map(dt => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;

					}
				}
			});
		}
	}


	detailData = (data) => {
		this.state.status.formDetail2 = true;
		this.state.judul = "Detail Pembayaran";
		this.forceUpdate();


		// console.log("PARSING DATA DETAIL")
		// console.log(this.props.location.state.data_pembayaran.bukti_bayar)

		if (this.props.location.state.data_pembayaran.status == "Belum bayar") {
			var final_bukti = null
		}
		else {
			var bukti = this.props.location.state.data_pembayaran.bukti_bayar
			var explode_bukti = JSON.parse(bukti);
			// console.log(explode_bukti);
			var final_bukti = explode_bukti[0];
		}

		this.state.data_detail_pembayaran.bukti_pembayaran = final_bukti;
	}


	render() {
		// console.log("DATA TOKO BOSKU")
		// console.log(this.props.location.state.data_transaksi_toko)
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		// var data = this.state.data;

		var data = this.props.location.state.data_transaksi_toko;
		var data2 = this.state.dataDetail.data_transaksi_detail;
		var data3 = this.props.location.state;
		const frmDef = {
			uuid: '',
			jenis_pembayaran: '',
		}
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'kode_transaksi',
				text: 'Kode Transaksi Toko',
				sort: true
			},

			{
				dataField: 'data_toko.nama_toko',
				text: 'Nama Toko',
				sort: true
			},
			{
				dataField: 'data_toko.jenis_toko',
				text: 'Jenis Toko',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},

		];

		const columns2 = [
			{
				dataField: 'produk.nama_produk',
				text: 'Nama Produk',
				sort: true
			},
			{
				dataField: 'jumlah',
				text: 'Jumlah',
				sort: true
			},
			{
				dataField: 'harga',
				text: 'Harga',
				sort: true
			},
			{
				dataField: 'catatan',
				text: 'Catatan',
				sort: true
			},

		];

		const columns3 = [
			{
				dataField: 'data_transaksi_detail[0].id_produk',
				text: 'Id Produk',
				sort: true
			},
			{
				dataField: 'data_status_transaksi[0].status',
				text: 'Status Transaksi',
				sort: true
			},
			{
				dataField: 'data_transaksi_detail[0].created_at',
				text: 'Tanggal',
				sort: true
			},

			{
				dataField: 'data_toko.nama_toko',
				text: 'Nama Toko',
				sort: true
			},

		];

		const columns6 = [
			{
				dataField: 'Pupuk Kandang',
				text: 'Nama Produk',
				sort: true
			},
			{
				dataField: '2',
				text: 'Jumlah',
				sort: true
			},
			{
				dataField: '150.000',
				text: 'Harga',
				sort: true
			},
			{
				dataField: 'Tidak ada',
				text: 'Keterangan',
				sort: true
			},
		];


		const columns7 = [
			{
				dataField: 'data_pembayaran',
				text: 'Jenis Pembayaran',
				formatter: (cell, obj) => {
					// console.log(obj.data_jenis_pembayaran)
					// let jenis_pembayaran = obj.data_jenis_pembayaran.jenis_pembayaran;
					return 'jenis_pembayaran'
				},
				sort: true
			},
			{
				dataField: 'created_at',
				text: 'Waktu Pembayaran',
				sort: true
			},
		];

		const columns8 = [
			{
				dataField: 'Jenis_Pengiriman',
				text: 'Jenis Pengiriman',
				sort: true
			},
			{
				dataField: 'waktu_pengiriman',
				text: 'Waktu Pengiriman',
				sort: true
			},

		];



		const defaultSorted = [{
			dataField: 'jenis_pembayaran',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Detail Transaksi" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button><br /><br /><br />
										</div>
									</div>


									<div>
										<div class="row">
											<div class="col-md-3">
												<b>Kode Transaksi</b>
											</div>
											<div class="col-md-9">
												: {this.props.location.state.kode_transaksi}
											</div>

											<div class="col-md-3">
												<b>&nbsp;</b>
											</div>
											<div class="col-md-9">
												&nbsp;
											</div>

											<div class="col-md-3">
												<b>Waktu Transaksi</b>
											</div>
											<div class="col-md-9">
												: {this.props.location.state.waktu_transaksi}
											</div>

											<div class="col-md-3">
												<b>&nbsp;</b>
											</div>
											<div class="col-md-9">
												&nbsp;
											</div>

											{
												this.props.location.state.data_pembeli === null
													?
													<>
													</>
													:
													<>
														<div class="col-md-3">
															<b>Pembeli</b>
														</div>
														<div class="col-md-9">
															{': ' + this.props.location.state.data_pembeli.username}
														</div>
													</>
											}
											<div class="col-md-3">
												<b>&nbsp;</b>
											</div>
											<div class="col-md-9">
												&nbsp;
											</div>

											<div class="col-md-3">
												<b>Status Pembayaran</b>
											</div>
											<div class="col-md-9">
												: {this.props.location.state.data_pembayaran.status}
											</div>
											{
												this.props.location.state.data_pembayaran.status == "Belum bayar" || this.props.location.state.data_pembayaran.data_jenis_pembayaran.jenis_pembayaran == "Pembayaran Ditempat"
													?
													<>
													</>
													:
													<>
														<div class="col-md-3">
															<b>&nbsp;</b>
														</div>
														<div class="col-md-9">
															&nbsp;
													</div>

														<div class="col-md-3">
															<b>Total Pembayaran</b>
														</div>
														<div class="col-md-9">
															: Rp. {new Intl.NumberFormat(['ban', 'id']).format(this.props.location.state.data_pembayaran.nominal_bayar)
															}
														</div>
														<div class="col-md-3">
															<b>&nbsp;</b>
														</div>
														<div class="col-md-9">
															&nbsp;
													</div>

														<div class="col-md-3">
															<b>Bukti Pembayaran</b>
														</div>
														<div class="col-md-9">
															: <button onClick={this.detailData} color="primary" className="btn btn-success"><i className="fa fa-eye"></i>  Lihat Bukti Pembayaran</button><br /><br /><br />
														</div>
													</>
											}
										</div>

										<div><br /><br /><h2 align="center">Transaksi Toko</h2><hr /><br /><br /></div>

										<BootstrapTable
											keyField="uuid"
											data={data}
											columns={columns}
										/>


									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">

							<div class="row">
								<div class="col-md-4">
									<div class="col-md-12">
										<b>Nama Toko</b>
									</div>
									<div class="col-md-12">
										{this.state.dataDetail.data_toko.nama_toko}
									</div>

									<div class="col-md-12">
										<b>&nbsp;</b>
									</div>
									<div class="col-md-12">
										&nbsp;
									</div>

									<div class="col-md-12">
										<b>Jenis Toko</b>
									</div>
									<div class="col-md-12">
										{this.state.dataDetail.data_toko.jenis_toko}
									</div>

									<div class="col-md-12">
										<b>&nbsp;</b>
									</div>
									<div class="col-md-12">
										&nbsp;
									</div>

									<div class="col-md-12">
										<b>Jenis Pembayaran</b>
									</div>
									<div class="col-md-12">
										{this.props.location.state.data_pembayaran.data_jenis_pembayaran.jenis_pembayaran}
										{
											this.props.location.state.data_pembayaran.data_jenis_pembayaran.jenis_pembayaran == "Pembayaran Ditempat"
												?
												<>
												</>
												:
												<>
													<br />(No. rek: {this.props.location.state.data_pembayaran.data_jenis_pembayaran.nomor_rekening}, a/n {this.props.location.state.data_pembayaran.data_jenis_pembayaran.atas_nama})
												</>
										}
									</div>

									<div class="col-md-12">
										<b>&nbsp;</b>
									</div>
									<div class="col-md-12">
										&nbsp;
									</div>

									<div class="col-md-12">
										<b>Jenis Pengiriman</b>
									</div>
									<div class="col-md-12">
										{this.state.dataDetail.data_pengiriman.jenis_pengiriman}
									</div>

								</div>

								<div class="col-md-8">

									<div class="col-md-12">
										<Timeline>
											{this.state.dataDetail.data_status_transaksi.map((st, uuid) => (
												<Event style={{}} interval={st.created_at}>
													{st.status}
												</Event>
											))}
										</Timeline>
									</div>
								</div>



							</div>

							<br />

							<div><br /><br /><h4 align="left">List Produk</h4><hr /></div>
							{	/* <p>Coba Detail {this.state.dataDetail.id_toko}</p> */}
							<BootstrapTable
								keyField="uuid"
								data={data2}
								columns={columns2}
							/>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
						</div>
					</Form>
				</Modal>



				<Modal styles={{ modal: { width: "70%" } }} open={this.state.status.formDetail2} onClose={this.tutupForm} closeOnEsc={true} closeOnOverlayClick={true}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title" align="center">Bukti Pembayaran</h5>
						</div>
						<div className="modal-body">

							<div class="row">
								<div class="col-md-12">
									<div class="col-md-12" align="center">
										<img src={this.state.data_detail_pembayaran.bukti_pembayaran} alt="" />
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
						</div>
					</Form>
				</Modal>

			</div>
		)
	}
}


export default DetailToko;
