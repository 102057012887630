import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';

import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col
} from 'reactstrap';
import classnames from 'classnames';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { HariTanggal, HariTanggalJam, Tanggal } from '../../function/Format';

const frmDef = {
	uuid: '',
	jenis_pembayaran: ''
};

class Komplain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				modalDetailKomplain: false,
				modalKonfirmasi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',

			dt: frmDef,
			judul: 'Tambah Toko',

			// modal
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',

			//Tab Pane
			active_tab_icon: '1',

			// detail transaksi
			detail_komplain: {
				id_komplain: '',
				data_komplain: null,
				data_toko: null,
				data_pembeli: null,
				list_foto_komplain: []
			},

			// data for select
			list_desa: [],
			id_desa: '',
			list_kategori_usaha: [],
			id_kategori_usaha: '',
			list_pengguna: [],
			id_pengguna: '',
			id_status_toko: null,

			name: ''
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};
	// =================================================

	fetch = (params = {}) => {
		// console.log("HAHA")
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		if (params.tab == 'Proses') {
			Get('transaksi/komplain?status=Proses' + link, null, (lihat) => {
				if (lihat.result) {
					// console.log(lihat.result);
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		} else if (params.tab == 'Terima') {
			Get('transaksi/komplain?status=Terima' + link, null, (lihat) => {
				if (lihat.result) {
					// console.log(lihat.result);
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		} else if (params.tab == 'Tolak') {
			Get('transaksi/komplain?status=Tolak' + link, null, (lihat) => {
				if (lihat.result) {
					// console.log(lihat.result);
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		}
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// Cari Data Table
	cariData = (e) => {
		if (e) {
			let addToko = {
				q: e.target.value
			};
			Post('transaksi/list', null, addToko, (data) => {
				if (data.data.result) {
					this.setState({ data: data.data.result.data, pagination: data.data.result });
				}
			});
		} else {
			Get('toko', null, (lihat) => {
				if (lihat.result) {
					this.setState({ data: lihat.result.data, pagination: lihat.result });
					this.pagination(lihat.result);
				}
			});
		}
	};

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({ data: [] });
			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: 'Proses' });
					break;

				case '2':
					this.fetch({ page: null, where: [], tab: 'Terima' });
					break;

				case '3':
					this.fetch({ page: null, where: [], tab: 'Tolak' });
					break;

				default:
					this.fetch({ page: null, where: [], tab: 'Proses' });
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	simpan = (e) => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let addToko = {
			uuid: document.getElementById('uuid').value,
			id_pengguna: this.state.id_pengguna.value,
			id_kategori_usaha: this.state.id_kategori_usaha.value,
			id_desa: this.state.id_desa.value.toString(),
			nama_toko: document.getElementById('nama_toko').value,
			telepon: document.getElementById('telepon').value,
			alamat: document.getElementById('alamat').value,
			lat: document.getElementById('lat').value,
			lang: document.getElementById('lang').value,
			jam_buka: document.getElementById('jam_buka').value + ':00',
			jam_tutup: document.getElementById('jam_tutup').value + ':00',
			is_active: this.state.id_status_toko.value
		};

		let psn = '';
		let resstat = 204;
		let metode = 'create';
		if (addToko.uuid === '') {
			psn = 'Tambah';
			resstat = 200;
			addToko.uuid = null;
		} else {
			psn = 'Ubah';
			resstat = 200;
			metode = 'update';
		}

		Post('toko/' + metode, addToko.uuid, addToko, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Toko',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Toko',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};

	// handleDetail= (id) =>{
	// 	this.props.history.push(`/detail-toko/${id}`);
	// }

	goDetail = (id) => {
		this.props.history.push(`/detail-transaksi/${id}`);

		Get('toko/find', id, (data) => {
			if (data.result) {
				this.state.data_toko.id_toko = id;
				this.state.id_kategori_usaha = {
					value: data.result.id_kategori_usaha,
					label: data.result.data_kategori_usaha.kategori_usaha
				};
				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

				this.state.data_toko.nama_toko = data.result.nama_toko;
				this.state.data_toko.telepon_toko = data.result.telepon;
				this.state.data_toko.alamat_toko = data.result.alamat;
				this.state.data_toko.lat_toko = data.result.lat;
				this.state.data_toko.lang_toko = data.result.lang;
				this.state.data_toko.jam_buka_toko = data.result.jam_buka;
				this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
				this.state.id_status_toko = {
					value: data.result.is_active,
					label: data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif'
				};
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	};

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = 'Ubah Data Toko';
		this.forceUpdate();

		Get('toko/find', id, (data) => {
			if (data.result) {
				this.state.data_toko.id_toko = id;
				this.state.id_kategori_usaha = {
					value: data.result.id_kategori_usaha,
					label: data.result.data_kategori_usaha.kategori_usaha
				};
				this.state.id_desa = { value: data.result.id_desa, label: data.result.data_keldes.nama };
				this.state.id_pengguna = { value: data.result.id_pengguna, label: data.result.id_pengguna };

				this.state.data_toko.nama_toko = data.result.nama_toko;
				this.state.data_toko.telepon_toko = data.result.telepon;
				this.state.data_toko.alamat_toko = data.result.alamat;
				this.state.data_toko.lat_toko = data.result.lat;
				this.state.data_toko.lang_toko = data.result.lang;
				this.state.data_toko.jam_buka_toko = data.result.jam_buka;
				this.state.data_toko.jam_tutup_toko = data.result.jam_tutup;
				this.state.id_status_toko = {
					value: data.result.is_active,
					label: data.result.is_active == 0 ? 'Tidak aktif' : 'Aktif'
				};
			}
			this.state.status.btnAksi = false;
			this.forceUpdate();
			this.bukaForm('edit');
		});
	};

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('toko/delete', id, (res) => {
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Toko',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Toko',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};

	bukaForm = (e) => {
		if (e == 'tambah') {
			this.state.data_toko.id_toko = '';
			this.state.id_kategori_usaha = '';
			this.state.id_desa = '';
			this.state.id_pengguna = '';
			this.state.data_toko.nama_toko = '';
			this.state.data_toko.telepon_toko = '';
			this.state.data_toko.alamat_toko = '';
			this.state.data_toko.lat_toko = '';
			this.state.data_toko.lang_toko = '';
			this.state.data_toko.jam_buka_toko = '';
			this.state.data_toko.jam_tutup_toko = '';
			this.state.id_status_toko = '';
			this.state.status.form = true;
			this.forceUpdate();
		} else if (e == 'edit') {
			this.state.status.form = true;
			this.forceUpdate();
		}
	};

	tutupForm = () => {
		this.state.status.form = false;
		this.state.status.modalDetailKomplain = false;
		this.state.status.modalKonfirmasi = false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		return (
			<div>
				<Link to={{ pathname: '/detail-transaksi/', state: row }}>
					<Button size="xs" color="success" className="btn-eye">
						<i className="fa fa-pencil" />
					</Button>
				</Link>
			</div>
		);
	};

	detailKomplain = (e) => {
		// console.log(e);

		this.state.detail_komplain.data_komplain = e.data_komplain;
		this.state.detail_komplain.data_toko = e.data_toko;
		this.state.detail_komplain.data_pembeli = e.data_transaksi.data_pembeli;
		this.state.detail_komplain.list_foto_komplain = JSON.parse(e.data_komplain.foto);

		this.state.status.modalDetailKomplain = true;
		this.state.judul = 'Detail Komplain';
		this.forceUpdate();
	};

	konfirmasiKomplain = (uuid) => {
		this.state.detail_komplain.id_komplain = uuid;
		this.state.status.modalKonfirmasi = true;
		this.state.judul = 'Konfirmasi Komplain';
		this.forceUpdate();
	};

	aksiKomplain = (param) => {
		let id_komplain = this.state.detail_komplain.id_komplain;

		let addKomplain = {
			status: param,
			catatan: document.getElementById('catatan-op').value
		};

		if (addKomplain.catatan != '') {
			let resstat = 200;

			Post('transaksi/komplain/update', id_komplain, addKomplain, (res) => {
				if (res.status === resstat) {
					this.setState({
						pageNumber: 0,
						show: true,
						basicType: 'success',
						basicTitle: 'Data Produk',
						pesanAlert: 'Berhasil ' + param + ' Komplain'
					});
				} else {
					this.setState({
						pageNumber: 0,
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Produk',
						pesanAlert: 'Gagal ' + param + ' Komplain'
					});
				}
				this.componentDidMount();
			});
			this.tutupForm();
		}
	};

	componentDidMount() {
		this.fetch({ page: null, where: [], tab: 'Proses' });
	}

	// Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: { value: e.value, label: e.label } });
					break;

				case 'id_desa':
					this.setState({ id_desa: { value: e.value, label: e.label } });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: { value: e.value, label: e.label } });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: { value: e.value, label: e.label } });
					break;
			}
		} else {
			switch (data) {
				case 'id_kategori_usaha':
					this.setState({ id_kategori_usaha: '' });
					break;

				case 'id_desa':
					this.setState({ id_desa: '' });
					break;

				case 'id_pengguna':
					this.setState({ id_pengguna: '' });
					break;

				case 'status_toko':
					this.setState({ id_status_toko: '' });
					break;
			}
		}
	};
	getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
			};
			Post(table, null, find_data, (data) => {
				if (data.data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.data.result.data.map((dt) => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.data.result.data.map((dt) => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.data.result.data.map((dt) => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;
					}
				}
			});
		} else {
			Get(table, null, (data) => {
				if (data.result) {
					switch (table) {
						case 'kategori-usaha':
							let list_kategori_usaha = [];
							data.result.data.map((dt) => {
								list_kategori_usaha.push({ value: dt.uuid, label: dt.kategori_usaha });
							});
							this.setState({ list_kategori_usaha });
							break;

						case 'keldes':
							let list_desa = [];
							data.result.data.map((dt) => {
								list_desa.push({ value: dt.id, label: dt.nama });
							});
							this.setState({ list_desa });
							break;

						case 'pengguna':
							let list_pengguna = [];
							data.result.data.map((dt) => {
								list_pengguna.push({ value: dt.uuid, label: dt.username });
							});
							this.setState({ list_pengguna });
							break;
					}
				}
			});
		}
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onSubmit = (e) => {
		e.preventDefault();

		let addTransaksi = {
			q: this.state.name
		};

		// console.log("Pencariannya")
		// console.log(this.state.name)

		Post('transaksi/list', null, addTransaksi, (data) => {
			if (data.data.result.data) {
				this.setState({ data: data.data.result.data, pagination: data.data.result });
			}
		});
		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	};

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;

		const { SearchBar } = Search;

		const columns = [
			{
				dataField: 'data_transaksi.waktu_transaksi',
				text: 'Tanggal Transaksi',
				headerAlign: 'center',
				sort: true,
				align: 'center',
				formatter: (cell, row) => {
					return <div>{Tanggal(row.data_transaksi.waktu_transaksi)}</div>;
				}
			},
			{
				dataField: 'kode_transaksi',
				text: 'Kode Transaksi',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'data_toko.nama_toko',
				text: 'Nama Toko',
				headerAlign: 'center',
				align: 'center',
				sort: true
			},
			{
				dataField: 'data_transaksi.data_pembeli.username',
				text: 'Pembeli',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'data_komplain.deskripsi',
				text: 'Deskripsi Komplain',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'detail',
				text: 'Detail',
				headerAlign: 'center',
				isDummyField: true,
				sort: true,
				formatter: (cell, row) => {
					return (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								size="xs"
								color="info"
								className="btn-icon"
								onClick={() => this.detailKomplain(row)}
							>
								<i className="fa fa-eye" />
							</Button>
						</div>
					);
				}
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				isDummyField: true,
				csvExport: false,
				// formatter: this.tombolAksi
				formatter: (cell, row) => {
					return (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								size="sm"
								color="success"
								className="btn-icon"
								onClick={() => this.konfirmasiKomplain(row.data_komplain.uuid)}
							>
								{/* <i className="fa fa-pencil" /> */}
								Konfirmasi
							</Button>
						</div>
					);
				}
			}
		];

		const columns2 = [
			{
				dataField: 'data_transaksi.waktu_transaksi',
				text: 'Tanggal Transaksi',
				headerAlign: 'center',
				sort: true,
				align: 'center',
				formatter: (cell, row) => {
					return <div>{Tanggal(row.data_transaksi.waktu_transaksi)}</div>;
				}
			},
			{
				dataField: 'kode_transaksi',
				text: 'Kode Transaksi',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'data_toko.nama_toko',
				text: 'Nama Toko',
				headerAlign: 'center',
				align: 'center',
				sort: true
			},
			{
				dataField: 'data_transaksi.data_pembeli.username',
				text: 'Pembeli',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'data_komplain.deskripsi',
				text: 'Deskripsi Komplain',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'detail',
				text: 'Detail',
				headerAlign: 'center',
				isDummyField: true,
				sort: true,
				formatter: (cell, row) => {
					return (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								size="xs"
								color="info"
								className="btn-icon"
								onClick={() => this.detailKomplain(row)}
							>
								<i className="fa fa-eye" />
							</Button>
						</div>
					);
				}
			}
		];

		const defaultSorted = [
			{
				dataField: 'username',
				order: 'asc'
			}
		];

		const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>
						{page}
					</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing {from} to {to} of {total} Result
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		const listFoto = this.state.detail_komplain.list_foto_komplain.map((val, index) => {
			return (
				<div className="col-sm-4">
					<img src={val} width="100%" alt="" />
				</div>
			);
		});

		return (
			<div>
				<Breadcrumb title="Data Komplain" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-sm-12">
											<Nav tabs className="tabs-color">
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '1'
														})}
														onClick={() => {
															this.toggle_icon('1');
														}}
													>
														<i className="icofont icofont-checked" />
														Menunggu konfirmasi
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '2'
														})}
														onClick={() => {
															this.toggle_icon('2');
														}}
													>
														<i className="icofont icofont-monitor" />Diterima
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '3'
														})}
														onClick={() => {
															this.toggle_icon('3');
														}}
													>
														<i className="icofont icofont-monitor" />Ditolak
													</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: 15 }}>
														<Row>
															<div className="col-sm-12">
																<form>
																	<label>
																		<input
																			name="name"
																			value={this.state.name}
																			onChange={(e) => this.handleChange(e)}
																			style={{ marginBottom: '0px' }}
																			className="form-control"
																			placeholder="Cari data..."
																		/>
																	</label>
																</form>
															</div>
														</Row>
														<Row>
															<Col sm="12">
																<BootstrapTable
																	keyField="uuid"
																	data={data}
																	columns={columns}
																/>
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
															</Col>
														</Row>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: 15 }}>
														<Row>
															<div className="col-sm-12">
																<form>
																	<label>
																		<input
																			name="name"
																			value={this.state.name}
																			onChange={(e) => this.handleChange(e)}
																			style={{ marginBottom: '0px' }}
																			className="form-control"
																			placeholder="Cari data..."
																		/>
																	</label>
																</form>
															</div>
														</Row>
														<Row>
															<Col sm="12">
																<BootstrapTable
																	keyField="uuid"
																	data={data}
																	columns={columns2}
																/>
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
															</Col>
														</Row>
													</div>
												</TabPane>
												<TabPane tabId="3">
													<div style={{ marginTop: 15 }}>
														<Row>
															<div className="col-sm-12">
																<form>
																	<label>
																		<input
																			name="name"
																			value={this.state.name}
																			onChange={(e) => this.handleChange(e)}
																			style={{ marginBottom: '0px' }}
																			className="form-control"
																			placeholder="Cari data..."
																		/>
																	</label>
																</form>
															</div>
														</Row>
														<Row>
															<Col sm="12">
																<BootstrapTable
																	keyField="uuid"
																	data={data}
																	columns={columns2}
																/>
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
															</Col>
														</Row>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				{/* // Modal */}
				<Modal
					styles={{ modal: { width: '70%' } }}
					open={this.state.status.modalDetailKomplain}
					onClose={this.tutupForm}
					closeOnEsc={this.tutupForm}
					closeOnOverlayClick={this.tutupForm}
				>
					<div className="modal-header">
						<h4 className="modal-title">{this.state.judul}</h4>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-sm-12">
								<h5 style={{ marginBottom: '5px' }}>Detail Toko : </h5>
								{this.state.detail_komplain.data_toko != null ? (
									<table style={{ fontWeight: '500', marginBottom: '15px' }}>
										<tr>
											<td style={{ width: '150px' }}>Nama Toko</td>
											<td>:</td>
											<td>{this.state.detail_komplain.data_toko.nama_toko}</td>
										</tr>
										<tr>
											<td>Jenis Toko</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{this.state.detail_komplain.data_toko.jenis_toko}
											</td>
										</tr>
										<tr>
											<td>Jam Buka & Tutup</td>
											<td>:</td>
											<td>
												{this.state.detail_komplain.data_toko.jam_buka.substring(0, 5)} s/d {' '}
												{this.state.detail_komplain.data_toko.jam_tutup.substring(0, 5)}
											</td>
										</tr>
										<tr>
											<td>Nomor Telepon</td>
											<td>:</td>
											<td>{this.state.detail_komplain.data_toko.telepon}</td>
										</tr>
										<tr>
											<td>Alamat</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{this.state.detail_komplain.data_toko.alamat}
											</td>
										</tr>
									</table>
								) : (
									''
								)}
								<h5 style={{ marginBottom: '5px' }}>Detail Pembeli : </h5>
								{this.state.detail_komplain.data_pembeli != null ? (
									// this.state.detail_komplain.data_komplain.deskripsi
									<table style={{ fontWeight: '500', marginBottom: '15px' }}>
										<tr>
											<td style={{ width: '150px' }}>Nama Pembeli</td>
											<td>:</td>
											<td>{this.state.detail_komplain.data_pembeli.username}</td>
										</tr>
										<tr>
											<td>Email</td>
											<td>:</td>
											<td>{this.state.detail_komplain.data_pembeli.email}</td>
										</tr>
										<tr>
											<td>Nomor HP</td>
											<td>:</td>
											<td>{this.state.detail_komplain.data_pembeli.no_hp}</td>
										</tr>
										<tr>
											<td>Nomor Telepon</td>
											<td>:</td>
											<td>{this.state.detail_komplain.data_toko.telepon}</td>
										</tr>
										<tr>
											<td>Alamat</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{this.state.detail_komplain.data_toko.alamat}
											</td>
										</tr>
									</table>
								) : (
									''
								)}
								<h5 style={{ marginBottom: '5px' }}>Deskripsi Komplain : </h5>
								<p style={{ marginBottom: '5px', fontSize: '14px', fontWeight: '500' }}>
									{this.state.detail_komplain.data_komplain != null ? (
										this.state.detail_komplain.data_komplain.deskripsi
									) : (
											''
										)}
								</p>
								<h5 style={{ marginBottom: '5px' }}>Catatan : </h5>
								<p style={{ marginBottom: '5px', fontSize: '14px', fontWeight: '500' }}>
									{this.state.detail_komplain.data_komplain != null ? this.state.detail_komplain
										.data_komplain.catatan != null ? (
										this.state.detail_komplain.data_komplain.catatan
									) : (
										'Belum ada catatan'
									) : (
										''
									)}
								</p>
							</div>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							{listFoto}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							disabled={this.state.status.btnForm}
							onClick={this.tutupForm}
						>
							Tutup
						</button>
					</div>
				</Modal>
				<Modal
					styles={{ modal: { width: '70%' } }}
					open={this.state.status.modalKonfirmasi}
					onClose={this.tutupForm}
					closeOnEsc={this.tutupForm}
					closeOnOverlayClick={this.tutupForm}
				>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title" style={{ textAlign: 'center' }}>
								{this.state.judul}
							</h5>
						</div>
						<div className="modal-body">
							<div className="row">
								<div
									className="col-sm-12"
									style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
								>
									<h5 style={{ textAlign: 'center' }}>
										Apakah anda menerima komplain transaksi ini ? Isi catatan dibawah
									</h5>
								</div>
								<div className="col-sm-12">
									<input type="text" id="catatan-op" className="form-control" autoFocus required />
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="submit"
								className="btn btn-warning"
								onClick={() => this.aksiKomplain('Terima')}
							>
								Ya, terima komplain
							</button>
							<button
								type="submit"
								className="btn btn-success"
								onClick={() => this.aksiKomplain('Tolak')}
							>
								Tidak, tolak komplain
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Komplain;
