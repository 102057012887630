import React, {Component} from 'react';

class UserPanel extends Component {
    render() {
        return (
            <>
            <br/><br/>
            </>
        );
    }
}

export default UserPanel